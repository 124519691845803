// src/store/progressSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  steps: [
    {
      label: 'Step 1 : Application Form',
      time: `5 - 7 minutes`,
      isCompleted: false,
      isInprogress: true,
    },
    {
      label: 'Step 2 : Career Goals',
      time: `3 minutes`,
      isCompleted: false,
      isInprogress: false,
    },
    {
      label: 'Step 3 : Program Eligibility',
      time: `10 - 15 minutes`,
      isCompleted: false,
      isInprogress: false,
    },
    {
      label: 'Step 4 : Program Selection',
      time: `2 minutes`,
      isCompleted: false,
      isInprogress: false,
    },
  ],
};

const progressSlice = createSlice({
  name: 'progress',
  initialState,
  reducers: {
    updateStep(state, action) {
      const { stepIndex } = action.payload;

      state.steps.forEach((step, index) => {
        if (index === stepIndex) {
          // Mark the selected step as in progress and not completed
          step.isInprogress = true;
          step.isCompleted = false;
        } else if (index < stepIndex) {
          // Mark all steps before the selected one as completed and not in progress
          step.isCompleted = true;
          step.isInprogress = false;
        } else {
          // Mark all steps after the selected one as not completed and not in progress
          step.isCompleted = false;
          step.isInprogress = false;
        }
      });
    },
    resetProgress(state) {
      state.steps = initialState.steps;
    },
  },
});

export const { updateStep, resetProgress } = progressSlice.actions;
export default progressSlice.reducer;
