import React, { useState } from 'react';
import {
  Box,
  Typography,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
} from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import VerifiedIcon from '@mui/icons-material/Verified';
import { FiberManualRecord } from '@mui/icons-material';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import CustomTabPanel from '../CustomTabPanel';
import { FindLargestPowerOfTwoConst } from '../../../utils/techniqueSagaConstant';
import TopicTitle from '../TopicTitle';
import ListRenderComponent from '../ListRenderComponent';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const SubsetSum = ({
  handlePostCompletedTopic,
  hasMarkedCompleted,
  loading,
  topic_id,
  handleNext,
  handlePrevious,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='36px'
      paddingY={2}
      maxWidth='calc(100% - 300px)'
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <TopicTitle>1.Subset Sum</TopicTitle>
        {hasMarkedCompleted(topic_id) && (
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              color: 'green',
              fontWeight: '500',
              border: '2px solid green',
              padding: '4px 10px',
              borderRadius: '20px',
              cursor: 'default',
            }}
          >
            Completed
          </Typography>
        )}
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        gap='24px'
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            width: '36rem',
            background: '#FEF7FF',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab
              label='Problem Statement'
              {...a11yProps(0)}
            />
            <Tab
              label='Brute Force Approach'
              {...a11yProps(1)}
            />
            <Tab
              label='BackTracking Approach'
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel
          value={value}
          index={0}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              Given an array of positive integers nums and a target sum target,
              determine if any subset of nums can add up exactly to target. If
              such a subset exists, return True; otherwise, return False.
            </Typography>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={1}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              A straightforward way to solve this problem is to check every
              possible subset of the array and see if any subset sums up to the
              target. This approach involves generating all possible subsets and
              checking each subset's sum. Following the below steps we can
              explore every possible combination and check the sum
            </Typography>
            <Box sx={{ maxWidth: 900, marginLeft: '50px', padding: '' }}>
              <ol>
                <li>
                  <Typography variant='body1'>
                    Use recursion to explore every subset by including or
                    excluding each element.
                  </Typography>
                </li>
                <li>
                  <Typography variant='body1'>
                    Start from the first element, decide whether to include it
                    in the subset or not.
                  </Typography>
                </li>
                <li>
                  <Typography variant='body1'>
                    If at any point the subset's sum equals the target, return
                    True.
                  </Typography>
                </li>
                <li>
                  <Typography variant='body1'>
                    If we've considered all elements and still don’t reach the
                    target, return False.
                  </Typography>
                </li>
              </ol>
            </Box>

            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '800px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`function subsetSum_bf(nums, target, index):
    if target == 0:
        return True
    if index == length of nums:
        return False

    // Include nums[index] and try to find a subset with target reduced by nums[index]
    if subsetSum_bf(nums, target - nums[index], index + 1):
        return True

    // Exclude nums[index] and try to find a subset with the same target
    if subsetSum_bf(nums, target, index + 1):
        return True

    return False


`}
              </pre>
            </Box>

            <Typography sx={{ paddingLeft: '1.5rem' }}>
              <b>Time and Space Complexity</b>
              <br />
              Since this approach involves generation of every possible subset
              which could be 2^n.
              <br />
              Time Complexity :O(2^n)<br></br>
              Space Complexity : O(n) (due to recursion stack space)<br></br>
            </Typography>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={2}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              We can use backtracking with pruning to avoid exploring
              unnecessary branches in the recursion tree. Here, we only explore
              branches where the current sum plus the next element could
              potentially reach the target. If at any point the sum exceeds the
              target, we stop exploring that path.
            </Typography>
            <Box sx={{ paddingLeft: '36px' }}>
              <ol>
                <li>
                  <Typography variant='body1'>
                    Sort the array to make pruning easier. This way, we can stop
                    early if the current subset sum plus the next element
                    exceeds the target.
                  </Typography>
                </li>
                <li>
                  <Typography variant='body1'>
                    Use a recursive function to build subsets by including or
                    excluding elements.
                  </Typography>
                </li>
                <li>
                  <Typography variant='body1'>
                    If the subset sum equals the target, we stop and return{' '}
                    <span style={{ color: 'green', fontWeight: 'bold' }}>
                      True
                    </span>
                    .
                  </Typography>
                </li>
                <li>
                  <Typography variant='body1'>
                    If the sum exceeds the target or if all elements have been
                    considered without reaching the target, backtrack and try a
                    different combination.
                  </Typography>
                </li>
              </ol>
            </Box>

            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '800px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`function subsetSum_backtrack(nums, target, index, current_sum):
    if current_sum == target:
        return True
    if current_sum > target or index == length of nums:
        return False

    // Try including nums[index]
    if subsetSum_backtrack(nums, target, index + 1, current_sum + nums[index]):
        return True

    // Try excluding nums[index]
    if subsetSum_backtrack(nums, target, index + 1, current_sum):
        return True

    return False

// Initial call with current_sum = 0
sort(nums)
return subsetSum_backtrack(nums, target, 0, 0)





`}
              </pre>
            </Box>

            <Typography sx={{ paddingLeft: '1.5rem' }}>
              <b>Time and Space Complexity</b>
              <br />
              Although the time and space complexity will actually be the same,
              this approach spots paths which will never lead to an optimal
              answer early and discards them saving computation.
              <br />
              Time Complexity :O(2^n)<br></br>
              Space Complexity : O(n)<br></br>
            </Typography>
          </Box>
        </CustomTabPanel>
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        alignSelf='end'
        justifyContent='space-between'
        gap='12px'
      >
        {!hasMarkedCompleted(topic_id) && (
          <LoadingButton
            variant='contained'
            onClick={() => handlePostCompletedTopic(topic_id)}
            loading={loading}
            loadingPosition='center'
            children='Mark as completed'
            style={{
              width: '170px',
              // backgroundColor: 'transparent',
              borderRadius: '8px',
              border: '1px solid rgba(64, 96, 245, 0.5)',
            }}
          />
        )}

        <Box
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Button
            sx={{ gap: '4px' }}
            onClick={handlePrevious}
          >
            <WestIcon /> Previous
          </Button>

          <Button
            sx={{ gap: '4px' }}
            onClick={handleNext}
          >
            Next <EastIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default SubsetSum;
