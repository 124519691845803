import React, { useState } from 'react';
import {
  Box,
  Typography,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
} from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import VerifiedIcon from '@mui/icons-material/Verified';
import { FiberManualRecord } from '@mui/icons-material';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import CustomTabPanel from '../CustomTabPanel';
import { FindLargestPowerOfTwoConst } from '../../../utils/techniqueSagaConstant';
import TopicTitle from '../TopicTitle';
import ListRenderComponent from '../ListRenderComponent';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const FairDistributionOfCookies = ({
  handlePostCompletedTopic,
  hasMarkedCompleted,
  loading,
  topic_id,
  handleNext,
  handlePrevious,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='36px'
      paddingY={2}
      maxWidth='calc(100% - 300px)'
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <TopicTitle>2.Fair Distribution of Cookies</TopicTitle>
        {hasMarkedCompleted(topic_id) && (
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              color: 'green',
              fontWeight: '500',
              border: '2px solid green',
              padding: '4px 10px',
              borderRadius: '20px',
              cursor: 'default',
            }}
          >
            Completed
          </Typography>
        )}
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        gap='24px'
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            width: '36rem',
            background: '#FEF7FF',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab
              label='Problem Statement'
              {...a11yProps(0)}
            />
            <Tab
              label='Brute Force Approach'
              {...a11yProps(1)}
            />
            <Tab
              label='BackTracking Approach'
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel
          value={value}
          index={0}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              Given an array of cookies, where each element represents the
              number of cookies in a particular packet, and a specified number
              of children k, we need to distribute all the cookie packets among
              the children. The goal is to minimize the "unfairness," where
              unfairness is defined as the maximum number of cookies any single
              child receives.
            </Typography>
            <Typography>
              Example
              <br />
              If <b>cookies = [8, 15, 10, 20, 8]</b> and <b>k = 2</b> , we want
              to distribute the cookies among 2 children in such a way that the
              maximum number of cookies any one child receives is minimized. So,
              in this case the cookies can be distributed as kid 1 gets{' '}
              {'{10, 20} and kid2 gets {8, 8, 15} '}and the maximum out of this
              31 which is the minimum unfairness possible.
            </Typography>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={1}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              In a brute force approach, we consider all possible distributions
              of cookie packets among the children and calculate the unfairness
              for each distribution, choosing the configuration with the
              smallest unfairness.
            </Typography>
            <Box sx={{ maxWidth: 850, marginLeft: '50px', padding: '' }}>
              <ol>
                <li>
                  <Typography variant='body1'>
                    <b>Generate All Configurations:</b> For each packet of
                    cookies, assign it to one of the k children.
                  </Typography>
                </li>
                <li>
                  <Typography variant='body1'>
                    <b>Calculate Unfairness:</b> After each distribution,
                    calculate the unfairness by taking the maximum number of
                    cookies any child has.
                  </Typography>
                </li>
                <li>
                  <Typography variant='body1'>
                    <b>Select Minimum Unfairness:</b> Track the configuration
                    with the minimum unfairness.
                  </Typography>
                </li>
              </ol>
            </Box>

            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '800px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`function fairDistribution_bruteForce(cookies, k):
    min_unfairness = infinity
    def distribute(i, distribution):
        nonlocal min_unfairness
        if i == len(cookies):
            min_unfairness = min(min_unfairness, max(distribution))
            return
        for j in range(k):
            distribution[j] += cookies[i]
            distribute(i + 1, distribution)
            distribution[j] -= cookies[i]
    distribute(0, [0] * k)
    return min_unfairness

`}
              </pre>
            </Box>

            <Typography sx={{ paddingLeft: '1.5rem' }}>
              <b>Time and Space Complexity</b>
              <br />
              The recursion depth is equal to the number of cookies, which is
              len(cookies) or n. For each cookie, there are k choices, so the
              recursion tree will have k^n nodes. We also require an array of
              size k to count cookies apart from that the recursion stack space.
              <br />
              Time Complexity : O(k^n)<br></br>
              Space Complexity : O(n+k)<br></br>
            </Typography>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={2}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              In the backtracking approach with pruning, we explore only the
              configurations that have the potential to lead to a solution with
              minimal unfairness. By avoiding unnecessary configurations early,
              we significantly reduce the search space.
            </Typography>
            <Box sx={{ paddingLeft: '36px' }}>
              <ol>
                <li>
                  <Typography variant='body1'>
                    <b>Distribute Cookies Recursively:</b> Similar to the brute
                    force, we assign each cookie packet to a child. However, we
                    add checks to prune unpromising paths.
                  </Typography>
                </li>
                <li>
                  <Typography variant='body1'>
                    <b>Pruning with Unfairness Bound:</b> If the current
                    distribution’s maximum cookies for any child exceeds the
                    best unfairness found so far, we backtrack early and don’t
                    explore this distribution further.
                  </Typography>
                </li>
                <li>
                  <Typography variant='body1'>
                    <b>Skip Empty Slots:</b> If a child has no cookies, try
                    assigning a packet to this child first before others to
                    reduce redundant computations.
                  </Typography>
                </li>
              </ol>
            </Box>

            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '800px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`function fairDistribution_backtracking(cookies, k):
    min_unfairness = infinity
    def distribute(i, distribution):
        nonlocal min_unfairness
        if i == len(cookies):
            min_unfairness = min(min_unfairness, max(distribution))
            return
        for j in range(k):
            if distribution[j] + cookies[i] >= min_unfairness:
                continue  # Prune paths exceeding current min_unfairness
            distribution[j] += cookies[i]
            distribute(i + 1, distribution)
            distribution[j] -= cookies[i]
            if distribution[j] == 0:
                break  # Skip further empty slots for the same cookie
    distribute(0, [0] * k)
    return min_unfairness




`}
              </pre>
            </Box>

            <Typography sx={{ paddingLeft: '1.5rem' }}>
              <b>Time and Space Complexity</b>
              <br />
              Time Complexity: O(k^n), but pruning can reduce the constant
              factor and improve performance in practice.<br></br>
              Space Complexity: O(n + k)<br></br>
            </Typography>
          </Box>
        </CustomTabPanel>
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        alignSelf='end'
        justifyContent='space-between'
        gap='12px'
      >
        {!hasMarkedCompleted(topic_id) && (
          <LoadingButton
            variant='contained'
            onClick={() => handlePostCompletedTopic(topic_id)}
            loading={loading}
            loadingPosition='center'
            children='Mark as completed'
            style={{
              width: '170px',
              // backgroundColor: 'transparent',
              borderRadius: '8px',
              border: '1px solid rgba(64, 96, 245, 0.5)',
            }}
          />
        )}

        <Box
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Button
            sx={{ gap: '4px' }}
            onClick={handlePrevious}
          >
            <WestIcon /> Previous
          </Button>

          <Button
            sx={{ gap: '4px' }}
            onClick={handleNext}
          >
            Next <EastIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default FairDistributionOfCookies;
