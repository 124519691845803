import React, { useState } from 'react';
import {
  Box,
  Typography,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
} from '@material-ui/core';
import lcs from '../../../../assets/lcs.png';
import LoadingButton from '@mui/lab/LoadingButton';
import VerifiedIcon from '@mui/icons-material/Verified';
import { FiberManualRecord } from '@mui/icons-material';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import CustomTabPanel from '../CustomTabPanel';
import { FindLargestPowerOfTwoConst } from '../../../utils/techniqueSagaConstant';
import TopicTitle from '../TopicTitle';
import ListRenderComponent from '../ListRenderComponent';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const LongestConsecutiveSequence = ({
  handlePostCompletedTopic,
  hasMarkedCompleted,
  loading,
  topic_id,
  handleNext,
  handlePrevious,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='36px'
      paddingY={2}
      maxWidth='calc(100% - 300px)'
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <TopicTitle>2.Longest Consecutive Sequence</TopicTitle>
        {hasMarkedCompleted(topic_id) && (
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              color: 'green',
              fontWeight: '500',
              border: '2px solid green',
              padding: '4px 10px',
              borderRadius: '20px',
              cursor: 'default',
            }}
          >
            Completed
          </Typography>
        )}
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        gap='24px'
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            width: '36rem',
            background: '#FEF7FF',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab
              label='Problem Statement'
              {...a11yProps(0)}
            />
            <Tab
              label='Brute Force Approach'
              {...a11yProps(1)}
            />
            <Tab
              label='Optimized Approach'
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel
          value={value}
          index={0}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              Given an unsorted array of integers, find the length of the
              longest consecutive elements sequence. For example arr = [0, 3, 2,
              6, 8, 4, 1] here the sequence [0, 1, 2, 3. 4] is a consecutive
              sequence and is the longest one thus the answer would be 5.
            </Typography>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={1}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              For a brute-force approach, we can attempt to build sequences for
              each element:
              <br />
              <br />
              1. For each element, check if it is the start of a sequence (i.e.,
              there’s no number smaller than it that is also in the array).
              <br />
              2. For each start element, count the length of the consecutive
              sequence.
            </Typography>

            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '800px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`function longestConsecutive_bf(nums):
    maxLength = 0

    for num in nums:
        currentNum = num
        currentStreak = 1

        // Check if currentNum is the start of a sequence
        while currentNum + 1 is in nums:
            currentNum += 1
            currentStreak += 1

        maxLength = max(maxLength, currentStreak)

    return maxLength
`}
              </pre>
            </Box>

            <Typography sx={{ paddingLeft: '1.5rem' }}>
              <b>Time and Space Complexity</b>
              <br />
              For each element we are trying to find the longest consecutive
              sequence possible from there, thus we would require two nested
              loops
              <br />
              <br />
              Time Complexity : O(n^2)
              <br></br>
              Space Complexity : O(1)
              <br></br>
            </Typography>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={2}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              The idea is to use a map to track the length of consecutive
              sequences for each number. For each number, the algorithm checks
              the length of the sequence it belongs to by looking at the
              sequences before and after it. It then merges those sequences, if
              necessary, and updates the map accordingly. The map efficiently
              stores the length of the sequence for each number, ensuring that
              we avoid redundant checks. The result is the length of the longest
              consecutive sequence found.
              <br />
              <br />
              Let us consider the example {'{'}100, 4, 200, 3, 2, 1{'}'}
              <br />
              Then, with each iteration our current streak, the left and the
              right streak will look as follows:
            </Typography>

            <Box
              sx={{
                // padding: '24px',
                borderRadius: '10px',
                width: '800px',
              }}
            >
              <img
                src={lcs}
                alt='Descriptive Alt Text'
                style={{ width: '100%', borderRadius: '10px' }}
              />
            </Box>
            <Typography>
              So, for each element we will check the longest streak to its left
              and right participant. The current mapping for each will be
              current +left+right. Eventually we will find the longest sequence
              by keeping track of it along the way.
              <br />
              <br />
              Below is the implementation for it
            </Typography>
            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '800px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`function longestConsecutive(nums):
    numMap = new map // Map to store each number and its sequence length
    longestStreak = 0 
    // Variable to track the longest consecutive sequence length

    for num in nums:
        if num not in numMap: 
        // Process only if num is not already part of a sequence

            leftStreak = numMap.get(num - 1, 0)   
            // Length of the sequence ending at num - 1
            rightStreak = numMap.get(num + 1, 0)  
            // Length of the sequence starting at num + 1

            currentStreak = 1 + leftStreak + rightStreak   
            // Total length of current sequence

            longestStreak = max(longestStreak, currentStreak)   
            // Update longestStreak if needed

            // Update the boundaries of the sequence in the map
            numMap[num] = currentStreak
            numMap[num - leftStreak] = currentStreak    
            // Update the left boundary
            numMap[num + rightStreak] = currentStreak   
            // Update the right boundary

    return longestStreak

`}
              </pre>
            </Box>
            <Typography sx={{ paddingLeft: '1.5rem' }}>
              <b>Time and Space Complexity</b>
              <br />
              Each element of the array is visited just once and we are storing
              the result that we get for that in a hashmap resulting in an
              efficient algorithm.
              <br></br>
              Time Complexity : O(n)
              <br />
              Space Complexity : O(n)
              <br></br>
            </Typography>
          </Box>
        </CustomTabPanel>
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        alignSelf='end'
        justifyContent='space-between'
        gap='12px'
      >
        {!hasMarkedCompleted(topic_id) && (
          <LoadingButton
            variant='contained'
            onClick={() => handlePostCompletedTopic(topic_id)}
            loading={loading}
            loadingPosition='center'
            children='Mark as completed'
            style={{
              width: '170px',
              // backgroundColor: 'transparent',
              borderRadius: '8px',
              border: '1px solid rgba(64, 96, 245, 0.5)',
            }}
          />
        )}

        <Box
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Button
            sx={{ gap: '4px' }}
            onClick={handlePrevious}
          >
            <WestIcon /> Previous
          </Button>

          <Button
            sx={{ gap: '4px' }}
            onClick={handleNext}
          >
            Next <EastIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default LongestConsecutiveSequence;
