// @ts-nocheck
// material
import { Box, Button, Stack, Typography, IconButton } from '@material-ui/core';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

// components
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { postTechniqueSagaCompleted, getHeaders } from '../apis/urls';
import { mechanismSortingTopics } from '../components/utils/techniqueSagaTopics';
import { ReactComponent as TickIcon } from '../images/tick_icon.svg';
import Introduction from 'src/components/TechniqueSaga/Sorting/Introduction';
import ErrorNotifier from '../components/ToastNotifications/ErrorNotifier';
import SuccessNotifier from '../components/ToastNotifications/SuccessNotifier';
import { shouldShowNewStudentLayout } from 'src/components/utils/common';
import LargestNumber from 'src/components/TechniqueSaga/Sorting/Mechanism/LargestNumber';
import MeetingRooms from 'src/components/TechniqueSaga/Sorting/Mechanism/MeetingRooms';
import SortColors from 'src/components/TechniqueSaga/Sorting/Mechanism/SortColors';
import { areAllTopicsCompleted } from 'src/components/TechniqueSaga/TechniqueSagaUtil';

// ----------------------------------------------------------------------

export default function Sorting({
  setSorting,
  completedTopics,
  setCompletedTopics,
  lastCompletedTopic = 1,
}) {
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const [loading, setLoading] = useState(false);
  const [activeComponent, setActiveComponent] = useState(lastCompletedTopic);
  const [errorMessage, setErrorMessage] = useState('');
  const [hasError, setHasError] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [showNewLayout, setShowNewLayout] = useState(null);
  useEffect(() => {
    const newLayoutFlag = shouldShowNewStudentLayout(currentProgram.programId);
    setShowNewLayout(newLayoutFlag);
  }, [currentProgram]);

  const setToastNotification = (type, message) => {
    if (type === 'error') {
      setHasError(true);
      setErrorMessage(message);
    } else {
      setIsSuccess(true);
      setSuccessMessage(message);
    }
  };

  const handlePostCompletedTopic = async (id) => {
    const body = { topic_id: id };
    const allTopicsCompleted = areAllTopicsCompleted(
      mechanismSortingTopics,
      completedTopics,
      id
    );

    if (allTopicsCompleted) {
      body.technique_type = 'technique_activities';
      body.technique_id = 6;
    }
    setLoading(true);
    try {
      const response = await axios.post(postTechniqueSagaCompleted(), body, {
        headers: getHeaders(currentProgram.programId),
      });

      if (response) {
        setCompletedTopics([...completedTopics, id]);
        setToastNotification('success', 'Topic completed successfully');
        setIsSuccess(true);
      } else {
        console.error('Failed to mark content as completed');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error marking content as completed:', error);
      setToastNotification(
        'error',
        'An error occured while creating the test.'
      );
      setHasError(true);
    } finally {
      setLoading(false);
    }
  };

  const hasMarkedCompleted = (problemId) => {
    const isProblemCompleted = completedTopics?.includes(problemId) ?? false;
    return isProblemCompleted;
  };

  const flattenTopics = mechanismSortingTopics?.flatMap(
    (group) => group.topics
  );

  const handleNext = () => {
    setActiveComponent((prevIndex) => {
      const currentIndex = flattenTopics.findIndex(
        (topic) => topic.id === prevIndex
      );

      const nextIndex = currentIndex + 1;

      return nextIndex < flattenTopics.length
        ? flattenTopics[nextIndex].id
        : prevIndex;
    });
  };

  const handlePrevious = () => {
    setActiveComponent((prevIndex) => {
      const previousIndex = prevIndex - 1;
      return previousIndex >= 0 ? previousIndex : prevIndex;
    });
  };

  const handleClick = (componentName) => {
    setActiveComponent(componentName);
  };

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case 26:
        return (
          <Introduction
            handlePostCompletedTopic={handlePostCompletedTopic}
            hasMarkedCompleted={hasMarkedCompleted}
            loading={loading}
            topic_id={26}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
          />
        );
      case 27:
        return (
          <SortColors
            handlePostCompletedTopic={handlePostCompletedTopic}
            hasMarkedCompleted={hasMarkedCompleted}
            loading={loading}
            topic_id={27}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
          />
        );
      case 28:
        return (
          <MeetingRooms
            handlePostCompletedTopic={handlePostCompletedTopic}
            hasMarkedCompleted={hasMarkedCompleted}
            loading={loading}
            topic_id={28}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
          />
        );
      case 29:
        return (
          <LargestNumber
            handlePostCompletedTopic={handlePostCompletedTopic}
            hasMarkedCompleted={hasMarkedCompleted}
            loading={loading}
            topic_id={29}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
          />
        );
      default:
        return (
          <Introduction
            handlePostCompletedTopic={handlePostCompletedTopic}
            hasMarkedCompleted={hasMarkedCompleted}
            loading={loading}
            topic_id={26}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
          />
        );
    }
  };

  const checkIcon = (iconStatus) => {
    return (
      <IconButton style={{ padding: '0' }}>
        <div
          style={{
            width: '14px',
            height: '14px',
            borderRadius: '50%',
            background: iconStatus ? 'green' : '#f0f0f0',
            position: 'relative',
          }}
        >
          {iconStatus && (
            <TickIcon
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fill: 'white',
                width: '10px',
                height: '10px',
              }}
            />
          )}
        </div>
      </IconButton>
    );
  };

  return (
    <div style={{ overflow: 'auto' }}>
      <Stack
        spacing={2}
        display='flex'
        position='relative'
      >
        {hasError && (
          <ErrorNotifier
            {...{
              message: errorMessage,
              setHasError,
            }}
          />
        )}
        {isSuccess && (
          <SuccessNotifier
            {...{
              message: successMessage,
              setIsSuccess,
            }}
          />
        )}
        <Box
          position='relative'
          width='calc(100% - 40px)'
        >
          <Box marginBottom={2}>
            <Stack
              direction='row'
              spacing={2}
            >
              <Typography
                sx={{
                  cursor: 'pointer',
                  color: '#005db9',
                }}
                onClick={() => setSorting(false)}
              >
                Technique Saga &gt;
              </Typography>
              <Typography>Technique 6: Sorting</Typography>
            </Stack>
          </Box>

          <Box>{renderActiveComponent()}</Box>
        </Box>

        {/* Side Navbar */}
        <Box
          display='flex'
          flexDirection='column'
          bgcolor='white'
          width={showNewLayout ? '310px' : '330px'}
          height={showNewLayout ? '70vh' : '100vh'}
          paddingX={1}
          position='fixed'
          right={showNewLayout ? '1.5%' : '0'}
          borderLeft='1px solid rgb(195 202 208)'
          marginTop='0 !important'
        >
          <Typography
            fontSize='16px'
            lineHeight='20px'
            fontWeight='500'
            color='#49454F'
            paddingX={1}
            mb={1}
          >
            Technique 6: Sorting
          </Typography>

          <div
            style={{
              margin: '8px auto',
              width: '92%',
              border: '1px solid rgb(195 202 208)',
            }}
          />

          {mechanismSortingTopics?.map((topicsGroup, index) => (
            <div key={index}>
              <Box>
                <Typography
                  fontSize='16px'
                  lineHeight='20px'
                  fontWeight='500'
                  color='#908e91'
                  p={1}
                >
                  {topicsGroup.title}
                </Typography>
                {topicsGroup.topics.map((topic, index) => (
                  <div key={index}>
                    <Typography
                      onClick={() => handleClick(topic.id)}
                      fontSize='16px'
                      lineHeight='20px'
                      fontWeight='500'
                      color={
                        activeComponent === topic.id ? '#1D192B' : '#49454F'
                      }
                      bgcolor={
                        activeComponent === topic.id ? '#E5F5FD' : 'transparent'
                      }
                      borderRadius={2}
                      p={1}
                      width='100%'
                      sx={{
                        cursor: 'pointer',
                        display: 'inline-flex',
                        gap: '10px',
                      }}
                    >
                      {checkIcon(completedTopics.includes(topic.id))}
                      {topic.name}
                    </Typography>
                  </div>
                ))}
              </Box>

              {!(index === 1) && (
                <div
                  style={{
                    margin: '8px auto',
                    width: '92%',
                    border: '1px solid rgb(195 202 208)',
                  }}
                />
              )}
            </div>
          ))}
        </Box>
      </Stack>
    </div>
  );
}
