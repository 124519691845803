import { Stack } from '@material-ui/core';
import { NoteAlt } from '@mui/icons-material';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import TaskIcon from '@mui/icons-material/Task';
import TimelineIcon from '@mui/icons-material/Timeline';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import Performance from '../performance/Performance';
import PreCourse from '../PreCourse/PreCourse';
import Practice from '../practice/Practice';
import { Sessions } from '../sessions/Sessions';
import SessionAPI from '../sessions/SessionContext';
import Overview from '../Overview/Overview';
import Progress from '../performance/Progress/Progress';
import { Tasks } from '../performance/Tasks/Tasks';
import { Assignments } from '../performance/Assignment/Assignments';
import CodingAssignments from '../performance/CodingAssignments/CodingAssignments';
import Mindmap from '../practice/Mindmap/Mindmap';
import LearnerLevelTests from '../practice/LevelTests/LearnerLevelTests';
import LevelTestAttempts from '../practice/LevelTests/LevelTestAttempts';
import DSAVisualization from '../practice/DSAVisualization/DSAVisualization';
import SessionTests from '../practice/SessionTests/SessionTests';
import TeachingStaff from '../Overview/TeachingStaff';
import ResourcesTab from '../PreCourse/ResourcesTab';
// import LearnersPlacementsMainPage from '../PlacementsTabForLearners/LearnersPlacementsMainPage';
import GrowthChart from '../performance/GrowthChart/GrowthChart';
import Mindmaps from '../practice/MindMaps/Pages/Mindmaps';
import DSAVisualizer from '../practice/DSAVisualizer/DSAVisualizer';
import TGPMainScreen from '../TheGreatePyramid/TGPMainScreen';
import TechniqueSaga from '../../pages/TechniqueSaga';
import LanguageBible from '../PreCourse/LanguageBible';
import { NewSessions } from '../sessionsNew/NewSessions';
import OnboardingGettingStarted from '../../layouts/onboardingDashboard/OnboardingGettingStarted';
import { Children } from 'react';
import NewTechnique from './NewTechnique';

export const topBarContents = [
  {
    label: 'Overview',
    component: <Overview />,
    key: 'overview',
    hideFromRoles: [],
    hideFromProgramTypes: [],
    showCondition: true,
    children: [],
  },
  {
    label: 'Pre Course',
    component: <PreCourse />,
    key: 'pre-course',
    hideFromRoles: [],
    hideFromProgramTypes: [],
    children: [
      {
        label: 'Resources',
        key: 'resources',
        Icon: NoteAlt,
        isFor: 'student',
        component: <ResourcesTab />,
      },
      {
        label: 'Language Bible',
        key: 'language-bible',
        Icon: NoteAlt,
        isFor: 'student',
        component: <LanguageBible />,
      },
      {
        label: 'Foundation Questions',
        key: 'foundation-questions',
        Icon: NoteAlt,
        isFor: 'student',
        component: <CodingAssignments category='pre_course' />,
      },
    ],
  },
  {
    label: 'Signature Frameworks',
    key: 'signature-frameworks',
    // component: <Practice />,
    hideFromRoles: [],
    hideFromProgramTypes: [],
    children: [
      {
        label: 'Mindmap',
        key: 'mindmap',
        Icon: NoteAlt,
        isFor: 'both',
        component: <Mindmaps />,
      },
      {
        label: 'The Great Pyramid',
        key: 'the-great-pyramid',
        isFor: 'student',
        component: <TGPMainScreen />,
      },
      {
        label: 'DSA Visualizer',
        key: 'dsa-visualization',
        Icon: AccountTreeIcon,
        isFor: 'both',
        component: <DSAVisualizer />,
      },
      {
        label: 'Session Tests',
        key: 'session-tests',
        Icon: WysiwygIcon,
        isFor: 'student',
        component: <SessionTests />,
      },
      {
        label: 'Technique Saga',
        key: 'Technique-saga',
        Icon: WysiwygIcon,
        isFor: 'student',
        component: <NewTechnique />,
      },
    ],
  },
  {
    label: 'Sessions',
    component: (
      <SessionAPI>
        <Sessions />
      </SessionAPI>
    ),
    key: 'sessions',
    hideFromRoles: [],
    hideFromProgramTypes: [],
    children: [],
  },
  {
    label: 'Performance',
    key: 'performance',
    hideFromRoles: [],
    hideFromProgramTypes: [],
    children: [
      {
        label: 'Growth Graph',
        key: 'growth',
        Icon: TimelineIcon,
        isFor: 'student',
        component: <GrowthChart />,
      },
      {
        label: 'Assignments',
        key: 'assignments',
        Icon: NoteAlt,
        isFor: 'both',
        component: <Assignments />,
      },
      {
        label: 'Coding Assignments',
        key: 'coding-assignments',
        Icon: NoteAlt,
        isFor: 'student',
        component: <CodingAssignments />,
      },
    ],
  },
  // {
  //   label: 'Placements',
  //   component: <LearnersPlacementsMainPage />,
  //   key: 'placements',
  //   hideFromRoles: [],
  //   hideFromProgramTypes: [],
  //   showCondition: true,
  //   children: [],
  // },
];
export const onboardingTopBarContent = [
  {
    label: 'Signature Frameworks',
    key: 'signature-frameworks',
    children: [],
  },
  {
    label: 'Unlock Live Sessions',
    key: 'unlock-live-sessions',
    component: <OnboardingGettingStarted />,
    children: [],
  },
];
