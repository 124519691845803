import React from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
} from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import { FiberManualRecord } from '@mui/icons-material';
import EastIcon from '@mui/icons-material/East';
import ParagraphBox from './ParagraphBox';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import image1 from '../../../assets/image1queue.png';
import image2 from '../../../assets/image2queue.png';

const Introduction = ({
  handlePostCompletedTopic,
  hasMarkedCompleted,
  loading,
  topic_id,
  handleNext,
}) => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='20px'
      paddingY={2}
      maxWidth='calc(100% - 300px)'
    >
      <Box>
        <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
          TECHNIQUE 9
        </Typography>

        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
        >
          <Typography
            sx={{
              fontSize: '48px',
              fontWeight: 600,
              lineHeight: '58px',
              letterSpacing: '0.01em',
              textAlign: 'left',
            }}
          >
            Queues
          </Typography>
          {hasMarkedCompleted(topic_id) && (
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
                color: 'green',
                fontWeight: '500',
                border: '2px solid green',
                padding: '4px 10px',
                borderRadius: '20px',
                cursor: 'default',
              }}
            >
              Completed
            </Typography>
          )}
        </Box>
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        gap='24px'
      >
        <Typography sx={{ fontSize: '22px', fontWeight: 400 }}>
          Introduction to Queues
        </Typography>

        <ParagraphBox>
          <Typography>
            Imagine waiting in line at a coffee shop: the first person to arrive
            is the first one to be served. This is the core idea of a queue:
            First-In-First-Out (FIFO). In a queue, items are added at the back
            and removed from the front, maintaining the order in which they were
            added. This principle is used in various real-world scenarios, such
            as in customer service lines, ticketing systems, or even print job
            management.
          </Typography>
          <Typography>
            In programming, a queue behaves the same way. When you add an item,
            it goes to the back of the queue, and when you remove an item, it’s
            always the one at the front. This ensures that tasks are processed
            in the order they arrive, without skipping over any.
          </Typography>
          <Typography>
            Queues are extremely useful in managing tasks that need to be
            processed sequentially, like handling requests in a web server, or
            simulating real-time processes where things need to happen in the
            order they arrive.
          </Typography>
          <Typography>
            The key operations of a queue are straightforward:
          </Typography>
        </ParagraphBox>

        <ParagraphBox>
          <div style={{ fontFamily: 'Arial, sans-serif', fontSize: '26px' }}>
            <ul
              style={{
                listStyleType: 'disc',
                marginLeft: '20px',
                fontSize: '16px',
              }}
            >
              <li>
                <p>
                  <strong>Enqueue: </strong>Add an item to the back of the
                  queue.
                </p>
              </li>
              <li>
                <p>
                  <strong>Dequeue: </strong>Remove an item from the front of the
                  queue.
                </p>
              </li>
            </ul>
          </div>

          <Typography>
            Queues are particularly helpful when you need to manage tasks in an
            orderly manner or maintain an efficient system for handling multiple
            items.
          </Typography>
          <Typography sx={{ fontSize: '20px', fontWeight: 400 }}>
            What is a Queue?
          </Typography>
          <Typography>
            A queue is a data structure that operates on a First-In-First-Out
            (FIFO) basis. The key concept of a queue is that the first item
            added to the queue is the first item to be removed. Think of it as a
            line at a grocery store: the first customer in line is the first one
            to check out, and customers are processed one by one as they arrive
            at the front.
          </Typography>
          <Typography>
            In computer science, queues are often used for managing data that
            needs to be processed in the order it is received, making them
            essential for tasks like:
          </Typography>

          <div style={{ fontFamily: 'Arial, sans-serif', fontSize: '26px' }}>
            <ul
              style={{
                listStyleType: 'disc',
                marginLeft: '20px',
                fontSize: '16px',
              }}
            >
              <li>
                <p>Task scheduling</p>
              </li>
              <li>
                <p>Buffering data streams</p>
              </li>
              <li>
                <p>Managing asynchronous processes</p>
              </li>
            </ul>
          </div>

          <Typography sx={{ fontSize: '20px', fontWeight: 400 }}>
            Queues are built with a few basic operations:
          </Typography>
          <div style={{ fontFamily: 'Arial, sans-serif', fontSize: '26px' }}>
            <ul
              style={{
                listStyleType: 'disc',
                marginLeft: '20px',
                fontSize: '16px',
              }}
            >
              <li>
                <p>
                  <strong>Enqueue: </strong>Add an item to the back of the
                  queue.
                </p>
              </li>
              <li>
                <p>
                  <strong>Dequeue: </strong>Remove an item from the front of the
                  queue.
                </p>
              </li>
              <li>
                <p>
                  <strong>Peek: </strong>Views the front item without removing
                  it.
                </p>
              </li>
              <li>
                <p>
                  <strong>IsEmpty: </strong>Checks if the queue is empty.
                </p>
              </li>
            </ul>
          </div>

          <Typography>
            The FIFO structure ensures that items are processed in the same
            order they were added, which makes queues indispensable in many
            real-world applications like event-driven programming or managing
            resources in a multi-tasking environment.
          </Typography>
          <Typography>
            Below is a simple implementation of queue using arrays
          </Typography>

          <Box
            sx={{
              backgroundColor: 'black',
              // padding: '24px',
              borderRadius: '10px',
              width: '803px',
              paddingLeft: '1.5em',
              marginLeft: '2em',
            }}
          >
            <pre style={{ color: 'white', margin: 0 }}>
              {`

Class Queue:
    Initialize an empty list items
    
    Method enqueue(item):
        Add item to the end of items list (back of the queue)
        Print "Enqueued item to the queue"

    Method dequeue():
        If is_empty() is False:
            Remove the first item from items list (front of the queue)
            Print "Dequeued item from the queue"
            Return the removed item
        Else:
            Print "Queue is empty. Nothing to dequeue."
            Return None

    Method peek():
        If is_empty() is False:
            Print "Front element is items[first element]"
            Return the first element in items list
        Else:
            Print "Queue is empty. Nothing to peek."
            Return None

    Method is_empty():
        Return True if items list has no elements
        Return False otherwise

    Method size():
        Print "Queue size is length of items list"
        Return length of items list
`}
            </pre>
          </Box>

          <Typography>
            The queue data structure contains all the important methods and if
            you analyze carefully all of these are operations that can be done
            in constant time O(1) making queue operations incredibly efficient.
            There are also inbuilt structure and methods in various programming
            languages.
          </Typography>
        </ParagraphBox>
      </Box>
      <Typography sx={{ fontSize: '20px', fontWeight: 400 }}>
        Example Usage
      </Typography>
      <ParagraphBox>
        <Typography>
          Consider a simple example where you are given a queue and you want to
          reverse the first k elements of it. Let us see how we can do this
          using what we know about queues.
        </Typography>
        <Typography>
          Consider the example : [1, 2, 3, 4, 5] and k=3, then the output should
          be [3,2,1,4,5]
        </Typography>

        <Typography>
          So the idea to do this would be to take out the first k elements,
          store it somewhere and store it back in the reverse order. For that we
          could use an array or even a stack.
        </Typography>
        <img
          src={image1}
          alt=''
          style={{ height: '450px', width: '700px' }}
        />
        <Typography>
          After that you can put the elements back to the queue and deque the
          remaining elements that should be at the last and then put it back
          again.
        </Typography>
        <img
          src={image2}
          alt=''
          style={{ height: '450px', width: '700px' }}
        />
        <Box
          sx={{
            backgroundColor: 'black',
            // padding: '24px',
            borderRadius: '10px',
            width: '803px',
            paddingLeft: '1.5em',
          }}
        >
          <pre style={{ color: 'white', margin: 0 }}>
            {`
Function reverse_k_elements(queue, K):
If K > 0:
    Initialize stack as an empty stack
    
    // Step 1: Dequeue the first K elements and push them onto the stack
    For i from 1 to K:
        element = queue.dequeue()
        stack.push(element)
    
    // Step 2: Pop from stack and enqueue back to the queue to reverse the order
    While stack is not empty:
        queue.enqueue(stack.pop())

    // Step 3: Move the remaining elements (size - K) to the back of the queue
        remaining_elements = size of queue - K
        For i from 1 to remaining_elements:
            queue.enqueue(queue.dequeue())
    
    Return the modified queue
`}
          </pre>
        </Box>

        <Typography>
          We are only dequeuing and enqueuing each element a constant number of
          times (3 times: dequeue, push, pop, and enqueue), where n is the size
          of the queue, thus we were able to do this in linear time complexity
          along with linear space complexity
        </Typography>

        <Typography sx={{ fontSize: '20px', fontWeight: 400 }}>
          Double Ended Queue
        </Typography>

        <Typography>
          Imagine a line where people can join or leave from either end, like a
          group of friends playing a game where the starting player can switch
          at both ends of the circle. This is the concept of a deque: a
          Double-Ended Queue.
        </Typography>

        <Typography>
          A deque allows you to insert and remove elements from both the front
          and the back, providing greater flexibility than a standard queue.
          This makes it a hybrid of a queue and a stack, offering both FIFO and
          LIFO behaviors depending on how you use it.
        </Typography>

        <Typography>
          In programming, deques are used in situations where items need to be
          added or removed from both ends, like in sliding window problems,
          scheduling processes, or caching mechanisms (e.g., implementing LRU
          Cache).
        </Typography>
        <Typography>
          A <strong>deque</strong> is a data structure that supports operations
          at both ends. Unlike a queue, which only allows adding at the back and
          removing from the front, a deque allows adding and removing items at{' '}
          <strong>both the front and back.</strong>
        </Typography>

        <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>
          Operations in a Deque:
        </Typography>
        <div style={{ fontFamily: 'Arial, sans-serif', fontSize: '26px' }}>
          <ul
            style={{
              listStyleType: 'disc',
              marginLeft: '20px',
              fontSize: '16px',
            }}
          >
            <li>
              <p>
                <strong>Add to Front: </strong>Inserts an item at the front of
                the deque.
              </p>
            </li>
            <li>
              <p>
                <strong>Add to Back: </strong>Inserts an item at the back of the
                deque.
              </p>
            </li>
            <li>
              <p>
                <strong>Remove from Front: </strong>Removes the item at the
                front of the deque.
              </p>
            </li>
            <li>
              <p>
                <strong>Remove from Back: </strong>Removes the item at the back
                of the deque.
              </p>
            </li>
            <li>
              <p>
                <strong>Peek Front: </strong>Views the item at the front without
                removing it.
              </p>
            </li>
            <li>
              <p>
                <strong>Peek Back: </strong>Views the item at the back without
                removing it.
              </p>
            </li>
            <li>
              <p>
                <strong>Is Empty: </strong>Checks if the deque is empty.
              </p>
            </li>
            <li>
              <p>
                <strong>Size: </strong>Returns the number of items in the deque.
              </p>
            </li>
          </ul>
        </div>

        <Typography sx={{ fontSize: '20px', fontWeight: 400 }}>
          Example
        </Typography>

        <Typography>
          Imagine you're organizing a game where players can join or leave from
          both the front and the back of the line. You can use a deque to manage
          this line.
        </Typography>
        <Typography>
          Let’s say you wanted to execute the following operations:
        </Typography>
        <ol>
          <li>1. Add "Alice" to the front of the line.</li>
          <li>2. Add "Bob" to the back of the line.</li>
          <li>3. Add "Charlie" to the front of the line.</li>
          <li>4. Remove a person from the front.</li>
          <li>5. Remove a person from the back.</li>
        </ol>

        <Typography>Finally, display the current line.</Typography>

        <Typography>
          Let us see how to do this using a deque data structure, the
          implementation of a deque can be done using arrays similar to that of
          queues.
        </Typography>
        <Box
          sx={{
            backgroundColor: 'black',
            // padding: '24px',
            borderRadius: '10px',
            width: '803px',
            paddingLeft: '1.5em',
          }}
        >
          <pre style={{ color: 'white', margin: 0 }}>
            {`
class Deque:
def __init__(self):
    self.items = []

def isEmpty(self):
    return self.items == []

def addRear(self, item):
    self.items.append(item)

def addFront(self, item):
    self.items.insert(0, item)

def removeFront(self):
    return self.items.pop(0)

def removeRear(self):
    return self.items.pop()

def size(self):
    return len(self.items)


Initialize game_line as a new Deque

# Add players to the deque
game_line.addFront("Alice")       # Add Alice to the front
game_line.addRear("Bob")          # Add Bob to the back
game_line.addFront("Charlie")     # Add Charlie to the front

# Remove players from the deque
game_line.removeFront()         # Remove the player at the front
game_line.removeRear()          # Remove the player at the back

# Display the final state of the deque
game_line.display()

`}
          </pre>
        </Box>
        <Typography>
          The time complexity every operation above would be O(1)
        </Typography>
      </ParagraphBox>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        alignSelf='end'
        justifyContent='space-between'
        gap='12px'
      >
        {!hasMarkedCompleted(topic_id) && (
          <LoadingButton
            variant='contained'
            onClick={() => handlePostCompletedTopic(topic_id)}
            loading={loading}
            loadingPosition='center'
            children='Mark as completed'
            style={{
              width: '170px',
              // backgroundColor: 'transparent',
              borderRadius: '8px',
              border: '1px solid rgba(64, 96, 245, 0.5)',
            }}
          />
        )}

        <Box
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Button
            sx={{ gap: '4px' }}
            onClick={handleNext}
          >
            Next <EastIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Introduction;
