import React, { useState } from 'react';
import {
  Box,
  Typography,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
} from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import VerifiedIcon from '@mui/icons-material/Verified';
import { FiberManualRecord } from '@mui/icons-material';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import CustomTabPanel from '../CustomTabPanel';
import { FindLargestPowerOfTwoConst } from '../../../utils/techniqueSagaConstant';
import TopicTitle from '../TopicTitle';
import ListRenderComponent from '../ListRendereComponent';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import divideStep from '../../../../assets/divideStep.png';
import merge1 from '../../../../assets/mergeStep.png';
import merge2 from '../../../../assets/mergeStep2.png';
import closestPowerPoint1 from '../../../../assets/closestPairPoint1.png';
import closestPowerPoint2 from '../../../../assets/closestPairPoint2.png';
import closestPowerPoint3 from '../../../../assets/closestPairPoint3.png';
import closestPowerPoint4 from '../../../../assets/closestPairPoint4.png';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ClosestPairOfPoints = ({
  handlePostCompletedTopic,
  hasMarkedCompleted,
  loading,
  topic_id,
  handleNext,
  handlePrevious,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='36px'
      paddingY={2}
      maxWidth='calc(100% - 300px)'
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <TopicTitle>2.Closest Pair of Points</TopicTitle>
        {hasMarkedCompleted(topic_id) && (
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              color: 'green',
              fontWeight: '500',
              border: '2px solid green',
              padding: '4px 10px',
              borderRadius: '20px',
              cursor: 'default',
            }}
          >
            Completed
          </Typography>
        )}
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        gap='24px'
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            width: '36rem',
            background: '#FEF7FF',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab
              label='Problem Statement'
              {...a11yProps(0)}
            />
            <Tab
              label='Brute Force Approach'
              {...a11yProps(1)}
            />
            <Tab
              label='Divide and Conquer Approach'
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel
          value={value}
          index={0}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              Given a set of n points in a two-dimensional plane, the goal is to
              find the closest pair of points and return their distance.
            </Typography>
            <Typography>
              Example:
              <br />
              For a set of points:
              <br />
              {' {(2, 3), (12, 30), (40, 50), (5, 1), (12, 10), (3, 4)}'}
            </Typography>
            <Typography>
              The closest pair of points is (2, 3) and (3, 4) with a distance of
              approximately 1.414.
            </Typography>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={1}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              One straightforward approach is to calculate the distance between
              every pair of points and keep track of the minimum distance.
            </Typography>
            <Typography>Below is the implementation for it</Typography>

            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '800px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`function closestPairNaive(points):
    minDistance = infinity
    for i from 0 to n-1:
        for j from i+1 to n-1:
            distance = calculateDistance(points[i], points[j])
            if distance < minDistance:
                minDistance = distance
    return minDistance




`}
              </pre>
            </Box>

            <Typography sx={{ paddingLeft: '1.5rem' }}>
              <b>Time and Space Complexity</b>
              <br />
              You are using two nested loops with no extra space.
              <br />
              Time Complexity : O(n^2)<br></br>
              Space Complexity : O(1)<br></br>
            </Typography>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={2}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Box
              display='flex'
              flexDirection='column'
              gap='8px'
            >
              <Box sx={{ padding: 4, lineHeight: 1.8 }}>
                <Typography>
                  Let us try to think if we can break this problem into smaller
                  subproblems, so suppose we have the pair of points given like
                  the image below:
                </Typography>
                <br />
                <img
                  src={closestPowerPoint1}
                  alt=''
                  style={{
                    height: '450px',
                    width: '300px',
                    display: 'block',
                    margin: 'auto',
                  }}
                />
                <br />
                <Typography>
                  We could maybe divide the number of points in two halves, also
                  to do that we will essentially have to sort the array
                  according to the x-coordinate of it. So, maybe we can see some
                  sort of division here but what should be our base condition,
                  till what point should we divide. A good idea would be to do
                  it until you have three or less points remaining, and then
                  check among these three manually.
                </Typography>
                <br />
                <img
                  src={closestPowerPoint2}
                  alt=''
                  style={{
                    height: '450px',
                    width: '300px',
                    display: 'block',
                    margin: 'auto',
                  }}
                />
                <br />
                <Typography>
                  Once you have divided and have a base case, simply check which
                  one is min(d_left, d_right). Now if you observe there could be
                  a small issue with this one, when the closest points actually
                  lie on different parts, because you are never comparing them
                  for example see the image below
                </Typography>
                <br />{' '}
                <img
                  src={closestPowerPoint3}
                  alt=''
                  style={{
                    height: '450px',
                    width: '300px',
                    display: 'block',
                    margin: 'auto',
                  }}
                />
                <br />{' '}
                <Typography>
                  In the above image actually the closest point is neither
                  d_left nor is it d_right. In that case, we know that the best
                  point is surely less than the min(d_left, d_right), so we will
                  create a strip of that size and check in just that region.
                </Typography>
                <br />
                <img
                  src={closestPowerPoint4}
                  alt=''
                  style={{
                    height: '450px',
                    width: '600px',
                    display: 'block',
                    margin: 'auto',
                  }}
                />
                <Box sx={{ padding: 4, lineHeight: 1.8 }}>
                  <Typography
                    variant='body1'
                    paragraph
                  >
                    Now it could be that the region is still too big to check
                    all points so we can do the same thing but from the
                    horizontal point of view.
                    <br />
                    Let us see how we can implement the same.
                  </Typography>
                  <Box
                    sx={{
                      backgroundColor: 'black',
                      padding: '24px',
                      borderRadius: '10px',
                      width: '800px',
                    }}
                  >
                    <pre style={{ color: 'white', margin: 0 }}>
                      {`function closestPair(points):
    // Sort points by x-coordinates
    sort(points by x-coordinate)
    return closestPairRecursive(points)

function closestPairRecursive(points):
    if the number of points <= 3:
        return closestPairNaive(points)  // Use naive method for small datasets

    mid = n / 2
    midPoint = points[mid]

    // Recursively find the smallest distance in left and right halves
    leftClosest = closestPairRecursive(points[0...mid])
    rightClosest = closestPairRecursive(points[mid...n])

    // The minimum distance found in either half
    minDistance = min(leftClosest, rightClosest)

    // Create an array to hold points within minDistance of midPoint
    strip = []
    for point in points:
        if abs(point.x - midPoint.x) < minDistance:
            strip.append(point)

    // Find the closest points in the strip
    return min(minDistance, closestPairInStrip(strip, minDistance))

function closestPairInStrip(strip, minDistance):
    // Sort strip points by y-coordinates
    sort(strip by y-coordinate)

    // Check only the points that are within minDistance in y
    for i from 0 to strip.length - 1:
        for j from i + 1 to min(7, strip.length - i): // Check up to 7 points ahead
            distance = calculateDistance(strip[i], strip[j])
            if distance < minDistance:
                minDistance = distance

    return minDistance

function calculateDistance(point1, point2):
    return sqrt((point1.x - point2.x)² + (point1.y - point2.y)²)






`}
                    </pre>
                  </Box>
                  <br />
                  <Typography sx={{ paddingLeft: '1.5rem' }}>
                    <b>Time and Space Complexity</b>
                    <br />
                    We are sorting the array which takes n*logn, apart from that
                    there is division of the dataset which will take logn, The
                    algorithm also uses additional extra spaces to store the
                    temporary data points.
                    <br />
                    Time Complexity : O(n*log n)<br></br>
                    Space Complexity : O(n)<br></br>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </CustomTabPanel>
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        alignSelf='end'
        justifyContent='space-between'
        gap='12px'
      >
        {!hasMarkedCompleted(topic_id) && (
          <LoadingButton
            variant='contained'
            onClick={() => handlePostCompletedTopic(topic_id)}
            loading={loading}
            loadingPosition='center'
            children='Mark as completed'
            style={{
              width: '170px',
              // backgroundColor: 'transparent',
              borderRadius: '8px',
              border: '1px solid rgba(64, 96, 245, 0.5)',
            }}
          />
        )}

        <Box
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Button
            sx={{ gap: '4px' }}
            onClick={handlePrevious}
          >
            <WestIcon /> Previous
          </Button>

          <Button
            sx={{ gap: '4px' }}
            onClick={handleNext}
          >
            Next <EastIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ClosestPairOfPoints;
