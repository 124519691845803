// @ts-nocheck
import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

const ApplicationCarousel = () => {
  const carouselItems = [
    'The highest total CTC offered to Heycoach alumni is Rs.1.2 Cr.',
    'Only 5% of eligible candidates receive an offer letter via fast-track test.',
    'Heycoach boasts top-tier recruiters like Meta, Amazon, Google, and Microsoft.',
  ];

  const carouselRef = useRef(null); // Reference to the scroll container
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md')); // Mobile and tablet screens

  // Automatic Horizontal Scrolling for Mobile and Tablet
  useEffect(() => {
    if (isMobileOrTablet && carouselRef.current) {
      const interval = setInterval(() => {
        const scrollWidth = carouselRef.current.scrollWidth;
        const clientWidth = carouselRef.current.clientWidth;

        if (carouselRef.current.scrollLeft + clientWidth >= scrollWidth) {
          carouselRef.current.scrollTo({ left: 0, behavior: 'smooth' });
        } else {
          carouselRef.current.scrollBy({
            left: clientWidth,
            behavior: 'smooth',
          });
        }
      }, 3000); // Scroll every 3 seconds

      return () => clearInterval(interval);
    }
  }, [isMobileOrTablet]);

  // Vertical Carousel State for Larger Screens
  const [currentIndex, setCurrentIndex] = useState(0);

  // Automatic Vertical Scrolling for Larger Screens
  useEffect(() => {
    if (!isMobileOrTablet) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselItems.length);
      }, 3000); // Scroll every 3 seconds

      return () => clearInterval(interval);
    }
  }, [isMobileOrTablet]);

  // Vertical Carousel Card Styles
  const getCardStyles = (index) => {
    const relativeIndex =
      (index - currentIndex + carouselItems.length) % carouselItems.length;

    if (relativeIndex === 0) {
      return {
        transform: 'translateY(0px) scale(1.1)',
        opacity: 1,
        zIndex: 3,
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
      };
    } else if (relativeIndex === 1) {
      return {
        transform: 'translateY(-120px) scale(0.9)',
        opacity: 0.6,
        zIndex: 2,
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
      };
    } else if (relativeIndex === 2) {
      return {
        transform: 'translateY(120px) scale(0.9)',
        opacity: 0.6,
        zIndex: 2,
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
      };
    }
    return {};
  };

  return (
    <Box
      sx={{
        width: isMobileOrTablet ? '100%' : '110%',
        padding: '1rem',
        overflow: 'hidden',
        position: 'relative',
        backgroundColor: '#fff',
        borderRadius: '12px',
      }}
    >
      {isMobileOrTablet ? (
        // Horizontal Carousel for Mobile and Tablet
        <Box
          ref={carouselRef}
          sx={{
            display: 'flex',
            gap: '1rem',
            overflowX: 'auto',
            scrollBehavior: 'smooth',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            padding: '1rem',
          }}
        >
          {carouselItems.map((item, index) => (
            <Box
              key={index}
              sx={{
                minWidth: '102%', // Ensures proper card width
                maxWidth: '110%',
                padding: '1rem',
                backgroundColor: '#fff',
                borderRadius: '12px',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              <Typography
                sx={{
                  fontSize: '1rem',
                  fontWeight: '500',
                  color: '#333',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '0.5rem',
                }}
              >
                💡 {item}
              </Typography>
            </Box>
          ))}
        </Box>
      ) : (
        // Vertical Carousel for Larger Screens
        <Box
          sx={{
            position: 'relative',
            height: '400px',
            overflow: 'hidden',
          }}
        >
          {carouselItems.map((item, index) => (
            <Box
              key={index}
              sx={{
                position: 'absolute',
                left: '0',
                right: '0',
                top: '6.875rem',
                margin: 'auto',
                width: '300px',
                height: '100px',
                padding: '0.8rem',
                backgroundColor: '#fff',
                borderRadius: '8px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                transition: 'all 0.5s ease-in-out',
                ...getCardStyles(index),
              }}
            >
              <Typography
                sx={{
                  fontSize: '1rem',
                  fontWeight: '500',
                  color: '#333',
                  lineHeight: 1.5,
                }}
              >
                💡 {item}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default ApplicationCarousel;
