// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Button, Typography, Stack } from '@mui/material';
import axios from 'axios';
import ErrorNotifier from 'src/components/ToastNotifications/ErrorNotifier';
import { BASE_URL, getHeaders } from 'src/apis/urls';
import { useEfPipeline } from 'src/layouts/EfPipelineContext/EfPipelineContext';
import { useDispatch } from 'react-redux';
import { updateStep } from 'src/components/redux-store/slices/progressSlice';

const ResumeUploadPage = () => {
  const { formId, setScreen, resumeLink, setResumeLink } = useEfPipeline();
  const [resumeFile, setResumeFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateStep({ stepIndex: 0 }));
  }, []);

  const handleApiCall = () => {
    setLoading(true);

    const payload = {
      source: 'attach_resume',
      event_name: 'continue',
      prev_screen: 'resumeScreen',
    };

    axios
      .post(
        `${BASE_URL}/api/eligibility-forms/${formId}/submit_fst_fail_questions`,
        payload,
        {
          headers: getHeaders(),
        }
      )
      .then((response) => {
        if (response?.data?.status === 'success') {
          setScreen('resumeScreen');
        }
      })
      .catch((error) => {
        console.error('Error submitting event:', error);
        setErrorMessage(
          error.response?.data?.message ||
            'Failed to submit event. Please try again later.'
        );
        setHasError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      // Check file size (minimum: 10 KB, maximum: 5 MB)
      if (file.size < 10 * 1024) {
        setErrorMessage('File size must be greater than 10 KB.');
        setHasError(true);
        return;
      }

      if (file.size > 5 * 1024 * 1024) {
        setErrorMessage('File size must not exceed 5 MB.');
        setHasError(true);
        return;
      }

      // Validate that the file is not blank
      if (file.size === 0) {
        setErrorMessage(
          'The selected file is empty. Please upload a valid resume.'
        );
        setHasError(true);
        return;
      }

      setResumeFile(file);

      // Upload file immediately
      const formData = new FormData();
      formData.append('resume', file);

      setLoading(true);
      axios
        .post(`${BASE_URL}/api/eligibility-forms/upload_resume`, formData, {
          headers: getHeaders(),
        })
        .then((response) => {
          if (response?.statusText?.toLowerCase() == 'created') {
            setErrorMessage('Resume successfully uploaded.');

            // Call API with updated payload
            const resumeUrl = response.data.url;
            const payload = {
              source: 'resumeScreen',
              event_name: 'attach_resume',
              prev_screen: null, // Set prev_screen to null
              resume_link: resumeUrl,
            };

            axios
              .post(
                `${BASE_URL}/api/eligibility-forms/${formId}/submit_fst_fail_questions`,
                payload,
                {
                  headers: getHeaders(),
                }
              )
              .then((res) => {
                setResumeLink(res?.data?.data?.resume);
                setHasError(false);
              })
              .catch((error) => {
                console.error('Error submitting event:', error);
                setErrorMessage(
                  error.response?.data?.message ||
                    'Failed to submit event. Please try again later.'
                );
                setHasError(true);
                setResumeFile(null);
              });
          } else {
            setErrorMessage('Resume upload failed. Please try again.');
            setHasError(true);
          }
        })
        .catch((error) => {
          console.error('Error uploading resume:', error);
          setErrorMessage(
            error.response?.data?.message ||
              'Failed to upload resume. Please try again later.'
          );
          setHasError(true);
          setResumeFile(null);
          setResumeLink(null);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      <Stack
        spacing={3}
        sx={{
          minHeight: '75vh',
          bgcolor: 'white',
          borderRadius: '0.625rem',
          boxShadow: '0rem 0.25rem 0.625rem rgba(0, 0, 0, 0.1)',
          width: '100%',
          padding: { xs: '1.5rem', sm: '2rem', md: '3rem' },
          margin: { xs: '1rem', sm: '1.5rem', md: '2rem' },
        }}
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
      >
        <Typography
          variant='h3'
          textAlign='center'
          color='#1976d2'
          sx={{
            fontSize: { xs: '1.8rem', sm: '2.25rem', md: '2.5rem' },
            fontWeight: 500,
          }}
        >
          Almost there! We just need to assess you better
        </Typography>

        <Typography
          sx={{
            maxWidth: { xs: '90%', sm: '90%', md: '90%', lg: '60%' },
            fontSize: { xs: '1rem', sm: '1.2rem', md: '1.3rem' },
            textAlign: 'center',
            fontWeight: '300',
            lineHeight: { xs: '1.5rem', sm: '2.2rem' },
            mb: '0.5rem',
          }}
        >
          Unfortunately, your performance didn’t meet the requirements for
          selection but the good news is that your application is still open!
        </Typography>

        <Typography
          sx={{
            maxWidth: { xs: '90%', sm: '90%', md: '90%', lg: '60%' },
            fontSize: { xs: '1rem', sm: '1.2rem', md: '1.3rem' },
            textAlign: 'center',
            fontWeight: '300',
            lineHeight: { xs: '1.5rem', sm: '2.2rem' },
          }}
          style={{
            marginBottom: '1.5rem',
          }}
        >
          Please attach your resume below for our team of counsellors to review
          your profile manually.
        </Typography>

        <Stack
          direction='column'
          spacing={3}
          justifyContent='center'
          alignItems='center'
          sx={{
            width: '100%',
          }}
        >
          <Button
            component='label'
            variant='outlined'
            sx={{
              color: '#1976d2',
              borderRadius: '2rem',
              textTransform: 'none',
              padding: '0.5rem',
              borderColor: '#1976d2',
              boxShadow: 'none',
              width: { xs: '80%', sm: '60%', md: '30%' },
              maxWidth: '400px',
            }}
            disabled={resumeLink || resumeFile}
          >
            + Attach Resume
            <input
              type='file'
              hidden
              accept='.pdf'
              onChange={handleFileChange}
            />
          </Button>

          <Button
            variant='contained'
            color='primary'
            onClick={handleApiCall}
            disabled={!resumeLink || loading}
            sx={{
              borderRadius: '0.5rem',
              textTransform: 'none',
              padding: '0.5rem',
              boxShadow: 'none',
              width: { xs: '60%', sm: '40%', md: '20%' },
              maxWidth: '400px',
              backgroundColor: loading ? 'rgba(0,0,0,0.1)' : '',
            }}
          >
            {loading ? 'Submitting...' : 'Continue →'}
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default ResumeUploadPage;
