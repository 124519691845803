import React from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
} from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import { FiberManualRecord } from '@mui/icons-material';
import EastIcon from '@mui/icons-material/East';
import ParagraphBox from './ParagraphBox';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import image1 from '../../../assets/stackExample.png';
import image2 from '../../../assets/stackExampleUsage.png';
import image3 from '../../../assets/monotonicstack.png';
const Introduction = ({
  handlePostCompletedTopic,
  hasMarkedCompleted,
  loading,
  topic_id,
  handleNext,
}) => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='20px'
      paddingY={2}
      maxWidth='calc(100% - 300px)'
    >
      <Box>
        <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
          TECHNIQUE 11
        </Typography>

        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
        >
          <Typography
            sx={{
              fontSize: '48px',
              fontWeight: 600,
              lineHeight: '58px',
              letterSpacing: '0.01em',
              textAlign: 'left',
            }}
          >
            Stack
          </Typography>
          {hasMarkedCompleted(topic_id) && (
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
                color: 'green',
                fontWeight: '500',
                border: '2px solid green',
                padding: '4px 10px',
                borderRadius: '20px',
                cursor: 'default',
              }}
            >
              Completed
            </Typography>
          )}
        </Box>
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        gap='24px'
      >
        <Typography sx={{ fontSize: '22px', fontWeight: 400 }}>
          Introduction to Stacks
        </Typography>

        <ParagraphBox>
          <Typography>
            Imagine a stack of plates in a cafeteria or a stack of books on a
            table. You always place new plates or books on top, and when you
            want one, you take the one on the very top. This is the concept of
            &nbsp;<strong>Last-In-First-Out (LIFO)</strong>, and it’s the
            underlying principle of stacks. In programming, a stack operates the
            same way: items are added to the top, and when you need one, you
            remove the most recently added item first. This structure may sound
            simple, but it’s incredibly powerful for many types of
            problem-solving.
          </Typography>
          <Typography>
            Stacks come in handy when you need to reverse a sequence, keep track
            of nested operations, or undo actions. Think about a web browser,
            where each page you visit gets added to a “back” stack. When you
            press the back button, you don’t go back to the first page you
            visited; you go back to the last one you visited. This is the LIFO
            nature of stacks in action.
          </Typography>
          <Typography>
            The key advantage of using a stack is that it restricts operations
            to just the top, simplifying the way you handle tasks. There are
            only two main actions you can perform:
          </Typography>
        </ParagraphBox>

        <ParagraphBox>
          <div style={{ fontFamily: 'Arial, sans-serif', fontSize: '26px' }}>
            <ul
              style={{
                listStyleType: 'disc',
                marginLeft: '20px',
                fontSize: '16px',
              }}
            >
              <li>
                <p>
                  <strong>Push: </strong>Add an item to the top of the stack.
                </p>
              </li>
              <li>
                <p>
                  <strong>Pop: </strong>Remove the item from the top.
                </p>
              </li>
            </ul>
          </div>

          <Typography>
            These simple operations make stacks ideal for managing temporary
            data in an organized way. You’ll find stacks used in parsing,
            function call management, and algorithms that require backtracking.
            Stacks let us manage data in ways that would otherwise require
            complex conditions and more memory.
          </Typography>
          <Typography sx={{ fontSize: '20px', fontWeight: 400 }}>
            What is a Stack?
          </Typography>
          <Typography>
            A stack is a specialized data structure that operates on a &nbsp;
            <strong>Last-In-First-Out (LIFO)</strong> basis. Imagine a stack as
            a single column where you can only add and remove items from the
            very top. This LIFO principle means that the most recently added
            item is always the first one available for removal. It’s like a
            stack of books or plates: if you need the one on the bottom, you
            have to remove everything on top first.
          </Typography>

          <Typography>
            In computer science, stacks are typically used to handle temporary
            or reversible data, allowing efficient management of tasks where we
            need to reverse or backtrack actions. A few core concepts in a stack
            include:
          </Typography>

          <div style={{ fontFamily: 'Arial, sans-serif', fontSize: '26px' }}>
            <ul
              style={{
                listStyleType: 'disc',
                marginLeft: '20px',
                fontSize: '16px',
              }}
            >
              <li>
                <p>
                  <strong>Push: </strong>Add an item to the top of the stack.
                </p>
              </li>
              <li>
                <p>
                  <strong>Pop: </strong>Removing the top item from the stack.
                </p>
              </li>
              <li>
                <p>
                  <strong>Peek: </strong>Viewing the top item without removing
                  it.
                </p>
              </li>
              <li>
                <p>
                  <strong>IsEmpty: </strong>Checking if the stack has any items.
                </p>
              </li>
            </ul>
          </div>
          <img
            src={image1}
            alt=''
            style={{ height: '400px', width: '500px' }}
          />
          <Typography sx={{ fontSize: '16px', fontWeight: 400 }}>
            Consider a stack’s structure for a real-world example. Let’s say
            you’re working with a pile of dishes. When you wash a dish, you
            place it on the top of the pile (Push). If you need a dish, you take
            the one on top (Pop). If you want to see which dish you’d grab next,
            you look at the top of the pile (Peek). And when there are no dishes
            in the stack, it’s empty (IsEmpty).
          </Typography>
          <Typography sx={{ fontSize: '16px', fontWeight: 400 }}>
            In programming, this stack behavior is especially helpful for
            scenarios involving:
          </Typography>
          <div style={{ fontFamily: 'Arial, sans-serif', fontSize: '26px' }}>
            <ul
              style={{
                listStyleType: 'disc',
                marginLeft: '20px',
                fontSize: '16px',
              }}
            >
              <li>
                <p>
                  <strong>Backtracking: </strong>Going back to a previous state,
                  such as undo actions or navigating back in history (like a
                  browser back button).
                </p>
              </li>
              <li>
                <p>
                  <strong>Managing Function Calls: </strong>Programming
                  languages use a stack to keep track of active functions and
                  return control to previous functions once current ones
                  complete.
                </p>
              </li>
              <li>
                <p>
                  <strong>Handling Expressions: </strong>Stacks help in
                  evaluating complex expressions or ensuring that symbols (like
                  parentheses) are balanced in an expression.
                </p>
              </li>
            </ul>
          </div>

          <Typography>
            The simplicity and restriction of only accessing the top element
            make stacks a powerful tool in problem-solving. They keep data
            organized in a way that’s natural for tasks that require reversing
            or backtracking, saving time and effort compared to more complex
            data structures
          </Typography>
          <Typography>
            Stacks could be implemented in a number of ways, below is a simple
            implementation of it using an empty array.
          </Typography>

          <Box
            sx={{
              backgroundColor: 'black',
              // padding: '24px',
              borderRadius: '10px',
              width: '803px',
              paddingLeft: '1.5em',
              marginLeft: '2em',
            }}
          >
            <pre style={{ color: 'white', margin: 0 }}>
              {`

Class Queue:
    Initialize an empty list items
    
    Method push(item):
        Add item to the end of items list
        Print "Pushed item onto the stack"


    Method pop():
        If is_empty() is False:
            Remove the last item from items list
            Print "Popped item from the stack"
            Return the removed item

        Else:
            Print "Stack is empty. Nothing to pop."
            Return None

    Method peek():
        If is_empty() is False:
            Print "Top element is items[last element]"
            Return the last element in items list
        Else:
            Print "Stack is empty. Nothing to peek."
            Return None

    Method is_empty():
        Return True if items list has no elements
        Return False otherwise

    Method size():
        Print "Stack size is length of items list"
        Return length of items list
`}
            </pre>
          </Box>

          <Typography>
            The stack data structure contains all the important methods and if
            you analyze carefully all of these are operations that can be done
            in constant time O(1) making stack operations incredibly efficient.
            There are also inbuilt structure and methods in various programming
            languages.
          </Typography>
        </ParagraphBox>
      </Box>
      <Typography sx={{ fontSize: '20px', fontWeight: 400 }}>
        Example Usage
      </Typography>
      <ParagraphBox>
        <Typography>
          Imagine you have a word, “HELLO,” and you want to reverse it. If you
          write each letter down and then stack them one on top of the other,
          you’ll end up with “O” on top, then “L,” and so forth down to “H” at
          the bottom. By popping each letter off, you can reconstruct the word
          in reverse order. This is the LIFO order of stacks in action.
        </Typography>

        <img
          src={image2}
          alt=''
          style={{ height: '250px', width: '180px' }}
        />
        <Typography>
          Now if you pop and store it, you will get the string “OLLEH”
        </Typography>

        <Box
          sx={{
            backgroundColor: 'black',
            // padding: '24px',
            borderRadius: '10px',
            width: '803px',
            paddingLeft: '1.5em',
          }}
        >
          <pre style={{ color: 'white', margin: 0 }}>
            {`
function reverse_string(input_string):
initialize stack as an empty list

// Step 1: Push each character of input_string onto the stack
for each char in input_string:
    append char to stack

initialize reversed_string as an empty string

// Step 2: Pop each character from stack to build reversed_string
while stack is not empty:
    append stack.pop() to reversed_string

return reversed_string

`}
          </pre>
        </Box>

        <Typography>
          Although there could be different ways of doing the problem it still
          shows how stacks can be used. The time complexity of this would be
          O(n) and space complexity will also be O(n).
        </Typography>

        <Typography sx={{ fontSize: '20px', fontWeight: 400 }}>
          Monotonic Stack
        </Typography>

        <Typography>
          The concept of a <strong>monotonic stack </strong>is an advanced stack
          technique that helps solve problems where elements need to be
          processed in a particular order—typically in increasing or decreasing
          order. Unlike a regular stack, which simply manages data in a
          Last-In-First-Out (LIFO) manner, a monotonic stack maintains a
          specific ordering among its elements, allowing us to perform certain
          types of operations and checks more efficiently.
        </Typography>

        <Typography>
          Monotonic stacks are particularly useful in array or list problems
          where you need to quickly find the{' '}
          <strong>next or previous larger/smaller element </strong>for each
          element in the sequence. They are often applied to problems in which:
        </Typography>

        <div style={{ fontFamily: 'Arial, sans-serif', fontSize: '26px' }}>
          <ul
            style={{
              listStyleType: 'disc',
              marginLeft: '20px',
              fontSize: '16px',
            }}
          >
            <li>
              <p>
                We need to calculate ranges or spans of elements (like the
                nearest larger or smaller element to the left or right).
              </p>
            </li>
            <li>
              <p>
                We want to process data in a sorted order without repeatedly
                sorting it.
              </p>
            </li>
          </ul>
        </div>

        <Typography sx={{ fontSize: '20px', fontWeight: 400 }}>
          Next Greater Element
        </Typography>

        <Typography>
          Let us discuss one of the applications of building a monotonic stack
          which is to find the next greater element to the right side of every
          element in an array.
        </Typography>
        <Typography>
          arr = [4,5,2,10,8] , if there is no greater element then mark that as
          -1.
        </Typography>
        <Typography>
          To solve this problem, we will use a{' '}
          <strong>monotonic decreasing stack</strong>. This means that the stack
          will keep elements in decreasing order (from top to bottom), and when
          we find a larger element, we pop the stack until the order is
          maintained.
        </Typography>
        <ol>
          <li>1. Initialize an empty stack to keep track of indices.</li>
          <li>2. Traverse the array from right to left.</li>
          <li>
            3. For each element, check if it is greater than the element
            corresponding to the index on top of the stack.
          </li>
          <li>
            4. If it is, this element is the "next greater" for the elements at
            those indices on the stack. We pop from the stack and assign this
            element as the NGE.
          </li>
          <li>5. Push the current index onto the stack.</li>
          <li>
            6. After traversing the array, if any elements remain in the stack,
            their NGE is -1 since no greater element exists to their right.
          </li>
        </ol>

        <img
          src={image3}
          alt=''
          style={{ height: '250px', width: '700px' }}
        />
        <Typography>Let us look at its implementation</Typography>
        <Box
          sx={{
            backgroundColor: 'black',
            // padding: '24px',
            borderRadius: '10px',
            width: '803px',
            paddingLeft: '1.5em',
          }}
        >
          <pre style={{ color: 'white', margin: 0 }}>
            {`Function NextGreaterElement(array):
    Initialize an empty stack S
    Initialize result array with -1 for each element in array

    For i from array.length - 1 to 0:
        While S is not empty AND S.top() <= array[i]:
            S.pop()  // Remove elements smaller than or equal to the current element

        If S is not empty:
            result[i] = S.top()  // Set NGE as the top of the stack

        Push array[i] onto S  // Add current element to the stack for future NGEs

    Return result


`}
          </pre>
        </Box>
        <Typography>
          Normally, to do this problem one might have to do a brute force by
          traversing again and again but using a monotonic nature of stack we
          were able to resolve it in linear time complexity.
        </Typography>
      </ParagraphBox>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        alignSelf='end'
        justifyContent='space-between'
        gap='12px'
      >
        {!hasMarkedCompleted(topic_id) && (
          <LoadingButton
            variant='contained'
            onClick={() => handlePostCompletedTopic(topic_id)}
            loading={loading}
            loadingPosition='center'
            children='Mark as completed'
            style={{
              width: '170px',
              // backgroundColor: 'transparent',
              borderRadius: '8px',
              border: '1px solid rgba(64, 96, 245, 0.5)',
            }}
          />
        )}

        <Box
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Button
            sx={{ gap: '4px' }}
            onClick={handleNext}
          >
            Next <EastIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Introduction;
