// @ts-nocheck
import React, { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  CircularProgress,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent,
} from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';

const UniversalProgress = () => {
  const steps = useSelector((state) => state.progress.steps);
  const currentStepIndex = steps.findIndex((step) => step.isInprogress);
  // Store a value

  const progress = {
    alignSelf: 'center',
    position: 'flexible', // Change 'flexible' to 'relative' for proper positioning
    top: 0,
    padding: 0,
    width: '97%', // Make the width 100% to fit the container's width
    // maxWidth: '70vw', // Ensure it doesn't overflow the viewport width
    marginLeft: '15px', // Center horizontally
    marginRight: '15px', // Center horizontally
    display: 'block', // Ensures it's displayed as a block-level element
    boxSizing: 'border-box', // Ensures padding and borders are included in the element's width calculation
  };

  const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    padding: '0px',
    backgroundColor: 'transparent',
    '& .MuiAccordionSummary-content': {
      marginTop: '5px !important',
      marginBottom: '2px !important', // Ensure margin is overridden
    },
  }));

  return (
    <Box sx={progress}>
      <Accordion
        sx={{
          backgroundColor: 'transparent',
          boxShadow: 'none',
          margin: 0,
          padding: 0,
        }}
      >
        <CustomAccordionSummary
          expandIcon={<ArrowDownwardIcon />}
          aria-controls='panel1-content'
          id='panel1-header'
        >
          {/* Content */}

          <Box
            position='relative'
            display='inline-flex'
            sx={{
              padding: '0px',
              margin: '0px',
            }}
          >
            {/* Background progress (full circle) */}
            <CircularProgress
              variant='determinate'
              value={100}
              size={50}
              sx={{
                color: 'white',
                position: 'absolute',
                padding: '0px',
                margin: '10px',
              }}
            />
            {/* Foreground progress (current progress) */}
            <CircularProgress
              variant='determinate'
              value={((currentStepIndex + 1) / steps.length) * 100}
              size={50}
              sx={{
                color: 'primary.main',
                padding: '0px',
                margin: '10px',
              }}
            />
            {/* Text inside CircularProgress */}
            <Box
              position='absolute'
              top='50%'
              left='50%'
              sx={{
                transform: 'translate(-50%, -50%)',
                textAlign: 'center',
                width: '300px',
                padding: '0px',
                margin: '0px',
              }}
            >
              <Typography
                variant='caption'
                component='div'
              >
                {currentStepIndex !== -1
                  ? `${currentStepIndex + 1} of ${steps.length}`
                  : 'No step in progress'}
              </Typography>
            </Box>
            {/* Step details */}
            <Box
              position='absolute'
              top='50%'
              left={80}
              sx={{
                transform: 'translateY(-50%)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Typography
                variant='body1'
                sx={{
                  fontWeight: 'bold',
                  color: 'blue',
                  minWidth: '200px',
                  fontSize: '14px',
                }}
              >
                {currentStepIndex !== -1
                  ? steps[currentStepIndex].label
                  : 'No active step'}
              </Typography>
              <Typography
                variant='body2'
                sx={{ color: 'black', fontSize: '0.7rem' }}
              >
                {currentStepIndex !== -1 ? steps[currentStepIndex].time : ''}
              </Typography>
            </Box>
          </Box>
        </CustomAccordionSummary>
        <AccordionDetails
          sx={{
            padding: 0,
            backgroundColor: 'white',
            position: 'absolute', // Make the details layer overlap
            zIndex: 10,
            width: '100%',
          }}
        >
          {/* Stepper Component */}
          <Box sx={{ marginLeft: '100px' }}>
            <Stepper
              activeStep={currentStepIndex}
              orientation='vertical'
            >
              {steps.map((step, index) => (
                <Step
                  key={step.label}
                  sx={{ marginBottom: '20px' }}
                >
                  <StepLabel
                    optional={
                      <Typography variant='caption'>{step.time}</Typography>
                    }
                    sx={{
                      '& .MuiStepLabel-label': {
                        fontWeight: 'bold',
                        color: (theme) =>
                          index === currentStepIndex
                            ? 'blue' // Active step
                            : index < currentStepIndex
                            ? 'blue' // Completed steps
                            : theme.palette.text.secondary, // Incomplete steps (grey)
                      },
                      '& .MuiStepLabel-label.Mui-active': {
                        fontWeight: 'bold',
                        color: 'blue', // Explicit blue for active step
                      },
                      '& .MuiStepLabel-label.Mui-completed': {
                        fontWeight: 'bold',
                        color: 'blue', // Completed steps
                      },
                    }}
                  >
                    {step.label}
                  </StepLabel>
                  <StepContent></StepContent>
                </Step>
              ))}
            </Stepper>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default UniversalProgress;
