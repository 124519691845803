// @ts-nocheck
import React, { useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DownloadIcon from '@mui/icons-material/Download';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import EfPipelineOfferLetter from '../ScreeningTest/EfPipelineOfferLetter';
import {
  Button,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import FastrackOfferLetterCountdown from './FastrackOfferLetterCountdown';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { LoadingButton } from '@material-ui/lab';
import axios from 'axios';
import { useFastrackPipeline } from 'src/layouts/EfPipelineContext/FastrackPipelineContex';

const OfferLetterModal = ({
  link,
  showOfferLetter,
  setShowOfferLetter,
  time,
}) => {
  const {
    olMessage: message,
    offerLetter,
    activeOLStatus,
    trackButtonClick,
  } = useFastrackPipeline();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const offerLetterContent = useRef(null);

  const downloadOfferLetter = () => {
    trackButtonClick({
      source: 'fastrack_release_ol_modal',
      eventName: 'download_offer_letter',
    });

    const element = offerLetterContent.current;
    html2canvas(element, { scale: 5, type: 'pdf' }).then((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg', 1.0);
      const pdf = jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: [500, 800],
      });
      pdf.addImage(imgData, 'JPEG', 0, 0, 500, 800);
      pdf.save('offer_letter.pdf');
    });
  };

  const handleClose = () => {
    setShowOfferLetter(false);
  };

  return (
    <Dialog
      fullScreen={isMobile}
      open={showOfferLetter}
      onClose={handleClose}
    >
      <Stack
        spacing={2}
        sx={{
          background: '#e3f2fd',
          padding: '0rem',
        }}
      >
        {isMobile && (
          <Typography
            alignSelf='flex-end'
            variant='body1'
            component='p'
            sx={{
              fontFamily: 'Inter',
              fontWeight: 600,
            }}
            onClick={handleClose}
          >
            X
          </Typography>
        )}

        <EfPipelineOfferLetter
          {...offerLetter}
          offerLetterContent={offerLetterContent}
          shouldOverflow
        />

        <Stack
          direction='row'
          justifyContent='center'
          alignItems='center'
          gap='0.6rem'
          sx={{
            background: 'white',
            padding: '0rem 0',
            borderRadius: '0.5rem',
          }}
        >
          <Typography
            variant='body1'
            component='p'
            sx={{
              fontFamily: 'Inter',
              fontWeight: 600,
            }}
          >
            The offer letter expires in:
          </Typography>
          <FastrackOfferLetterCountdown
            time={time}
            style={{
              background: '#ffeb3b',
            }}
          />
        </Stack>

        <Stack
          direction={isMobile ? 'column' : 'row'}
          spacing={1}
          alignItems='center'
          justifyContent='center'
        >
          <Button
            sx={{
              fontFamily: 'Inter',
              background: '#6D6D6D',
              color: 'white',
              '&:hover': {
                background: '#6D6D6D',
                color: 'white',
              },
              padding: '0rem 0rem',
              width: '15rem',
              alignSelf: 'center',
            }}
            startIcon={<DownloadIcon />}
            onClick={downloadOfferLetter}
          >
            Download offer letter
          </Button>

          <Tooltip title={activeOLStatus === 'processing' ? message : ''}>
            <LoadingButton
              loading={activeOLStatus === 'processing'}
              disabled={activeOLStatus === 'processing'}
              variant='contained'
              href={link}
              sx={{
                fontFamily: 'Inter',
                backgroundImage: 'linear-gradient(#FF6B00, #FFB518)',
                color: 'white',
                padding: '0rem 0rem',
                width: '15rem',
                alignSelf: 'center',
              }}
              endIcon={<ArrowRightAltIcon fontSize='large' />}
            >
              Secure your seat
            </LoadingButton>
          </Tooltip>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default OfferLetterModal;
