import React, { useState } from 'react';
import { Box, Tabs, Tab, Button } from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import CustomTabPanel from '../CustomTabPanel';
import TopicTitle from '../../Recursion/TopicTiltle';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import image1 from '../../../../assets/largestRectangleHistogram.png';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Largestrectangleinhistogram = ({
  handlePostCompletedTopic,
  hasMarkedCompleted,
  loading,
  topic_id,
  handleNext,
  handlePrevious,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='36px'
      paddingY={2}
      maxWidth='calc(100% - 300px)'
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <TopicTitle>3.Largest rectangle in histogram</TopicTitle>
        {hasMarkedCompleted(topic_id) && (
          <p
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              color: 'green',
              fontWeight: 500,
              border: '2px solid green',
              padding: '4px 10px',
              borderRadius: '20px',
              cursor: 'default',
            }}
          >
            Completed
          </p>
        )}
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        gap='24px'
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            width: '34rem',
            background: '#FEF7FF',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab
              label='Problem Statement'
              {...a11yProps(0)}
            />
            <Tab
              label='Brute Force Approach'
              {...a11yProps(1)}
            />
            <Tab
              label='Stack Based Approach'
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel
          value={value}
          index={0}
        >
          <p style={{ fontSize: '20px' }}>Description</p>
          <Box
            display='flex'
            flexDirection='column'
            gap='10px'
          >
            <p>
              You are given an array of integers representing the heights of
              bars in a histogram. The task is to find the area of the largest
              rectangle that can be formed in the histogram.
            </p>

            <p style={{ fontSize: '20px' }}>Example:</p>

            <img
              src={image1}
              alt=''
              style={{ height: '300px', width: '540px' }}
            />
            <p>Input: heights = [6, 5, 8, 9, 2]</p>
            <p>Output: 20</p>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={1}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='20px'
          >
            <p>
              If we observe, the way to form a rectangle from a particular graph
              is to keep including bars into it until you find a bar with height
              less than that because otherwise it won’t be a rectangle. So for
              each bar, we will do just that for each bar i, expand the
              rectangle as far left and right as possible without encountering a
              smaller bar.
            </p>

            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '800px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`function largestRectangleArea(heights):
    max_area = 0
    
    for i = 0 to len(heights) - 1:
        min_height = heights[i]
        
        for j = i to len(heights) - 1:
            min_height = min(min_height, heights[j])
            width = j - i + 1
            area = min_height * width
            max_area = max(max_area, area)
        return max_area

`}
              </pre>
            </Box>

            <p>
              <b>Time and Space Complexity</b>
              <br />
              This solution involves a nested loops, as we extend far left and
              right for each index.
              <br />
              <br />
              Time Complexity : O(n*2)<br></br>
              Space Complexity : O(1)
            </p>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={2}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='20px'
          >
            <Box
              display='flex'
              flexDirection='column'
              gap='8px'
            >
              <p>
                If you recall, we studied monotonic stacks and in that we
                explored an example of finding the next greater element (NGE) in
                an array. Here also we can explore a similar approach, since for
                each index what we are concerned with is finding an element
                which is smaller to the left and right of it, thus we will find
                the next smaller and the previous smaller element for each index
                by the use of stacks.
              </p>
              <p>
                <strong>NSE (Next Smaller Element):</strong> For each bar, find
                the index of the next bar that has a smaller height than the
                current one. If no such element exists, the rectangle can extend
                all the way to the end.
              </p>
              <p>
                <strong>Left Bound (Previous Smaller Element):</strong> For each
                bar, determine the index of the nearest smaller bar on the left.
                This is essentially the{' '}
                <strong> Previous Smaller Element</strong> for the current bar.
              </p>
              <p>
                <strong>Calculate Area:</strong> Once we have the left and right
                boundaries for each bar (found using NSE and PSE), we can
                calculate the maximum area that can be formed using that bar as
                the shortest bar in the rectangle.
              </p>
            </Box>
            <Box>
              <Box
                sx={{
                  backgroundColor: 'black',
                  padding: '24px',
                  borderRadius: '10px',
                  width: '800px',
                  marginLeft: '1em',
                }}
              >
                <pre style={{ color: 'white', margin: 0 }}>
                  {`
function largestRectangleArea(heights):
n = len(heights)
# Initialize the stacks and results
stack = []
max_area = 0
left_bound = [-1] * n  # Store indices of previous smaller elements
right_bound = [n] * n  # Store indices of next smaller elements

# Find the left bounds (previous smaller element indices)
for i = 0 to n-1:
    while stack is not empty and heights[stack[-1]] >= heights[i]:
        stack.pop()
    if stack is not empty:
        left_bound[i] = stack[-1]
    stack.append(i)

# Clear the stack for calculating right bounds


stack.clear()
    
# Find the right bounds (next smaller element indices)
for i = n-1 down to 0:
    while stack is not empty and heights[stack[-1]] >= heights[i]:
        stack.pop()
    if stack is not empty:
        right_bound[i] = stack[-1]
    stack.append(i)

# Calculate the maximum area using the left and right bounds
for i = 0 to n-1:
    width = right_bound[i] - left_bound[i] - 1
    area = heights[i] * width
    max_area = max(max_area, area)

return max_area

`}
                </pre>
              </Box>
              <br />
              <p>
                <b>Time and Space Complexity</b>
                <br />
                This solution will work in linear complexity because each bar is
                pushed and popped from the stack only once during the
                calculations for both left and right bounds.
                <br /> <br />
                Time Complexity : O(n)<br></br>
                Space Complexity : O(n)<br></br>
              </p>
            </Box>
          </Box>
        </CustomTabPanel>
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        alignSelf='end'
        justifyContent='space-between'
        gap='12px'
      >
        {!hasMarkedCompleted(topic_id) && (
          <LoadingButton
            variant='contained'
            onClick={() => handlePostCompletedTopic(topic_id)}
            loading={loading}
            loadingPosition='center'
            children='Mark as completed'
            style={{
              width: '170px',
              // backgroundColor: 'transparent',
              borderRadius: '8px',
              border: '1px solid rgba(64, 96, 245, 0.5)',
            }}
          />
        )}

        <Box
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Button
            sx={{ gap: '4px' }}
            onClick={handlePrevious}
          >
            <WestIcon /> Previous
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Largestrectangleinhistogram;
