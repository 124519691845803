import React, { useState } from 'react';
import { Box, Tabs, Tab, Button } from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import CustomTabPanel from '../CustomTabPanel';
import TopicTitle from '../../Recursion/TopicTiltle';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const BuyTickets = ({
  handlePostCompletedTopic,
  hasMarkedCompleted,
  loading,
  topic_id,
  handleNext,
  handlePrevious,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='36px'
      paddingY={2}
      maxWidth='calc(100% - 300px)'
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <TopicTitle>1. Time Needed to Buy Tickets</TopicTitle>
        {hasMarkedCompleted(topic_id) && (
          <p
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              color: 'green',
              fontWeight: 500,
              border: '2px solid green',
              padding: '4px 10px',
              borderRadius: '20px',
              cursor: 'default',
            }}
          >
            Completed
          </p>
        )}
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        gap='24px'
      >
        <Box
          style={{
            borderBottom: 1,
            borderColor: 'divider',
            width: '34rem',
            background: '#FEF7FF',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab
              label='Problem Statement'
              {...a11yProps(0)}
            />
            <Tab
              label='Brute Force Approach'
              {...a11yProps(1)}
            />
            <Tab
              label='Queue Based Approach'
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel
          value={value}
          index={0}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='10px'
          >
            <p>
              You are given an array tickets where each element represents the
              number of tickets a person in line needs to buy. Each person can
              buy one ticket at a time, and they can only buy a ticket when it's
              their turn. Each person can buy a ticket in a constant amount of
              time (1 minute).
            </p>
            <p>
              The task is to determine the total time it will take for all the
              people in the line to buy their tickets, given that people can buy
              their tickets one by one.
            </p>
            <ul
              style={{
                listStyleType: 'disc',
                marginLeft: '20px',
                fontFamily: 'Arial, sans-serif',
                fontSize: '16px',
              }}
            >
              <li>The first person needs 2 tickets.</li>
              <li>The second person needs 3 tickets.</li>
              <li>The third person needs 2 tickets.</li>
            </ul>
            <p>
              The total time to buy all the tickets will be calculated by the
              sum of individual ticket purchases over time.
            </p>
            <p style={{ fontSize: '20px' }}>Example:</p>
            <p>Input</p>
            <p>tickets = [2, 3, 2]</p>
            <p>Output</p>
            <p>8</p>
            <p>
              <strong>Explanation:</strong>
            </p>

            <div style={{ fontFamily: 'Arial, sans-serif', fontSize: '16px' }}>
              <ol style={{ listStyleType: 'decimal', marginLeft: '20px' }}>
                <li style={{ marginBottom: '10px' }}>
                  The first person buys 2 tickets (takes 2 minutes).
                </li>
                <li style={{ marginBottom: '10px' }}>
                  The second person buys 1 ticket (takes 1 minute), then goes
                  back to buy 1 more ticket (takes 1 minute), and then 1 more
                  ticket (takes 1 minute). So, 3 minutes for the second person.
                </li>
                <li style={{ marginBottom: '10px' }}>
                  The third person buys 2 tickets (takes 2 minutes).
                </li>
              </ol>
            </div>

            <p>Thus, the total time is 2 + 3 + 2 = 8 minutes.</p>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={1}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='20px'
          >
            <p>
              In the brute force approach, we can simulate the process of each
              person buying one ticket at a time. We will keep track of the
              total time by allowing each person to buy tickets sequentially.
            </p>
            <div style={{ fontFamily: 'Arial, sans-serif', fontSize: '16px' }}>
              <ul
                style={{
                  listStyleType: 'disc',
                  marginLeft: '20px',
                  fontSize: '16px',
                }}
              >
                <li>Start with the first person in the line.</li>
                <li>
                  For each person, repeatedly subtract 1 from their ticket count
                  until their ticket count is zero.
                </li>
                <li>Each time a ticket is bought, increment the time by 1.</li>
                <li>
                  Move on to the next person in the line after the current one
                  finishes buying their tickets.
                </li>
              </ul>
            </div>

            <Box
              style={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '800px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`function time_needed_to_buy_tickets_bruteforce(tickets):
    total_time = 0
    while tickets:
        # Each round, process each person in the line
        for i from 0 to length of tickets - 1:
            if tickets[i] > 0:
                tickets[i] -= 1  # Person buys one ticket
                total_time += 1
    return total_time

`}
              </pre>
            </Box>

            <p style={{ paddingLeft: '1.5rem' }}>
              <b>Time and Space Complexity</b>
              <br />
              Since for each person we need to traverse the length of queue (n)
              and let’s say in the worst case he had to buy ‘m’ tickets, thus he
              does the whole process m times.
              <br />
              <br />
              Time Complexity : O(n*m)<br></br>
              Space Complexity : O(1)
            </p>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={2}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='20px'
          >
            <Box
              display='flex'
              flexDirection='column'
              gap='8px'
            >
              <p>
                To simulate the process, we can use a queue. Since people can
                buy one ticket at a time, we can treat the people as a queue and
                simulate their actions more directly.
              </p>
            </Box>
            <Box>
              <Box
                style={{
                  backgroundColor: 'black',
                  padding: '24px',
                  borderRadius: '10px',
                  width: '800px',
                  marginLeft: '1em',
                }}
              >
                <pre style={{ color: 'white', margin: 0 }}>
                  {`
function time_needed_to_buy_tickets_queue(tickets):
total_time = 0
queue = deque(tickets)  # Initialize the queue with ticket requests

while queue:
    person = queue.popleft()  # Take the first person in line
    total_time += 1  # Increment the total time (1 minute per ticket)
    
    # If the person still needs more tickets, they go back in the queue
    if person - 1 > 0:
        queue.append(person - 1)  # Append back to queue with 1 less ticket
    
return total_time
`}
                </pre>
              </Box>
              <br />
              <p style={{ paddingLeft: '1.5rem' }}>
                <b>Time and Space Complexity</b>
                <br />
                Although we still need to traverse the whole line for each
                person, the simulation process becomes easier with the
                implementation of the queue.
                <br />
                Time Complexity : O(n*m)<br></br>
                Space Complexity : O(n)<br></br>
              </p>
            </Box>
          </Box>
        </CustomTabPanel>
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        alignSelf='end'
        justifyContent='space-between'
        gap='12px'
      >
        {!hasMarkedCompleted(topic_id) && (
          <LoadingButton
            variant='contained'
            onClick={() => handlePostCompletedTopic(topic_id)}
            loading={loading}
            loadingPosition='center'
            children='Mark as completed'
            style={{
              width: '170px',
              // backgroundColor: 'transparent',
              borderRadius: '8px',
              border: '1px solid rgba(64, 96, 245, 0.5)',
            }}
          />
        )}

        <Box
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Button
            style={{ gap: '4px' }}
            onClick={handlePrevious}
          >
            <WestIcon /> Previous
          </Button>

          <Button
            style={{ gap: '4px' }}
            onClick={handleNext}
          >
            Next <EastIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default BuyTickets;
