// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography, Stack, useTheme, useMediaQuery } from '@mui/material';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import axios from 'axios';
import { BASE_URL, getHeaders } from 'src/apis/urls';
import { useEfPipeline } from 'src/layouts/EfPipelineContext/EfPipelineContext';
import AnalyzingResponseAnimation from './AnalyzingResponseAnimation';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

const FakeResponseAnalyzer = () => {
  const { formId, setShowFstFailFlow } = useEfPipeline();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [progress, setProgress] = useState(0);
  const [report, setReport] = useState({});

  useEffect(() => {
    fetchResponseAnalysis();
  }, []);

  const fetchResponseAnalysis = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/api/eligibility-forms/${formId}/response_analysis`,
        {
          headers: getHeaders(),
        }
      );
      setReport(res.data.data);
      setShowFstFailFlow(res?.data?.data?.show_fst_fail_flow);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Stack
      spacing={isMobile ? 2 : 4}
      alignItems='center'
      sx={{
        mt: 'clamp(1.5rem, 2.3rem, 3rem)',
        backgroundColor: 'white',
        borderRadius: '8px',
        width: {
          xs: '95vw',
          md: '80vw',
          lg: '75vw',
          xl: '70vw',
        },
        height: {
          xs: 'fit-content',
          sm: '100%',
          md: '90%',
          lg: '95%',
        },
        '@media (max-width: 600px)': {
          height: 'fit-content',
        },
        padding: 'clamp(1.2rem, 2.2rem, 3rem)',
      }}
    >
      <Stack
        direction='row'
        spacing={1}
      >
        <Box
          sx={{
            paddingLeft: '8px',
            paddingRight: '8px',
            alignSelf: 'center',
            fontSize: '2rem',
            [theme.breakpoints.down('md')]: {
              fontSize: '1.7rem',
            },
          }}
        >
          🔍
        </Box>
        <Typography
          variant='body1'
          component='p'
          sx={{
            color: '#1976d2',
            fontFamily: 'Inter',
            fontWeight: 'bolder',
            fontSize: '3rem',
            '@media (max-width: 1100px)': {
              fontSize: '2.8rem',
            },
            '@media (max-width: 800px)': {
              fontSize: '2.5rem',
            },
            '@media (max-width: 700px)': {
              fontSize: '2.2rem',
            },
            '@media (max-width: 600px)': {
              fontSize: '1.7rem',
            },
            width: 'fit-content',
          }}
        >
          Analyzing your responses
        </Typography>
      </Stack>

      <Typography
        variant='caption'
        component='p'
        sx={{
          textAlign: 'center',
          textWrap: 'pretty',
          fontFamily: 'Inter',
          fontWeight: 500,
          fontSize: {
            xs: '1.1rem',
            sm: '1.2rem',
            md: '1.5rem',
          },
        }}
      >
        Please hold on while we evaluate your responses and determine your
        eligibility.
      </Typography>

      {Object.keys(report).length > 0 && (
        <Stack width='inherit'>
          <AnalyzingResponseAnimation report={report} />
        </Stack>
      )}
    </Stack>
  );
};

export default FakeResponseAnalyzer;
