import BookIcon from '@material-ui/icons/Book';
import LinkIcon from '@material-ui/icons/Link';
import BookImg1 from '../../images/resources/book.jpg';
import BookImg2 from '../../images/resources/book2.jpg';
import BookImg3 from '../../images/resources/book3.png';
import BookImg4 from '../../images/resources/book4.jpg';
import BlogImg1 from '../../images/resources/BlogImg1.png';
import BlogImg2 from '../../images/resources/BlogImg2.png';
import BlogImg3 from '../../images/resources/BlogImg3.png';
import BlogImg4 from '../../images/resources/BlogImg4.png';
import BlogImg5 from '../../images/resources/BlogImg5.png';
import BlogImg6 from '../../images/resources/BlogImg6.png';
import BlogImg7 from '../../images/resources/BlogImg7.png';
import BlogImg8 from '../../images/resources/BlogImg8.png';
import BlogImg9 from '../../images/resources/BlogImg9.png';
import BlogImg10 from '../../images/resources/BlogImg10.png';
import BlogImg11 from '../../images/resources/BlogImg11.png';
import BlogImg12 from '../../images/resources/BlogImg12.png';
import BlogImg13 from '../../images/resources/BlogImg13.svg';
import BlogImg14 from '../../images/resources/BlogImg14.png';
import BlogImg15 from '../../images/resources/BlogImg15.png';
import BlogImg16 from '../../images/resources/BlogImg16.png';
import BlogImg17 from '../../images/resources/BlogImg17.svg';
import BlogImg18 from '../../images/resources/BlogImg18.jpeg';
import BlogImg19 from '../../images/resources/BlogImg19.png';
import BlogImg20 from '../../images/resources/BlogImg20.png';
import BlogImg21 from '../../images/resources/BlogImg21.png';
import BlogImg22 from '../../images/resources/BlogImg22.png';
import BlogImg23 from '../../images/resources/BlogImg23.jpeg';
import BlogImg24 from '../../images/resources/BlogImg24.png';
import BlogImg25 from '../../images/resources/BlogImg25.png';
import BlogImg26 from '../../images/resources/BlogImg26.png';
import BlogImg27 from '../../images/resources/BlogImg27.png';
import BlogImg28 from '../../images/resources/BlogImg28.png';
import BlogImg29 from '../../images/resources/BlogImg29.png';
import BlogImg30 from '../../images/resources/BlogImg30.png';
import BlogImg31 from '../../images/resources/BlogImg31.png';
import BlogImg32 from '../../images/resources/BlogImg32.png';
import BlogImg33 from '../../images/resources/BlogImg33.png';
import BlogImg34 from '../../images/resources/BlogImg34.png';
import BlogImg35 from '../../images/resources/BlogImg35.png';
import BlogImg36 from '../../images/resources/BlogImg36.png';
import BlogImg37 from '../../images/resources/BlogImg37.png';
import BlogImg38 from '../../images/resources/BlogImg38.png';
import BlogImg39 from '../../images/resources/BlogImg39.png';
import BlogImg40 from '../../images/resources/BlogImg40.png';
import BlogImg41 from '../../images/resources/BlogImg41.png';
import BlogImg42 from '../../images/resources/BlogImg42.png';
import BlogImg43 from '../../images/resources/BlogImg43.png';
import BlogImg44 from '../../images/resources/BlogImg44.png';
import BlogImg45 from '../../images/resources/BlogImg45.png';
import BlogImg46 from '../../images/resources/BlogImg46.png';
import BlogImg47 from '../../images/resources/BlogImg47.png';
import BlogImg48 from '../../images/resources/BlogImg48.png';
import BlogImg49 from '../../images/resources/BlogImg49.png';
import BlogImg50 from '../../images/resources/BlogImg50.png';
import BlogImg51 from '../../images/resources/BlogImg51.png';
import BlogImg52 from '../../images/resources/BlogImg52.png';
import BlogImg53 from '../../images/resources/BlogImg53.png';
import BlogImg54 from '../../images/resources/BlogImg54.png';
import BlogImg56 from '../../images/resources/BlogImg56.png';
import BlogImg58 from '../../images/resources/BlogImg58.png';
import BlogImg59 from '../../images/resources/BlogImg59.png';
import BlogImg60 from '../../images/resources/BlogImg60.png';
import BlogImg61 from '../../images/resources/BlogImg61.jpeg';
import BlogImg62 from '../../images/resources/BlogImg62.jpg';
import BlogImg63 from '../../images/resources/BlogImg63.webp';
import BlogImg64 from '../../images/resources/BlogImg64.jpeg';
import BlogImg65 from '../../images/resources/BlogImg65.jpeg';
import BlogImg66 from '../../images/resources/BlogImg66.jpeg';
import BlogImg67 from '../../images/resources/BlogImg67.jpeg';
import BlogImg68 from '../../images/resources/BlogImg68.png';
import GitHubImg1 from '../../images/resources/GitHubImg1.png';

export const NewBooksResourcesContent = [
  {
    name: 'Introduction to Algorithms',
    author: 'Thomas H. Cormen',
    image: BookImg1,
    link: 'https://dl.ebooksworld.ir/books/Introduction.to.Algorithms.4th.Leiserson.Stein.Rivest.Cormen.MIT.Press.9780262046305.EBooksWorld.ir.pdf',
  },
  {
    name: 'Cracking the Coding Interview',
    author: 'Gyale Laakmann McDowell',
    image: BookImg2,
    link: 'http://englishonlineclub.com/pdf/Cracking%20the%20Coding%20Interview%20-%20189%20Programming%20Questions%20and%20Solutions%20(6th%20Edition)%20[EnglishOnlineClub.com].pdf',
  },
  {
    name: 'Object Oriented Desgin & Analysis',
    author: 'Brett D. McLaughlin',
    image:
      'https://heycoach-public.s3.ap-south-1.amazonaws.com/pre-courses/thumbnail/Screenshot+from+2024-12-19+15-50-13.png',
    link: 'https://heycoach-public.s3.ap-south-1.amazonaws.com/pre-courses/original/Head+First+Object-Oriented+Analysis+%26+Design.pdf',
  },
  {
    name: 'Designing Data-Intensive Applications',
    author: 'Martin Kleppmann',
    image:
      'https://heycoach-public.s3.ap-south-1.amazonaws.com/pre-courses/thumbnail/Screenshot+from+2024-12-19+15-50-49.png',
    link: 'https://heycoach-public.s3.ap-south-1.amazonaws.com/pre-courses/original/Martin-Kleppmann---Designing-Data-Intensive-Applications_-O%E2%80%99Reilly-Media-(2017).pdf',
  },
];

export const NewBlogssResourcesContent = [
  {
    topicName: 'User Interface',
    recources: [
      {
        image: BlogImg10,
        name: '16 little UI design rules that make a big impact',
        description:
          'User interface design is hard. With so many options to choose from regarding layout, spacing, typography, and colour, making design decisions can be overwhelming.',
        tags: ['User Interface', 'User Experience'],
        link: 'https://uxplanet.org/16-ui-design-tips-ba2e7524d203',
      },
      {
        image: BlogImg26,
        name: '21 UI and UX designs to try in 2024',
        description:
          'UI and UX are two facets of web design that work hand-in-hand. While UI focuses on the user interface design, UX covers the overall user experience as they interact with your product or service.',
        tags: ['User Interface', 'User Experience'],
        link: 'https://webflow.com/blog/ui-ux-design-tools',
      },
      {
        image: BlogImg28,
        name: 'Ultimate guide UI/UX design in 2024',
        description:
          'It is introducing new and exciting tools and workflows that are transforming how designers work. Plus, AI is making design processes smarter and more efficient, so that designers to focus more on the strategic aspects.',
        tags: ['User Interface', 'User Experience'],
        link: 'https://www.thedesignership.com/blog/the-ultimate-guide-to-ux-ui-design-in-2024',
      },
      {
        image: BlogImg58,
        name: 'User Interface UI Design',
        description:
          'User interface (UI) design is the process designers use to build interfaces in software or computerized devices, focusing on looks or style. Designers aim to create interfaces which users find easy to use and pleasurable. UI design refers to graphical user interfaces and other forms—e.g., voice-controlled interfaces.',
        tags: ['User Interface', 'User Experience'],
        link: 'https://www.interaction-design.org/literature/topics/ui-design',
      },
      {
        image: BlogImg56,
        name: '18 Best User Interface (UI) Design Software Picks In 2024',
        description:
          'If your approach to UI design is anything like mine, you are not really interested in anything but the best user interface design software. After all, this gig is really about making products feel so seamless to use, your users should feel like they were born knowing how. Clunky, user-unfriendly tools just not it—and that is where I come in.',
        tags: ['User Interface', 'User Experience'],
        link: 'https://theproductmanager.com/tools/best-user-interface-design-software/',
      },
    ],
  },
  {
    topicName: 'User Experience',
    recources: [
      {
        image: BlogImg8,
        name: 'What I Learned as a Product Designer at Apple',
        description:
          'In 2021 I landed my dream job. Working at Apple, the holy grail of minimalistic design, innovation and creativity.',
        tags: ['User Experience', 'Product Development'],
        link: 'https://medium.com/@pachecoandrea/what-i-learned-as-a-product-designer-at-apple-35341d9ced8a',
      },
      {
        image: BlogImg10,
        name: '16 little UI design rules that make a big impact',
        description:
          'User interface design is hard. With so many options to choose from regarding layout, spacing, typography, and colour, making design decisions can be overwhelming.',
        tags: ['User Interface', 'User Experience'],
        link: 'https://uxplanet.org/16-ui-design-tips-ba2e7524d203',
      },
      {
        image: BlogImg53,
        name: 'Designing for the future: 10 UX design trends for 2024',
        description:
          'This is not just another list of trendy UI elements and UX features, nor is it a trite compilation of cool design features and components. Instead, it’s a deep look into the big changes happening in how things work online. As the way we use the internet is changing, so will the way we design experiences and interfaces across these technology-enabled solutions.',
        tags: ['User Experience'],
        link: 'https://blog.logrocket.com/ux-design/10-ux-design-trends-2024/',
      },
      {
        image: BlogImg15,
        name: 'Top 5 UI/UX Design Agencies In The World — July 2024',
        description:
          'Find the best UX design agency in the world. We rank the world’s top user experience (UX) design firms and UI/UX design companies in San Francisco, NYC, Los Angeles, and London.',
        tags: ['User Experience'],
        link: 'https://bootcamp.uxdesign.cc/top-user-experience-ui-ux-design-agencies-37f6423bde26',
      },
      {
        image: BlogImg25,
        name: 'The top UX design trends in 2024 (and what they mean for UX designers)',
        description:
          'What are the most influential trends shaping the UX industry right now? Discover the top UX trends for 2024—and what they mean for designers.',
        tags: ['User Experience'],
        link: 'https://www.uxdesigninstitute.com/blog/the-top-ux-design-trends-in-2024/',
      },
      {
        image: BlogImg54,
        name: 'Top 10 UX Design Agencies to Work With – July 2024',
        description:
          'As we step into 2024, the significance of investing in user experience (UX) and user interface (UI) design remains dominant. Choose from the top UX agencies that can help you, whether you are looking to develop a new website, revamp an app, or identify areas for improvement in your digital product.',
        tags: ['User Experience'],
        link: 'https://uxstudioteam.com/ux-blog/top-ux-agencies/',
      },
    ],
  },
  {
    topicName: 'Product Development',
    recources: [
      {
        image: BlogImg8,
        name: 'What I Learned as a Product Designer at Apple',
        description:
          'In 2021 I landed my dream job. Working at Apple, the holy grail of minimalistic design, innovation and creativity.',
        tags: ['User Experience', 'Product Development'],
        link: 'https://medium.com/@pachecoandrea/what-i-learned-as-a-product-designer-at-apple-35341d9ced8a',
      },
      {
        image: BlogImg43,
        name: 'What Is Product Development? 7 Steps to Making a Product (2024)',
        description:
          'Learn everything about the product development lifecycle as well as tactics to improve your workflow to help bring your vision to life.',
        tags: ['Product Development'],
        link: 'https://www.shopify.com/blog/product-development-process',
      },
      {
        image: BlogImg38,
        name: 'Agile teams choose Jira',
        description:
          'ira has allowed a great deal of transparency across business and development teams – contributing to a product that is truly a collaborative effort every step of the way.',
        tags: ['Product Development'],
        link: 'https://www.atlassian.com/software/jira?&aceid=&adposition=&adgroup=143541079852&campaign=17623646553&creative=607643206043&device=c&keyword=production%20management%20software%20development&matchtype=p&network=g&placement=&ds_kids=p71908719707&ds_e=GOOGLE&ds_eid=700000001756546&ds_e1=GOOGLE&gad_source=1&gclid=Cj0KCQjwu8uyBhC6ARIsAKwBGpSQfT55rB43RydtD683MsXBzEEBKRdwrgmX0biiWPJz0F76zF7EdNIaAoC0EALw_wcB&gclsrc=aw.ds',
      },
      {
        image: BlogImg59,
        name: '29 Best Product Development Software Picks In 2024',
        description:
          'As a product manager, I’ve evaluated hundreds of tools and selected the top product development software to help you save time and foster a more efficient and integrated development process.',
        tags: ['Product Development'],
        link: 'https://theproductmanager.com/tools/product-development-software/',
      },
    ],
  },
  {
    topicName: 'Technology',
    recources: [
      {
        image: BlogImg1,
        name: 'The Art of Paying Attention',
        description:
          'Attention is the beginning of devotion,” the poet Mary Oliver wrote in her final collection of essays. In 2021, the poet Leila Chatti took up Oliver’s words, reflecting on the challenge of them: “All day, the world makes its demands. There is so much of it, world / begging to be noticed',
        tags: ['Technology', 'Digital Life'],
        link: 'https://www.theatlantic.com/newsletters/archive/2023/05/attention-mary-oliver-technology/674227/',
      },
      {
        image: BlogImg3,
        name: 'Neuralink and the Brain’s Magical Future',
        description:
          'When I wrote about Tesla and SpaceX,ss I learned that you can only fully wrap your head around certain companies by zooming both way, way in and way, way out.',
        tags: ['Technology'],
        link: 'https://waitbutwhy.com/2017/04/neuralink.html',
      },
      {
        image: BlogImg48,
        name: 'The 11 most exciting tech trends of 2024, according to CES 2024',
        description:
          'CES 2024 is over, and what have we learned? Well, mainly not to put our hands in wearable ovens or indecently expose ourselves in smart showers. But also, some more serious lessons about where tech is headed in 2024. Yes, this year CES gave us some fascinating glimpses of how gadgets could change our lives this year – and we have rounded up all of those flashes of insight below.',
        tags: ['Technology'],
        link: 'https://www.techradar.com/tech-events/the-11-biggest-tech-trends-of-ces-2024',
      },
      {
        image: BlogImg31,
        name: 'Tech Trends 2024',
        description:
          'Deloitte’s 15th annual Tech Trends report helps business and technology leaders separate signal from noise and embrace technology’s evolution as a tool to revolutionize business.',
        tags: ['Technology'],
        link: 'https://www2.deloitte.com/us/en/insights/focus/tech-trends.html#elevating-forces',
      },
      {
        image: BlogImg44,
        name: '13 Top Technology Trends (2024 & 2025)',
        description:
          'Notably, AI is breaking into finance, healthcare, manufacturing, retail and dozens of other industries.And, it is not a technology reserved for large enterprises anymore.With open source AI solutions and lower cost and complexity of systems, the democratization of AI is in full swing.',
        tags: ['Technology'],
        link: 'https://explodingtopics.com/blog/technology-trends',
      },
      {
        image: BlogImg46,
        name: 'Human by design: How AI unleashes the next level of human potential',
        description:
          'This is a moment for reinvention. In the coming years, businesses will have an increasingly powerful array of technologies at their disposal that will open new pathways to unleash greater human potential, productivity, and creativity. Early adopters and leading businesses have kick-started a race toward a new era of value and capability. And their strategies are underpinned by one common thread – the technology is becoming more human.',
        tags: ['Technology'],
        link: 'https://www.accenture.com/us-en/insights/technology/technology-trends-2024',
      },
      {
        image: BlogImg49,
        name: 'Top 10: Technology trends for 2024',
        description:
          'From more advances in AI to cybersecurity and developments in quantum computing, we hear from a range of technology experts on their 2024 predictions',
        tags: ['Technology'],
        link: 'https://technologymagazine.com/top10/top-10-technology-trends-for-2024',
      },
    ],
  },
  {
    topicName: 'Digital Life',
    recources: [
      {
        image: BlogImg1,
        name: 'The Art of Paying Attention',
        description:
          'Attention is the beginning of devotion,” the poet Mary Oliver wrote in her final collection of essays. In 2021, the poet Leila Chatti took up Oliver’s words, reflecting on the challenge of them: “All day, the world makes its demands. There is so much of it, world / begging to be noticed',
        tags: ['Technology', 'Digital Life'],
        link: 'https://www.theatlantic.com/newsletters/archive/2023/05/attention-mary-oliver-technology/674227/',
      },
      {
        image: BlogImg52,
        name: 'An introduction to Deloitte’s 2024 Digital Media Trends',
        description:
          'As streaming, social, and gaming converge, companies should consider comprehensive strategies to define media and entertainment future—or they could risk living in someone else vision.',
        tags: ['Digital Life'],
        link: 'https://www2.deloitte.com/us/en/insights/industry/technology/digital-media-trends-consumption-habits-survey/2024/digital-media-trends-introduction.html',
      },
      {
        image: BlogImg46,
        name: 'Human by design: How AI unleashes the next level of human potential',
        description:
          'This is a moment for reinvention. In the coming years, businesses will have an increasingly powerful array of technologies at their disposal that will open new pathways to unleash greater human potential, productivity, and creativity. Early adopters and leading businesses have kick-started a race toward a new era of value and capability. And their strategies are underpinned by one common thread – the technology is becoming more human.',
        tags: ['Technology', 'Digital Life'],
        link: 'https://www.accenture.com/us-en/insights/technology/technology-trends-2024',
      },
      {
        image: BlogImg27,
        name: 'As AI Spreads, Experts Predict the Best and Worst Changes in Digital Life by 2035',
        description:
          'They have deep concerns about people’s and society’s overall well-being. But they also expect great benefits in health care, scientific advances and education',
        tags: ['Technology', 'Digital Life'],
        link: 'https://www.pewresearch.org/internet/2023/06/21/as-ai-spreads-experts-predict-the-best-and-worst-changes-in-digital-life-by-2035/',
      },
    ],
  },
  {
    topicName: 'Version Control',
    recources: [
      // {
      //   image: BlogImg2,
      //   name: 'Use Git like a Senior Engineer',
      //   description:
      //     'I’ve used these features of Git for years across teams and projects. I’m still developing opinions around some workflows (like to squash or not) but the core tooling is powerful and flexible (and scriptable!).',
      //   tags: ['Git', 'Version Control', 'Programming'],
      //   link: 'https://levelup.gitconnected.com/use-git-like-a-senior-engineer-ef6d741c898e',
      // },
      {
        image: BlogImg13,
        name: '10 Git Skills You Still Need to Know for Version Control in 2024',
        description:
          'I hope this never happens to you―because it happened to me once and it was awful! While working on a complex software project with a team of developers from around the world, one of my worst nightmares became a reality: I accidentally deleted some critical code by overwriting a colleague work. But the worst part was, we did not have a backup! I spent hours cleaning up my mistake by meticulously rebuilding my teammate work.',
        tags: ['Git', 'Version Control'],
        link: 'https://www.dataquest.io/blog/10-git-skills-you-still-need-to-know-for-version-control-in-2024/',
      },
      {
        image: BlogImg24,
        name: 'What are Git version control best practices?',
        description:
          'Making the most of Git involves learning best practices to streamline workflows and ensure consistency across a codebase.',
        tags: ['Git', 'Version Control'],
        link: 'https://about.gitlab.com/topics/version-control/version-control-best-practices/',
      },
      {
        image: BlogImg40,
        name: 'A Comprehensive Guide to Version Control with Git and GitHub',
        description:
          'In the world of software development, version control is an indispensable tool that can save you countless headaches and help streamline your development process. Whether you are a solo developer working on a personal project or part of a large team collaborating on a complex software product, mastering version control with Git and GitHub is a skill that can significantly enhance your productivity and code management. In this comprehensive guide, we will explore the fundamentals of Git and how to leverage the power of GitHub for effective version control.',
        tags: ['Git', 'Version Control'],
        link: 'https://dev.to/saloman_james/a-comprehensive-guide-to-version-control-with-git-and-github-3m0n',
      },
      {
        image: GitHubImg1,
        name: 'What is version control?',
        description:
          'Version control, also known as source control, is the practice of tracking and managing changes to software code. Version control systems are software tools that help software teams manage changes to source code over time. As development environments have accelerated, version control systems help software teams work faster and smarter. ',
        tags: ['Git', 'Version Control'],
        link: 'https://www.atlassian.com/git/tutorials',
      },
      {
        image: BlogImg39,
        name: 'Here is a basic overview of how Git works:',
        description:
          'Git is a free and open-source version control system, originally created by Linus Torvalds in 2005. Unlike older centralized version control systems such as SVN and CVS, Git is distributed: every developer has the full history of their code repository locally. This makes the initial clone of the repository slower, but subsequent operations such as commit, blame, diff, merge, and log dramatically faster.',
        tags: ['Git', 'Version Control'],
        link: 'https://www.atlassian.com/git',
      },
      {
        image: BlogImg17,
        name: 'Advanced Git tutorials',
        description:
          'Atlassian’s Git tutorials introduce the most common Git commands, and our Git Workflows modules discuss how these commands are typically used to facilitate collaboration. Alone, these are enough to get a development team up and running with Git. But, if you really want to leverage the full power of Git, you are re ready to dive into our Advanced Git articles.',
        tags: ['Git', 'Version Control'],
        link: 'https://www.atlassian.com/git/tutorials/advanced-overview',
      },
      {
        image: GitHubImg1,
        name: 'Learn Git & GitHub',
        description:
          'Use our beginner friendly Git course to integrate Git and GitHub and manage versions of your projects using Git branches.',
        tags: ['Git', 'Version Control'],
        link: 'https://www.codecademy.com/learn/learn-git',
      },
    ],
  },
  {
    topicName: 'Programming',
    recources: [
      // {
      //   image: BlogImg2,
      //   name: 'Use Git like a Senior Engineer',
      //   description:
      //     'I’ve used these features of Git for years across teams and projects. I’m still developing opinions around some workflows (like to squash or not) but the core tooling is powerful and flexible (and scriptable!).',
      //   tags: ['Git', 'Version Control', 'Programming'],
      //   link: 'https://levelup.gitconnected.com/use-git-like-a-senior-engineer-ef6d741c898e',
      // },
      {
        image: BlogImg5,
        name: 'Say bye bye to bad javascript practices',
        description:
          'When we take our first steps into the wonderful world of programming, we get to see for ourselves what it does for millions of people.',
        tags: ['Web Development', 'Programming'],
        link: 'https://blog.gogrow.dev/say-bye-bye-to-bad-javascript-practices-5971688ba80a',
      },
      {
        image: BlogImg9,
        name: '5 ways to review code without wasting everyone’s time',
        description:
          'As an iOS developer, I have reviewed thousands of lines of code from my peers. Like programming, reviewing code makes you feel either smart or utterly stupid.',
        tags: ['Code Review', 'Programming'],
        link: 'https://medium.com/volvo-cars-engineering/5-ways-to-review-code-without-wasting-everyones-time-aedeecc51094',
      },
      {
        image: BlogImg12,
        name: '10 Alpha Tips for Solving any Coding Question in an Interview',
        description:
          'If the input is a sorted array or list, both binary search and the two pointers strategy can be efficient ways to find a particular element or a range of elements.',
        tags: ['Coding Interview', 'Programming'],
        link: 'https://medium.com/@Harshit_Raj_14/10-alpha-tips-for-solving-any-coding-question-in-an-interview-30d1414ede18',
      },
      {
        image: BlogImg60,
        name: 'Coding Best Practices and Guidelines for Better Code',
        description:
          'Creating code is an essential part of many data professions. But creating code that functions is only half the job. The code also needs to be clear, easy to hand off, and robust to disturbances. By following a few coding guidelines in your projects, you can save yourself time restructuring your code later on and make your collaborators happy, too.',
        tags: ['Coding Interview', 'Programming'],
        link: 'https://www.datacamp.com/tutorial/coding-best-practices-and-guidelines',
      },
      {
        image: BlogImg35,
        name: 'Using GitHub Copilot in your IDE: Tips, tricks, and best practices',
        description:
          'GitHub Copilot is a powerful AI assistant. Learn practical strategies to get the most out of GitHub Copilot to generate the most relevant and useful code suggestions in your editor.',
        tags: ['Coding Interview', 'Programming'],
        link: 'https://github.blog/2024-03-25-how-to-use-github-copilot-in-your-ide-tips-tricks-and-best-practices/',
      },
      {
        image: BlogImg34,
        name: '6 Coding Trends for 2024 and Beyond',
        description:
          'According to MarketSplash, there are more than 27 million software developers worldwide. Moreover, the software market revenue is expected to grow to almost $660 billion by the end of 2023.',
        tags: ['Programming'],
        link: 'https://www.codementor.io/blog/coding-trends-2024-ifmigy3uyl',
      },
      {
        image: BlogImg21,
        name: 'The Future of Coding: 7 Trends for 2024 and Beyond',
        description:
          'In the ever-evolving world of coding, staying ahead of the curve is essential. As we step into 2024, let’s explore the cutting-edge trends that will shape the way we write code, solve problems, and innovate digitally. Buckle up, fellow developers, because the future is here, and it is electrifying.',
        tags: ['Programming'],
        link: 'https://medium.com/@coding.kevin_/the-future-of-coding-7-trends-for-2024-and-beyond-8792084a1a43',
      },
      {
        image: BlogImg36,
        name: 'Turn your workforce into a force of nature',
        description:
          'Designed by in-house instructional design experts, our learning programs surface talent and build durable skills. Whether you’re upskilling analysts, equipping your frontline with power skills and digital foundations, or embracing the transition to AI, you can count on us to help prepare your people with the right skills for what’s next.',
        tags: ['Programming'],
        link: '',
      },
      // {
      //   image: BlogImg42,
      //   name: 'Top 100+ Websites to Learn to Code for Free',
      //   description:
      //     'As COVID-19 has boosted the growth of the global eCommerce and tech industries, more and more people are learning to code to find or switch to tech jobs. To do so, many rely on online learning resources, as there are fewer in-person coding bootcamps and workshops.',
      //   tags: ['Programming'],
      //   link: 'https://www.hostinger.in/tutorials/learn-coding-online-for-free',
      // },
      {
        image: BlogImg47,
        name: '11 Best Websites to Practice Coding for Beginners in 2024',
        description:
          'Coding is not a spectator sport. To develop your skills, you must write it yourself. Here are the best websites to practice coding.',
        tags: ['Programming'],
        link: 'https://flatironschool.com/blog/best-websites-to-practice-coding-for-beginners/',
      },
      {
        image: BlogImg18,
        name: 'Top 13 Websites to Learn To Code – A Comprehensive Guide',
        description:
          'Code is becoming an essential skill in our modern world, and more and more people are looking to learn how to code because of the wide variety of careers available to coders. ',
        tags: ['Programming'],
        link: 'https://www.codemonkey.com/blog/top-websites-to-learn-to-code/',
      },
    ],
  },
  {
    topicName: 'Code Review',
    recources: [
      {
        image: BlogImg9,
        name: '5 ways to review code without wasting everyone’s time',
        description:
          'As an iOS developer, I have reviewed thousands of lines of code from my peers. Like programming, reviewing code makes you feel either smart or utterly stupid.',
        tags: ['Code Review', 'Programming'],
        link: 'https://medium.com/volvo-cars-engineering/5-ways-to-review-code-without-wasting-everyones-time-aedeecc51094',
      },
      {
        image: BlogImg22,
        name: '8 proven code review best practices for developers',
        description:
          'One of the best ways to improve the quality and security of software is to implement a formal process for manual code reviews. Given the potential errors during code writing, using several fresh eyes with complementary expertise can uncover mistakes the original programmer may never notice. Moreover, while humans are prone to error on their own, a group of experts may catch a bug or security flaw within the code that automated code review tools may miss.',
        tags: ['Code Review'],
        link: 'https://snyk.io/learn/code-review/',
      },
      {
        image: BlogImg41,
        name: 'Secure coding practices every developer should know',
        description:
          'In  this interconnected, software- dependent world, releasing secure apps has become a top priority for developers.The good news is that many potential exploits and attacks can be prevented through writing better and more secure source code.',
        tags: ['Code Review'],
        link: 'https://snyk.io/learn/secure-coding-practices/',
      },
      {
        image: GitHubImg1,
        name: 'All About Code Review',
        description:
          'This is a curated collection of articles, tools, checklists and other awesome resources about code reviews. For more insights into code reviews...',
        tags: ['Code Review'],
        link: 'https://github.com/mgreiler/all-about-code-review',
      },
      {
        image: BlogImg20,
        name: 'Code Review: Best Practices',
        description:
          'Code Review is an essential part of the software development process, but it can be counterproductive if done wrong. In this course, Code Review: Best Practices, you will learn the essential soft skills necessary for Code Reviews. First, you will explore the Code Review process fundamentals that should be put in place before the first review even happens. ',
        tags: ['Code Review'],
        link: 'https://www.pluralsight.com/courses/code-review-best-practices',
      },
      {
        image: BlogImg19,
        name: 'Code Review Best Practices ',
        description:
          'Documenting and standardizing the code review process is a vital aspect of software development, as it ensures consistency, maintainability, and readability throughout your codebase. ',
        tags: ['Code Review'],
        link: 'https://roadmap.sh/best-practices/code-review',
      },
      {
        image: BlogImg30,
        name: 'Top 11 Best Practices for Code Review',
        description:
          'Collaborative code evaluation is essential for ensuring code quality and knowledge sharing. By following code review best practices, teams can enhance code quality, identify and fix bugs, and foster effective collaboration. Discover 11 best practices for code review to improve code quality and smooth out software development ',
        tags: ['Code Review'],
        link: 'https://quokkalabs.com/blog/top-11-best-practices-for-code-review/',
      },
      {
        image: BlogImg45,
        name: 'Code Review – Best Practices, Guidelines & Process Insights',
        description:
          'Code review is one of the buzzwords everyone heard about. This technique strictly related to creating software is worth getting familiar with by everyone working in the IT environment.',
        tags: ['Code Review'],
        link: 'https://www.ideamotive.co/blog/code-review-best-practices',
      },
    ],
  },
  {
    topicName: 'Time Management',
    recources: [
      {
        image: BlogImg4,
        name: '100 Blocks a Day',
        description:
          'Most people sleep about seven or eight hours a night. That leaves 16 or 17 hours awake each day. Or about 1,000 minutes.',
        tags: ['Time Management'],
        link: 'https://waitbutwhy.com/2016/10/100-blocks-day.html',
      },
      // {
      //   image: BlogImg6,
      //   name: 'In 1911, a genius revealed a forgotten science of how to be 50x more productive without working more hours',
      //   description:
      //     'Peter Drucker, one of the most respected management thinkers in history, stated these words at the close of the 20th century.',
      //   tags: ['Time Management', 'Self-Improvement'],
      //   link: 'https://medium.com/accelerated-intelligence/in-1911-an-influential-expert-revealed-a-forgotten-science-of-how-to-be-50x-more-productive-8828f86eb1c9',
      // },
      {
        image: BlogImg29,
        name: '18 Effective Time Management Strategies and Techniques',
        description:
          'You may feel like there’s never enough time in the day to get all of your work done. That’s why it’s crucial to find effective time management strategies and techniques to get the most out of each day.',
        tags: ['Time Management'],
        link: 'https://www.upwork.com/resources/time-management-strategies',
      },
      {
        image: BlogImg23,
        name: '10 Best Free Online Time Management Training Courses in 2024',
        description:
          'Effective time management is crucial to obtain success, both in the professional and personal life.This article will explore ten exceptional free online time management training courses that cover essential time management principles, strategies, and techniques.',
        tags: ['Time Management'],
        link: 'https://timeular.com/blog/best-free-online-time-management-training-courses/',
      },
      {
        image: BlogImg51,
        name: '11 Best Time Management Apps in 2024 (Reviews & Pricing)',
        description:
          'Do you find yourself getting distracted when working on a tight deadline? Or end the day feeling unproductive and wondering where all the time went?',
        tags: ['Time Management'],
        link: 'https://clickup.com/blog/time-management-apps/',
      },
      {
        image: BlogImg50,
        name: 'Time Management Skills: Strategies and Resources',
        description:
          'Think back to the last time you unexpectedly ran into a friend, acquaintance, or business partner. Did you both express how busy you are, how packed your schedule is, or how time is just flying by? These days it seems that we’re all in a constant state of ‘busy’ — and this is especially true for project managers. Not only do you need to manage your own time, you’ve got to manage the time of your teammates. This is a challenge, yes, but a challenge you can overcome with solid time management skills. ',
        tags: ['Time Management'],
        link: 'https://unito.io/blog/time-management-skills-strategies-resources/',
      },
    ],
  },
  {
    topicName: 'Self-Improvement',
    recources: [
      // {
      //   image: BlogImg6,
      //   name: 'In 1911, a genius revealed a forgotten science of how to be 50x more productive without working more hours',
      //   description:
      //     'Peter Drucker, one of the most respected management thinkers in history, stated these words at the close of the 20th century.',
      //   tags: ['Time Management', 'Self-Improvement'],
      //   link: 'https://medium.com/accelerated-intelligence/in-1911-an-influential-expert-revealed-a-forgotten-science-of-how-to-be-50x-more-productive-8828f86eb1c9',
      // },
      // {
      //   image: BlogImg14,
      //   name: 'Drive talent outcomes to transform business outcomes',
      //   description:
      //     'Get the only platform that braids coaching, science, and AI-fueled experiences to drive the performance your organization needs.',
      //   tags: ['Time Management', 'Self-Improvement'],
      //   link: 'https://www.betterup.com',
      // },
      {
        image: BlogImg62,
        name: 'Why You Do not Vibe With Everyone You Meet',
        description:
          'Is it weird that we connect to some people more than others? Think about it. Say you meet someone at the park and you feel at home with one another. ',
        tags: ['Time Management', 'Self-Improvement'],
        link: 'https://www.verywellmind.com',
      },
      {
        image: BlogImg32,
        name: 'An Easy & Proven Way to Build Good Habits & Break Bad Ones',
        description:
          'Packed with self-improvement strategies, Atomic Habits will teach you how to make the small changes that will transform your habits and deliver remarkable results.',
        tags: ['Time Management', 'Self-Improvement'],
        link: 'https://jamesclear.com',
      },
    ],
  },
  {
    topicName: 'Web Development',
    recources: [
      {
        image: BlogImg5,
        name: 'Say bye bye to bad javascript practices',
        description:
          'When we take our first steps into the wonderful world of programming, we get to see for ourselves what it does for millions of people.',
        tags: ['Web Development', 'Programming'],
        link: 'https://blog.gogrow.dev/say-bye-bye-to-bad-javascript-practices-5971688ba80a',
      },
      {
        image: BlogImg7,
        name: '12 Websites You’ll Love As A Developer',
        description:
          'Websites are now an essential tool for every business. And if you are a web developer and need to find new material or resources, then this is the place for you.',
        tags: ['Web Development'],
        link: 'https://blog.gogrow.dev/say-bye-bye-to-bad-javascript-practices-5971688ba80a',
      },
      {
        image: BlogImg63,
        name: 'Developer essentials: JavaScript console methods',
        description:
          'People use the console to log outputs such as a calculation result, a REST API return value, the outcome of string manipulation, a reminder to revisit a certain part of a program (e.g., TODO: fix). The possibilities are endless. You can use the console to output any kind of information you like. When running JavaScript in your browser, console logs are visible in your browser developer console.',
        tags: ['Web Development', 'Programming'],
        link: 'https://developer.mozilla.org/en-US/blog/learn-javascript-console-methods/',
      },
      {
        image: BlogImg64,
        name: 'Text Editing, Done Right',
        description:
          'With the new Tab Multi-Select functionality, tabs become first-class citizens in the interface. A simple modifier when performing actions will split the interface to show multiple tabs at once. Works with the side bar, tab bar, Goto Anything and more!',
        tags: ['Web Development', 'Programming'],
        link: 'https://www.sublimetext.com',
      },
      // {
      //   image: BlogImg65,
      //   name: 'App & Browser Testing Made Easy',
      //   description:
      //     'Give your users a seamless experience by testing on 20,000+ real devices. Do not compromise with emulators and simulators.',
      //   tags: ['Web Development', 'Programming'],
      //   link: 'https://www.browserstack.com',
      // },
    ],
  },
  {
    topicName: 'Coding Interview',
    recources: [
      {
        image: BlogImg11,
        name: 'After Solving 400 Leetcode questions, I have gained valuable insights and lessons that have shaped my approach to software development. Here are five things that I have learned',
        description:
          'Many software developers who are searching for a job are familiar with Leetcode, a website that compiles technical interview questions and answers from top companies such as Google, Facebook, and Microsoft.',
        tags: ['Coding Interview'],
        link: 'https://medium.com/@rbharambe66/after-solving-400-leetcode-questions-i-have-gained-valuable-insights-and-lessons-that-have-shaped-5d5f6ea9f594',
      },
      {
        image: BlogImg12,
        name: '10 Alpha Tips for Solving any Coding Question in an Interview',
        description:
          'If the input is a sorted array or list, both binary search and the two pointers strategy can be efficient ways to find a particular element or a range of elements.',
        tags: ['Coding Interview', 'Programming'],
        link: 'https://medium.com/@Harshit_Raj_14/10-alpha-tips-for-solving-any-coding-question-in-an-interview-30d1414ede18',
      },
      {
        image: BlogImg66,
        name: 'Data structures and algorithms study cheatsheets for coding interviews',
        description:
          'This section dives deep into practical knowledge and techniques for algorithms and data structures which appear frequently in algorithm interviews. The more techniques you have in your arsenal, the higher the chances of passing the interview. They may lead you to discover corner cases you might have missed out or even lead you towards the optimal approach!',
        tags: ['Coding Interview', 'Programming'],
        link: 'https://www.techinterviewhandbook.org/algorithms/study-cheatsheet/',
      },
      {
        image: BlogImg37,
        name: 'Tech Interview Bootcamp: From Coding Fundamentals to FAANG Offers',
        description:
          'Fast-track your job search with our 12-week program, designed for software engineers targeting top tech companies. Led by ex-FAANG engineers, we focus on technical and communication skills for coding and system design interviews.',
        tags: ['Coding Interview', 'Programming'],
        link: 'https://www.designgurus.io/interview-bootcamp',
      },
      {
        image: BlogImg67,
        name: 'Data Structures & Algorithms',
        description:
          'Follow a structured path to learn all of the core data structures & algorithms. Perfect for coding interview preparation.',
        tags: ['Coding Interview', 'Programming'],
        link: 'https://neetcode.io/roadmap',
      },
      {
        image: BlogImg16,
        name: 'My favorite coding question to give candidates (and why)',
        description:
          'There are so many blogs and videos online showing you answers to LeetCode questions. But the viewpoint is mostly as an interviewee, not as an interviewer.',
        tags: ['Coding Interview', 'Programming'],
        link: 'https://carloarg02.medium.com/my-favorite-coding-question-to-give-candidates-17ea4758880c',
      },
      {
        image: BlogImg68,
        name: 'Gear Up for Success: Your Ultimate Preparation Hub!',
        description:
          'Navigate Your Learning Adventure: Explore DSA, Master CS Concepts, Design Systems, Hone Competitive Skills, and Ace Interviews Effortlessly',
        tags: ['Coding Interview', 'Programming'],
        link: 'https://takeuforward.org',
      },
      {
        image: BlogImg33,
        name: 'The Interview Prep& Talent Hiring AI Platform',
        description:
          'Powered by Large Language Models, Vernacular Speech AI to source, screen, filter, assess candidates And tools for candidates to prepare and apply',
        tags: ['Coding Interview', 'Programming'],
        link: 'https://interviewcracker.com',
      },
    ],
  },
];

export const ResourcesContent = [
  {
    category: 'Books on DSA and System Design',
    icon: <BookIcon />,
    items: [
      {
        name: 'Introduction to Algorithms by Thomas H. Cormen et al.',
        link: 'https://dl.ebooksworld.ir/books/Introduction.to.Algorithms.4th.Leiserson.Stein.Rivest.Cormen.MIT.Press.9780262046305.EBooksWorld.ir.pdf',
      },
      {
        name: 'Cracking the Coding Interview by Gayle Laakmann McDowell.',
        link: 'http://englishonlineclub.com/pdf/Cracking%20the%20Coding%20Interview%20-%20189%20Programming%20Questions%20and%20Solutions%20(6th%20Edition)%20[EnglishOnlineClub.com].pdf',
      },
    ],
  },
  {
    category: 'Books on ways to improve your learning methods',
    icon: <BookIcon />,
    items: [
      {
        name: 'The Science of Learning by Deans for Impact',
        link: 'http://www.deansforimpact.org/wp-content/uploads/2016/12/The_Science_of_Learning.pdf',
      },
      {
        name: 'Make It Stick: The Science of Successful Learning',
        link: 'http://scaling4growth.com/wp-content/uploads/2015/10/Make-it-Stick.pdf',
      },
    ],
  },
  {
    category: 'Blogs',
    icon: <LinkIcon />,
    items: [
      {
        name: 'The Art of Paying Attention',
        link: 'https://www.theatlantic.com/newsletters/archive/2023/05/attention-mary-oliver-technology/674227/',
      },
      {
        name: 'You’re Using ChatGPT Wrong! Here’s How to Be Ahead of 99% of ChatGPT Users',
        link: 'https://artificialcorner.com/youre-using-chatgpt-wrong-here-s-how-to-be-ahead-of-99-of-chatgpt-users-886a50dabc54?gi=e176e5213bd9&source=explore---------0-102--------------------97fe0490_b441_40d6_bcd1_e32ecc2dce01-------15',
      },
      {
        name: 'Use Git like a senior engineer',
        link: 'https://levelup.gitconnected.com/use-git-like-a-senior-engineer-ef6d741c898e',
      },
      {
        name: 'The 11 Lessons Dharmesh Shah Learned From 80+ Angel Investments',
        link: 'https://thehustle.co/11-lessons-dharmesh-shah-learned-from-80-angel-investments/',
      },
      {
        name: 'Neuralink and the Brain’s Magical Future',
        link: 'https://waitbutwhy.com/2017/04/neuralink.html',
      },
      {
        name: '100 Blocks a Day',
        link: 'https://waitbutwhy.com/2016/10/100-blocks-day.html',
      },
      {
        name: 'Say bye bye to bad JavaScript practices',
        link: 'https://blog.gogrow.dev/say-bye-bye-to-bad-javascript-practices-5971688ba80a?gi=5117eb624ff5&source=explore---------33-98--------------------477f80a7_ef26_4153_a705_b017572775cc-------15',
      },
      {
        name: 'In 1911, a genius revealed a forgotten science of how to be 50x more productive without working more hours',
        link: 'https://medium.com/accelerated-intelligence/in-1911-an-influential-expert-revealed-a-forgotten-science-of-how-to-be-50x-more-productive-8828f86eb1c9',
      },
      {
        name: '12 Websites You’ll Love As A Developer',
        link: 'https://medium.com/@ibrahimzahir7/12-websites-youll-love-as-a-developer-1e4180d0a729',
      },
      {
        name: 'What I Learned as a Product Designer at Apple',
        link: 'https://medium.com/@pachecoandrea/what-i-learned-as-a-product-designer-at-apple-35341d9ced8a?source=explore---------38-102--------------------477f80a7_ef26_4153_a705_b017572775cc-------15',
      },
      {
        name: '5 ways to review code without wasting everyone’s time',
        link: 'https://medium.com/volvo-cars-engineering/5-ways-to-review-code-without-wasting-everyones-time-aedeecc51094?source=explore---------69-98--------------------477f80a7_ef26_4153_a705_b017572775cc-------15',
      },
      {
        name: '16 little UI design rules that make a big impact',
        link: 'https://uxplanet.org/16-ui-design-tips-ba2e7524d203',
      },
      {
        name: 'After Solving 400 Leetcode questions, I have gained valuable insights and lessons that have shaped my approach to software development. Here are five things that I have learned',
        link: 'https://medium.com/@rbharambe66/after-solving-400-leetcode-questions-i-have-gained-valuable-insights-and-lessons-that-have-shaped-5d5f6ea9f594',
      },
      {
        name: '10 Alpha Tips for Solving any Coding Question in an Interview',
        link: 'https://medium.com/@Harshit_Raj_14/10-alpha-tips-for-solving-any-coding-question-in-an-interview-30d1414ede18',
      },
    ],
  },
];
