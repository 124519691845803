import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import LockIcon from '@mui/icons-material/Lock';
import LoadingScreen from '../../../../pages/LoadingScreen';

const circleStyle = {
  position: 'relative',
  width: '150px',
  height: '150px',
  borderRadius: '50%',
  backgroundColor: '#1976D2',
  justifyContent: 'center',
  textAlign: 'center',
  alignItems: 'center',
  display: 'inline-block',
  paddingTop: '55px',
  // margin: '5px',
  color: 'white',
  cursor: 'pointer',
};

const circleLockedStyle = {
  position: 'relative',
  width: '150px',
  height: '150px',
  borderRadius: '50%',
  backgroundColor: 'rgb(155, 159, 168)',
  justifyContent: 'center',
  textAlign: 'center',
  alignItems: 'center',
  pointerEvents: 'none',
  display: 'flex',
  flexDirection: 'column',
  // paddingTop: '55px',
  color: 'white',
  cursor: 'pointer',
};

const SearchResult = ({ result, completedProblemList, loading }) => {
  const navigate = useNavigate();
  const currentProgram = useSelector((state) => state?.programs.currentProgram);
  const programSlug = currentProgram?.name;

  function checkProgramRange(currentProgram) {
    if (currentProgram >= 1 && currentProgram < 100) {
      return false;
    }

    return true;
  }

  const isValid = checkProgramRange(currentProgram.programId);

  return (
    <Box
      ml={10}
      mt={5}
      display='flex'
      gap={3}
    >
      <LoadingScreen loading={loading} />
      {result[0] === 'No results found' ? (
        <Box
          ml={45}
          mt={8}
          display='flex'
          justifyContent='center'
          style={{ color: 'grey' }}
        >
          {result[0]}
        </Box>
      ) : (
        <>
          {result.map((res, index) => {
            let url;
            const [topic, subtopic] = res.split('.');

            if (res.includes('.')) {
              url = `/student/${programSlug}/signature-frameworks/mindmap/${topic}/${subtopic}`;
            } else {
              url = `/student/${programSlug}/signature-frameworks/mindmap/${res}`;
            }

            const topicStatus = completedProblemList.find(
              (status) => status.topic === topic
            )?.is_locked;

            const finalStatus = topicStatus && isValid;

            return (
              <div
                key={index}
                style={finalStatus === true ? circleLockedStyle : circleStyle}
                onClick={() => navigate(url)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    navigate(url);
                  }
                }}
                role='button'
                tabIndex={0}
              >
                {finalStatus === true && (
                  <LockIcon
                    style={{
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      borderRadius: '50%',
                      padding: '45px',
                      background: '#4242424D',
                    }}
                  />
                )}
                {res.includes('.')
                  ? res
                      .split('.')
                      .map((str, index) => <div key={index}>{str}</div>)
                  : res}
              </div>
            );
          })}
        </>
      )}
    </Box>
  );
};

export default SearchResult;
