import React, { useState } from 'react';
import {
  Box,
  Typography,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
} from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import VerifiedIcon from '@mui/icons-material/Verified';
import { FiberManualRecord } from '@mui/icons-material';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import CustomTabPanel from '../CustomTabPanel';
import { FindLargestPowerOfTwoConst } from '../../../utils/techniqueSagaConstant';
import TopicTitle from '../TopicTitle';
import ListRenderComponent from '../ListRenderComponent';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const GroupAnagrams = ({
  handlePostCompletedTopic,
  hasMarkedCompleted,
  loading,
  topic_id,
  handleNext,
  handlePrevious,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='36px'
      paddingY={2}
      maxWidth='calc(100% - 300px)'
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <TopicTitle>1.Group Anagrams</TopicTitle>
        {hasMarkedCompleted(topic_id) && (
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              color: 'green',
              fontWeight: '500',
              border: '2px solid green',
              padding: '4px 10px',
              borderRadius: '20px',
              cursor: 'default',
            }}
          >
            Completed
          </Typography>
        )}
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        gap='24px'
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            width: '36rem',
            background: '#FEF7FF',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab
              label='Problem Statement'
              {...a11yProps(0)}
            />
            <Tab
              label='Brute Force Approach'
              {...a11yProps(1)}
            />
            <Tab
              label='Optimized Approach'
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel
          value={value}
          index={0}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              Given a list of strings, group anagrams together. Each group
              should contain words that are anagrams of each other. Two words
              are anagram of each other if they contain the exact same letters
              in the exact same count irrespective of their ordering.
            </Typography>
            <Typography>
              For Example
              <br />
              strs = ["eat","tea","tan","ate","nat","bat"] then output for that
              should be:
              <br />
              Output: [["bat"],["nat","tan"],["ate","eat","tea"]]
            </Typography>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={1}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              We can iterate on the whole array and for each word individually
              check every pair to see if they are anagrams of each other. To
              check if two words are anagrams, we can sort both of them and see
              if both result in the same string or not because anagrams have
              same characters with same frequencies.
            </Typography>

            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '800px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`function groupAnagrams_bf(strs):
    visited = [False] * length of strs   // Array to mark visited strings
    result = []                          // List to store grouped anagrams

    for i from 0 to length of strs - 1:
        if visited[i] is False:
            anagramGroup = [strs[i]]     
            // Start a new group with the current word
            visited[i] = True

            // Check if any other word is an anagram of strs[i]
            for j from i + 1 to length of strs - 1:
                if visited[j] is False and areAnagrams(strs[i], strs[j]):
                    anagramGroup.append(strs[j])
                    visited[j] = True    // Mark the anagram as visited

            result.append(anagramGroup)  // Add the group to the result

    return result

// Helper function to check if two words are anagrams
function areAnagrams(word1, word2):
    if length of word1 is not equal to length of word2:
        return False
    return sort(word1) == sort(word2)
`}
              </pre>
            </Box>

            <Typography sx={{ paddingLeft: '1.5rem' }}>
              <b>Time and Space Complexity</b>
              <br />
              The algorithm is not efficient as we traverse the array every step
              and to check we have to perform sorting each time and that too for
              both the elements. The traversal to check every pair will require
              nested loops and for each comparison we will sort as well. To keep
              a track of what strings are already part of some anagram, we will
              also keep a visited array to make sure the mappings are unique.
              <br />
              <br />
              Time Complexity : (n^2)*k*logk (k being the size of string)
              <br></br>
              Space Complexity : O(n)
              <br></br>
            </Typography>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={2}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              Instead of continuously sorting at every step, we will sort the
              elements and keep them as key. That reduces the times of sorting
              because now we are sorting just once, also we just have to
              traverse once because keys will be unique and thus we won’t need a
              visited array like before.
            </Typography>

            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '800px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`function groupAnagrams(strs):
    anagramMap = new map

    for word in strs:
        sortedWord = sort(word)
        if sortedWord in anagramMap:
            anagramMap[sortedWord].append(word)
        else:
            anagramMap[sortedWord] = [word]

    return list(anagramMap.values())
`}
              </pre>
            </Box>

            <Typography sx={{ paddingLeft: '1.5rem' }}>
              <b>Time and Space Complexity</b>
              <br />
              We are saving ourselves a nested loop because we know that the
              keys will be unique and each one will be correctly mapped to a
              unique key. The space is utilized with a map.
              <br></br>
              Time Complexity : O(n*k*logk)
              <br />
              Space Complexity : O(n)
              <br></br>
            </Typography>
          </Box>
        </CustomTabPanel>
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        alignSelf='end'
        justifyContent='space-between'
        gap='12px'
      >
        {!hasMarkedCompleted(topic_id) && (
          <LoadingButton
            variant='contained'
            onClick={() => handlePostCompletedTopic(topic_id)}
            loading={loading}
            loadingPosition='center'
            children='Mark as completed'
            style={{
              width: '170px',
              // backgroundColor: 'transparent',
              borderRadius: '8px',
              border: '1px solid rgba(64, 96, 245, 0.5)',
            }}
          />
        )}

        <Box
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Button
            sx={{ gap: '4px' }}
            onClick={handlePrevious}
          >
            <WestIcon /> Previous
          </Button>

          <Button
            sx={{ gap: '4px' }}
            onClick={handleNext}
          >
            Next <EastIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default GroupAnagrams;
