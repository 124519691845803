// @ts-nocheck
import React, { useState } from 'react';
import {
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
  Button,
  Box,
  Stepper,
  StepLabel,
  Step,
  Grid,
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { styled } from '@mui/material/styles';
import ScreeningTestWelcomeScreenExitPopup from '../ExitIntentPopups/ScreeningTestWelcomScreenExitPopup';
import actue from '../../assets/acute.png';
import StepConnector from '@mui/material/StepConnector';
import { WelcomeScreenContent } from './WelcomeScreenGridContent';
import axios from 'axios';
import { BASE_URL, getHeaders } from 'src/apis/urls';
import UniversalProgress from '../eligibility-forms/UniversalProgress';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateStep } from 'src/components/redux-store/slices/progressSlice';
const steps = ['Application form', 'Program Eligibility', 'Offer letter'];

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`& .MuiStepConnector-line`]: {
    borderColor: '#1976D2',
  },
}));

const CustomStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.grey[700],
  zIndex: 1,
  color: '#fff',
  width: 24,
  height: 24,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundColor: theme.palette.primary.main,
  }),
  ...(ownerState.completed && {
    backgroundColor: theme.palette.primary.main,
  }),
}));

function CustomStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    3: <LockIcon fontSize='inherit' />,
  };

  return (
    <CustomStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </CustomStepIconRoot>
  );
}

const WelcomeScreen = ({
  trackButtonClick,
  setShowInstructions,
  setShouldConfirmScreeningInterview,
  setShowReschedule,
  formId,
  setShowRescheduleScreen,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateStep({ stepIndex: 2 }));
  }, []);

  const startScreeningTest = ({
    source = 'welcome_screen',
    eventName = 'fastrack_screening_test',
  }) => {
    trackButtonClick({
      source,
      eventName,
    });
    setShowInstructions(true);
  };

  const rescheduleTest = async ({
    source = 'welcome_screen',
    eventName = 'Reschedule_Test',
  }) => {
    const body = {};
    try {
      setLoading(true);
      const res = await axios.patch(
        `${BASE_URL}/api/eligibility-forms/${formId}/reschedule_test`,
        body,
        {
          headers: getHeaders(),
        }
      );
      trackButtonClick({
        source,
        eventName,
      });
      if (res?.data?.status == 'success') {
        setShowRescheduleScreen(1);
      }
      setShowReschedule(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <ScreeningTestWelcomeScreenExitPopup
        trackButtonClick={trackButtonClick}
        startScreeningTest={startScreeningTest}
      />

      <Stack
        spacing={3}
        alignItems='center'
        sx={{
          height: 'fit-content',
          width: {
            xs: '100%',
            sm: '100%',
            md: '85%',
            lg: '80%',
          },
          padding: {
            xs: '20px',
            sm: '30px',
            md: '2rem',
            lg: '3rem',
          },
          backgroundColor: 'white',
          borderRadius: '12px',
        }}
      >
        <Stack
          spacing={2}
          alignItems='center'
        >
          <Stack
            direction='row'
            spacing={1}
            alignItems='center'
          >
            <Box sx={{ fontSize: '2em', paddingLeft: '8px' }}>🌟</Box>
            <Typography
              variant='h1'
              sx={{
                textAlign: 'center',
                fontFamily: 'Inter',
                color: '#1976d2',
                fontWeight: 'bold',
                fontSize: {
                  xs: '1.5rem',
                  sm: '1.7rem',
                  md: '1.8rem',
                  lg: '2rem',
                },
              }}
            >
              Congratulations, you're on the fastrack!
            </Typography>
            <Box sx={{ fontSize: '2em', paddingRight: '8px' }}>🌟</Box>
          </Stack>

          <Typography
            variant='body1'
            sx={{
              textAlign: 'center',
              fontFamily: 'Inter',
              fontWeight: 600,
              fontSize: {
                xs: '1.2rem',
                sm: '1.4rem',
                md: '1.6rem',
                lg: '1.8rem',
              },
            }}
          >
            You're eligible for the Fastrack Screening Test.
          </Typography>
        </Stack>

        <Grid
          container
          direction='column'
          spacing={{
            xs: 2,
            sm: 2,
            md: 3,
            lg: 4,
          }}
          width={{
            xs: '100%',
            sm: '100%',
            md: '90%',
            lg: '70%',
          }}
          sx={{
            marginLeft: !isMobile && 5,
          }}
          style={{
            marginTop: '0px',
          }}
        >
          {WelcomeScreenContent.map((item, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              key={index}
            >
              <Stack
                direction='row'
                alignItems='center'
                justifyContent='flex-start'
                spacing={2}
                paddingTop={'16px'}
                sx={{
                  textAlign: 'left',
                  marginLeft: 0,
                  '@media (min-width: 400px)': { marginLeft: '0.6rem' },
                  '@media (min-width: 768px)': { marginLeft: '8rem' },
                  '@media (min-width: 1024px)': { marginLeft: '10rem' },
                  '@media (min-width: 1440px)': { marginLeft: '12rem' },
                  '@media (min-width: 2560px)': { marginLeft: '22rem' },
                }}
              >
                <Box
                  component='img'
                  src={item.imgSrc}
                  alt={item.title}
                  sx={{
                    width: { xs: 40, sm: 50 },
                    height: { xs: 40, sm: 50 },
                    borderRadius: '50%',
                    backgroundColor: '#f0f0f0',
                  }}
                />

                <Stack
                  direction='column'
                  spacing={0.5}
                >
                  <Typography
                    variant='subtitle1'
                    fontWeight='bold'
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    variant='body2'
                    color='text.secondary'
                  >
                    {item.description}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          ))}
        </Grid>
        <Stack
          spacing={2}
          alignItems='center'
          sx={{
            position: 'relative',
            paddingTop: '3rem',
            paddingBottom: '2rem',
            width: '100%',
          }}
        >
          <Stack
            direction={isTablet || isMobile ? 'column-reverse' : 'row'}
            spacing={3}
            sx={{ position: 'relative' }}
          >
            <Button
              variant='outlined'
              sx={{
                fontFamily: 'Inter',
                padding: {
                  xs: '15px',
                  sm: '20px',
                  md: '25px',
                  lg: '30px',
                },
                boxShadow: 'none',
                borderRadius: '0.5rem',
                borderColor: '#434343',
                color: '#434343',
                ':hover': {
                  background: 'white',
                  borderColor: '#434343',
                },
              }}
              size='large'
              disableElevation
              onClick={rescheduleTest}
              disabled={loading}
            >
              Reschedule the test
            </Button>
            <Button
              variant='contained'
              onClick={startScreeningTest}
              sx={{
                fontFamily: 'Inter',
                padding: {
                  xs: '15px',
                  sm: '20px',
                  md: '25px',
                  lg: '30px',
                },
                boxShadow: 'none',
                borderRadius: '0.5rem',
                background: 'linear-gradient(90deg, #186FC5 0%, #0E4173 100%)',
                ':hover': {
                  background:
                    'linear-gradient(90deg, #186FC5 0%, #0E4173 100%)',
                },
              }}
              size='large'
              disableElevation
              disabled={loading}
              startIcon={
                <Box
                  component='img'
                  src={actue}
                  alt='Priority'
                  sx={{ width: 24, height: 24 }}
                />
              }
            >
              Fastrack Screening Test
            </Button>
            <Box
              sx={{
                visibility: (isTablet || isMobile) && 'hidden',
                position: 'absolute',
                top: '50%',
                left: '100%',
                transform: 'translate(-15%, -50%)',
                fontFamily: '"Handlee", "cursive"',
              }}
            >
              <p className='description'>It takes only 5-7 minutes!</p>
              <svg
                className='arrow'
                viewBox='0 0 92 57'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='M60.9608 40.4168C53.8318 44.6433 46.0027 47.5567 37.6905 49.0759C34.992 49.5691 32.2901 49.9068 29.5912 50.089C25.0325 50.3968 20.4839 50.2609 15.9846 49.681L17.6106 56.6836L0.62137 45.7084L20.3152 41.1721L16.506 47.0661C23.339 47.9271 30.2899 47.7264 37.2142 46.4611C45.2171 44.9984 52.7524 42.1951 59.6107 38.129C66.469 34.0629 72.5489 28.7939 77.6819 22.4685C82.8148 16.1431 86.7232 9.10335 89.2986 1.54428C89.5354 0.849713 90.289 0.479337 90.9815 0.716715C91.6745 0.95407 92.0444 1.70961 91.8077 2.404C89.1306 10.2612 85.0701 17.5764 79.7387 24.146C74.4074 30.7161 68.0896 36.1902 60.9608 40.4168Z' />
              </svg>
            </Box>
            <style>
              {`
                .arrow {
                  width: 70px;
                  height: 60px;
                  fill: #2d2d2d;
                  margin-left: 10px;
                }
                .description {
                  font-size: 16px;
                  position: absolute;
                  bottom: 100%;
                  left: 50%;
                  transform: translateX(-15%);
                  white-space: nowrap;
                }
              `}
            </style>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default WelcomeScreen;
