// @ts-nocheck
import {
  Alert,
  Box,
  Grid,
  Snackbar,
  SnackbarContent,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import EastIcon from '@mui/icons-material/East';
import { LoadingButton } from '@material-ui/lab';
import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import FastrackOfferLetterCountdown from '../FastrackOfferLetterCountdown';
import OfferLetterModal from '../OfferLetterModal';
import GreatFitForCourse from './GreatFitForCourse';
import NotYetReadyCard from './NotYetReadyCard';
import { useFastrackPipeline } from 'src/layouts/EfPipelineContext/FastrackPipelineContex';
import { useEfPipeline } from 'src/layouts/EfPipelineContext/EfPipelineContext';
import FastrackOfferLetterExitPopup from '../FastrackOfferLetterExitPopup';
import SelectConcernScreen from './SelectConcernScreen';
import SharingConcernTerminationScreen from './SharingConcernTerminationScreen';
import CompanisListScreen from './FeesAreHigh/CompanisListScreen';
import SalaryROICalculatorIntro from './FeesAreHigh/SalaryROICalculatorIntro';
import SelectYourCTC from './FeesAreHigh/SelectYourCTC';
import ShowPercentageGain from './FeesAreHigh/ShowPercentageGain';
import CompanyRoleSelectionScreen from './FeesAreHigh/CompanyRoleSelectionScreen';
import ConcernedAboutWorkBalance from './TimeManagementIssues/ConcernedAboutWorkBalance';
import WorkHoursEachDay from './TimeManagementIssues/WorkHoursEachDay';
import DailyCommitments from './TimeManagementIssues/DailyCommitments';
import HowManyHourSleep from './TimeManagementIssues/HowManyHourSleep';
import PersonalGrowth from './TimeManagementIssues/PersonalGrowth';
import TimePressed from './TimeManagementIssues/PressedForTime';
import FiguredOut from './TimeManagementIssues/FiguredOut';
import SelectReasonForWorriedness from './WorriedWontLandAJob/SelectReasonForWorriedness';
import KeyModulesIntro from './WorriedWontLandAJob/KeyModulesIntro';
import StrugglingAreasInPlacement from './WorriedWontLandAJob/StrugglingAreasInPlacement';
import MeetLearner from './TimeManagementIssues/MeetLearner';
import BalanceSupportSection from './TimeManagementIssues/BalanceSupportSection';
import BalanceSupportSection1 from './TimeManagementIssues/BalanceSupportSection1';
import EnrollSection from './TimeManagementIssues/EnrollSection';
import NegativeHike from './TimeManagementIssues/NegativeHike';
import HoldBack from './TimeManagementIssues/HoldBack';
import MakeItHappen from './TimeManagementIssues/MakeItHappen';
import OneToOneInterviewsCaseStudy from './WorriedWontLandAJob/OneToOneInterviewsCaseStudy';
import SmallChangesBigResults from './TimeManagementIssues/SmallChangesBigResults';
import TimePressed1 from './TimeManagementIssues/PressedForTime1';
import OneToOnePastInterviewAnalysis from './WorriedWontLandAJob/OneToOnePastInterviewAnalysis';
import OneToOneInterviewComparison from './WorriedWontLandAJob/OneToOneInterviewComparison';
import OneToOnePastInterviewMetrics from './WorriedWontLandAJob/OneToOnePastInterviewMetrics';
import { ALLOWED_ENROLLMENT_SCREENS, tooltipTitle } from './index';
import { useSelector, useDispatch } from 'react-redux';
import { updateStep } from 'src/components/redux-store/slices/progressSlice';

const SCREEN_COMPONENTS = {
  screen_3: {
    Component: ConcernedAboutWorkBalance,
    extraProps: ['handleReadyToEnroll'],
  },
  screen_3a: {
    Component: WorkHoursEachDay,
    extraProps: ['handleReadyToEnroll'],
  },
  screen_3b: {
    Component: HowManyHourSleep,
    extraProps: ['handleReadyToEnroll'],
  },
  screen_3c: {
    Component: DailyCommitments,
    extraProps: ['handleReadyToEnroll'],
  },
  screen_3d: { Component: PersonalGrowth, extraProps: ['handleReadyToEnroll'] },
  screen_3e: { Component: TimePressed, extraProps: ['handleReadyToEnroll'] },
  screen_3f: { Component: FiguredOut, extraProps: ['handleReadyToEnroll'] },
  screen_3g: { Component: MeetLearner, extraProps: ['handleReadyToEnroll'] },
  screen_3h: {
    Component: BalanceSupportSection,
    extraProps: ['handleReadyToEnroll'],
  },
  screen_3i: {
    Component: BalanceSupportSection1,
    extraProps: ['handleReadyToEnroll'],
  },
  screen_3j: { Component: EnrollSection, extraProps: ['handleReadyToEnroll'] },
  screen_3k: { Component: NegativeHike, extraProps: ['handleReadyToEnroll'] },
  screen_3l: { Component: HoldBack, extraProps: ['handleReadyToEnroll'] },
  screen_3m: { Component: MakeItHappen, extraProps: ['handleReadyToEnroll'] },
  screen_3n: {
    Component: SmallChangesBigResults,
    extraProps: ['handleReadyToEnroll'],
  },
  screen_3o: { Component: TimePressed1, extraProps: ['handleReadyToEnroll'] },

  screen_4: { Component: CompanisListScreen, extraProps: [] },
  screen_6: { Component: CompanyRoleSelectionScreen, extraProps: [] },
  screen_7: { Component: SelectYourCTC, extraProps: [] },
  screen_8: {
    Component: ShowPercentageGain,
    extraProps: ['handleReadyToEnroll', 'setShowTerminalScreen'],
  },

  screen_2b: { Component: SharingConcernTerminationScreen, extraProps: [] },
  screen_2: {
    Component: SelectConcernScreen,
    extraProps: ['setShowTerminalScreen'],
  },
  pcf_welcome_screen: { Component: SelectReasonForWorriedness, extraProps: [] },
  ps_effectiveness_1: { Component: KeyModulesIntro, extraProps: [] },
  ps_effectiveness_2: { Component: StrugglingAreasInPlacement, extraProps: [] },
  clearing_interview_1: {
    Component: OneToOneInterviewsCaseStudy,
    extraProps: [],
  },
  clearing_interview_2: {
    Component: OneToOnePastInterviewAnalysis,
    extraProps: [],
  },
  clearing_interview_3: {
    Component: OneToOnePastInterviewMetrics,
    extraProps: [],
  },
  clearing_interview_4: {
    Component: OneToOneInterviewComparison,
    extraProps: ['handleReadyToEnroll', 'setShowTerminalScreen'],
  },
};

const DefaultScreen = ({ isSmall, handleReadyToEnroll, time }) => (
  <>
    <Grid
      item
      xs={12}
    >
      <Stack
        spacing={isSmall ? 2 : 1.3}
        alignItems='center'
      >
        <Typography
          variant='h3'
          component='h3'
          sx={{
            color: '#1976d2',
            fontFamily: 'Inter',
            fontWeight: 'bolder',
            fontSize: {
              xs: '1.8rem',
              sm: '2.1rem',
              md: '2.1rem',
              lg: '2.1rem',
            },
            width: 'fit-content',
            textAlign: 'center',
          }}
        >
          🌟 Congratulations you've passed 🌟
        </Typography>
        <Typography
          variant='body1'
          component='p'
          sx={{
            textAlign: 'center',
            fontFamily: 'Inter',
            fontWeight: 'normal',
            fontSize: {
              xs: '1.2rem',
              sm: '1.4rem',
              md: '1.4rem',
              lg: '1.4rem',
            },
          }}
        >
          With a selection rate of just 5%, you've made it through several
          layers of evaluations.
        </Typography>
      </Stack>
    </Grid>
    <Grid
      item
      xs={12}
    >
      <Stack
        direction={isSmall ? 'column-reverse' : 'row'}
        justifyContent='center'
        alignItems='center'
        spacing={4}
        sx={{ paddingTop: '1rem' }}
      >
        <NotYetReadyCard />
        <GreatFitForCourse
          time={time}
          handleReadyToEnroll={handleReadyToEnroll}
        />
      </Stack>
    </Grid>
  </>
);

const FastrackOfferLetterNotYetReadyScreen = ({
  flowData,
  setFlowData,
  saveFlowData,
}) => {
  const { offerLetter, trackButtonClick, releaseOfferLetter, screenSource } =
    useFastrackPipeline();
  const { setShowFastrackOfferLetter, setMessage } = useEfPipeline();
  const theme = useTheme();
  const { link } = offerLetter;
  const expiryDate = new Date(offerLetter?.expires_at).getTime();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [showOfferLetter, setShowOfferLetter] = useState(false);
  const [time, setTime] = useState(expiryDate - new Date().getTime());
  const [showTerminalScreen, setShowTerminalScreen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateStep({ stepIndex: 3 }));
    const interval = setInterval(() => {
      setTime(expiryDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [expiryDate]);

  const reset = () => {
    setShowFastrackOfferLetter(false);
    setMessage(
      'You need an invitation to apply to HeyCoach programs. If you are an existing learner, please check your login email id.'
    );
  };

  if (time <= 0) {
    reset();
    return;
  }

  const handleReadyToEnroll = ({ source }) => {
    releaseOfferLetter({ source });
  };

  const getComponentProps = (screenConfig) => {
    const baseProps = {
      flowData,
      setFlowData,
      saveFlowData,
    };

    const extraProps = {};
    screenConfig.extraProps.forEach((prop) => {
      if (prop === 'handleReadyToEnroll')
        extraProps[prop] = handleReadyToEnroll;
      if (prop === 'setShowTerminalScreen')
        extraProps[prop] = setShowTerminalScreen;
    });

    return { ...baseProps, ...extraProps };
  };

  const renderScreen = () => {
    const exactMatch = SCREEN_COMPONENTS[screenSource];

    if (exactMatch) {
      const componentProps = getComponentProps(exactMatch);
      const { Component } = exactMatch;
      return <Component {...componentProps} />;
    }

    const broaderMatch = Object.entries(SCREEN_COMPONENTS).find(([key]) =>
      screenSource.startsWith(key)
    )?.[1];

    if (broaderMatch) {
      const componentProps = getComponentProps(broaderMatch);
      const { Component } = broaderMatch;
      return <Component {...componentProps} />;
    }

    return (
      <DefaultScreen
        isSmall={isSmall}
        handleReadyToEnroll={handleReadyToEnroll}
        time={time}
      />
    );
  };

  const shouldShowReadyToEnrollButton = () => {
    return ALLOWED_ENROLLMENT_SCREENS.includes(screenSource);
  };

  return (
    <>
      <FastrackOfferLetterExitPopup setShowOfferLetter={setShowOfferLetter} />

      {/* Note: Commented because we don't want to show offer letter in modal. */}
      {/* <OfferLetterModal
        {...{
          showOfferLetter,
          setShowOfferLetter,
          time,
          link,
        }}
      /> */}

      <Grid
        container
        item
        rowSpacing={4}
        height='inherit'
        justifyContent='space-between'
        sx={{
          flexWrap: 'wrap',
          paddingTop: '0.5rem',
          paddingLeft: 'clamp(2rem, 3rem, 4.5rem)',
          paddingRight: 'clamp(2rem, 3rem, 4.5rem)',
        }}
      >
        {renderScreen()}
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'flex-end',
          paddingTop: 0,
          height: 'fit-content',
        }}
      >
        <Stack
          spacing={isMobile ? 2 : 4}
          direction={isMobile ? 'column' : 'row'}
          alignItems='center'
          justifyContent='center'
          sx={{
            width: {
              xs: '95%',
              sm: '95%',
              md: '90%',
              lg: '70%',
              xl: '70%',
            },
            height: 'fit-content',
            padding: 'clamp(0.5rem, 0.8rem, 1rem)',
            borderRadius: '36px',
            background: '#e3f2fd',
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 5px 5px 0px',
          }}
        >
          <Stack
            direction='row'
            spacing={2}
            alignItems='center'
          >
            <Typography
              variant='h6'
              component='h6'
              sx={{
                fontFamily: 'Inter',
                fontWeight: 500,
              }}
            >
              {!isSmall ? 'This offer letter expires in: ' : 'Expires in: '}
            </Typography>
            <FastrackOfferLetterCountdown
              time={time}
              style={{ background: '#ffeb3b' }}
            />
          </Stack>

          {shouldShowReadyToEnrollButton() && (
            <Tooltip
              title={tooltipTitle}
              arrow
              placement='top'
              componentsProps={{
                tooltip: {
                  sx: {
                    backgroundColor: '#FFF1C2',
                    color: '#333',
                    fontFamily: 'Inter',
                    fontSize: '0.9rem',
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    padding: '0.5rem',
                    borderRadius: '8px',
                    textAlign: 'center',
                  },
                },
                arrow: {
                  sx: {
                    color: '#FFF1C2',
                  },
                },
              }}
            >
              <Button
                variant='contained'
                sx={{
                  fontFamily: 'Inter',
                  textTransform: 'none',
                  padding: '0.8rem 1.1rem',
                  background:
                    'linear-gradient(to right, rgb(0, 154, 240), rgb(251, 199, 4))',
                }}
                onClick={(e) => handleReadyToEnroll({ source: screenSource })}
                endIcon={<EastIcon />}
              >
                I'm ready to enroll
              </Button>
            </Tooltip>
          )}
        </Stack>
      </Grid>
    </>
  );
};

export default FastrackOfferLetterNotYetReadyScreen;
