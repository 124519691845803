// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import axios from 'axios';
import moment from 'moment';
import { Typography } from '@mui/material';
import Countdown, { zeroPad } from 'react-countdown';
import { useEfPipeline } from '../../layouts/EfPipelineContext/EfPipelineContext';
import { getUserName } from '../../configs/auth';
import { getForm, getHeaders, fetchDropdownURL } from '../../apis/urls';
import ErrorNotifier from '../ToastNotifications/ErrorNotifier';
import FormContainer from './FormContainer';
import { useDispatch } from 'react-redux';
import { updateStep } from 'src/components/redux-store/slices/progressSlice';

const RootContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '10px',
  paddingBottom: '3rem',
});

export default function ActiveFormComponent({ invitationMessage }) {
  const { formId, setIsSuccess, setSuccessMessage, isSuccess, setMessage } =
    useEfPipeline();
  const userName = getUserName();
  const [loading, setLoading] = useState(true);
  const [expiresAt, setExpiresAt] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();
  const [dropdownData, setDropdownData] = useState({});

  useEffect(() => {
    dispatch(updateStep({ stepIndex: 0 }));
    const fetchData = async () => {
      try {
        setLoading(true);

        // Fetch both form questions and dropdown data in parallel
        const [formResponse, dropdownResponse] = await Promise.all([
          axios.get(getForm(formId), { headers: getHeaders() }),
          axios.post(`${fetchDropdownURL()}/listData`, {
            listName: [
              'cities',
              'universities',
              'degrees',
              'roles',
              'organizations',
            ],
            page: 1,
            limit: 50,
          }),
        ]);

        const {
          expires_at,
          message,
          questions: formQuestions,
        } = formResponse.data;
        const dropdownArray = dropdownResponse.data;

        // If there is a message, handle success or error states
        const congratulatoryMessage =
          'Congratulations! Your response has been received.';
        if (message) {
          if (message.toLowerCase() === congratulatoryMessage.toLowerCase()) {
            setIsSuccess(true);
            setSuccessMessage(congratulatoryMessage);
          } else {
            setMessage(message);
          }
          return;
        }

        const dropdownData = dropdownArray.data;

        formQuestions[0]['options'] = Array.from(
          { length: 2030 - 1990 + 1 },
          (_, i) => 1990 + i
        );
        formQuestions[1]['options'] = Array.from(
          { length: 2010 - 1970 + 1 },
          (_, i) => 1970 + i
        );

        // Map dropdown options to each question based on the key
        const updatedQuestions = formQuestions.map((question) => {
          if (question.key && dropdownData[question.key]) {
            return {
              ...question,
              options: dropdownData[question.key].map((item) => item.label), // Only use the 'label' field
            };
          }
          return question;
        });

        // Set the updated questions and expiration date
        setQuestions(updatedQuestions);
        setExpiresAt(expires_at);
      } catch (error) {
        console.error('Error fetching data:', error);
        setHasError(true);
        setErrorMessage(
          error.response?.data?.message || 'Failed to load form data.'
        );
      } finally {
        setLoading(false); // Ensure loading is set to false once fetching completes
      }
    };

    fetchData();
  }, []);

  const CountdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
    return (
      <span>
        {zeroPad(days)}:{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
      </span>
    );
  };

  return (
    <>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}

      {!isSuccess && !loading && (
        <RootContainer>
          <>
            <Typography
              variant='h3'
              component='div'
              align='center'
            >
              Congrats, {userName}!
            </Typography>
            <Typography
              variant='h5'
              align='center'
            >
              {invitationMessage}
              <br />
              <br />
              The invitation expires in{' '}
              {expiresAt !== null && (
                <span
                  style={{
                    padding: '8px',
                    borderRadius: '5px',
                    background: '#f0f0f0',
                  }}
                >
                  <Countdown
                    date={moment(expiresAt)}
                    renderer={CountdownRenderer}
                  />
                </span>
              )}
            </Typography>
          </>
          <FormContainer
            questions={questions}
            loading={loading}
            setQuestions={setQuestions}
          />
        </RootContainer>
      )}
    </>
  );
}
