import React, { useState } from 'react';
import {
  Box,
  Typography,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
} from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import VerifiedIcon from '@mui/icons-material/Verified';
import { FiberManualRecord } from '@mui/icons-material';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import CustomTabPanel from '../CustomTabPanel';
// import { FindLargestPowerOfTwoConst } from '../../../utils/techniqueSagaConstant';
import { PrintAllSubsequenceOfStringConstant } from '../../../utils/techniqueSagaConstant';
// import TopicTitle from '../TopicTitle';
import TopicTitle from '../../Recursion/TopicTiltle';
import ListRenderComponent from '../ListRenderComponent';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import subChain from '../../../../assets/subChain.png';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const PrintAllSubsequenceOfaString = ({
  handlePostCompletedTopic,
  hasMarkedCompleted,
  loading,
  topic_id,
  handleNext,
  handlePrevious,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='36px'
      paddingY={2}
      maxWidth='calc(100% - 300px)'
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <TopicTitle>3.Print all subsequences of a string</TopicTitle>
        {hasMarkedCompleted(topic_id) && (
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              color: 'green',
              fontWeight: '500',
              border: '2px solid green',
              padding: '4px 10px',
              borderRadius: '20px',
              cursor: 'default',
            }}
          >
            Completed
          </Typography>
        )}
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        gap='24px'
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            width: '34rem',
            background: '#FEF7FF',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab
              label='Problem Statement'
              {...a11yProps(0)}
            />
            <Tab
              label='Iterative Approach'
              {...a11yProps(1)}
            />
            <Tab
              label='Recursive Approach'
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel
          value={value}
          index={0}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              Given a string ‘s’ print all the subsequences of it. String is a
              subsequence of a given String that is generated by deleting some
              character of a given string without changing its order.
            </Typography>
            <Typography>
              For example: s= “alt”, then the answer would be:
            </Typography>

            <Typography>
              “a”
              <br />
              “al”
              <br />
              “at”
              <br />
              “alt”
              <br />
              “l”
              <br />
              “lt”
              <br />
              “t”
              <br />
              “”
              <br />
            </Typography>
            <Typography>
              So there are a total of 8 sub sequences, including the empty
              subsequence.
            </Typography>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={1}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              We can make the use of bit-manipulation to check whether a certain
              character in the string is included or not. So basically every
              sequence will be represented by a binary number where every
              character will be represented as ‘0’ meaning it is excluded and
              ‘1’ if it is included.
            </Typography>
            <Box sx={{ paddingLeft: '20px', fontFamily: 'Arial, sans-serif' }}>
              {/* Title */}
              <Typography
                sx={{ fontSize: '18px', fontWeight: 500, marginBottom: '16px' }}
              >
                For example, consider the input string "ab":
              </Typography>

              {/* Points */}
              <ul>
                {/* Total Subsequences */}
                <li style={{ marginLeft: '2em' }}>
                  <Typography>
                    The total subsequences are 2^2 = 4 (i.e., "", "a", "b",
                    "ab").
                  </Typography>
                </li>

                {/* Binary Representations */}
                <li style={{ marginLeft: '2em' }}>
                  <Typography>The binary representations:</Typography>
                  <ol style={{ paddingLeft: '20px' }}>
                    <ul>
                      <Typography>00 → "" (neither included)</Typography>
                    </ul>
                    <ul>
                      <Typography>01 → "b" (only 'b' included)</Typography>
                    </ul>
                    <ul>
                      <Typography>10 → "a" (only 'a' included)</Typography>
                    </ul>
                    <ul>
                      <Typography>11 → "ab" (both included)</Typography>
                    </ul>
                  </ol>
                </li>
              </ul>
            </Box>
            <Typography>
              Using this we can create all the possible subsequences, which
              would be a total of 2^n where n is the size of a string. Below is
              the implementation for it.
            </Typography>
            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '883px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`void printSubsequence(string input) {
    int n = input.length();
    int totalSubsequences = 1 << n;  // This gives 2^n total subsequences

    // Iterate through all possible subsequences
    for (int i = 0; i < totalSubsequences; i++) {
        string subsequence = "";

        for (int j = 0; j < n; j++) {
            // Check if the j-th bit is set in i
            if (i & (1 << j)) {
                subsequence += input[j];
            }
        }

        // Print the subsequence generated for the current combination
        print(subsequence);
    }
}




`}
              </pre>
            </Box>
            <br />
            <Typography sx={{ paddingLeft: '1.5rem' }}>
              <b>Time and Space Complexity</b>
              <br />
              We are iterating over all the subsequences which is in total of
              2^n, thus the time complexity is 2^n and no extra space is used.
              <br />
              <br />
              Time Complexity : O(2^n)<br></br>
              Space Complexity : O(n)
            </Typography>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={2}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Box
              display='flex'
              flexDirection='column'
              gap='8px'
            >
              <Typography>
                Let us try to imagine how one would have solved this problem
                without the use of computers. In order to create all possible
                subsequence, one would go in an order as to not miss any
                particular case. So, first he will form all the subsequences
                starting from ‘a’ then from ‘l’ and then from ‘t’. And when
                forming subsequences of ‘a’, he will again consider all the
                subsequences starting from ‘al’ and then the ones starting from
                ‘at’.
              </Typography>
              <Typography>
                So, if you observe carefully, at each character you are deciding
                whether to include that element or not. A subsequence is
                basically a set of elements that were picked in a continuous
                manner. Let us pick a small example to illustrate that.
              </Typography>
              <Typography>
                <b>S = "ab"</b>
              </Typography>
              <Box sx={{ padding: '10px', fontFamily: 'Arial, sans-serif' }}>
                {/* Title */}
                <Typography
                  sx={{
                    fontSize: '18px',
                    fontWeight: 500,
                    marginBottom: '12px',
                  }}
                >
                  At each character, we have two choices:
                </Typography>

                {/* Numbered List */}
                <ol style={{ paddingLeft: '20px', lineHeight: '1.8' }}>
                  <li>
                    <Typography>
                      <b>Include</b> the current character in the subsequence.
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      <b>Do not include</b> the current character in the
                      subsequence.
                    </Typography>
                  </li>
                </ol>
              </Box>
              <Box sx={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
                {/* Rendering text03 */}
                <Typography sx={{}}>
                  {
                    PrintAllSubsequenceOfStringConstant.RecursiveApproach01
                      .text03.title
                  }
                </Typography>
                <ol style={{ paddingLeft: '20px' }}>
                  {PrintAllSubsequenceOfStringConstant.RecursiveApproach01.text03.topics.map(
                    (topic) => (
                      <li
                        key={topic.number}
                        style={{ marginBottom: '16px' }}
                      >
                        <Typography
                          sx={{ fontWeight: 'bold', marginBottom: '8px' }}
                        >
                          {topic.description}
                        </Typography>
                        <ul
                          style={{
                            paddingLeft: '20px',
                            listStyleType: 'circle',
                          }}
                        >
                          {topic.subpoints.map((subpoint, subIndex) => (
                            <li
                              key={subIndex}
                              style={{ marginBottom: '8px' }}
                            >
                              <Typography>{subpoint.detail}</Typography>
                              {subpoint.subdetails && (
                                <ul
                                  style={{
                                    paddingLeft: '20px',
                                    listStyleType: 'square',
                                  }}
                                >
                                  {subpoint.subdetails.map(
                                    (subdetail, subDetailIndex) => (
                                      <li
                                        key={subDetailIndex}
                                        style={{ marginBottom: '4px' }}
                                      >
                                        <Typography>
                                          {subdetail.detail}
                                        </Typography>
                                      </li>
                                    )
                                  )}
                                </ul>
                              )}
                            </li>
                          ))}
                        </ul>
                      </li>
                    )
                  )}
                </ol>
              </Box>
              <Typography>
                So these are the 4 cases that can happen and they result in
                strings : a, b, ab and an empty.
              </Typography>
              <Typography>
                Following is the representation of it as a recursive tree
              </Typography>
              <Box>
                <img
                  src={subChain}
                  alt=''
                  style={{ height: '250px', width: '400px', text: 'center' }}
                />
                <Typography>
                  Observe how the tree grows exponentially
                  <br />
                </Typography>
              </Box>
              <Typography>
                So, basically we will need to call two kinds of function, one
                where we include the element in our subsequence and one where we
                do not and then ask both of them to solve for a smaller string
                of their version.
              </Typography>
              <br />
              <Box
                sx={{
                  backgroundColor: 'black',
                  padding: '24px',
                  borderRadius: '10px',
                  width: '883px',
                }}
              >
                <pre style={{ color: 'white', margin: 0 }}>
                  {`Func printSubsequence(string input, string output, int index)
{
    // Base Case
    // if the input is empty print the output string
    if (index==input.length()) {
        cout << output << endl;
        return;
    }
 
    printSubsequence(input, output + input[index], index+1); //include
 
    printSubsequence(input, output, index+1); //Do not include
}




`}
                </pre>
              </Box>
              <br />
              <Typography sx={{ paddingLeft: '1.5rem' }}>
                <b>Time and Space Complexity</b>
                <br />
                Since each function calls two other functions, just like we saw
                in fibonacci, thus the time complexity for this as well is
                O(2^n) and although it is the same as the iterative one, the
                code is much more simple and readable.
                <br />
                <br />
                Time Complexity : O(2^n)<br></br>
                Space Complexity : O(n)<br></br>
              </Typography>
            </Box>
          </Box>
        </CustomTabPanel>
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        alignSelf='end'
        justifyContent='space-between'
        gap='12px'
      >
        {!hasMarkedCompleted(topic_id) && (
          <LoadingButton
            variant='contained'
            onClick={() => handlePostCompletedTopic(topic_id)}
            loading={loading}
            loadingPosition='center'
            children='Mark as completed'
            style={{
              width: '170px',
              // backgroundColor: 'transparent',
              borderRadius: '8px',
              border: '1px solid rgba(64, 96, 245, 0.5)',
            }}
          />
        )}

        <Box
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Button
            sx={{ gap: '4px' }}
            onClick={handlePrevious}
          >
            <WestIcon /> Previous
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PrintAllSubsequenceOfaString;
