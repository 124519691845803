import React from 'react';
import { List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { FiberManualRecord } from '@mui/icons-material';

const ListRenderComponent = ({ data }) => {
  return (
    <List>
      {data?.map((operator, index) => (
        <ListItem
          key={index}
          sx={{ paddingY: '2px', alignItems: 'flex-start' }}
        >
          <ListItemIcon>
            <FiberManualRecord sx={{ width: '0.5em' }} />
          </ListItemIcon>
          <ListItemText primary={operator} />
        </ListItem>
      ))}
    </List>
  );
};

export default ListRenderComponent;
