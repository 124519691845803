export const mechanismTopics = [
  {
    title: 'Theory',
    topics: [
      {
        id: 1,
        name: 'Introduction',
      },
    ],
  },
  {
    title: 'Mechanism & Examples',
    topics: [
      {
        id: 2,
        name: 'Check if a number is a power of 2',
      },
      {
        id: 3,
        name: 'Count Ones in Binary Representation',
      },
      {
        id: 4,
        name: 'Check if ith Bit is Set',
      },
      {
        id: 5,
        name: 'Generate All Possible Subsets',
      },
      {
        id: 6,
        name: 'Find the largest power of 2',
      },
      {
        id: 7,
        name: 'Trailing Zeroes',
      },
      {
        id: 8,
        name: 'Iterate It',
      },
    ],
  },
];

export const mechanismPointerTopics = [
  {
    title: 'Theory',
    topics: [
      {
        id: 9,
        name: 'Introduction',
      },
    ],
  },
  {
    title: 'Mechanism & Examples',
    topics: [
      {
        id: 10,
        name: 'Move Zeroes',
      },
      {
        id: 11,
        name: 'Books',
      },
      {
        id: 12,
        name: 'Two Sum',
      },
      {
        id: 13,
        name: 'Minimum Size Subarray Sum',
      },
    ],
  },
];
export const mechanismRecursionTopics = [
  {
    title: 'Theory',
    topics: [
      {
        id: 14,
        name: 'Introduction',
      },
    ],
  },
  {
    title: 'Mechanism & Examples',
    topics: [
      {
        id: 15,
        name: 'Find power of x',
      },
      {
        id: 16,
        name: 'Find the winner of circular game',
      },
      {
        id: 17,
        name: 'Print all subsequences of a string',
      },
    ],
  },
];

export const mechanismDivideAndConqureTopics = [
  {
    title: 'Theory',
    topics: [
      {
        id: 18,
        name: 'Introduction',
      },
    ],
  },
  {
    title: 'Mechanism & Examples',
    topics: [
      {
        id: 19,
        name: 'Count Inversions',
      },
      {
        id: 20,
        name: 'Closest Pair of Points',
      },
      {
        id: 21,
        name: 'Longest Nice Substring',
      },
    ],
  },
];
export const mechanismBackTrackingTopics = [
  {
    title: 'Theory',
    topics: [
      {
        id: 22,
        name: 'Introduction',
      },
    ],
  },
  {
    title: 'Mechanism & Examples',
    topics: [
      {
        id: 23,
        name: 'Subset Sum',
      },
      {
        id: 24,
        name: 'Fair Distribution of Cookies',
      },
      {
        id: 25,
        name: 'N Queens Problem',
      },
    ],
  },
];
export const mechanismSortingTopics = [
  {
    title: 'Theory',
    topics: [
      {
        id: 26,
        name: 'Introduction',
      },
    ],
  },
  {
    title: 'Mechanism & Examples',
    topics: [
      {
        id: 27,
        name: 'Sort Colors',
      },
      {
        id: 28,
        name: 'Meeting Rooms',
      },
      {
        id: 29,
        name: 'Largest Number',
      },
    ],
  },
];
export const mechanismBinarySearchTopics = [
  {
    title: 'Theory',
    topics: [
      {
        id: 30,
        name: 'Introduction',
      },
    ],
  },
  {
    title: 'Mechanism & Examples',
    topics: [
      {
        id: 31,
        name: 'Search in a Rotated Sorted Array',
      },
      {
        id: 32,
        name: 'Aggressive Cows',
      },
      {
        id: 33,
        name: 'Search in a 2d matrix',
      },
    ],
  },
];

export const mechanismMapsTopics = [
  {
    title: 'Theory',
    topics: [
      {
        id: 34,
        name: 'Introduction',
      },
    ],
  },
  {
    title: 'Mechanism & Examples',
    topics: [
      {
        id: 35,
        name: 'Group Anagrams',
      },
      {
        id: 36,
        name: 'Longest Consecutive Sequence',
      },
      {
        id: 37,
        name: 'Two Sum',
      },
    ],
  },
];
export const mechanismQueueTopics = [
  {
    title: 'Theory',
    topics: [
      {
        id: 38,
        name: 'Introduction',
      },
    ],
  },
  {
    title: 'Mechanism & Examples',
    topics: [
      {
        id: 39,
        name: 'Time Needed to Buy Tickets',
      },
      {
        id: 40,
        name: 'First Non-Repeating Character in a Stream',
      },
      {
        id: 41,
        name: 'Sliding Window Maximum',
      },
    ],
  },
];
export const mechanismSetTopics = [
  {
    title: 'Theory',
    topics: [
      {
        id: 42,
        name: 'Introduction',
      },
    ],
  },
  {
    title: 'Mechanism & Examples',
    topics: [
      {
        id: 43,
        name: 'Find All Duplicates in an Array',
      },
      {
        id: 44,
        name: 'Subarray with zero sum',
      },
      {
        id: 45,
        name: 'Two Sum',
      },
    ],
  },
];
export const mechanismStackTopics = [
  {
    title: 'Theory',
    topics: [
      {
        id: 46,
        name: 'Introduction',
      },
    ],
  },
  {
    title: 'Mechanism & Examples',
    topics: [
      {
        id: 47,
        name: 'Valid Paranthesis',
      },
      {
        id: 48,
        name: 'Decode String',
      },
      {
        id: 49,
        name: 'Largest rectangle in histogram',
      },
    ],
  },
];
