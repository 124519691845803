import React, { useState, useEffect } from 'react';
import './Templates/SplashScreen.css';
import { Box } from '@mui/material';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import LockIconComponent from './Atoms/LockIconComponent';
import TypographyText from './Atoms/TypographyText';
import DividerComponent from './Atoms/DividerComponent';
import PlacementText from './Molecules/PlacementText';
import TGPTriangle from './Molecules/TGPTriangle';
import ButtonComponent from './Atoms/ButtonComponent';
import { shouldShowNewStudentLayout } from '../utils/common';
import { useSelector } from 'react-redux';

const levelIdToNameMap = {
  2: 'C1',
  3: 'C2',
  4: 'C3',
  5: 'C4',
  6: 'C5',
  7: 'C6',
  8: 'C7',
  9: 'C8',
  10: 'C9',
  11: 'C10',
  12: 'C11',
  13: 'C12',
  14: 'C13',
};

const SplashScreen = ({
  setOpenApologyModal,
  setSelectedLevel,
  clearedLevels,
  allTests,
  level,
}) => {
  const [clickableLevel, setClickableLevel] = useState([]);
  const [showNewLayout, setShowNewLayout] = useState(null);
  const currentProgram = useSelector((state) => state.programs?.currentProgram);
  useEffect(() => {
    const newLayoutFlag = shouldShowNewStudentLayout(currentProgram.programId);
    setShowNewLayout(newLayoutFlag);
  }, [currentProgram]);

  const toggleApologyModal = () => {
    setOpenApologyModal(true);
  };

  const getLevelStatus = (test) => {
    if (!test) return 'locked';
    if (test?.hasSubmitted) {
      return test?.hasCleared ? 'passed' : 'failed';
    }
    return 'unsubmitted';
  };

  return (
    <>
      <div className='container'>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column-reverse',
            width: '100%',
            gap: '12.5px',
          }}
        >
          {Object.keys(levelIdToNameMap).map((levelId) => {
            const test = allTests.find(
              (test) => test.levelName === levelIdToNameMap[levelId]
            );
            const levelStatus = getLevelStatus(test);
            return (
              <Box
                key={levelId}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '94%',
                  justifyContent: 'center',
                  textAlign: 'center',
                  alignItems: 'center',
                  paddingRight: '390px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px',
                    justifyContent: 'center',
                    textAlign: 'center',
                    alignItems: 'center',
                    padding: '0 15px 0 0',
                  }}
                >
                  {levelStatus === 'passed' &&
                    clickableLevel.includes(Number(levelId) - 1) && (
                      <Box
                        sx={{
                          width: '15px',
                          height: '15px',
                          backgroundColor: '#06C146',
                          borderRadius: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          textAlign: 'center',
                        }}
                      >
                        <CheckRoundedIcon
                          sx={{ fontSize: '15px', color: 'white' }}
                        />
                      </Box>
                    )}
                  {(levelStatus === 'failed' ||
                    levelStatus === 'unsubmitted' ||
                    levelStatus === 'locked') &&
                    clickableLevel.includes(Number(levelId) - 1) && (
                      <Box
                        sx={{
                          width: '15px',
                          height: '15px',
                          backgroundColor: '#4287f5',
                          borderRadius: '100%',
                        }}
                      />
                    )}
                  {!clickableLevel.includes(Number(levelId) - 1) && (
                    <LockIconComponent />
                  )}
                  <TypographyText
                    levelNumber={Number(levelId) - 1}
                    text='Level C'
                    stylingClass='level-text'
                  />
                </Box>
                <DividerComponent dividerStyleClass='level-divider' />
              </Box>
            );
          })}
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: '0',
            right: '0',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        ></Box>
        <TGPTriangle
          clearedLevels={clearedLevels}
          setSelectedLevel={setSelectedLevel}
          setClickableLevel={setClickableLevel}
        />
      </div>
    </>
  );
};

export default SplashScreen;
