// @ts-nocheck
import React, { useState } from 'react';
import {
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
  Box,
  Stepper,
  StepLabel,
  Step,
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { styled } from '@mui/material/styles';
import StepConnector from '@mui/material/StepConnector';
import HoldOn from '../../../assets/holdOn.svg';
import { useEfPipeline } from 'src/layouts/EfPipelineContext/EfPipelineContext';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateStep } from 'src/components/redux-store/slices/progressSlice';

const steps = ['Application form', 'Screening test', 'Offer letter'];

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`& .MuiStepConnector-line`]: {
    borderColor: '#1976D2',
  },
}));

const CustomStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor:
    ownerState.completed || ownerState.active
      ? theme.palette.primary.main
      : theme.palette.grey[500],
  zIndex: 1,
  color: '#fff',
  width: 24,
  height: 24,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
}));

function CustomStepIcon(props) {
  const { active, completed, icon } = props;

  const icons = {
    2: <LockIcon fontSize='inherit' />,
    3: <LockIcon fontSize='inherit' />,
  };

  return (
    <CustomStepIconRoot ownerState={{ completed, active }}>
      {icons[icon] || icon}
    </CustomStepIconRoot>
  );
}

const HoldOnTight = ({
  trackButtonClick,
  setShowInstructions,
  setShouldConfirmScreeningInterview,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [holdON, setHoldOn] = useState(true);
  const { startsIn } = useEfPipeline();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateStep({ stepIndex: 1 }));
  }, []);
  function convertUtcToIstTime(utcDateStr) {
    // Parse the UTC date string as a Date object
    const utcDate = new Date(utcDateStr);

    // Convert to IST using toLocaleString with timezone option
    const options = {
      timeZone: 'Asia/Kolkata',
      hour12: true,
      hour: 'numeric',
      minute: '2-digit',
    };

    const istTime = utcDate.toLocaleString('en-IN', options);
    return istTime;
  }

  return (
    <>
      <Stack
        spacing={3}
        alignItems='center'
        sx={{
          height: 'fit-content',
          width: {
            xs: '100%',
            sm: '100%',
            md: '85%',
            lg: '80%',
          },
          padding: {
            xs: '20px',
            sm: '30px',
            md: '2rem',
            lg: '3rem',
          },
          backgroundColor: 'white',
          borderRadius: '12px',
        }}
      >
        <Stack
          spacing={2}
          paddingTop='1rem'
          alignItems='center'
        >
          <Box
            sx={{ width: '100%', paddingBottom: '1rem', marginBottom: '30px' }}
          ></Box>

          <Stack
            direction='row'
            spacing={1}
            alignItems='center'
          >
            <Typography
              variant='h2'
              sx={{
                textAlign: 'center',
                fontFamily: 'Inter',
                color: '#1976d2',
                fontWeight: 'bolder',
                fontSize: {
                  xs: '1.8rem',
                  sm: '2rem',
                  md: '2.2rem',
                  lg: '2.5rem',
                },
              }}
            >
              Hold on tight!
            </Typography>
          </Stack>
          <img
            src={HoldOn}
            alt='Hold On Illustration'
            style={{
              width: '350px',
              margin: '20px 0',
            }}
          />
          <Typography
            variant='p'
            sx={{
              textAlign: 'center',
              fontFamily: 'Inter',
              fontWeight: 400,
              fontSize: {
                xs: '1.2rem',
                sm: '1.4rem',
                md: '1.6rem',
                lg: '1.8rem',
              },
            }}
          >
            We're reviewing your career goals and profile to determine your{' '}
            <br />
            eligibility for our exclusive Fastrack Screening Test.
          </Typography>
          <Typography
            variant='p'
            sx={{
              textAlign: 'center',
              fontFamily: 'Inter',
              fontWeight: 500,
              color: '#1976d2',
              fontSize: {
                xs: '1.2rem',
                sm: '1.4rem',
                md: '1.5rem',
                lg: '1.6rem',
              },
            }}
          >
            {`Your results will be available by ${convertUtcToIstTime(
              startsIn
            )}`}
          </Typography>
        </Stack>
      </Stack>
    </>
  );
};

export default HoldOnTight;
