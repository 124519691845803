import React, { useState } from 'react';
import {
  Box,
  Typography,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
} from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import VerifiedIcon from '@mui/icons-material/Verified';
import { FiberManualRecord } from '@mui/icons-material';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import CustomTabPanel from '../CustomTabPanel';
import { FindLargestPowerOfTwoConst } from '../../../utils/techniqueSagaConstant';
import TopicTitle from '../TopicTitle';
import ListRenderComponent from '../ListRenderComponent';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import nqueen1 from '../../../../assets/nqueen1.png';
import nqueen2 from '../../../../assets/nqueen2.png';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const NqueenProblems = ({
  handlePostCompletedTopic,
  hasMarkedCompleted,
  loading,
  topic_id,
  handleNext,
  handlePrevious,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='36px'
      paddingY={2}
      maxWidth='calc(100% - 300px)'
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <TopicTitle>3.N Queens Problem</TopicTitle>
        {hasMarkedCompleted(topic_id) && (
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              color: 'green',
              fontWeight: '500',
              border: '2px solid green',
              padding: '4px 10px',
              borderRadius: '20px',
              cursor: 'default',
            }}
          >
            Completed
          </Typography>
        )}
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        gap='24px'
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            width: '36rem',
            background: '#FEF7FF',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab
              label='Problem Statement'
              {...a11yProps(0)}
            />
            <Tab
              label='Brute Force Approach'
              {...a11yProps(1)}
            />
            <Tab
              label='BackTracking Approach'
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel
          value={value}
          index={0}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              The N-Queens problem asks us to place N queens on an N x N
              chessboard so that no two queens can attack each other. Queens can
              attack along rows, columns, and diagonals, so we must ensure that
              each queen is placed such that it does not share the same row,
              column, or diagonal with any other queen.
            </Typography>
            <Typography>
              The goal is to return all possible valid configurations (or
              solutions) where the queens are placed safely. The answers should
              be in a form of 1-D array where every index represents the row and
              the value at that index represents the column at which the queen
              must be placed.
            </Typography>
            <Typography>
              For Example <b>N=4</b>.<br />
              <b>Output : [[2,0,3,1], [1,3,0,2]] </b>
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%', // Optional, adjust based on your layout
                padding: '16px',
              }}
            >
              <img
                src={nqueen1}
                alt=''
                style={{ height: '300px', width: '600px' }}
              />
            </Box>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={1}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              In a brute force approach, we try placing queens in every possible
              configuration on the chessboard, checking after each placement to
              see if the configuration is valid.
            </Typography>
            <Box sx={{ maxWidth: 850, marginLeft: '50px', padding: '' }}>
              <ol>
                <li>
                  <Typography variant='body1'>
                    <b>Generate All Configurations:</b> For each row, try
                    placing a queen in every column. Continue this process for
                    each row until either a valid configuration is found or all
                    placements are exhausted.
                  </Typography>
                </li>
                <li>
                  <Typography variant='body1'>
                    <b>Validation Check: </b> For each queen placed, verify if
                    it conflicts with any previously placed queens. If it does,
                    discard this configuration and try another.
                  </Typography>
                </li>
                <li>
                  <Typography variant='body1'>
                    <b>Complexity:</b> Since each queen has <b> N</b> possible
                    positions per row, there are <b>N^N</b> total configurations
                    to consider, making the brute force approach extremely
                    inefficient as <b>N</b> grows.
                  </Typography>
                </li>
              </ol>
            </Box>

            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '800px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`function nQueens_bruteForce(N):
    solutions = []
    def placeQueens(board, row):
        if row == N:
            if isValid(board):
                solutions.append(board[:])
            return
        for col in range(N):
            board[row] = col  # place queen in this column for this row
            placeQueens(board, row + 1)
    placeQueens([-1] * N, 0)
    return solutions

function isValid(board):
    for i in range(len(board)):
        for j in range(i + 1, len(board)):
            if board[i] == board[j] or abs(board[i] - board[j]) == abs(i - j):
                return false
    return true

`}
              </pre>
            </Box>

            <Typography sx={{ paddingLeft: '1.5rem' }}>
              <b>Time and Space Complexity</b>
              <br />
              We try placing queens in each possible column for every row and
              after each combination we try to see if it's valid and
              computationally very expensive.
              <br />
              Time Complexity : O(n^n)<br></br>
              Space Complexity : O(n)<br></br>
            </Typography>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={2}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              Backtracking with pruning significantly improves efficiency by
              narrowing down the search space and terminating invalid
              configurations early.
            </Typography>
            <Typography sx={{ fontSize: '22px', fontWeight: 400 }}>
              In the backtracking approach:
            </Typography>
            <Box sx={{ maxWidth: 850, marginLeft: '50px' }}>
              <ol>
                <li>
                  <Typography variant='body1'>
                    We place queens row by row.
                  </Typography>
                </li>
                <li>
                  <Typography variant='body1'>
                    For each row, we attempt to place a queen in each column.
                  </Typography>
                </li>
                <li>
                  <Typography variant='body1'>
                    Before placing a queen in a column, we check if it is safe:
                  </Typography>
                  <ul style={{ marginLeft: '1.5rem' }}>
                    <li>
                      <Typography variant='body2'>
                        <strong>Row Pruning:</strong> Only one queen per row, so
                        no need to check other rows.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant='body2'>
                        <strong>Column Pruning:</strong> Maintain a set of
                        columns that already contain a queen, so we can skip
                        these columns.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant='body2'>
                        <strong>Diagonal Pruning:</strong> Track occupied
                        diagonals to avoid placing queens where they would
                        conflict diagonally.
                      </Typography>
                    </li>
                  </ul>
                </li>
              </ol>
            </Box>
            <Typography>
              This pruning ensures we don’t waste time exploring configurations
              that are immediately known to be invalid.
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%', // Optional, adjust based on your layout
                padding: '16px',
              }}
            >
              <img
                src={nqueen2}
                alt=''
                style={{ height: '300px', width: '600px' }}
              />
            </Box>
            <Typography>
              If you look at the image you will understand that we are not
              checking the configurations that are sure to fail instead we drop
              that end there itself and start again.
            </Typography>
            <Typography>Below is the code implementation for it.</Typography>
            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '800px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`function nQueens_backtracking(N):
    solutions = []
    def placeQueens(row, columns, diag1, diag2, board):
        if row == N:
            solutions.append(board[:])
            return
        for col in range(N):
            if col in columns or (row - col) in diag1 or (row + col) in diag2:
                continue
            board[row] = col
            columns.add(col)
            diag1.add(row - col)
            diag2.add(row + col)
            placeQueens(row + 1, columns, diag1, diag2, board)
            columns.remove(col)
            diag1.remove(row - col)
            diag2.remove(row + col)
    placeQueens(0, set(), set(), set(), [-1] * N)
    return solutions






`}
              </pre>
            </Box>

            <Typography sx={{ paddingLeft: '1.5rem' }}>
              <b>Time and Space Complexity</b>
              <br />
              Time Complexity : O(N!) in the worst case, as we have<b>
                {' '}
                N
              </b>{' '}
              choices initially, but pruning eliminates many invalid
              configurations.<br></br>
              Space Complexity: O(N)<br></br>
            </Typography>
          </Box>
        </CustomTabPanel>
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        alignSelf='end'
        justifyContent='space-between'
        gap='12px'
      >
        {!hasMarkedCompleted(topic_id) && (
          <LoadingButton
            variant='contained'
            onClick={() => handlePostCompletedTopic(topic_id)}
            loading={loading}
            loadingPosition='center'
            children='Mark as completed'
            style={{
              width: '170px',
              // backgroundColor: 'transparent',
              borderRadius: '8px',
              border: '1px solid rgba(64, 96, 245, 0.5)',
            }}
          />
        )}

        <Box
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Button
            sx={{ gap: '4px' }}
            onClick={handlePrevious}
          >
            <WestIcon /> Previous
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default NqueenProblems;
