import React, { useState } from 'react';
import { Box, Tabs, Tab, Button } from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import CustomTabPanel from '../CustomTabPanel';
import TopicTitle from '../../Recursion/TopicTiltle';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ValidParanthesis = ({
  handlePostCompletedTopic,
  hasMarkedCompleted,
  loading,
  topic_id,
  handleNext,
  handlePrevious,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='36px'
      paddingY={2}
      maxWidth='calc(100% - 300px)'
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <TopicTitle>1. Valid Paranthesis</TopicTitle>
        {hasMarkedCompleted(topic_id) && (
          <p
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              color: 'green',
              fontWeight: 500,
              border: '2px solid green',
              padding: '4px 10px',
              borderRadius: '20px',
              cursor: 'default',
            }}
          >
            Completed
          </p>
        )}
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        gap='24px'
      >
        <Box
          style={{
            borderBottom: 1,
            borderColor: 'divider',
            width: '34rem',
            background: '#FEF7FF',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab
              label='Problem Statement'
              {...a11yProps(0)}
            />
            <Tab
              label='Brute Force Approach'
              {...a11yProps(1)}
            />
            <Tab
              label='Stack Based Approach'
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel
          value={value}
          index={0}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='10px'
          >
            <p style={{ fontSize: '20px' }}>Description</p>
            <p>
              Given a string s containing just the characters '(', ')', '{', '}
              ', '[' and ']', determine if the input string is valid.
            </p>
            <p>An input string is valid if:</p>
            <ul
              style={{
                listStyleType: 'disc',
                marginLeft: '20px',
                fontFamily: 'Arial, sans-serif',
                fontSize: '16px',
              }}
            >
              <li>
                Open brackets must be closed by the same type of brackets.
              </li>
              <li>Open brackets must be closed in the correct order.</li>
              <li>
                Every close bracket has a corresponding open bracket of the same
                type.
              </li>
            </ul>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={1}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='10px'
          >
            <p>
              The brute force approach for the Valid Parentheses problem would
              involve repeatedly scanning the string and removing any matched
              pairs of parentheses ((), {}, or []) until no pairs remain, or
              until we determine that the string is invalid. If, by the end of
              this process, we have removed all pairs and the string is empty,
              the parentheses are balanced. If any unmatched parentheses remain,
              the string is invalid.
            </p>

            <Box
              style={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '800px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`function isValidBruteForce(s):
    # Repeat the following steps until no more pairs can be removed:
    while "()" is in s or "{}" is in s or "[]" is in s:
        # Remove all pairs of parentheses
        s = s with all occurrences of "()" replaced by ""
        s = s with all occurrences of "{}" replaced by ""
        s = s with all occurrences of "[]" replaced by ""

    # Check if all parentheses were matched and removed
    if s is empty:
        return true   # All parentheses are balanced
    else:
        return false  # Unmatched parentheses remain

`}
              </pre>
            </Box>
            <p style={{ paddingLeft: '1.5rem' }}>
              <b>Time and Space Complexity</b>
              <br />
              We are checking every possible pair in the string, thus in the
              worst case that would require O(n^2).
              <br />
              <br />
              Time Complexity : O(n^2)<br></br>
              Space Complexity : O(n)
            </p>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={2}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='20px'
          >
            <p>
              A stack provides an ideal solution here, as it allows us to handle
              the parentheses in a "last-in, first-out" (LIFO) order. We can use
              a stack to keep track of the opening brackets, pushing them onto
              the stack as they appear. When we encounter a closing bracket, we
              check if it matches the most recent opening bracket (on top of the
              stack).
            </p>
            <p>
              This would work because in a valid parentheses pair, there will
              always be a match available and when each match is eliminated some
              other will be available until there are none left.
            </p>

            <Box
              style={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '800px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`function isValid(s):
    stack = []
    mapping = {')': '(', '}': '{', ']': '['}

    for char in s:
        if char is a closing bracket:
            top_element = stack.pop() if stack is not empty else '#'
            if mapping[char] != top_element:
                return false
        else:
            stack.append(char)
    
    return stack is empty

`}
              </pre>
            </Box>

            <p style={{ paddingLeft: '1.5rem' }}>
              <b>Time and Space Complexity</b>
              <br />
              The solution involves a single iteration where we store the
              elements in a stack.
              <br />
              <br />
              Time Complexity : O(n)<br></br>
              Space Complexity : O(n)
            </p>
          </Box>
        </CustomTabPanel>
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        alignSelf='end'
        justifyContent='space-between'
        gap='12px'
      >
        {!hasMarkedCompleted(topic_id) && (
          <LoadingButton
            variant='contained'
            onClick={() => handlePostCompletedTopic(topic_id)}
            loading={loading}
            loadingPosition='center'
            children='Mark as completed'
            style={{
              width: '170px',
              // backgroundColor: 'transparent',
              borderRadius: '8px',
              border: '1px solid rgba(64, 96, 245, 0.5)',
            }}
          />
        )}

        <Box
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Button
            style={{ gap: '4px' }}
            onClick={handlePrevious}
          >
            <WestIcon /> Previous
          </Button>

          <Button
            style={{ gap: '4px' }}
            onClick={handleNext}
          >
            Next <EastIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ValidParanthesis;
