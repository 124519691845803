// @ts-nocheck
import React, { useRef, useState, useEffect } from 'react';
import {
  Box,
  Stack,
  Step,
  StepLabel,
  Stepper,
  useMediaQuery,
  useTheme,
  Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import StepConnector from '@mui/material/StepConnector';
import SmallScreenOfferLetter from './SmallScreenOfferLetter';
import LargeScreenOfferLetter from './LargeScreenOfferLetter';
import SuccessScreen from '../../eligibility-forms/SuccessScreen';
import axios from 'axios';
import { BASE_URL, getActiveLetter, getHeaders } from 'src/apis/urls';
import LoadingScreen from 'src/pages/LoadingScreen';
import NoFurtherExtensionPopup from './NoFurtherExtensionPopup';
import { useFastrackPipeline } from 'src/layouts/EfPipelineContext/FastrackPipelineContex';
import { useEfPipeline } from 'src/layouts/EfPipelineContext/EfPipelineContext';

const steps = ['Application form', 'Screening test', 'Offer letter'];

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`& .MuiStepConnector-line`]: {
    borderColor: '#1976D2',
  },
}));

const Screen1 = () => {
  const {
    setLoading,
    offerLetter,
    setExtensionRequestCount,
    setOfferLetter,
    setExpiryExtensionMessage,
    showConnectingScreen,
  } = useFastrackPipeline();

  const { setMessage } = useEfPipeline();
  const [showPopup, setShowPopup] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTab = useMediaQuery('(max-width: 1200px)');

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const requestCallback = async () => {
    try {
      const id = offerLetter.olId;
      const res = await axios.post(
        `${BASE_URL}/api/offer-letters/${id}/request_callback`,
        {
          lead_id: offerLetter.leadId,
          assignee: offerLetter.lead_owner,
          lead_name:
            (offerLetter.fname ?? '') +
            (offerLetter.lname ? ` ${offerLetter.lname}` : ''),
        },
        {
          headers: getHeaders(),
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const requestExtension = async () => {
    setLoading(true);
    try {
      const id = offerLetter.olId;
      const res = await axios.post(
        `${BASE_URL}/api/offer-letters/${id}/request_extension`,
        {},
        {
          headers: getHeaders(),
        }
      );

      const { message, extend_expiry_request_count, expires_at } = res.data;
      setExtensionRequestCount(extend_expiry_request_count);
      setOfferLetter((prev) => ({ ...prev, expires_at }));
      setExpiryExtensionMessage(message);
      setShowPopup(extend_expiry_request_count > 1);
    } catch (e) {
      console.log(e);
      setMessage(e?.response?.data?.message ?? 'Oops! Something went wrong.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {showPopup && (
        <NoFurtherExtensionPopup
          showPopup={showPopup}
          handleClosePopup={handleClosePopup}
        />
      )}

      <Box
        alignSelf='center'
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: '#e3f2fd',
          paddingTop: '6rem',
          paddingBottom: '2rem',
          paddingLeft: '2rem',
          paddingRight: '2rem',
          overflowY: 'auto',
          overflowX: 'auto',
          '@media (max-width: 500px)': {
            paddingTop: '1.5rem',
          },
          '@media (min-height: 700px)': {
            paddingTop: '3rem',
          },
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            backgroundColor: 'white',
            borderRadius: '12px',
            width: {
              xs: '80vw',
              sm: '80vw',
              md: '84vw',
              lg: '80vw',
              xl: '80vw',
            },
            height: '100%',
            '@media (max-width: 1200px)': {
              height: 'fit-content',
              width: {
                xs: '90vw',
                sm: '85vw',
                md: '80vw',
              },
              maxWidth: 'fit-content',
            },
            paddingTop: '0rem',
            paddingBottom: '3rem',
          }}
        >
          {/* {!showConnectingScreen && (
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              <Box
                sx={{
                  width: '50%',
                  maxWidth: '90%',
                  marginBottom: '30px',
                  '@media (max-width: 600px)': {
                    width: '100%', // Ensures it takes full width on mobile
                    marginRight: '20px',
                  },
                  '@media (max-width: 1200px)': {
                    width: '70%', // Adjust width for tablets and smaller laptop screens
                  },
                  '@media (max-width: 900px)': {
                    width: '80%', // Further adjust width for smaller devices
                  },
                }}
              >
                <Stepper
                  activeStep={2}
                  alternativeLabel={isMobile}
                  connector={<CustomConnector />}
                >
                  {steps.map((label, index) => (
                    <Step key={label}>
                      <StepLabel
                        sx={{
                          fontFamily: 'Inter',
                          '& .MuiStepLabel-label.Mui-completed': {
                            color: '#1976D2',
                          },
                          '& .MuiStepLabel-label.Mui-active': {
                            color: '#1976D2',
                          },
                        }}
                      >
                        {label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>
            </Grid>
          )} */}

          <Grid
            item
            xs={12}
            container
            direction='column'
            height='95%'
            style={{
              paddingLeft: 0,
            }}
          >
            {isTab ? (
              <SmallScreenOfferLetter
                requestExtension={requestExtension}
                requestCallback={requestCallback}
              />
            ) : (
              <LargeScreenOfferLetter
                requestExtension={requestExtension}
                requestCallback={requestCallback}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Screen1;
