// @ts-nocheck
import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Stepper,
  Step,
  StepLabel,
  styled,
} from '@mui/material';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import { ProgramQuestions } from '.';
import moment from 'moment';
import axios from 'axios';
import { BASE_URL, getHeaders } from 'src/apis/urls';
import { useEfPipeline } from 'src/layouts/EfPipelineContext/EfPipelineContext';
import ErrorNotifier from 'src/components/ToastNotifications/ErrorNotifier';
import { useDispatch } from 'react-redux';
import { updateStep } from 'src/components/redux-store/slices/progressSlice';

// Styled components for Stepper and Steps
const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 5,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.grey[400],
      borderWidth: '2px',
    },
  },
  [`&.${stepConnectorClasses.completed} .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.primary.main,
    borderWidth: '2px',
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.grey[400],
    borderWidth: '2px',
  },
}));

const CustomStep = styled(Step)(({ theme }) => ({
  '& .MuiStepLabel-iconContainer': {
    '& .MuiSvgIcon-root': {
      width: 10,
      height: 10,
      borderRadius: '50%',
      border: `2px solid ${theme.palette.grey[400]}`,
      color: 'transparent',
      backgroundColor: 'transparent',
    },
  },
  '&.Mui-completed .MuiStepLabel-iconContainer .MuiSvgIcon-root': {
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.main,
  },
  '&.Mui-active .MuiStepLabel-iconContainer .MuiSvgIcon-root': {
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.main,
  },
  '&:not(.Mui-active):not(.Mui-completed) .MuiStepLabel-iconContainer .MuiSvgIcon-root':
    {
      border: `2px solid ${theme.palette.primary.main}`,
      backgroundColor: 'transparent',
    },
  '& .MuiStepLabel-label': {
    color: theme.palette.text.primary,
  },
}));

const ProgramFitForm = () => {
  const { formId, setShowApplicationProgress, setScreen } = useEfPipeline();
  const [activeStep, setActiveStep] = useState(1);
  const [response, setResponse] = useState('');
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateStep({ stepIndex: 0 }));
  }, []);

  // Handle API Call
  const handleApiCall = async (answer) => {
    const currentQuestion = ProgramQuestions.find(
      (question) => question.id === activeStep
    );

    const payload = {
      questionId: currentQuestion.id,
      question: currentQuestion.text,
      answer,
      created_at: moment().utc().toISOString(),
      prev_screen: `programFit${currentQuestion.id}`,
      source: `programFit${currentQuestion.id}`,
      event_name: currentQuestion.id == '1' ? 'submit' : answer,
    };

    try {
      const res = await axios.post(
        `${BASE_URL}/api/eligibility-forms/${formId}/submit_fst_fail_questions`,
        payload,
        {
          headers: getHeaders(),
        }
      );
      setScreen(res?.data?.data?.prevscreen);
      if (res?.data?.data?.prevscreen) {
        setShowApplicationProgress(true);
      }
    } catch (error) {
      console.error('Some thing went wrong', error);
    }
  };

  const handleNext = (answer = null) => {
    if (answer === 'submit' && response?.trim().length > 1000) {
      setErrorMessage('Your response should be less than 1000 characters');
      setHasError(true);
      return;
    }
    if (answer === 'submit') {
      handleApiCall(response.trim());
    } else {
      handleApiCall(answer);
    }

    if (activeStep === 4 && response) {
      setShowApplicationProgress(true);
    }

    if (activeStep < ProgramQuestions.length) {
      setActiveStep(activeStep + 1);
      setResponse('');
    }
  };

  const currentQuestion = ProgramQuestions.find(
    (question) => question.id === activeStep
  );

  return (
    <>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
          minHeight: '75vh',
          padding: '1rem',
          backgroundColor: '#fff',
          borderRadius: '0.625rem',
          boxShadow: '0rem 0.25rem 0.625rem rgba(0, 0, 0, 0.1)',
        }}
      >
        {/* Stepper with Custom Connector */}
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          connector={<CustomConnector />}
          sx={{
            width: '80%',
            maxWidth: '600px',
            marginTop: '2.8rem',
            marginBottom: '3.5rem',
          }}
        >
          {ProgramQuestions.map((question) => (
            <CustomStep key={question.id}>
              <StepLabel />
            </CustomStep>
          ))}
        </Stepper>

        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          width={'80%'}
        >
          {/* Display Current Question */}
          {currentQuestion && (
            <>
              <Typography
                variant='h4'
                sx={{
                  fontWeight: activeStep > 1 ? 'normal' : 'bold',
                  textAlign: 'center',
                  color: activeStep > 1 ? '#555' : '#333',
                  padding: { xs: '0', md: '1.8rem' },
                  fontSize: activeStep > 1 ? '1rem' : '1.1rem',
                  marginBottom: { xs: '2rem', md: '0' },
                }}
              >
                {currentQuestion.text}
              </Typography>

              <Typography
                variant='body1'
                sx={{
                  fontWeight: activeStep > 1 ? 'bold' : 'normal',
                  textAlign: 'center',
                  color: activeStep > 1 ? '#333' : '#555',
                  fontSize: activeStep > 1 ? '1.1rem' : '0.9rem',
                  marginBottom: '2.5rem',
                }}
              >
                {currentQuestion.prompt}
              </Typography>
            </>
          )}

          {/* Dynamic Content */}
          {activeStep === 1 ? (
            <>
              <TextField
                multiline
                rows={4}
                fullWidth
                placeholder='Type your response here...'
                variant='outlined'
                sx={{
                  width: '100%',
                  maxWidth: '500px',
                  marginBottom: '1.5rem',
                  backgroundColor: '#fff',
                  borderRadius: '4px',
                }}
                onChange={(e) => setResponse(e.target.value)}
              />
              <Button
                variant='contained'
                color='primary'
                sx={{
                  width: '100%',
                  maxWidth: '150px',
                  textTransform: 'none',
                  fontWeight: 'bold',
                }}
                onClick={() => handleNext('submit')}
                disabled={response?.trim().length === 0}
              >
                Submit →
              </Button>
            </>
          ) : (
            <Box
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              width='100%'
              maxWidth='400px'
            >
              <Button
                variant='outlined'
                sx={{
                  color: '#1976d2',
                  width: { xs: '50%', md: '35%' },
                  textTransform: 'none',
                  borderRadius: '3px',
                  borderColor: '#1976d2',
                }}
                onClick={() => handleNext('Not Sure')}
              >
                Not Sure →
              </Button>
              <Button
                variant='outlined'
                sx={{
                  color: '#1976d2',
                  width: '25%',
                  textTransform: 'none',
                  borderRadius: '3px',
                  borderColor: '#1976d2',
                }}
                onClick={() => handleNext('Yes')}
              >
                Yes
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ProgramFitForm;
