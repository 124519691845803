import React from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
} from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import { FiberManualRecord } from '@mui/icons-material';
import EastIcon from '@mui/icons-material/East';
import ListRenderComponent from './ListRendereComponent';
import ParagraphBox from './ParagraphBox';
import { Grid } from '@mui/material';
import { IntroductionRecursionConstant } from '../../utils/techniqueSagaConstant';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

// Images

import DandD1 from '../../../assets/DandD1.png';
import DandD2 from '../../../assets/DandD2.png';
import c1 from '../../../assets/c1.png';
import c2 from '../../../assets/c2.png';
import c3 from '../../../assets/c3.png';
import c4 from '../../../assets/c4.png';
import c5 from '../../../assets/c5.png';
import c6 from '../../../assets/c6.png';

const Introduction = ({
  handlePostCompletedTopic,
  hasMarkedCompleted,
  loading,
  topic_id,
  handleNext,
}) => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='20px'
      paddingY={2}
      maxWidth='calc(100% - 300px)'
    >
      {/* Header */}
      <Box>
        <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
          TECHNIQUE 4
        </Typography>
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
        >
          <Typography
            sx={{
              fontSize: '48px',
              fontWeight: 600,
              lineHeight: '58px',
              letterSpacing: '0.01em',
              textAlign: 'left',
            }}
          >
            Divide and Conquer
          </Typography>
          {hasMarkedCompleted(topic_id) && (
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
                color: 'green',
                fontWeight: 500,
                border: '2px solid green',
                padding: '4px 10px',
                borderRadius: '20px',
                cursor: 'default',
              }}
            >
              Completed
            </Typography>
          )}
        </Box>
      </Box>

      {/* Introduction */}
      <Box
        display='flex'
        flexDirection='column'
        gap='24px'
      >
        <Typography sx={{ fontSize: '22px', fontWeight: 400 }}>
          Introduction
        </Typography>
        <ParagraphBox>
          <Typography>
            The Divide and Conquer algorithm is a powerful approach for solving
            problems by breaking them into smaller subproblems, solving these
            subproblems independently, and then combining their solutions to
            form the final answer. This method is particularly effective for
            problems that can be broken down into multiple subproblems of the
            same type, such as sorting, searching, or finding the minimum and
            maximum in an array.
          </Typography>
          <Typography>
            In Divide and Conquer, a given problem is divided into two or more
            subproblems that are easier to solve. Each subproblem is solved
            recursively, and then the individual solutions are combined to form
            the solution for the original problem.
          </Typography>
          <Typography>
            One primary application of Divide and Conquer is the Merge Sort
            algorithm, which splits an array into two halves, sorts each half,
            and then merges them. Another popular use is the Quick Sort
            algorithm, which selects a pivot element, partitions the array, and
            then recursively sorts the partitions.
          </Typography>
          <br />
          <Typography sx={{ fontSize: '22px', fontWeight: 400 }}>
            What is a Divide and Conquer Approach?
          </Typography>

          <Typography>
            {`Consider a problem with size “n” answer and make an observation that this particular problem can be divided into smaller subproblems. Let us say that we divide the original problem into “a” number of smaller subproblems of the same nature of each size “n/b” where a>=1 and b>1.`}
          </Typography>
          <Typography>
            Such a problem can be labeled as a problem that can be solved using
            the divide and conquer principle.
          </Typography>
          <Box sx={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
            {/* Title */}
            <Typography
              sx={{ fontSize: '20px', fontWeight: 500, marginBottom: '16px' }}
            >
              Divide and Conquer
            </Typography>

            {/* Step 1: Divide */}
            <Typography
              sx={{ fontSize: '16px', fontWeight: 500, marginBottom: '8px' }}
            >
              1. Divide:
            </Typography>
            <Typography sx={{ marginBottom: '16px', paddingLeft: '1rem' }}>
              Split the original problem into smaller, independent subproblems.
              These subproblems should ideally be of the same type as the
              original problem or of the same nature.
            </Typography>

            {/* Step 2: Conquer */}
            <Typography
              sx={{ fontSize: '16px', fontWeight: 500, marginBottom: '8px' }}
            >
              2. Conquer:
            </Typography>
            <Typography sx={{ marginBottom: '16px', paddingLeft: '1rem' }}>
              Solve each subproblem recursively. When the subproblems are small
              enough, solve them directly (base case).
            </Typography>

            {/* Step 3: Combine */}
            <Typography
              sx={{ fontSize: '16px', fontWeight: 500, marginBottom: '8px' }}
            >
              3. Combine:
            </Typography>
            <Typography sx={{ paddingLeft: '1rem' }}>
              Merge the solutions of the subproblems to form the solution of the
              original problem.
            </Typography>
          </Box>
          {/* Example */}
          <Typography sx={{ marginBottom: '16px' }}>
            For example, in Merge Sort, we <b>divide</b> the array into two
            halves, <b>conquer</b> each half by sorting it, and then{' '}
            <b>combine</b> the two sorted halves into a single sorted array. The
            same approach can be applied to many other problems, such as finding
            the maximum and minimum of an array using a balanced split.
          </Typography>

          {/* Explanation */}
          <Typography sx={{ marginBottom: '16px' }}>
            The Divide and Conquer approach is often implemented using
            recursion, which allows us to naturally express the repeated
            subdivision of the problem. This recursive breakdown is the core
            advantage, as it reduces the size of the problem exponentially with
            each step.
          </Typography>

          {/* Advantages */}
          <Typography>
            Using Divide and Conquer, problems can often be solved more
            efficiently in terms of both time and space complexity, especially
            when the problem exhibits overlapping substructure and can be broken
            into smaller parts. However, the overhead of recursive calls and the
            necessity of merging solutions are factors that should be taken into
            account when deciding whether this approach is the best choice.
          </Typography>

          <Box>
            <Typography sx={{ fontSize: '20px', fontWeight: 400 }}>
              Merge Sort as an example
            </Typography>
            <Typography>
              Let us take the popular algorithm merge sort which works on the
              principle of Divide and Conquer to understand more about this
              technique.
            </Typography>
            <Typography>
              Given an unsorted array, sort it using merge sort
            </Typography>
            <Typography>
              {`For example : Let’s take the array {8 3 4 5 1 2} as the array that needs to be sorted.`}
            </Typography>
            <Box>
              <br />
              <Typography>
                <h3>Divide</h3>
              </Typography>
              <Typography>
                When approaching from the point of Divide and Conquer the first
                step is to see if you can divide the problem into two segments
                such that each becomes a smaller problem of the same nature.
                Looking at the example, we can see something of that sort.
              </Typography>
              <br />
              <Typography>
                {`{8,3,4} could be the first part and  {5,1,2} could be the second part, also both the parts are the very same problem as well, if you look at them you can just assume them to be two separate arrays that need to be sorted.`}
              </Typography>
              <br />
              <Typography>
                Let us initialize them as left and right part:
              </Typography>
              <Typography>Left Part</Typography>
              <Grid
                container
                spacing={0}
                sx={{ width: 'fit-content', border: '1px solid black' }}
              >
                {[8, 3, 4].map((num, index) => (
                  <Grid
                    item
                    key={index}
                    sx={{
                      border: '1px solid black',
                      width: '50px',
                      height: '50px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography>{num}</Typography>
                  </Grid>
                ))}
              </Grid>
              <br />
              <Typography>Right Part</Typography>
              <Grid
                container
                spacing={0}
                sx={{ width: 'fit-content', border: '1px solid black' }}
              >
                {[5, 1, 2].map((num, index) => (
                  <Grid
                    item
                    key={index}
                    sx={{
                      border: '1px solid black',
                      width: '50px',
                      height: '50px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography>{num}</Typography>
                  </Grid>
                ))}
              </Grid>
              <br />
              <Typography>
                Now that we’ve divided the array into two parts, we need to
                further break down the array until we reach subarrays that are
                small enough to be sorted easily. The base case should be
                something for which the answer is trivial or already known, for
                this breakdown is when the array has only one element, which is
                already sorted by definition. So, let’s continue dividing:
              </Typography>
              <br />
              <img
                src={DandD1}
                alt=''
                style={{ height: '250px', width: '500px', text: 'center' }}
              />
              <Typography>
                Similarly you will do the same for the right array as well. When
                you reach a single element you will have a sorted array, thus no
                need of dividing further and you have successfully done the
                first part.
              </Typography>
              <img
                src={DandD2}
                alt=''
                style={{ height: '250px', width: '500px', text: 'center' }}
              />
            </Box>
            <Box sx={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
              {/* Title */}
              <br />
              <Typography>
                <h3>Conquer</h3>
              </Typography>

              {/* Explanation */}
              <Typography sx={{ marginBottom: '16px' }}>
                In the conquer phase, we merge the divided subarrays in a sorted
                manner. Since each subarray contains a single element (which is
                trivially sorted), the merging step will involve comparing
                elements and combining them into sorted subarrays.
              </Typography>

              {/* Merge Step */}
              <Typography sx={{}}>Merge Step:</Typography>

              {/* Merging the smaller subarrays */}
              <Typography sx={{}}>Merging the smaller subarrays:</Typography>
              <List>
                <ListItem>
                  <ListItemText primary='Merge {3} and {4} → Sorted result: {3, 4}' />
                </ListItem>
                <ListItem>
                  <ListItemText primary='Merge {1} and {2} → Sorted result: {1, 2}' />
                </ListItem>
              </List>

              {/* Merging with larger subarrays */}
              <Typography sx={{}}>Merging with larger subarrays:</Typography>
              <List>
                <ListItem>
                  <ListItemText primary='Merge {8} with {3, 4} → Sorted result: {3, 4, 8}' />
                </ListItem>
                <ListItem>
                  <ListItemText primary='Merge {5} with {1, 2} → Sorted result: {1, 2, 5}' />
                </ListItem>
              </List>

              {/* Conclusion */}
              <Typography>
                So ultimately you have the left part sorted as {`{3, 4, 8}`}.
                Similarly, the right part will be sorted as {`{1, 2, 5}`}.
              </Typography>
            </Box>
            <Box>
              <br />
              <Typography>
                <h3>Combine (Merge)</h3>
              </Typography>
              <Typography>
                In the previous step when we had the sorted left and the right
                half we essentially did the combine step to merge them into one
                sorted array. In the Combine step, the goal is to merge two
                sorted subarrays back together into one sorted array. Since each
                subarray is already sorted (from the recursive Divide step), the
                merging process compares elements from each subarray and adds
                them in sorted order.
              </Typography>
              <br />

              {/* Explanation */}
              <Typography sx={{}}>
                Let’s try to understand how you will do this step for the merge
                sort. Basically, you want a sorted array and to help you with
                that you already have two sorted arrays:
              </Typography>
              <Box
                sx={{
                  padding: '15px',
                  fontFamily: 'Arial, sans-serif',
                  maxWidth: '1000px',
                }}
              >
                {/* List of Steps */}
                <List>
                  {/* Step 1 */}
                  <ListItem alignItems='flex-start'>
                    <Box>
                      <Typography>
                        1. Compare elements from the starting of both the arrays
                        and then select whichever is the smaller one and add it
                        to the empty list.
                      </Typography>
                      <Box
                        sx={{
                          marginTop: '16px',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <img
                          src={c1}
                          alt=''
                          style={{ height: '150px', width: '400px' }}
                        />
                      </Box>
                    </Box>
                  </ListItem>

                  {/* Step 2 */}
                  <ListItem alignItems='flex-start'>
                    <Box>
                      <Typography>
                        2. Now compare the next element from the previously
                        selected array with the current element from the not
                        selected array.
                      </Typography>
                      <br />
                      <Box
                        sx={{
                          marginTop: '16px',
                          display: 'flex',
                          justifyContent: 'center',
                          width: '100%',
                        }}
                      >
                        <img
                          src={c2}
                          alt=''
                          style={{ height: '150px', width: '400px' }}
                        />
                      </Box>
                    </Box>
                  </ListItem>

                  {/* Step 3 */}
                  <ListItem alignItems='flex-start'>
                    <Box>
                      <Typography>
                        3. Basically keep repeating the step where you move to
                        the next element of the selected array and compare it
                        with the current element of the unsorted array.
                      </Typography>
                      <Box
                        sx={{
                          marginTop: '16px',
                          display: 'flex',
                          justifyContent: 'center',
                          width: '100%',
                        }}
                      >
                        <img
                          src={c3}
                          alt=''
                          style={{ height: '150px', width: '400px' }}
                        />
                      </Box>
                    </Box>
                  </ListItem>
                </List>
              </Box>
              <Typography>
                Finally your array would look like {(1, 2, 3, 4, 5, 8)} where
                all the elements are in a sorted order. You could also come
                across a case where each element in the left array is actually
                smaller than every other element in the right array during the
                merging process.
              </Typography>
              <br />
              <br />
              <Box
                sx={{
                  marginTop: '16px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <img
                  src={c4}
                  alt=''
                  style={{ height: '150px', width: '400px' }}
                />
              </Box>
            </Box>
            <Typography>
              In that case your array will look like this at some point:
            </Typography>
            <br />
            <br />
            <Box
              sx={{
                marginTop: '16px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <img
                src={c5}
                alt=''
                style={{ height: '150px', width: '400px' }}
              />
            </Box>
            <Typography>
              So basically you will have no more elements to compare that simply
              shows that now all the elements that are left are actually
              greater, so just append the remaining elements at the end of the
              array.
            </Typography>
            <br />
            <br />
            <Box
              sx={{
                marginTop: '16px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <img
                src={c6}
                alt=''
                style={{ height: '150px', width: '400px' }}
              />
            </Box>
          </Box>
          <br />
          <Typography>
            And thus in the end we will have a sorted array, which we were able
            to do by understanding the particular problem that we were solving
            could be divided into segments and can be solved independently. Let
            us look at its implementation
          </Typography>
          <br />
          <Box
            sx={{
              backgroundColor: 'black',
              padding: '24px',
              borderRadius: '10px',
              width: '800px',
            }}
          >
            <pre style={{ color: 'white', margin: 0 }}>
              {`
Function MergeSort(arr, left, right)
    If left >= right
        Return

    mid = (left + right) / 2

    // Recursively divide the array into two halves
    MergeSort(arr, left, mid)           // Left half
    MergeSort(arr, mid + 1, right)      // Right half

    // Merge the sorted halves
    Merge(arr, left, mid, right)


Function Merge(arr, left, mid, right)
    Create empty left_subarray from arr[left to mid]
    Create empty right_subarray from arr[mid+1 to right]

    Initialize i = 0  // index for left_subarray
    Initialize j = 0  // index for right_subarray
    Initialize k = left  // index for arr

    // While both subarrays have elements to compare
    While i < length of left_subarray AND j < length of right_subarray
        If left_subarray[i] <= right_subarray[j]
            arr[k] = left_subarray[i]
            Increment i
        Else
            arr[k] = right_subarray[j]
            Increment j
        Increment k

    // Copy remaining elements from left_subarray (if any)
    While i < length of left_subarray
        arr[k] = left_subarray[i]
        Increment i
        Increment k

    // Copy remaining elements from right_subarray (if any)
    While j < length of right_subarray
        arr[k] = right_subarray[j]
        Increment j
        Increment k



`}
            </pre>
          </Box>
        </ParagraphBox>
        <ParagraphBox>
          <Typography sx={{ paddingLeft: '1.5rem' }}>
            <b>Time and Space Complexity</b>
            <br />
            The first part of the algorithm is when you are recursively dividing
            the array into two halves:
            <br />
            <strong>{`n => n/2 => n/4 => n/8 ……….. n/2^k`}</strong>
            <br />
            <br />
            Basically you will stop when the size of you array becomes one or we
            can say when n/2^k = 1, where k is the number of times the function
            is called. Rewriting the equation as:
            <br />
            <strong>
              n = 2^k, taking log on both sides we get k = log n, thus we can
              say for the division part the time complexity is O(log n).
            </strong>
            <br />
            <br />
            Now, each division goes through the part of merging, which in the
            worst case you will have to traverse on the whole n size array in
            order to compare and fill, so that takes O(n). So our final time
            complexity comes out to be O(n log n). For merging we also create a
            temporary array of size n, so the space complexity is O(n).
            <br />
            <br />
            Time Complexity : O(n*log n)<br></br>
            Space Complexity : O(n)<br></br>
          </Typography>
        </ParagraphBox>
        <ParagraphBox>
          <Typography sx={{ fontSize: '20px', fontWeight: 400 }}>
            When to use Divide and Conquer?
          </Typography>
          <Typography>
            These are general tips to identify if a particular problem could
            benefit by the use of Divide and Conquer approach.
          </Typography>
          <Typography style={{ paddingLeft: '1rem' }}>
            <b> 1. Identifiable Subproblems of the Same Nature</b>
            <br />
            If you can easily spot that a problem can be divided into smaller
            subproblems of the same type, it’s a strong indicator that Divide
            and Conquer can be applied. For instance, in Merge
          </Typography>
          <Typography style={{ paddingLeft: '1rem' }}>
            Sort, you divide the array into two halves, and both halves
            represent the same sorting problem, just with fewer elements.
          </Typography>
          <Typography style={{ paddingLeft: '1rem' }}>
            Example: Sorting a list of numbers. By splitting the list into two
            smaller lists, you can apply the same sorting algorithm to each
            list, then combine the results.
          </Typography>
          <Typography style={{ paddingLeft: '1rem' }}>
            <b>2. Presence of a Base Case</b>
            <br />
            After dividing the problem, ask yourself if you can reach a trivial
            or base case where the problem becomes small enough to be solved
            directly. This is essential because it ensures the problem has an
            end point where it no longer needs to be divided.
          </Typography>
          <Typography style={{ paddingLeft: '1rem' }}>
            Example: In Binary Search, the base case occurs when the list has
            only one element left, making it easy to check if it's the desired
            value.
          </Typography>
          <Typography style={{ paddingLeft: '1rem' }}>
            <b> 3. Efficient Combine Step</b>
            <br />
            Just breaking down the problem isn’t enough—combining the results
            from the subproblems should also be efficient. If the combining step
            is too costly, using Divide and Conquer may not optimize your
            solution. For instance, in Merge Sort, the merge operation is
            efficient, taking linear time, which makes the overall approach
            valuable.
          </Typography>
          <Typography style={{ paddingLeft: '1rem' }}>
            Example: In Merge Sort, after sorting two halves of an array,
            merging them into a single sorted array takes linear time, which is
            efficient relative to the overall process.
          </Typography>
          <Typography style={{ paddingLeft: '1rem' }}>
            <b> 4. No Overlapping Subproblems</b> <br />
            Check if the subproblems you are dividing into are distinct or
            overlapping. If they are overlapping (i.e., subproblems share a lot
            of common information), Dynamic Programming might be more suitable,
            as it avoids redundant calculations. Divide and Conquer is best
            suited for problems where subproblems are distinct and don’t repeat.
          </Typography>
          <Typography style={{ paddingLeft: '1rem' }}>
            <b>5. Optimal for Recursive Tree Structures</b>
            <br />
            Divide and Conquer is particularly well-suited for problems that can
            be represented by recursive tree-like structures, where each node
            corresponds to a subproblem. In these cases, the recursive nature of
            Divide and Conquer mirrors the hierarchical structure of the
            problem, making it an ideal fit.
          </Typography>
          <Typography style={{ paddingLeft: '1rem' }}>
            Example: Tree Traversals (like Preorder, Inorder, and Postorder)
            follow a natural Divide and Conquer approach. Each node's subtree is
            recursively processed, and the tree’s hierarchical structure makes
            the algorithm clean and efficient.
          </Typography>
          <Box sx={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
            {/* Introduction */}
            <Typography sx={{ marginBottom: '16px' }}>
              So, in brief divide and conquer works as follows:
            </Typography>

            {/* Bullet Points */}
            <List>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText primary='Given a problem, identify a number of significantly smaller subproblems.' />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText primary='Solve each subproblem recursively (do this until the subproblem is a base-case).' />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText primary='Combine these solutions into a solution for the main problem.' />
              </ListItem>
            </List>
          </Box>
        </ParagraphBox>
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        alignSelf='end'
        justifyContent='space-between'
        gap='12px'
      >
        {!hasMarkedCompleted(topic_id) && (
          <LoadingButton
            variant='contained'
            onClick={() => handlePostCompletedTopic(topic_id)}
            loading={loading}
            loadingPosition='center'
            children='Mark as completed'
            style={{
              width: '170px',
              // backgroundColor: 'transparent',
              borderRadius: '8px',
              border: '1px solid rgba(64, 96, 245, 0.5)',
            }}
          />
        )}

        <Box
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Button
            sx={{ gap: '4px' }}
            onClick={handleNext}
          >
            Next <EastIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Introduction;
