export const ProgramQuestions = [
  {
    id: 1,
    text: 'Please help us understand how this program aligns with your goals and how it can support your growth.',
    prompt: 'We believe in your potential—give it your best shot!',
  },
  {
    id: 2,
    text: 'This course requires dedication, not just in terms of financial commitment but also time.',
    prompt:
      'Are you ready to allocate at least 10 hrs/week to attend live classes, practice coding, and complete assignments?',
  },
  {
    id: 3,
    text: 'This program will require you to stay disciplined throughout the course & avoid giving up.',
    prompt:
      "Do you believe you'll be able to complete tests on time & stay consistent?",
  },
  {
    id: 4,
    text: 'Growth comes from stepping out of your comfort zone, and this course will challenge you.',
    prompt:
      'Are you prepared to tackle complex problems and coding challenges as part of this course?',
  },
];
