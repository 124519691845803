import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useEfPipeline } from 'src/layouts/EfPipelineContext/EfPipelineContext';
import SuccessNotifier from 'src/components/ToastNotifications/SuccessNotifier';
import { useDispatch } from 'react-redux';
import { updateStep } from 'src/components/redux-store/slices/progressSlice';
import axios from 'axios';
import { BASE_URL, getHeaders } from 'src/apis/urls';
import ErrorNotifier from 'src/components/ToastNotifications/ErrorNotifier';

const ScreeningUI = () => {
  const { token, formId } = useEfPipeline();
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateStep({ stepIndex: 0 }));
  }, []);

  // Function to copy the token to clipboard
  const handleCopy = async () => {
    if (token) {
      // Copy token to clipboard
      navigator.clipboard.writeText(token);
      setSuccessMessage('Token copied to clipboard!');
      setIsSuccess(true);

      // Fire API call
      const payload = {
        source: 'screening_ui',
        event_name: 'token_copied',
        prev_screen: 'applicationProgress_review',
      };

      setLoading(true);
      try {
        await axios.post(
          `${BASE_URL}/api/eligibility-forms/${formId}/submit_fst_fail_questions`,
          payload,
          {
            headers: getHeaders(),
          }
        );
      } catch (error) {
        console.error('Error submitting event:', error);
        setErrorMessage(
          error.response?.data?.message ||
            'Failed to submit event. Please try again later.'
        );
        setHasError(true);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      {isSuccess && (
        <SuccessNotifier
          message={successMessage}
          setIsSuccess={setIsSuccess}
        />
      )}
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      <Box
        sx={{
          minHeight: '75vh',
          bgcolor: 'white',
          borderRadius: '0.625rem',
          boxShadow: '0rem 0.25rem 0.625rem rgba(0, 0, 0, 0.1)',
          width: '100%',
          padding: { xs: '1.5rem', sm: '2rem', md: '3rem', lg: '5rem' },
          margin: { xs: '1rem', sm: '1.5rem', md: '2rem' },
          textAlign: 'center',
        }}
      >
        {/* Container for headline and body text */}
        <Box
          sx={{
            maxWidth: '800px',
            margin: '0 auto',
          }}
        >
          <Typography
            variant='h3'
            sx={{
              fontWeight: 'bold',
              color: '#1976d2',
              marginBottom: '3rem',
            }}
          >
            Uh Oh.. Not quite there yet let's give it another shot
          </Typography>
          <Typography
            variant='body1'
            sx={{
              color: 'black',
              marginBottom: '0.5rem',
              fontSize: '1.1rem',
              lineHeight: '2rem',
            }}
          >
            Your profile hasn’t met the minimum program criteria yet so we’d
            like to schedule an online screening interview with one of our
            counsellors.
          </Typography>
          <Typography
            variant='body1'
            sx={{
              color: 'black',
              marginBottom: '1.5rem',
              fontSize: '1.1rem',
              lineHeight: '2rem',
            }}
          >
            We truly value the time and effort you’ve put into this process, and
            as a token of appreciation, here’s an exclusive code for you.
          </Typography>
        </Box>
        <Typography
          variant='body2'
          sx={{
            color: 'black',
            fontWeight: 'bold',
            fontSize: '1rem',
          }}
        >
          Remember to mention it to your counsellor during the call!
        </Typography>
        {/* Exclusive code */}
        <Typography
          variant='h4'
          onClick={handleCopy} // Add onClick handler
          sx={{
            fontWeight: 'bold',
            color: '#1565c0',
            marginTop: '1.2rem',
            marginBottom: '1rem',
            fontSize: { xs: '1.5rem', sm: '2rem' },
            cursor: 'pointer', // Indicate clickability
            userSelect: 'none', // Prevent accidental selection
            '&:hover': {
              color: '#0d47a1', // Add hover effect
            },
          }}
        >
          {token}
        </Typography>
        <Typography
          variant='body1'
          sx={{
            fontWeight: '100',
            fontSize: '1rem',
          }}
        >
          (Click on token to Copy)
        </Typography>
      </Box>
    </>
  );
};

export default ScreeningUI;
