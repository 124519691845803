import React, { useState } from 'react';
import {
  Box,
  Typography,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
} from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import VerifiedIcon from '@mui/icons-material/Verified';
import { FiberManualRecord } from '@mui/icons-material';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import CustomTabPanel from '../CustomTabPanel';
import { FindLargestPowerOfTwoConst } from '../../../utils/techniqueSagaConstant';
import TopicTitle from '../TopicTitle';
import ListRenderComponent from '../ListRenderComponent';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const SearchIna2ndMatrix = ({
  handlePostCompletedTopic,
  hasMarkedCompleted,
  loading,
  topic_id,
  handleNext,
  handlePrevious,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='36px'
      paddingY={2}
      maxWidth='calc(100% - 300px)'
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <TopicTitle>3. Search in a 2d matrix</TopicTitle>
        {hasMarkedCompleted(topic_id) && (
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              color: 'green',
              fontWeight: '500',
              border: '2px solid green',
              padding: '4px 10px',
              borderRadius: '20px',
              cursor: 'default',
            }}
          >
            Completed
          </Typography>
        )}
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        gap='24px'
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            width: '36rem',
            background: '#FEF7FF',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab
              label='Problem Statement'
              {...a11yProps(0)}
            />
            <Tab
              label='Brute Force Approach'
              {...a11yProps(1)}
            />
            <Tab
              label='Binary Search Approach'
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel
          value={value}
          index={0}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              You are given an m x n matrix with the following properties:
            </Typography>
            <Typography>
              Integers in each row are sorted in ascending order from left to
              right.
            </Typography>
            <Typography>
              The first integer of each row is greater than the last integer of
              the previous row.
            </Typography>
            <Typography>
              Given a target integer, determine if the target exists in the
              matrix. The matrix has sorted rows and columns but can be
              considered a <b>"flattened" </b>sorted array.
            </Typography>
            <Typography>Example</Typography>
            <Box sx={{ maxWidth: 600, paddingLeft: '35px', textAlign: 'left' }}>
              <Typography
                variant='body1'
                sx={{ fontSize: '20px' }}
              >
                matrix = [
              </Typography>
              <Typography
                variant='body1'
                sx={{ paddingLeft: '35px', fontSize: '20px' }}
              >
                [1, 3, 5, 7],
              </Typography>
              <Typography
                variant='body1'
                sx={{ paddingLeft: '35px', fontSize: '20px' }}
              >
                [10, 11, 16, 20],
              </Typography>
              <Typography
                variant='body1'
                sx={{ paddingLeft: '35px', fontSize: '20px' }}
              >
                [23, 30, 34, 50]
              </Typography>
              <Typography
                variant='body1'
                sx={{ paddingLeft: '35px', fontSize: '20px' }}
              >
                ]
              </Typography>
            </Box>

            <Typography>target = 3,</Typography>
            <Typography>
              In this case 3 is there in the matrix so we will return true.
            </Typography>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={1}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              A straightforward way to solve this problem is to search every
              element in the matrix to see if it matches the target. We can do
              this with a nested loop, iterating through each row and each
              column within each row.
            </Typography>

            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '800px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`function searchMatrix(matrix, target):
    for row in matrix:
        for element in row:
            if element == target:
                return true
    return false
`}
              </pre>
            </Box>

            <Typography sx={{ paddingLeft: '1.5rem' }}>
              <b>Time and Space Complexity</b>
              <br />
              Since we are searching every element of the matrix in the worst
              case
              <br />
              Time Complexity : O(n*m)<br></br>
              Space Complexity : O(1)<br></br>
            </Typography>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={2}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              If we look at the problem, it is clearly mentioned that each row’s
              last element is less than the next row’s first element. So in a
              way you can imagine this as a 1D array in the following manner
            </Typography>
            <Box sx={{ paddingLeft: '20px' }}>
              <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                <li>
                  <Typography variant='body1'>
                    For a given "flattened" 1D index i, the corresponding matrix
                    row index is i // n and the column index is i % n.
                  </Typography>
                </li>
                <li>
                  <Typography variant='body1'>
                    This allows us to perform binary search on the entire matrix
                    while keeping it in its 2D form.
                  </Typography>
                </li>
              </ul>
            </Box>

            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '800px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`function searchMatrix(matrix, target):
    if matrix is empty:
        return false

    rows = number of rows in matrix
    cols = number of columns in matrix
    low = 0
    high = rows * cols - 1

    while low <= high:
        mid = low + (high - low) // 2
        row = mid // cols
        col = mid % cols

        if matrix[row][col] == target:
            return true
        elif matrix[row][col] < target:
            low = mid + 1
        else:
            high = mid - 1

    return false


`}
              </pre>
            </Box>

            <Typography sx={{ paddingLeft: '1.5rem' }}>
              <b>Time and Space Complexity</b>
              <br />
              We are applying binary search on a flattened 1D array which would
              in total have n*m elements.
              <br />
              Time Complexity: O(log(n*m))<br></br>
              Space Complexity: O(1)<br></br>
            </Typography>
          </Box>
        </CustomTabPanel>
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        alignSelf='end'
        justifyContent='space-between'
        gap='12px'
      >
        {!hasMarkedCompleted(topic_id) && (
          <LoadingButton
            variant='contained'
            onClick={() => handlePostCompletedTopic(topic_id)}
            loading={loading}
            loadingPosition='center'
            children='Mark as completed'
            style={{
              width: '170px',
              // backgroundColor: 'transparent',
              borderRadius: '8px',
              border: '1px solid rgba(64, 96, 245, 0.5)',
            }}
          />
        )}

        <Box
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Button
            sx={{ gap: '4px' }}
            onClick={handlePrevious}
          >
            <WestIcon /> Previous
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default SearchIna2ndMatrix;
