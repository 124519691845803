import { Box, Typography } from '@mui/material';
import React from 'react';
import CircularProgressWithLabel from './CircularProgressBar';

const SignatureFrameworkItem = ({
  title,
  icon,
  data,
  onClick,
  index,
  text,
}) => {
  const extractNumericValue = (str) => {
    const numericValue = str.match(/\d+/);
    return numericValue ? parseInt(numericValue[0], 10) : 0;
  };

  const completed =
    typeof data.completed === 'string'
      ? extractNumericValue(data.completed)
      : data.completed;
  const total =
    typeof data.total === 'string'
      ? extractNumericValue(data.total)
      : data.total;

  const progress = (completed / total) * 100;
  return (
    <Box
      onClick={onClick}
      style={{
        width: '48.4%',
        height: '70px',
        marginLeft: index % 2 === 0 ? 0 : 'auto',
        padding: '8px',
        display: 'flex',
        alignItems: 'center',
        cursor: onClick ? 'pointer' : 'default',
        backgroundColor: '#c1e6f7',
        borderRadius: '7px',
        marginBottom: '18px',
      }}
    >
      {icon && (
        <div style={{ marginRight: '8px' }}>
          <img
            src={icon}
            alt='svg'
            height='40px'
            width='40px'
          />
        </div>
      )}
      <Box
        sx={{
          marginLeft: '10px',
        }}
      >
        <Typography
          variant='body1'
          color='initial'
          fontWeight={600}
        >
          {title}
        </Typography>
        {total !== 0 && title !== 'TGP' ? (
          <Typography>
            {data.completed}/{data.total} {text}
          </Typography>
        ) : (
          <Typography>Total 10 {text}</Typography>
        )}
      </Box>
      {total !== 0 && <CircularProgressWithLabel value={progress} />}
    </Box>
  );
};

export default SignatureFrameworkItem;
