import React, { useState } from 'react';
import {
  Box,
  Typography,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
} from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import VerifiedIcon from '@mui/icons-material/Verified';
import { FiberManualRecord } from '@mui/icons-material';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import CustomTabPanel from '../CustomTabPanel';
import { FindLargestPowerOfTwoConst } from '../../../utils/techniqueSagaConstant';
import { FindTheWinnerOfCircularGameConstant } from '../../../utils/techniqueSagaConstant';
// import TopicTitle from '../TopicTitle';
import TopicTitle from '../../Recursion/TopicTiltle';
import ListRenderComponent from '../ListRenderComponent';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import cwinner from '../../../../assets/cwinner.png';
import winnerRecursive from '../../../../assets/winnerrecursive.png';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const FindTheWinnerOfCircularGame = ({
  handlePostCompletedTopic,
  hasMarkedCompleted,
  loading,
  topic_id,
  handleNext,
  handlePrevious,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='36px'
      paddingY={2}
      maxWidth='calc(100% - 300px)'
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <TopicTitle>2. Find the winner of circular game</TopicTitle>
        {hasMarkedCompleted(topic_id) && (
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              color: 'green',
              fontWeight: '500',
              border: '2px solid green',
              padding: '4px 10px',
              borderRadius: '20px',
              cursor: 'default',
            }}
          >
            Completed
          </Typography>
        )}
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        gap='24px'
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            width: '34rem',
            background: '#FEF7FF',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab
              label='Problem Statement'
              {...a11yProps(0)}
            />
            <Tab
              label='Iterative Approach'
              {...a11yProps(1)}
            />
            <Tab
              label='Recursive Approach'
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel
          value={value}
          index={0}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              {`There are 'n' friends that are playing a game. The friends are sitting in a circle and 
  are numbered from 1 to n in clockwise order. More formally, moving clockwise from the ith 
  friend brings you to the (i+1)th friend for 1 <= i < n, and moving clockwise 
  from the nth friend brings you to the 1st friend.`}
            </Typography>

            <Box
              style={{ paddingLeft: '20px', fontFamily: 'Arial, sans-serif' }}
            >
              <Typography
                sx={{ fontSize: '22px', fontWeight: 500, marginBottom: '16px' }}
              >
                {FindTheWinnerOfCircularGameConstant.gameRules.title}
              </Typography>

              <ol style={{ paddingLeft: '30px' }}>
                {FindTheWinnerOfCircularGameConstant.gameRules.points.map(
                  (point, index) => (
                    <li
                      key={index}
                      style={{ marginBottom: '10px', lineHeight: '1.6' }}
                    >
                      <Typography>{point}</Typography>
                    </li>
                  )
                )}
              </ol>
            </Box>
            <Typography>
              Given the number of friends, n, and an integer k, return the
              winner of the game.
            </Typography>
            <Typography>
              For Example:
              <br />
              If n=4 and k=2
              <br />
              Then the answer would be 1 as that is the last one left.
            </Typography>
            <img
              src={cwinner}
              alt=''
              style={{ height: '350px', width: '700px', text: 'center' }}
            />
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={1}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              We can use a queue to simulate the entire process and by
              eliminating players in a circular fashion. Every time you
              encounter the kth friend pop him from queue otherwise push him
              back to the queue. Keep doing this until your queue still have
              more than one candidate. Return the last person that exists.
            </Typography>

            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '800px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`Function findTheWinner(n, k):
    Initialize an empty queue 'q'

    // Populate the queue with numbers from 1 to n
    For i = 1 to n:
        Push i into the queue

    // Loop until only one player remains in the queue
    While size of q is not 1:
        x = k  // Number of players to skip
        
        // Rotate the queue by moving k-1 players to the back
        While x > 1:
            r = front element of q
            Pop the front element from q
            Push r to the back of q
            Decrement x by 1

        // Remove the k-th player from the game
        Pop the front element from q

    // Return the last remaining player
    Return front element of q


`}
              </pre>
            </Box>

            <Typography>
              <b>Time and Space Complexity</b>
              <br />
              The loop runs for ‘n’ elements in the worst case for ‘k’ times,
              thus the time complexity of this would be O(n*k and also since we
              are using a queue to simulate the process we require O(n) space as
              well.
              <br />
              <br />
              Time complexity : O(n*k)<br></br>
              Space complexity : O(n)
            </Typography>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={2}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Box
              display='flex'
              flexDirection='column'
              gap='8px'
            >
              <Typography sx={{ fontSize: '18px', fontWeight: 400, mb: '8px' }}>
                In order to see if we can solve the problem recursively the
                first step is to understand whether the solution to the larger
                subproblem depends on the solution of a smaller subproblem,
                which is the case, to get the answer for n=4 you need to
                calculate for n=3 and for that n=2 and so on. So the first step
                is fulfilled.
                <br />
                But we need to somehow preserve the indexes of the original
                problem so it is not as easy. Suppose consider the example
                provided in the description.
              </Typography>
              <img
                src={winnerRecursive}
                alt=''
                style={{ height: '350px', width: '700px', text: 'center' }}
              />

              <Typography>
                Imagine we start by removing the 3rd element. If k = 7, we would
                still remove the same 3rd element, because the index to remove
                always falls within the range from 1 to n. So, to find the index
                of the next element to remove, we use the formula (Index + k) %
                n.
              </Typography>
              <Typography>
                Now, after removing one element, we need to continue, but we
                can't just keep starting from 1. Instead, we treat the next
                number as the "new" 1. Essentially, we're shifting our
                perspective for the next round of eliminations, and we apply the
                same formula to determine the new index to remove
              </Typography>
              <Typography>
                We know that when there's only 1 element left (n = 1), that
                element will be at index 0. As the recursion unwinds, each
                previous step calculates its own result using the formula (index
                + k) % n, adjusting for the elements that have already been
                removed.
              </Typography>
              <Typography>
                This process continues until we find the final winner.
              </Typography>
              <Typography>
                In short, every round of elimination adjusts the index relative
                to the current size of the circle, and we keep shifting our
                perspective as we move forward, which allows us to correctly
                calculate the winner at the end.
              </Typography>
            </Box>
            <Box>
              <Box
                sx={{
                  backgroundColor: 'black',
                  padding: '24px',
                  borderRadius: '10px',
                  width: '883px',
                }}
              >
                <pre style={{ color: 'white', margin: 0 }}>
                  {`
function findTheWinner(n, k):
    return solve(n, k) + 1 //for 1-based indexing

function solve(n, k):
    if n == 1:
        return 0   // Base case: if there's only one person, they are the winner (index 0)
                  // Recursive case: Calculate the position of the next person to eliminate
    return (solve(n-1, k) + k) % n


`}
                </pre>
              </Box>
              <br />
              <Typography sx={{ paddingLeft: '1.5rem' }}>
                <b>Time and Space Complexity</b>
                <br />
                We are calling the recursive function n times and the recursion
                stack will take at most n space.
                <br />
                <br />
                Time Complexity : O(n)<br></br>
                Space Complexity : O(n)<br></br>
              </Typography>
            </Box>
          </Box>
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Box
              display='flex'
              flexDirection='column'
              gap='8px'
            >
              <br></br>

              <Typography>
                <h3>Another approach:</h3>
              </Typography>
              <Typography>
                You could break the problem in a different way.<br></br>
                &nbsp;&nbsp;x^n = x*x*x*....x (n times)
                <br></br>
                &nbsp;&nbsp;x^n = x^(n/2) * x^(n/2) (in case n is even).
                <br />
                &nbsp;&nbsp;x^n = x* x^(n/2) * x^(n/2) (in case n is odd).
                <br />
              </Typography>
            </Box>
            <Box>
              <Typography>
                Let’s use this way of breaking down the problem and write the
                code for it:<br></br>
                <br></br>
              </Typography>
              <Box
                sx={{
                  backgroundColor: 'black',
                  padding: '24px',
                  borderRadius: '10px',
                  width: '800px',
                }}
              >
                <pre style={{ color: 'white', margin: 0 }}>
                  {`
Func power(int x, int n){

    //Base Case
    if(n==0){
        Return 1;
    }

    //Smaller output calculation
    Int small_output = power(x, n/2)

    //Combine the values
    if(n%2==0){
        Return small_output * small_output;
    }

    Else{
        Return small_output * small_output * x
    }
}







`}
                </pre>
              </Box>
              <br />
              <Typography sx={{ paddingLeft: '1.5rem' }}>
                <b>Time and Space Complexity</b>
                <br />
                We are reducing our problem in half every time we call the
                function and each function calls only one other function, thus
                the time complexity would be O(log n) and so is the case for the
                recursion call stacks so the space complexity is also O(log n).
                <br />
                <br />
                Time complexity: O(log n)<br></br>
                Space Complexity: O(log n)<br></br>
              </Typography>
            </Box>
          </Box>
        </CustomTabPanel>
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        alignSelf='end'
        justifyContent='space-between'
        gap='12px'
      >
        {!hasMarkedCompleted(topic_id) && (
          <LoadingButton
            variant='contained'
            onClick={() => handlePostCompletedTopic(topic_id)}
            loading={loading}
            loadingPosition='center'
            children='Mark as completed'
            style={{
              width: '170px',
              // backgroundColor: 'transparent',
              borderRadius: '8px',
              border: '1px solid rgba(64, 96, 245, 0.5)',
            }}
          />
        )}

        <Box
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Button
            sx={{ gap: '4px' }}
            onClick={handlePrevious}
          >
            <WestIcon /> Previous
          </Button>

          <Button
            sx={{ gap: '4px' }}
            onClick={handleNext}
          >
            Next <EastIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default FindTheWinnerOfCircularGame;
