import React from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
} from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import { FiberManualRecord } from '@mui/icons-material';
import EastIcon from '@mui/icons-material/East';
import ListRenderComponent from './ListRenderComponent';
import ParagraphBox from './ParagraphBox';
import { IntroductionRecursionConstant } from '../../utils/techniqueSagaConstant';

// Images
import Representation from '../../../images/Representation.png';
import BitwiseOperators from '../../../images/BitwiseOperators.png';
// import { Factotial } from '../../../assets/factorial.png'
import factorial from '../../../assets/factorial.png';
import naturalnum from '../../../assets/natural_numbers.png';
import valueofn from '../../../assets/valueofn.png';
import fib1 from '../../../assets/fib1.png';
import fib2 from '../../../assets/fib2.png';
import fib3 from '../../../assets/fib3.png';
import fibchain from '../../../assets/fibchain.png';

const Introduction = ({
  handlePostCompletedTopic,
  hasMarkedCompleted,
  loading,
  topic_id,
  handleNext,
}) => {
  const { text01, text04, text05, text06 } = IntroductionRecursionConstant;

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='20px'
      paddingY={2}
      maxWidth='calc(100% - 300px)'
    >
      {/* Header */}
      <Box>
        <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
          TECHNIQUE 3
        </Typography>
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
        >
          <Typography
            sx={{
              fontSize: '48px',
              fontWeight: 600,
              lineHeight: '58px',
              letterSpacing: '0.01em',
              textAlign: 'left',
            }}
          >
            Recursion
          </Typography>
          {hasMarkedCompleted(topic_id) && (
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
                color: 'green',
                fontWeight: 500,
                border: '2px solid green',
                padding: '4px 10px',
                borderRadius: '20px',
                cursor: 'default',
              }}
            >
              Completed
            </Typography>
          )}
        </Box>
      </Box>

      {/* Introduction */}
      <Box
        display='flex'
        flexDirection='column'
        gap='30px'
      >
        <Typography sx={{ fontSize: '22px', fontWeight: 400 }}>
          Introduction
        </Typography>
        <ParagraphBox>
          <Typography sx={{ fontSize: '20px', fontWeight: 400 }}>
            Recursion
          </Typography>
          <Typography>
            Recursion is a process where a function calls itself, either
            directly or indirectly. A function that uses this approach is called
            a recursive function. Recursion is especially useful for solving
            problems that can be broken down into smaller, similar subproblems.
            However, simply thinking of recursion as "a function calling itself"
            isn't the most helpful way to understand its purpose.
          </Typography>
          <Typography>
            A recursive function works by solving a smaller version of the
            original problem with each call. These smaller problems keep getting
            reduced until they reach a point where the solution is simple and
            direct. This point is called the base case. The base case is crucial
            because it provides a stopping condition for the recursive process,
            preventing it from continuing indefinitely.
          </Typography>
          <ul>
            {text01.points.map((point, index) => (
              <li
                key={index}
                style={{ paddingLeft: '1.5em' }}
              >
                <Typography>{point}</Typography>
              </li>
            ))}
          </ul>

          <Typography>
            The base case here is when n equals 1 (or 0), where the answer is
            already known.
          </Typography>
          <Typography>
            Let us see how we can implement the approach that we talked about.
          </Typography>
          <Box
            sx={{
              backgroundColor: 'black',
              padding: '24px',
              borderRadius: '10px',
              width: '800px',
            }}
          >
            <pre style={{ color: 'white', margin: 0 }}>
              {`
factorial(n):
    if n is 0 or 1:      //Base Case
        return 1
    else:
    return n * factorial(n - 1)//Solve for smaller subproblem

`}
            </pre>
          </Box>
        </ParagraphBox>

        {/* Need of Recursion */}
        <ParagraphBox>
          <Typography sx={{ fontSize: '20px', fontWeight: 400 }}>
            Need of Recursion
          </Typography>
          <Typography>
            The problem that we discussed earlier about finding the factorial of
            a number, for that problem we could have done this with an iterative
            approach as well using loops. However, there are reasons why
            recursion is still valuable even when an iterative approach exists.
            Let's explore these points:
          </Typography>
          <Typography style={{ paddingLeft: '1.5em' }}>
            <b>1. Key Role in Simplifying Complex Problems</b>
            <br />
            Recursion provides an intuitive way to solve complex problems by
            breaking them down into smaller, more manageable subproblems. By
            working on these simpler cases first, recursion allows you to
            gradually build up the solution to the entire problem. This
            step-by-step decomposition often makes complex tasks easier to
            reason about and solve. Recursion supports the "divide-and-conquer"
            approach, where a problem is split into smaller parts, solved
            independently, and then combined to form the final solution.
          </Typography>
          <Typography style={{ paddingLeft: '1.5em' }}>
            <b>2. Improving Code Readability</b>
            <br />
            While iterative solutions may involve managing complex loops and
            auxiliary data structures, recursion can often reduce the amount of
            boilerplate code. The recursive function manages the state
            automatically through function calls, keeping the solution clean and
            less error-prone.
          </Typography>
          <Typography style={{ paddingLeft: '1.5em' }}>
            <b>3. Alignment with Problem Structure</b>
            <br />
            Certain problems, like those involving trees, graphs, or other
            recursive data structures, are more naturally solved using
            recursion. In such cases, a recursive solution closely mirrors the
            structure of the problem, making it easier to design and implement.
          </Typography>
        </ParagraphBox>

        {/* Recursion Stack */}
        <ParagraphBox>
          <Typography sx={{ fontSize: '22px', fontWeight: 400 }}>
            Recursion Stack
          </Typography>
          <Typography>
            In any recursive function, when a function calls itself, the system
            needs to remember where it left off in the current call so that it
            can resume execution once the recursive call finishes. This "memory"
            is managed through a data structure known as the{' '}
            <b> recursion stack</b>.
          </Typography>
          <Typography>
            <Typography>
              Each time a function is called, the system pushes a new stack
              frame onto the <b>stack</b>. This frame contains information such
              as:
            </Typography>
            {text04.title}
            <ol style={{ listStyleType: 'decimal', color: '#333' }}>
              {text04.points.map((point, index) => (
                <li
                  key={index}
                  style={{ textIndent: '5px', paddingLeft: '1.5em' }}
                >
                  {point}
                </li>
              ))}
            </ol>
          </Typography>
        </ParagraphBox>

        <Box>
          <Typography
            sx={{ fontSize: '22px', fontWeight: 400, marginBottom: '10px' }}
          >
            How it Works
          </Typography>
          <div>
            {text05.points.map((point, index) => (
              <div
                key={index}
                style={{ marginBottom: '10px' }}
              >
                {/* Heading */}
                <Typography>
                  <h3 style={{ marginBottom: '5px' }}>{point.heading}</h3>
                  {/* Description */}
                </Typography>
                <Typography>
                  <p style={{ margin: 0 }}>{point.description}</p>
                </Typography>
              </div>
            ))}
          </div>

          {/* Example */}

          <Typography>
            Although recursive solutions are cleaner and easier to read but they
            can also be tricky to understand and write. A great way to approach
            a recursive solution is to define very straightforward steps to the
            solution, let us take an example:
          </Typography>
          <Typography>
            Given a number ‘n’ print all the natural numbers from 1 to n but in
            reverse order.
          </Typography>
          <br></br>
          <Typography>
            So what you will do essentially is count till N and then start
            printing from there until you reach 0. Now let us start putting that
            in code.
          </Typography>
          <br />
          <Box
            sx={{
              backgroundColor: 'black',
              padding: '24px',
              borderRadius: '10px',
              width: '583px',
            }}
          >
            <pre style={{ color: 'white', margin: 0 }}>
              {`
function natural_numbers(int n, int i){
   
    //Stop when counting reaches n    
    if(i>n){ 
        return;
    }
    
    natural_numbers(n,i+1); //Count numbers
    print(i); //Print the number
}

`}
            </pre>
          </Box>
        </Box>
      </Box>
      <Box>
        <br></br>
        <Typography>
          Let us see its call stack to understand better: let’s say the value of
          n was 4 then our stack would look something like this.
        </Typography>
        <br></br>
        {/* <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '583px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`
function natural_numbers(int n, int i){
   
    //Stop when counting reaches n    
    if(i>n){ 
        return;
    }
    
    natural_numbers(n,i+1); //Count numbers
    print(i); //Print the number
}

`}
              </pre>
            </Box> */}
        <img
          src={valueofn}
          alt=''
          style={{ height: '250px', width: '700px', text: 'center' }}
        />
        <Typography>
          Since we counted first and then we printed we started printing from n
          all the way to 1. Output would be 4 3 2 1.
        </Typography>
      </Box>
      <Box>
        <Typography sx={{ fontSize: '22px', fontWeight: 600 }}>
          Stack Overflow
        </Typography>
        <Typography>
          Stack overflow errors in recursion occur when the recursive function
          calls consume more stack space than is available. This typically
          happens when the base case of the recursion is not reached or not
          properly defined, leading to an infinite recursion loop. That’s why
          it's very important to define good base cases.
        </Typography>

        <Typography>
          The algorithmic steps for implementing recursion in a function are as
          follows:
        </Typography>
        <div style={{ padding: '5px', fontFamily: 'Arial, sans-serif' }}>
          <div>
            {text06.steps.map((step, index) => (
              <div
                key={index}
                style={{ marginBottom: '15px', lineHeight: '1.6' }}
              >
                <Typography style={{ paddingLeft: '1.5em' }}>
                  <strong>
                    {step.stepNumber} - {step.heading}:
                  </strong>{' '}
                  {step.description}
                </Typography>
              </div>
            ))}
          </div>
        </div>
        <Typography>
          Let us try to understand it with a classic example
        </Typography>
        <br />
        <ParagraphBox>
          <Typography>
            <b>
              <h3>Find the nth fibonacci number</h3>
            </b>
            Given a number ‘n’, find the nth number in the fibonacci series. A
            fibonacci series is formed by adding the previous two numbers where
            the series starts with the first two numbers as 1 Below is a
            fibonacci series:<strong>&nbsp;1, 1, 2, 3, 5, 8….</strong>
          </Typography>

          <Typography>
            Let us try to solve this problem recursively using the steps that we
            discussed:
          </Typography>
          <Typography style={{ paddingLeft: '1.5em' }}>
            <b>Step 1: Identify the Recursive Relationship</b>
            <br></br>
            We need to identify how the solution to the bigger problem (nth
            Fibonacci number) depends on smaller subproblems. The Fibonacci
            number at position n is formed by adding the previous two Fibonacci
            numbers:<br></br>
            nth element = (n-1)th element + (n-2)th element , where n>2.
          </Typography>
          <Box>
            <br></br>
            <Typography style={{ paddingLeft: '1.5em' }}>
              <b>Step 2: Define the Base Case</b>
              <br></br>
              The base case is the simplest form of the problem where the
              solution is known. In this case, the 1st and 2nd Fibonacci numbers
              are both 1, so we can write the code as follows:
            </Typography>
            <br />
            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '583px',
                // paddingLeft: '2em',
                marginLeft: '2em',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`
Func fib(int n){

    //Base case defined
    if(n==1 OR n==2){
        Return 1;
    }
}



`}
              </pre>
            </Box>
          </Box>
          <Box>
            <br></br>

            <Typography style={{ paddingLeft: '1.5em' }}>
              <b>Step 3: Solve the Smaller Subproblems</b>
              <br></br>
              Once we have the base case, the next step is to solve the smaller
              subproblems. We need to call the function recursively to find the
              (n-1)th and (n-2)th Fibonacci numbers:
            </Typography>
            <br />
            <Box
              sx={{
                backgroundColor: 'black',
                // padding: '24px',
                borderRadius: '10px',
                width: '583px',
                paddingLeft: '1.5em',
                marginLeft: '2em',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`

Func fib(int n){

//Base case defined
if(n==1 OR n==2){
    Return 1;
}

//Solution to the smaller sub-problem
Int smaller_output1 = fib(n-1)
Int smaller_output2 = fib(n-2)
}


}



`}
              </pre>
            </Box>
          </Box>
          <Box>
            <br></br>

            <Typography style={{ paddingLeft: '1.5em' }}>
              <b>Step 4: Combine the Solutions of Smaller Subproblems</b>
              <br></br>
              Now that we have the solution to the smaller subproblems, we
              combine them to find the larger solution, i.e., the nth Fibonacci
              number:
            </Typography>
            <br />
            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '583px',
                paddingLeft: '1.5em',
                marginLeft: '2em',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`
Func fib(int n){

    //Base case defined
    if(n==1 OR n==2){
        Return 1;
    }

    //Solution to the smaller sub-problem
    Int smaller_output1 = fib(n-1)
    Int smaller_output2 = fib(n-2)

    //Combine the solution of smaller sub-problems
    Int larger_output = smaller_output1 + smaller_output2
    Return larger_output
}


`}
              </pre>
            </Box>
          </Box>
          <br />
          <Typography>
            This is a simple example of recursion where we repeatedly break down
            the problem into smaller subproblems, solve them, and use the
            solutions to solve the original problem.
          </Typography>
          <Typography>
            Time Complexity The time complexity of the provided code is
            exponential, specifically O(2^n). This is because the recursive
            function fib makes two recursive calls for each call, leading to an
            exponential growth in the number of function calls.
          </Typography>
          <Typography>
            To understand it better take a look at this recursion tree. A
            recursion tree is a graphical representation used to visualize the
            execution of a recursive algorithm. It helps to understand how the
            recursive function calls are made and how the subproblems are broken
            down at each level of recursion.
          </Typography>
          <Box>
            <Typography>
              To understand this, let's consider the recursion tree for fib(6)
            </Typography>
            <img
              src={fibchain}
              alt=''
              style={{ height: '450px', width: '700px', text: 'center' }}
            />
            <Typography>
              As you can see, many calls are repeated multiple times, leading to
              redundant computations. The number of nodes in the recursion tree
              grows exponentially with the input value n. Every function calls
              two more functions resulting in a time complexity of (2^n).
            </Typography>
          </Box>
        </ParagraphBox>
      </Box>

      {/* Footer Actions */}
      {/* <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        alignSelf='end'
        justifyContent='space-between'
        gap='12px'
      >
        {!hasMarkedCompleted(topic_id) && (
          <LoadingButton
            variant='contained'
            onClick={() => handlePostCompletedTopic(topic_id)}
            loading={loading}
            loadingPosition='center'
            children='Mark as completed'
            style={{
              width: '170px',
              // backgroundColor: 'transparent',
              borderRadius: '8px',
              border: '1px solid rgba(64, 96, 245, 0.5)',
            }}
          />
        )}

        <Box
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Button
            sx={{ gap: '4px' }}
            onClick={handleNext}
          >
            Next <EastIcon />
          </Button>
        </Box>
      </Box> */}
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        alignSelf='end'
        justifyContent='space-between'
        gap='12px'
      >
        {!hasMarkedCompleted(topic_id) && (
          <LoadingButton
            variant='contained'
            onClick={() => handlePostCompletedTopic(topic_id)}
            loading={loading}
            loadingPosition='center'
            children='Mark as completed'
            style={{
              width: '170px',
              // backgroundColor: 'transparent',
              borderRadius: '8px',
              border: '1px solid rgba(64, 96, 245, 0.5)',
            }}
          />
        )}

        <Box
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Button
            sx={{ gap: '4px' }}
            onClick={handleNext}
          >
            Next <EastIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Introduction;
