import React, { useState } from 'react';
import {
  Box,
  Typography,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
} from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import VerifiedIcon from '@mui/icons-material/Verified';
import { FiberManualRecord } from '@mui/icons-material';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import CustomTabPanel from '../CustomTabPanel';
import { FindLargestPowerOfTwoConst } from '../../../utils/techniqueSagaConstant';
import TopicTitle from '../TopicTitle';
import ListRenderComponent from '../ListRendereComponent';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import divideStep from '../../../../assets/divideStep.png';
import merge1 from '../../../../assets/mergeStep.png';
import merge2 from '../../../../assets/mergeStep2.png';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const CountInversions = ({
  handlePostCompletedTopic,
  hasMarkedCompleted,
  loading,
  topic_id,
  handleNext,
  handlePrevious,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='36px'
      paddingY={2}
      maxWidth='calc(100% - 300px)'
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <TopicTitle>1.Count Inversions</TopicTitle>
        {hasMarkedCompleted(topic_id) && (
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              color: 'green',
              fontWeight: '500',
              border: '2px solid green',
              padding: '4px 10px',
              borderRadius: '20px',
              cursor: 'default',
            }}
          >
            Completed
          </Typography>
        )}
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        gap='24px'
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            width: '36rem',
            background: '#FEF7FF',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab
              label='Problem Statement'
              {...a11yProps(0)}
            />
            <Tab
              label='Brute Force Approach'
              {...a11yProps(1)}
            />
            <Tab
              label='Divide and Conquer Approach'
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel
          value={value}
          index={0}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Box sx={{ padding: 4, lineHeight: 1.6 }}>
              <Typography
                variant='body1'
                paragraph
              >
                Given an array <strong>arr[]</strong> of <strong>n</strong>{' '}
                integers, the goal is to count the number of inversions in the
                array. An inversion is a pair of indices <strong>(i, j)</strong>{' '}
                such that:
              </Typography>
              <Box
                component='ul'
                sx={{ paddingLeft: 4 }}
              >
                <Typography component='li'>
                  <Typography
                    variant='body1'
                    component='span'
                  >
                    <strong>i &lt; j</strong>
                  </Typography>
                </Typography>
                <Typography component='li'>
                  <Typography
                    variant='body1'
                    component='span'
                  >
                    <strong>arr[i] &gt; arr[j]</strong>
                  </Typography>
                </Typography>
              </Box>
              <br />
              <Typography
                variant='body1'
                paragraph
              >
                In other words, an inversion is when a larger number appears
                before a smaller number in the array.
              </Typography>
              <Typography
                variant='h6'
                gutterBottom
              >
                Example:
              </Typography>
              <Typography
                variant='body1'
                paragraph
              >
                For an array <strong>arr = [8, 4, 2, 1]</strong>, the inversions
                are:
              </Typography>
              <Box
                component='ul'
                sx={{ paddingLeft: 4 }}
              >
                {[
                  '(0, 1) because 8 > 4',
                  '(0, 2) because 8 > 2',
                  '(0, 3) because 8 > 1',
                  '(1, 2) because 4 > 2',
                  '(1, 3) because 4 > 1',
                  '(2, 3) because 2 > 1',
                ].map((text, index) => (
                  <Typography
                    component='li'
                    key={index}
                    sx={{ marginBottom: 2 }}
                  >
                    <Typography
                      variant='body1'
                      component='span'
                    >
                      {text}
                    </Typography>
                  </Typography>
                ))}
              </Box>
              <Typography
                variant='body1'
                paragraph
              >
                <strong>Total inversions = 6.</strong>
              </Typography>
            </Box>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={1}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              A very straightforward approach would be to simply check every
              possible pair, for which you can just iterate two loops, where the
              first loop will iterate over every possible element and the inner
              loop would iterate over every element after the selected element
              and then just do the comparison to find all the valid pairs.A very
              straightforward approach would be to simply check every possible
              pair, for which you can just iterate two loops, where the first
              loop will iterate over every possible element and the inner loop
              would iterate over every element after the selected element and
              then just do the comparison to find all the valid pairs.
            </Typography>
            <Typography>Below is the implementation for it:</Typography>

            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '583px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`function countInversions(arr):
    count = 0
    for i = 0 to n-1:
        for j = i+1 to n-1:
            if arr[i] > arr[j]:
                count += 1
    return count




`}
              </pre>
            </Box>

            <Typography sx={{ paddingLeft: '1.5rem' }}>
              <b>Time and Space Complexity</b>
              <br />
              You are using two nested loops with no extra space.
              <br />
              Time Complexity : O(n^2)<br></br>
              Space Complexity : O(1)<br></br>
            </Typography>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={2}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Box
              display='flex'
              flexDirection='column'
              gap='8px'
            >
              <Typography>
                {
                  "Trying to think of a solution from this approach might be tricky, sure we can divide the array into two parts, for example: {(8, 4, 2, 1)}, we can take it apart into two parts {(8, 4)}{' '} and {(2, 1)} and then {(8, 4)} further into {8} and {4}, concluding that we found a pair since 8>4 and in the array it is coming before 4 but the problem is that 8 contains such pairs from the right array as well and so does 4. This would eventually make us compare every element with every other element, but what if the two sides were sorted?"
                }
              </Typography>
              <Box sx={{ padding: 4, lineHeight: 1.8 }}>
                <Typography
                  variant='body1'
                  paragraph
                >
                  For example: let’s say the left side was{' '}
                  <strong>{`{3, 4, 9}`}</strong> and the right side was{' '}
                  <strong>{`{1, 6, 7}`}</strong>, now you know two things:
                </Typography>
                <Box
                  component='ul'
                  sx={{ paddingLeft: 4 }}
                >
                  <Typography component='li'>
                    <Typography
                      variant='body1'
                      component='span'
                    >
                      Every element on the right side is coming after every
                      other element in the left side index wise.
                    </Typography>
                  </Typography>
                  <Typography component='li'>
                    <Typography
                      variant='body1'
                      component='span'
                    >
                      If we encounter an element in the left half that is
                      greater than an element in the right half, we can conclude
                      that all subsequent elements in the left half will also be
                      greater. This is due to the sorted nature of the array.
                    </Typography>
                  </Typography>
                </Box>
                <br />
                <Typography>
                  {
                    'This will basically save us the hassle of traversing the whole array. So, how do we use this in a solution, going back to the original example, if we have already compared {(8, 4)}, then actually sorting them would change nothing and we can convert it to {(4, 8)} and we can do a similar thing for the right part as well and turn {(2, 1)} to {(1, 2)}. Now, basically we have the same problem that we just discussed where we have two sorted halves and we have to count inversions.'
                  }
                </Typography>
                <br />
                <Typography>
                  So, from whatever we have discussed, you might get a hint that
                  we can use a merge sort with slight modification. The idea is
                  that during the merge step, when two sorted halves are
                  combined, we can count how many elements from the right half
                  are smaller than the current element from the left half. Every
                  time this happens, it indicates an inversion because elements
                  in the right half have larger indices but smaller values than
                  those in the left half.
                </Typography>
                <Box sx={{ padding: 4, lineHeight: 1.8 }}>
                  <Box
                    component='ul'
                    sx={{ paddingLeft: 4 }}
                  >
                    <Typography component='li'>
                      <Typography
                        variant='body1'
                        component='span'
                      >
                        Divide the array into two halves recursively until each
                        half has only one element (base case).
                      </Typography>
                    </Typography>
                    <Typography component='li'>
                      <Typography
                        variant='body1'
                        component='span'
                      >
                        Count inversions in the left and right halves using
                        recursion.
                      </Typography>
                    </Typography>
                    <Typography component='li'>
                      <Typography
                        variant='body1'
                        component='span'
                      >
                        During the merge step, count inversions between the two
                        halves.
                      </Typography>
                    </Typography>
                    <Typography component='li'>
                      <Typography
                        variant='body1'
                        component='span'
                      >
                        To count inversions, keep in mind that if an element in
                        the left half is greater than an element in the right
                        half, all remaining elements in the left half will also
                        form an inversion with the current element in the right
                        half (since the left half is sorted).
                      </Typography>
                    </Typography>
                  </Box>
                  <br />
                  <Typography
                    variant='body1'
                    paragraph
                  >
                    So, basically, our example array{' '}
                    <strong>{`{8, 4, 2, 1}`}</strong> will be done as follows:
                  </Typography>
                  <Typography>
                    <b>Divide Step:</b>
                  </Typography>
                  <br />
                  <br />
                  <img
                    src={divideStep}
                    alt=''
                    style={{
                      height: '170px',
                      width: '350px',
                      display: 'block',
                      margin: 'auto',
                    }}
                  />
                  <br />
                  <br />
                  <Typography>
                    <b>Merge Step:</b>
                  </Typography>
                  <br />
                  <br />
                  <img
                    src={merge1}
                    alt=''
                    style={{
                      height: '170px',
                      width: '350px',
                      display: 'block',
                      margin: 'auto',
                    }}
                  />
                  <Typography
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                    }}
                  >
                    <h3>{'8>4 & 2>1, '} Inversions = 2</h3>
                  </Typography>

                  <img
                    src={merge2}
                    alt=''
                    style={{
                      height: '170px',
                      width: '350px',
                      display: 'block',
                      margin: 'auto',
                    }}
                  />
                </Box>
                <Box sx={{ lineHeight: 1.8 }}>
                  <Typography
                    variant='body1'
                    paragraph
                  >
                    4 &gt; 1, and since the left is sorted, thus all elements
                    after 4 will also be greater, so we can directly write that:
                    <br />
                    <strong>{`{inversions = indexOf(mid) - indexOf(4) + 1}`}</strong>
                  </Typography>

                  <Typography
                    variant='body1'
                    paragraph
                  >
                    So, our inversion count with 4 becomes 2 and with 8 also 2,
                    in total we will have 2 + 4 = 6 inversions.
                  </Typography>
                  <Typography
                    variant='body1'
                    paragraph
                  >
                    Below is the implementation for it:
                  </Typography>
                  <Box
                    sx={{
                      backgroundColor: 'black',
                      padding: '24px',
                      borderRadius: '10px',
                      width: '800px',
                    }}
                  >
                    <pre style={{ color: 'white', margin: 0 }}>
                      {`mergeSortAndCount(arr[], temp[], left, right):
    if left == right:
        return 0

    mid = (left + right) / 2

    invCount = 0
    invCount += mergeSortAndCount(arr, temp, left, mid)
    invCount += mergeSortAndCount(arr, temp, mid + 1, right)
    invCount += mergeAndCount(arr, temp, left, mid, right)

    return invCount

mergeAndCount(arr[], temp[], left, mid, right):
    i = left     // Starting index for left subarray
    j = mid + 1  // Starting index for right subarray
    k = left     // Starting index for temp subarray
    invCount = 0

    while i <= mid and j <= right:
        if arr[i] <= arr[j]:
            temp[k] = arr[i]
            i += 1
        else:
            temp[k] = arr[j]
            invCount += (mid-i + 1)  // There are (mid - i + 1) inversions
            j += 1
        k += 1

    // Copy the remaining elements of left subarray, if any
    while i <= mid:
        temp[k] = arr[i]
        i += 1
        k += 1

    // Copy the remaining elements of right subarray, if any
    while j <= right:
        temp[k] = arr[j]
        j += 1
        k += 1

    // Copy the sorted subarray into Original array
    for i = left to right:
        arr[i] = temp[i]

    return invCount





`}
                    </pre>
                  </Box>
                  <br />
                  <Typography sx={{ paddingLeft: '1.5rem' }}>
                    <b>Time and Space Complexity</b>
                    <br />
                    Since we are just using merge sort with a slight
                    modification, the complexities will be the same as that
                    <br />
                    Time Complexity : O(n*log n)<br></br>
                    Space Complexity : O(n)<br></br>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </CustomTabPanel>
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        alignSelf='end'
        justifyContent='space-between'
        gap='12px'
      >
        {!hasMarkedCompleted(topic_id) && (
          <LoadingButton
            variant='contained'
            onClick={() => handlePostCompletedTopic(topic_id)}
            loading={loading}
            loadingPosition='center'
            children='Mark as completed'
            style={{
              width: '170px',
              // backgroundColor: 'transparent',
              borderRadius: '8px',
              border: '1px solid rgba(64, 96, 245, 0.5)',
            }}
          />
        )}

        <Box
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Button
            sx={{ gap: '4px' }}
            onClick={handlePrevious}
          >
            <WestIcon /> Previous
          </Button>

          <Button
            sx={{ gap: '4px' }}
            onClick={handleNext}
          >
            Next <EastIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CountInversions;
