import React, { useState } from 'react';
import {
  Box,
  Typography,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
} from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import VerifiedIcon from '@mui/icons-material/Verified';
import { FiberManualRecord } from '@mui/icons-material';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import CustomTabPanel from '../CustomTabPanel';
import { FindLargestPowerOfTwoConst } from '../../../utils/techniqueSagaConstant';
import TopicTitle from '../TopicTitle';
import ListRenderComponent from '../ListRenderComponent';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const LargestNumber = ({
  handlePostCompletedTopic,
  hasMarkedCompleted,
  loading,
  topic_id,
  handleNext,
  handlePrevious,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='36px'
      paddingY={2}
      maxWidth='calc(100% - 300px)'
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <TopicTitle>3.Largest Number</TopicTitle>
        {hasMarkedCompleted(topic_id) && (
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              color: 'green',
              fontWeight: '500',
              border: '2px solid green',
              padding: '4px 10px',
              borderRadius: '20px',
              cursor: 'default',
            }}
          >
            Completed
          </Typography>
        )}
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        gap='24px'
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            width: '36rem',
            background: '#FEF7FF',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab
              label='Problem Statement'
              {...a11yProps(0)}
            />
            <Tab
              label='Brute Force Approach'
              {...a11yProps(1)}
            />
            <Tab
              label='Sorting Approach'
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel
          value={value}
          index={0}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              Given a list of non-negative integers, arrange them in such a way
              that they form the largest possible number. The result should be
              returned as a string since the number might be very large.
            </Typography>
            <Typography>
              For Example:
              <br />
              <b> arr = [13, 9, 23, 54, 6]</b> , so the answer should be{' '}
              <b>“96542313”</b>
            </Typography>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={1}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              A brute-force approach would be to generate all possible
              permutations of the numbers and calculate the resulting
              concatenated numbers to find the largest one. However, this
              approach is highly inefficient for large lists due to the
              exponential number of permutations.
            </Typography>

            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '800px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`// Function to find the largest number by generating all permutations
function largestNumber_bf(nums):
    all_permutations = generate_all_permutations(nums)
    max_number = ""
    for perm in all_permutations:
        number = concatenate_elements(perm)
        max_number = maximum(max_number, number)
    return max_number

// Helper function to generate all permutations of a list
function generate_all_permutations(nums):
    result = []
    backtrack(nums, [], result)
    return result

// Helper function for recursive backtracking to generate permutations
function backtrack(nums, current, result):
    if length(current) == length(nums):
        result.append(current)
        return
    for i = 0 to length(nums) - 1:
        if nums[i] not in current:     // Avoid duplicates in the current permutation
            backtrack(nums, current + [nums[i]], result)

// Helper function to concatenate elements in an array as a string
function concatenate_elements(arr):
    result = ""
    for num in arr:
        result = result + to_string(num)  // Concatenate each number as a string
    return result

// Helper function to find the maximum between two numbers represented as strings
function maximum(num1, num2):
    if num1 > num2:
        return num1
    else:
        return num2





`}
              </pre>
            </Box>

            <Typography sx={{ paddingLeft: '1.5rem' }}>
              <b>Time and Space Complexity</b>
              <br />
              The code is highly inefficient as it requires calculating every
              possible permutation, storing them and then comparing all of them.
              <br />
              Time Complexity : O(n!)<br></br>
              Space Complexity : O(n!)<br></br>
            </Typography>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={2}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              A more efficient approach is to use a custom sorting rule based on
              concatenation. We define a comparator that sorts the numbers in
              such a way that, when concatenated, they form the largest possible
              number. Specifically, for two numbers x and y, we consider them in
              both possible concatenations x + y and y + x. If x + y is greater
              than y + x, then x should come before y.
            </Typography>

            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '800px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`function largestNumber(nums):
    // Convert all numbers to strings
    str_nums = map(str, nums)

    // Custom sort: compare based on which concatenation is larger
    sort str_nums based on (x + y > y + x)

    // Edge case: if the largest number is "0", return "0"
    if str_nums[0] == "0":
        return "0"

    // Join sorted numbers into a single string
    return join(str_nums)






`}
              </pre>
            </Box>

            <Typography sx={{ paddingLeft: '1.5rem' }}>
              <b>Time and Space Complexity</b>
              <br />
              We are just using a custom sort function on the array. Now since
              it is an inbuilt sort function, the space complexity would vary
              from language to language.<br></br>
              Time Complexity : O(n*logn)
              <br />
              Space Complexity: O(n)<br></br>
            </Typography>
          </Box>
        </CustomTabPanel>
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        alignSelf='end'
        justifyContent='space-between'
        gap='12px'
      >
        {!hasMarkedCompleted(topic_id) && (
          <LoadingButton
            variant='contained'
            onClick={() => handlePostCompletedTopic(topic_id)}
            loading={loading}
            loadingPosition='center'
            children='Mark as completed'
            style={{
              width: '170px',
              // backgroundColor: 'transparent',
              borderRadius: '8px',
              border: '1px solid rgba(64, 96, 245, 0.5)',
            }}
          />
        )}

        <Box
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Button
            sx={{ gap: '4px' }}
            onClick={handlePrevious}
          >
            <WestIcon /> Previous
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default LargestNumber;
