import React from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
} from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import { FiberManualRecord } from '@mui/icons-material';
import EastIcon from '@mui/icons-material/East';
import ListRenderComponent from './ListRenderComponent';
import ParagraphBox from './ParagraphBox';
import { Grid } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

// Images

import BubbleSort from '../../../assets/Bubblesort.gif';
import SelectionSort from '../../../assets/selectionsort.gif';
import InsertionSort from '../../../assets/insertionsort.gif';
import QuickSort from '../../../assets/Quicksort.gif';
import MergeSort from '../../../assets/mergesort.gif';

const Introduction = ({
  handlePostCompletedTopic,
  hasMarkedCompleted,
  loading,
  topic_id,
  handleNext,
}) => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='20px'
      paddingY={2}
      maxWidth='calc(100% - 300px)'
    >
      {/* Header */}
      <Box>
        <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
          TECHNIQUE 6
        </Typography>
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
        >
          <Typography
            sx={{
              fontSize: '48px',
              fontWeight: 600,
              lineHeight: '58px',
              letterSpacing: '0.01em',
              textAlign: 'left',
            }}
          >
            Sorting
          </Typography>
          {hasMarkedCompleted(topic_id) && (
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
                color: 'green',
                fontWeight: 500,
                border: '2px solid green',
                padding: '4px 10px',
                borderRadius: '20px',
                cursor: 'default',
              }}
            >
              Completed
            </Typography>
          )}
        </Box>
      </Box>

      {/* Introduction */}
      <Box
        display='flex'
        flexDirection='column'
        gap='24px'
      >
        <Typography sx={{ fontSize: '22px', fontWeight: 400 }}>
          Introduction
        </Typography>
        <ParagraphBox>
          <Typography>
            Sorting is one of the most fundamental concepts in computer science
            and programming. At its core, sorting is the process of arranging
            data in a particular order—typically ascending or descending.
            Imagine you have a stack of books, and you want to arrange them by
            height, publication date, or even author name. Sorting helps us
            structure this data in a meaningful way that makes retrieval,
            analysis, and decision-making faster and more efficient.
          </Typography>
          <Typography>
            <b>Why Sorting Matters</b>
          </Typography>
          <Typography>
            Think about real-world scenarios: looking for a contact in your
            phone, finding a particular file on your computer, or checking the
            latest scores in a sports league. When things are organized, finding
            what you need becomes intuitive. In programming, sorting often
            becomes a crucial part of working with lists, tables, or datasets,
            especially when we need to access information quickly. Efficient
            sorting saves not only computational resources but also makes the
            experience smoother for users who interact with the sorted data.
          </Typography>
          <Typography>
            Beyond organizing data, sorting can simplify complex problems. In
            algorithm design, sorting is often the first step that enables
            further operations, like finding the smallest or largest values,
            identifying duplicates, or merging multiple lists. This makes
            understanding sorting techniques a powerful tool in your
            problem-solving toolkit.
          </Typography>
          <Box>
            <Typography
              sx={{ fontSize: '22px', fontWeight: 400, lineHeight: '2' }}
            >
              Bubble Sort
            </Typography>
            <Typography>
              <b>Bubble Sort</b> is perhaps the simplest sorting algorithm,
              though not the most efficient. It repeatedly steps through the
              list, comparing adjacent elements and swapping them if they are in
              the wrong order. This process "bubbles" the largest unsorted
              element to its correct position at the end of the list in each
              pass.
            </Typography>
            <Typography
              variant='body1'
              paragraph
            >
              Here’s a step-by-step breakdown:
            </Typography>
            <Box sx={{ marginLeft: '20px', paddingLeft: '20px' }}>
              <ol>
                <li>
                  <Typography variant='body1'>
                    Start at the beginning of the list.
                  </Typography>
                </li>
                <li>
                  <Typography variant='body1'>
                    Compare the first two elements. If the first element is
                    larger, swap them.
                  </Typography>
                </li>
                <li>
                  <Typography variant='body1'>
                    Move to the next pair of elements and repeat the comparison
                    and swap process.
                  </Typography>
                </li>
                <li>
                  <Typography variant='body1'>
                    Continue until reaching the end of the list, where the
                    largest element is now correctly placed.
                  </Typography>
                </li>
                <li>
                  <Typography variant='body1'>
                    Repeat the process, each time ignoring the last sorted
                    element, until the entire list is sorted.
                  </Typography>
                </li>
              </ol>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%', // Optional, adjust based on your layout
              }}
            >
              <img
                src={BubbleSort}
                alt=''
                style={{ height: '200px', width: '400px' }}
              />
            </Box>
            <Typography>Below is the code implementation for it</Typography>
            <br></br>
            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '800px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`
function bubbleSort(arr):
    for i from 0 to arr.length - 1:
        // Initialize a flag to detect if the array is already sorted
        swapped = false
        
        // Perform a pass through the array, bubbling up the largest unsorted element
        for j from 0 to arr.length - i - 2:
            if arr[j] > arr[j + 1]:
                // Swap arr[j] and arr[j + 1]
                temp = arr[j]
                arr[j] = arr[j + 1]
                arr[j + 1] = temp
                swapped = true
        
        // If no elements were swapped, the array is already sorted
        if swapped == false:
            break


`}
              </pre>
            </Box>
            <Typography sx={{ paddingLeft: '1.5rem' }}>
              <br />
              <b>Time and Space Complexity</b>
              <br />
              Time Complexity : O(n^2)<br></br>
              Space Complexity : O(1)<br></br>
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{ fontSize: '22px', fontWeight: 400, lineHeight: '2' }}
            >
              Selection Sort
            </Typography>
            <Typography>
              <b>Selection Sort</b> sorts by repeatedly finding the smallest (or
              largest) element in the unsorted part of the list and moving it to
              the correct position. Unlike Bubble Sort, which swaps elements
              repeatedly, Selection Sort minimises swaps by only moving each
              element once.
            </Typography>
            <Typography
              variant='body1'
              paragraph
            >
              Here’s a step-by-step breakdown:
            </Typography>
            <Box sx={{ marginLeft: '20px', paddingLeft: '20px' }}>
              <ol>
                <li>
                  <Typography variant='body1'>
                    Start by scanning the list to find the smallest element.
                  </Typography>
                </li>
                <li>
                  <Typography variant='body1'>
                    Swap this smallest element with the first unsorted element.
                  </Typography>
                </li>
                <li>
                  <Typography variant='body1'>
                    Move the boundary of the sorted section one step forward,
                    effectively growing the
                  </Typography>
                </li>
                <li>
                  <Typography variant='body1'>
                    Repeat this process until all elements are sorted.
                  </Typography>
                </li>
              </ol>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%', // Optional, adjust based on your layout
              }}
            >
              <br />
              <img
                src={SelectionSort}
                alt=''
                style={{ height: '400px', width: '130px' }}
              />
              <br />
            </Box>
            <Typography>Below is the code implementation for it</Typography>
            <br />
            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '800px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`
        function selectionSort(arr):
         for i from 0 to arr.length - 1:
        // Assume the first unsorted element is the minimum
        minIndex = i
        
        // Find the smallest element in the unsorted section
        for j from i + 1 to arr.length - 1:
            if arr[j] < arr[minIndex]:
                minIndex = j
        
        // Swap the found minimum element with the first unsorted element
        if minIndex != i:
            temp = arr[i]
            arr[i] = arr[minIndex]
            arr[minIndex] = temp


`}
              </pre>
            </Box>
            <Typography sx={{ paddingLeft: '1.5rem' }}>
              <br />
              <b>Time and Space Complexity</b>
              <br />
              Time Complexity : O(n^2)<br></br>
              Space Complexity : O(1)<br></br>
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{ fontSize: '22px', fontWeight: 400, lineHeight: '2' }}
            >
              Insertion Sort
            </Typography>
            <Typography>
              <b>Insertion Sort</b> works by taking one element at a time and
              inserting it into its correct position in the already sorted
              section of the list. The sorted section grows as the algorithm
              proceeds, and elements are shifted as needed to make space for the
              inserted element.
            </Typography>
            <Typography
              variant='body1'
              paragraph
            >
              Here’s a step-by-step breakdown:
            </Typography>
            <Box sx={{ marginLeft: '20px', paddingLeft: '20px' }}>
              <ol>
                <li>
                  <Typography variant='body1'>
                    Start with the second element (index 1) and treat the first
                    element as a <strong>"sorted" </strong>section.
                  </Typography>
                </li>
                <li>
                  <Typography variant='body1'>
                    Take the current element and compare it with the elements in
                    the sorted section.
                  </Typography>
                </li>
                <li>
                  <Typography variant='body1'>
                    Shift the elements in the sorted section to the right if
                    they are greater than the current element.
                  </Typography>
                </li>
                <li>
                  <Typography variant='body1'>
                    Insert the current element into its correct position in the
                    sorted section.
                  </Typography>
                </li>
                <li>
                  <Typography variant='body1'>
                    Repeat this process for all remaining elements in the
                    unsorted section.
                  </Typography>
                </li>
              </ol>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%', // Optional, adjust based on your layout
              }}
            >
              <img
                src={InsertionSort}
                alt=''
                style={{ height: '200px', width: '400px' }}
              />
              <br />
            </Box>
            <Typography>Below is the code implementation for it</Typography>
            <br />
            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '800px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`
        function insertionSort(arr) {
         for (let i = 1; i < arr.length; i++) {
        // Store the current element
        let current = arr[i];
        let j = i - 1;

        // Shift elements of the sorted section to the right
        while (j >= 0 && arr[j] > current) {
            arr[j + 1] = arr[j];
            j--;
        }

        // Insert the current element at the correct position
        arr[j + 1] = current;
    }
}


`}
              </pre>
            </Box>
            <Typography sx={{ paddingLeft: '1.5rem' }}>
              <br />
              <b>Time and Space Complexity</b>
              <br />
              Time Complexity : O(n^2)<br></br>
              Space Complexity : O(1)<br></br>
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{ fontSize: '22px', fontWeight: 400, lineHeight: '2' }}
            >
              Quick Sort
            </Typography>
            <Typography>
              <b>Quick Sort </b>is another "divide and conquer" algorithm that
              is often faster in practice than Merge Sort, although it has a
              worst-case complexity of O(n2)O(n^2)O(n2) if poorly implemented.
              It works by selecting a "pivot" element and then partitioning the
              array around this pivot, so elements less than the pivot come
              before it, and elements greater than the pivot come after it. This
              is how quicksort works:
            </Typography>

            <Box sx={{ marginLeft: '20px', paddingLeft: '20px' }}>
              <ol>
                <li>
                  <Typography variant='body1'>
                    <b>Pivot Selection:</b> Choose a pivot element from the
                    array (often the first, last, or middle element, or a random
                    element).
                  </Typography>
                </li>
                <li>
                  <Typography variant='body1'>
                    <b>Partitioning:</b> Reorder the array so all elements less
                    than the pivot come before it, and all elements greater come
                    after it.
                  </Typography>
                </li>
                <li>
                  <Typography variant='body1'>
                    <b>Recursion: </b>Recursively apply the same process to the
                    left and right subarrays (elements before and after the
                    pivot).
                  </Typography>
                </li>
              </ol>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%', // Optional, adjust based on your layout
              }}
            >
              <img
                src={QuickSort}
                alt=''
                style={{ height: '200px', width: '400px' }}
              />
              <br />
            </Box>
            <Typography>Below is the code implementation for it</Typography>
            <br />
            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '800px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`
function quickSort(arr, low, high):
    if low < high:
        // Partition the array and get the pivot index
        pivotIndex = partition(arr, low, high)

        // Recursively apply quickSort to left and right partitions
        quickSort(arr, low, pivotIndex - 1)
        quickSort(arr, pivotIndex + 1, high)

function partition(arr, low, high):
    // Select the last element as the pivot
    pivot = arr[high]
    i = low - 1

    for j from low to high - 1:
        if arr[j] < pivot:
            i += 1
            // Swap arr[i] and arr[j]
            temp = arr[i]
            arr[i] = arr[j]
            arr[j] = temp

    // Swap the pivot element to its correct position
    temp = arr[i + 1]
    arr[i + 1] = arr[high]
    arr[high] = temp

    return i + 1  // Return the index of the pivot element

`}
              </pre>
            </Box>
            <Typography sx={{ paddingLeft: '1.5rem' }}>
              <br />
              <b>Efficiency:</b> Quick Sort has an average{' '}
              <b>time complexity of O(n*logn) but can degrade to)O(n^2)</b> in
              the worst case (e.g., when the pivot is consistently the smallest
              or largest element). Choosing an optimal pivot or using techniques
              like randomized Quick Sort can help avoid this.
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{ fontSize: '22px', fontWeight: 400, lineHeight: '2' }}
            >
              Merge Sort
            </Typography>
            <Typography>
              <b>Merge Sort</b> is a classic example of the{' '}
              <b>"divide and conquer"</b> approach. It recursively divides the
              list into smaller sublists until each sublist has only one
              element, which is inherently sorted. Then, it merges these sorted
              sublists in a way that produces a fully sorted list. Here is a
              step by step approach for how that can be done.
            </Typography>

            <Box sx={{ marginLeft: '20px', paddingLeft: '20px' }}>
              <ol>
                <li>
                  <Typography variant='body1'>
                    <b>Divide: </b>Split the array into two halves until each
                    half has only one element.
                  </Typography>
                </li>
                <li>
                  <Typography variant='body1'>
                    <b>Conquer: </b>Recursively sort each half.
                  </Typography>
                </li>
                <li>
                  <Typography variant='body1'>
                    <b> Combine:</b> Merge the sorted halves back together in
                    sorted order.
                  </Typography>
                </li>
              </ol>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%', // Optional, adjust based on your layout
              }}
            >
              <img
                src={MergeSort}
                alt=''
                style={{ height: '200px', width: '400px' }}
              />
            </Box>
            <Typography>Below is the code implementation for it</Typography>
            <br />
            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '800px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`
function mergeSort(arr):
    if arr.length <= 1:
        return arr

    // Find the midpoint of the array
    mid = arr.length / 2

    // Recursively split and sort both halves
    left = mergeSort(arr[0...mid-1])
    right = mergeSort(arr[mid...arr.length-1])

    // Merge the sorted halves
    return merge(left, right)

function merge(left, right):
    merged = []
    i = 0, j = 0

    // Merge elements from left and right in sorted order
    while i < left.length and j < right.length:
        if left[i] <= right[j]:
            merged.append(left[i])
            i += 1
        else:
            merged.append(right[j])
            j += 1

    // Append remaining elements from left and right (if any)
    while i < left.length:
        merged.append(left[i])
        i += 1
    while j < right.length:
        merged.append(right[j])
        j += 1

    return merged


`}
              </pre>
            </Box>
            <Typography sx={{ paddingLeft: '1.5rem' }}>
              <br />
              <b>Efficiency:</b> Merge Sort has a{' '}
              <b>time complexity of O(n*log n)</b> in all cases (worst, average,
              and best), making it reliable for sorting large datasets. However,
              it requires additional memory for merging, as it creates temporary
              arrays during the merging process.
            </Typography>
          </Box>
        </ParagraphBox>
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        alignSelf='end'
        justifyContent='space-between'
        gap='12px'
      >
        {!hasMarkedCompleted(topic_id) && (
          <LoadingButton
            variant='contained'
            onClick={() => handlePostCompletedTopic(topic_id)}
            loading={loading}
            loadingPosition='center'
            children='Mark as completed'
            style={{
              width: '170px',
              // backgroundColor: 'transparent',
              borderRadius: '8px',
              border: '1px solid rgba(64, 96, 245, 0.5)',
            }}
          />
        )}

        <Box
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Button
            sx={{ gap: '4px' }}
            onClick={handleNext}
          >
            Next <EastIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Introduction;
