/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Button,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SearchIcon from '@mui/icons-material/Search';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ApplicationCarousel from './ApplicationCarousel';
import { BASE_URL, getHeaders } from 'src/apis/urls';
import { useEfPipeline } from 'src/layouts/EfPipelineContext/EfPipelineContext';
import axios from 'axios';
import ErrorNotifier from 'src/components/ToastNotifications/ErrorNotifier';
import { useDispatch } from 'react-redux';
import { updateStep } from 'src/components/redux-store/slices/progressSlice';

const ApplicationProcess = () => {
  const {
    formId,
    setScreen,
    setShowApplicationProgress,
    setShowFastrackOfferLetter,
    screen,
    setToken,
    setLoading,
  } = useEfPipeline();
  const initialStep = screen === 'applicationProgress_resume' ? 3 : 2;
  const [currentStep, setCurrentStep] = useState(initialStep);
  const [proceed, setProceed] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateStep({ stepIndex: 0 }));
  }, []);

  const steps = [
    { label: 'Eligibility form' },
    { label: 'Analysing resume' },
    { label: 'Program fit evaluation' },
    { label: 'Manual counsellor review' },
  ];
  useEffect(() => {
    if (screen === 'programFit4') {
      setCurrentStep(4);
    }
  }, [screen]);
  useEffect(() => {
    if (currentStep <= steps.length) {
      const timeoutDuration = currentStep === 2 ? 20000 : 45000;
      const timer = setTimeout(() => {
        callApi();

        if (currentStep === 3 && !proceed) return;

        setCurrentStep((prevStep) => prevStep + 1);
      }, timeoutDuration);

      return () => clearTimeout(timer);
    }
  }, [currentStep, proceed]);

  const callApi = async () => {
    const payload = {
      source:
        currentStep === 2
          ? 'resume_analysis'
          : currentStep === 4
          ? 'manual_counsellor_review'
          : 'program_evaluation',
      ...(currentStep === 3 && { event_name: 'continue' }),
      prev_screen:
        currentStep === 2
          ? 'applicationProgress_resume'
          : currentStep === 4
          ? 'applicationProgress_review'
          : 'applicationProgress_evaluation',
    };
    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/api/eligibility-forms/${formId}/submit_fst_fail_questions`,
        payload,
        { headers: getHeaders() }
      );

      if (
        response?.data?.data &&
        Object.keys(response?.data?.data).length > 0
      ) {
        const { prevscreen, show_Offer_Letter, generatedToken } =
          response.data.data;
        setScreen(prevscreen);
        if (show_Offer_Letter != null) {
          setShowFastrackOfferLetter(show_Offer_Letter);
        }

        if (generatedToken != null) {
          setToken(generatedToken);
        }
      }
      setLoading(false);
    } catch (error) {
      console.error('Error submitting event:', error);
      setErrorMessage('Error While Submitting the Event', error);
      setHasError(true);
      setLoading(false);
    }
  };

  const handleContinue = () => {
    callApi();
    setProceed(true);
    setCurrentStep((prevStep) => prevStep + 1);
    setShowApplicationProgress(false);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  return (
    <>
      {hasError && (
        <ErrorNotifier
          message={errorMessage}
          setHasError={setHasError}
        />
      )}
      <Box
        sx={{
          padding: isMobile ? '2rem 1rem' : '6rem',
          backgroundColor: '#fff',
          minHeight: '80vh',
          width: '90%',
          textAlign: 'center',
          borderRadius: '0.625rem',
          boxShadow: '0rem 0.25rem 0.625rem rgba(0, 0, 0, 0.1)',
        }}
      >
        {/* Main Heading */}
        <Typography
          variant='h3'
          sx={{
            marginBottom: '2rem',
            fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
            fontWeight: 500,
            color: '#1976d2',
          }}
        >
          Analysing your application & profile. This will not take more than 2-3
          minutes ...
        </Typography>

        {/* Content Wrapper */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile || isTablet ? 'column' : 'row',
            justifyContent: 'space-between',
            alignItems: isMobile || isTablet ? 'center' : 'flex-start',
            marginTop: '3rem',
          }}
        >
          {/* Timeline */}
          <Box
            sx={{
              flex: 1,
              maxWidth: isMobile ? '100%' : '80%',
              marginBottom: isMobile || isTablet ? '2rem' : 0,
              padding: isMobile ? '0 1rem' : '0',
            }}
          >
            <Box
              sx={{ marginLeft: isMobile ? '0' : '1rem', position: 'relative' }}
            >
              {steps.map((step, index) => (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '2.5rem',
                    position: 'relative',
                    marginLeft: { lg: '13rem' },
                  }}
                >
                  {/* Custom Connector */}
                  {index > 0 && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '-2.9rem',
                        left: '11px',
                        height: '2.9rem',
                        width: '1.2px',
                        backgroundColor:
                          index < currentStep ? '#1976d2' : 'transparent',
                        backgroundImage:
                          index >= currentStep
                            ? 'repeating-linear-gradient(#bdbdbd 0px, #bdbdbd 2px, transparent 2px, transparent 4px)'
                            : 'none',
                      }}
                    />
                  )}

                  {/* Step Icon */}
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '24px',
                      height: '3.5rem',
                      zIndex: 2,
                      marginRight: '0.5rem',
                    }}
                  >
                    {index < currentStep - 1 ? (
                      <CheckCircleIcon
                        style={{
                          color: '#58A52C',
                          fontSize: '1.4rem',
                        }}
                      />
                    ) : index === currentStep - 1 ? (
                      <SearchIcon
                        style={{
                          color: '#1976d2',
                          fontSize: '1.4rem',
                        }}
                      />
                    ) : (
                      <RadioButtonUncheckedIcon
                        style={{
                          color: '#bdbdbd',
                          fontSize: '1.4rem',
                        }}
                      />
                    )}
                  </Box>

                  {/* Step Label */}
                  <Typography
                    sx={{
                      fontSize: isTablet
                        ? '1rem'
                        : isMobile
                        ? '0.8rem'
                        : '1.3rem',
                      fontWeight: index === currentStep - 1 ? '300' : 'normal',
                      color:
                        index < currentStep - 1
                          ? '#58A52C'
                          : index === currentStep - 1
                          ? 'black'
                          : '#bdbdbd',
                    }}
                  >
                    {step.label}
                  </Typography>

                  {/* Loading Dots Animation for the Current Step */}
                  {index === currentStep - 1 && (
                    <Box
                      sx={{
                        marginLeft: '0.8rem',
                        display: 'flex',
                        '@keyframes pulse': {
                          '0%, 100%': { transform: 'scale(0.8)', opacity: 0.6 },
                          '50%': { transform: 'scale(1)', opacity: 1 },
                        },
                      }}
                    >
                      {[...Array(4)].map((_, i) => (
                        <Box
                          key={i}
                          sx={{
                            width: '6px',
                            height: '6px',
                            backgroundColor: 'black',
                            borderRadius: '50%',
                            marginRight: '0.3rem',
                            animation: 'pulse 1.4s infinite',
                            animationDelay: `${i * 0.2}s`,
                          }}
                        />
                      ))}
                    </Box>
                  )}
                </Box>
              ))}
            </Box>
          </Box>

          {/* Conditional Rendering for the Third Step */}
          <Box
            sx={{
              width: isMobile ? '110%' : isTablet ? '110%' : '50%',
            }}
          >
            {currentStep === 3 ? (
              // Custom Screen for Program Fit Evaluation
              <Box
                sx={{
                  padding: isMobile ? '1rem' : isTablet ? '1.5rem' : '2rem',
                  borderRadius: '8px',
                  textAlign: 'left',
                  width: '90%',
                  marginLeft: isMobile ? '1rem' : isTablet ? '2.5rem' : '0',
                }}
              >
                <Typography
                  variant='body1'
                  sx={{
                    marginBottom: '1rem',
                    color: '#333',
                    fontSize: isMobile ? '0.9rem' : '1.3rem',
                  }}
                >
                  We don't offer second chances to everyone. This exclusive
                  program requires commitment & dedication, and we want to
                  ensure that every candidate is ready to succeed.
                </Typography>
                <Typography
                  variant='body2'
                  sx={{
                    marginBottom: '3.5rem',
                    color: 'black',
                    fontWeight: 'bold',
                    fontSize: isMobile ? '1rem' : '1.3rem',
                  }}
                >
                  So before we proceed, please take a quick program fit
                  evaluation.
                </Typography>
                <Button
                  variant='outlined'
                  sx={{
                    color: '#1976d2',
                    borderColor: '#1976d2',
                  }}
                  onClick={handleContinue}
                >
                  Continue →
                </Button>
              </Box>
            ) : (
              <ApplicationCarousel />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ApplicationProcess;
