// @ts-nocheck
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import UploadIcon from '@mui/icons-material/Upload';
import { Button, Stack, Typography } from '@material-ui/core';
import { Box } from '@material-ui/system';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { updateSession } from '../../../redux-store/slices/sessionSlice';
import { BASE_URL, getHeaders, updateSessionURL } from '../../../../apis/urls';
import SessionLogDetails from './SessionLogDetails';
import NewSessionRecordings from './NewSessionRecordings';
import { getUserType } from 'src/configs/auth';
const timeFormat = 'MMM DD, YYYY, hh:mm A';
export default function SessionsTable({
  sessions,
  coaches,
  setHasError,
  setErrorMessage,
  setIsSuccess,
  setSuccessMessage,
  programId,
  sessionIdToIndexMap,
  setOpenRescheduleSessionsModal,
  setSession,
}) {
  const dispatch = useDispatch();
  const [selectedSession, setSelectedSession] = useState({});
  const [openLog, setOpenLog] = useState(false);
  const [sessionBeingUpdated, setSessionBeingUpdated] = useState({});
  const [openSessionRecordingsModal, setOpenSessionRecordingsModal] =
    useState(false);

  const columns = [
    {
      field: 'id',
      headerName: 'Id',
      type: 'number',
      width: 70,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'title',
      headerName: 'Title',
      width: 240,
      align: 'center',
      editable: true,
      headerAlign: 'center',
    },
    {
      field: 'group_name',
      headerName: 'Group name',
      width: 180,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'session_type',
      headerName: 'Type',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => params.row?.session_type?.name || 'NA',
    },
    {
      field: 'duration',
      headerName: 'Max duration',
      width: 130,
      align: 'center',
      headerAlign: 'center',
      valueGetter: (params) => params.row?.session_type?.duration || 'NA',
    },
    {
      field: 'start',
      headerName: 'Start time',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      type: 'dateTime',
      editable: false,
      valueGetter: (params) =>
        params.row?.start ? moment(params.row.start).format(timeFormat) : 'NA',
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      type: 'singleSelect',
      valueOptions: [
        'Created',
        'Scheduled',
        'Conducted',
        'Verified',
        'Payment processed',
      ],
      editable: true,
      valueGetter: (params) => {
        if (params.row.status === 'created') {
          return 'Created';
        }
        if (params.row.status === 'scheduled') {
          return 'Scheduled';
        }
        if (params.row.status === 'conducted') {
          return 'Conducted';
        }
        if (params.row.status === 'verified') {
          return 'Verified';
        }
        return 'Payment processed';
      },
    },
    {
      field: 'coach',
      headerName: 'Coach',
      width: 200,
      align: 'center',
      headerAlign: 'center',
      type: 'singleSelect',
      valueOptions: Object.keys(coaches),
      editable: true,
      valueGetter: (params) => {
        return params.row.coach
          ? `${params.row.coach?.name} (${params.row.coach?.email})`
          : 'NA';
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      cellClassName: 'actions',
      getActions: ({ id, row }) => {
        const isInEditMode = sessionBeingUpdated[id]?.mode === 'edit';
        if (row.status === 'payment_processed') {
          return [];
        }
        if (isInEditMode) {
          return [
            <CheckIcon
              fontSize='medium'
              color='success'
              sx={{ cursor: 'pointer' }}
              onClick={(e) => handleSessionUpdate('update', id)}
            />,
            <ClearIcon
              fontSize='medium'
              color='error'
              onClick={(e) => handleSessionUpdate('clear', id)}
              sx={{ cursor: 'pointer' }}
            />,
          ];
        }
        return [
          <EditIcon
            onClick={(e) => {
              setSessionBeingUpdated({
                ...sessionBeingUpdated,
                [id]: { mode: 'edit' },
              });
            }}
            sx={{ cursor: 'pointer' }}
          />,
        ];
      },
    },
    {
      field: 'reschedule',
      headerName: 'Reschedule',
      type: 'actions',
      width: 180,
      align: 'center',
      headerAlign: 'center',
      cellClassName: 'reshedule',
      getActions: ({ id, row }) => {
        if (row.status !== 'created' && row.status !== 'scheduled') {
          return [];
        }
        return [
          <Button
            variant='contained'
            onClick={(e) => {
              setOpenRescheduleSessionsModal(true);
              setSession(row);
            }}
            sx={{
              mr: 1,
            }}
          >
            Reschedule Session
          </Button>,
        ];
      },
    },
    {
      field: 'recording',
      headerName: 'Upload recording',
      type: 'actions',
      width: 180,
      align: 'center',
      headerAlign: 'center',
      cellClassName: 'recording',
      getActions: ({ id, row }) => {
        if (row.status === 'created' || row.status === 'scheduled') {
          return [];
        }

        const { recording_uploaded = false } = row;
        return [
          <Button
            variant='contained'
            size='small'
            color={recording_uploaded ? 'success' : 'primary'}
            onClick={(e) => {
              setOpenSessionRecordingsModal(true);
              setSelectedSession(row);
            }}
            sx={{
              mr: 1,
            }}
            startIcon={recording_uploaded ? <CheckIcon /> : <UploadIcon />}
          >
            {recording_uploaded ? 'Recording uploaded' : 'Upload recording'}
          </Button>,
        ];
      },
    },
    // Conditionally render the "Delete Recordings" column for admins
    ...(getUserType() === 'admin'
      ? [
          {
            field: 'delete_recordings',
            headerName: 'Delete Recordings',
            type: 'actions',
            width: 180,
            align: 'center',
            headerAlign: 'center',
            cellClassName: 'delete-recordings',
            getActions: ({ id, row }) => {
              const { recording_uploaded } = row;
              return [
                <Button
                  variant='contained'
                  size='small'
                  color='error'
                  disabled={!recording_uploaded}
                  onClick={() => {
                    const confirmDelete = window.confirm(
                      `Are you sure you want to delete the recording for session: ${row.title}?`
                    );

                    if (confirmDelete) {
                      handleDeleteSessionRecording(id);
                    }
                  }}
                  sx={{
                    mr: 1,
                  }}
                >
                  Delete Recording
                </Button>,
              ];
            },
          },
        ]
      : []),

    {
      field: 'hide',
      headerName: 'Hide Session',
      type: 'actions',
      width: 180,
      align: 'center',
      headerAlign: 'center',
      cellClassName: 'hide',
      getActions: ({ id, row }) => {
        if (row.status !== 'created' && row.status !== 'scheduled') {
          return [];
        }
        return [
          <Button
            variant='contained'
            onClick={() => {
              const confirmHide = window.confirm(
                `Are you sure you want to hide the session: ${row.title}?`
              );

              if (confirmHide) {
                // Call the API if the user confirms
                handleHideSessionApiCall(id);
              }
            }}
            sx={{
              mr: 1,
            }}
          >
            Hide Session
          </Button>,
        ];
      },
    },
  ];

  const handleSessionUpdate = (clickType, id) => {
    if (clickType === 'clear') {
      setSessionBeingUpdated({
        ...sessionBeingUpdated,
        [id]: { mode: 'view', ignoreModifications: true },
      });
      return;
    }
    setSessionBeingUpdated({ ...sessionBeingUpdated, [id]: { mode: 'view' } });
  };

  const processSessionUpdate = (updatedSession) => {
    const body = {};
    body.status = updatedSession.status.toLowerCase().split(' ').join('_');
    body.start_time = moment(updatedSession.start).utc();
    body.coach_id = coaches[updatedSession.coach];
    body.title = updatedSession.title;

    const index = sessionIdToIndexMap[updatedSession.id];
    const from = sessions[index].status;
    const to = body.status;

    if (to === 'verified' && !selectedSession.duration) {
      setHasError(true);
      setErrorMessage('Please add session duration.');
      return sessions[index];
    }

    const foundStatus = validSessionStatusOrder[from].find(
      (status) => status === to
    );
    if (!foundStatus) {
      setHasError(true);
      setErrorMessage(
        `Session status from ${from} cannot be converted back to ${to}`
      );
      return sessions[index];
    }

    handleUpdateSessionApiCall(updatedSession.id, body);

    return updatedSession; // this is required. Didn't find the reason in MUI documentation.
    // for reference, refer to this link: https://mui.com/x/react-data-grid/editing/#full-featured-crud-component
  };

  const handleUpdateSessionApiCall = (sessId, body) => {
    axios
      .patch(updateSessionURL(sessId), body, {
        headers: getHeaders(programId),
      })
      .then((res) => {
        dispatch(updateSession(res?.data));
        setIsSuccess(true);
        setSuccessMessage('Session successfully updated.');
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || 'Could not update the session details.'
        );
      });
  };

  const handleNewRecordingModalClose = () => {
    setOpenSessionRecordingsModal(false);
    setSelectedSession({});
  };

  const handleHideSessionApiCall = (sessId) => {
    axios
      .patch(
        `${BASE_URL}/api/sessions/${sessId}/hide_session`,
        {},
        {
          headers: getHeaders(programId),
        }
      )
      .then((res) => {
        dispatch(updateSession(res?.data)); // Update the session in Redux state
        setIsSuccess(true);
        setSuccessMessage('Session successfully hidden.');
      })
      .catch((err) => {
        console.error(err);
        setHasError(true);
        setErrorMessage(
          err.response?.data?.message || 'Could not hide the session.'
        );
      });
  };

  const handleDeleteSessionRecording = (sessId) => {
    axios
      .delete(`${BASE_URL}/api/sessions/${sessId}/delete_recording`, {
        headers: getHeaders(programId),
      })
      .then((res) => {
        dispatch(updateSession(res?.data));
        setIsSuccess(true);
        setSuccessMessage('Recording Deleted Successfully');
      })
      .catch((err) => {
        console.error('Error deleting recording:', err);
        const errorMessage =
          err?.response?.data?.message || 'Could not delete the recording.';
        setHasError(true);
        setErrorMessage(errorMessage);
      });
  };

  return (
    <Stack>
      {sessions.length > 0 && (
        <Box sx={{ width: 'fit', height: '100%', mt: 2 }}>
          <DataGrid
            rows={sessions}
            columns={columns}
            pageSize={100}
            rowsPerPageOptions={[100]}
            autoHeight
            disableSelectionOnClick
            onRowClick={(params, event) => {
              setOpenLog(true);
              setSelectedSession(params.row);
            }}
            sx={{
              '& .MuiDataGrid-row:hover': {
                backgroundColor: '#e3f2fd',
                cursor: 'pointer',
              },
            }}
            rowModesModel={sessionBeingUpdated}
            experimentalFeatures={{ newEditingApi: true }}
            editMode='row'
            processRowUpdate={processSessionUpdate}
            onRowEditStart={(params, event) => {
              event.defaultMuiPrevented = true;
            }}
            onRowEditStop={(params, event) => {
              event.defaultMuiPrevented = true;
            }}
          />
        </Box>
      )}
      {sessions.length === 0 && (
        <Typography
          align='center'
          variant='body2'
          sx={{ mt: 2 }}
        >
          No sessions have been added so far.
        </Typography>
      )}
      {openLog && Object.keys(selectedSession)?.length > 0 && (
        <SessionLogDetails
          openLog={openLog}
          setHasError={setHasError}
          setErrorMessage={setErrorMessage}
          setOpenLog={setOpenLog}
          session={selectedSession}
          handleUpdateSessionApiCall={handleUpdateSessionApiCall}
        />
      )}

      {openSessionRecordingsModal &&
        Object.keys(selectedSession)?.length > 0 && (
          <NewSessionRecordings
            openSessionRecordingsModal={openSessionRecordingsModal}
            handleClose={handleNewRecordingModalClose}
            selectedSession={selectedSession}
            setHasError={setHasError}
            setErrorMessage={setErrorMessage}
            setIsSuccess={setIsSuccess}
            setSuccessMessage={setSuccessMessage}
          />
        )}
    </Stack>
  );
}

// uni-directional graph.
const validSessionStatusOrder = {
  created: [
    'created',
    'scheduled',
    'conducted',
    'verified',
    'payment_processed',
  ],
  scheduled: ['scheduled', 'conducted', 'verified', 'payment_processed'],
  conducted: ['conducted', 'verified', 'payment_processed'],
  verified: ['verified', 'payment_processed'],
  payment_processed: [],
};
