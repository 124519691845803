import React, { useState } from 'react';
import { Box, Tabs, Tab, Button } from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import CustomTabPanel from '../CustomTabPanel';
import TopicTitle from '../../Recursion/TopicTiltle';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const FindDuplicates = ({
  handlePostCompletedTopic,
  hasMarkedCompleted,
  loading,
  topic_id,
  handleNext,
  handlePrevious,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='36px'
      paddingY={2}
      maxWidth='calc(100% - 300px)'
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <TopicTitle>1. Find All Duplicates in an Array</TopicTitle>
        {hasMarkedCompleted(topic_id) && (
          <p
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              color: 'green',
              fontWeight: '500',
              border: '2px solid green',
              padding: '4px 10px',
              borderRadius: '20px',
              cursor: 'default',
            }}
          >
            Completed
          </p>
        )}
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        gap='24px'
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            width: '34rem',
            background: '#FEF7FF',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab
              label='Problem Statement'
              {...a11yProps(0)}
            />
            <Tab
              label='Brute Force Approach'
              {...a11yProps(1)}
            />
            <Tab
              label='Set Approach'
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel
          value={value}
          index={0}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='10px'
          >
            <p>
              Given an array of integers nums where each integer is in the range
              [1, n] (where n is the size of the array), find all the elements
              that appear more than once in the array.
            </p>
            <p>
              For example, given nums = [4,3,2,7,8,2,3,1], the output should be:
              {'{2, 3}'} since these are the duplicates.
            </p>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={1}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='20px'
          >
            <p>
              In the brute force approach, we can use two loops to check each
              element and mark duplicates. If an element appears more than once,
              we can add it to the result.
            </p>

            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '800px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`function findDuplicates_bf(nums):
    result = []
    for i from 0 to length of nums - 1:
        for j from i + 1 to length of nums:
            if nums[i] == nums[j]:
                result.append(nums[i])
    return result

`}
              </pre>
            </Box>

            <p sx={{ paddingLeft: '1.5rem' }}>
              <b>Time and Space Complexity</b>
              <br />
              Due to checking for each element individually with every other
              element it will require two nested loops.
              <br />
              <br />
              Time Complexity: O(n^2)<br></br>
              Space Complexity : O(1)
            </p>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={2}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='20px'
          >
            <Box
              display='flex'
              flexDirection='column'
              gap='8px'
            >
              <p sx={{ fontSize: '22px', fontWeight: 400, mb: '8px' }}>
                We have to basically see which elements are unique and which are
                not and for this set is the best data-structure that comes to
                mind. Iterate the array once and then put all the elements in
                the set, meanwhile also check if this element is already in the
                set, if yes then we know that this is a duplicate element.
              </p>
            </Box>
            <Box>
              <Box
                sx={{
                  backgroundColor: 'black',
                  padding: '24px',
                  borderRadius: '10px',
                  width: '800px',
                  marginLeft: '1em',
                }}
              >
                <pre style={{ color: 'white', margin: 0 }}>
                  {`
function findDuplicates(nums):
    numSet = set()   # Set to track seen numbers
    result = []
    for num in nums:
        if num in numSet:
            result.append(num)  # Add to result if duplicate
        else:
            numSet.add(num)  # Add the number to the set
    return result


`}
                </pre>
              </Box>
              <br />
              <p sx={{ paddingLeft: '1.5rem' }}>
                <b>Time and Space Complexity</b>
                <br />
                Time Complexity: O(n), where n is the size of the array (due to
                the O(1) lookups and insertions in the set).<br></br>
                Space Complexity: O(n) for storing the set.<br></br>
              </p>
            </Box>
          </Box>
        </CustomTabPanel>
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        alignSelf='end'
        justifyContent='space-between'
        gap='12px'
      >
        {!hasMarkedCompleted(topic_id) && (
          <LoadingButton
            variant='contained'
            onClick={() => handlePostCompletedTopic(topic_id)}
            loading={loading}
            loadingPosition='center'
            children='Mark as completed'
            style={{
              width: '170px',
              // backgroundColor: 'transparent',
              borderRadius: '8px',
              border: '1px solid rgba(64, 96, 245, 0.5)',
            }}
          />
        )}

        <Box
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Button
            sx={{ gap: '4px' }}
            onClick={handlePrevious}
          >
            <WestIcon /> Previous
          </Button>

          <Button
            sx={{ gap: '4px' }}
            onClick={handleNext}
          >
            Next <EastIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default FindDuplicates;
