import React, { useState } from 'react';
import { Box, Tabs, Tab, Button } from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import CustomTabPanel from '../CustomTabPanel';
import TopicTitle from '../../Recursion/TopicTiltle';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import image1 from '../../../../assets/image3queue.png';
import image2 from '../../../../assets/image4queue.png';
import image3 from '../../../../assets/image5queue.png';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const SlidingWindow = ({
  handlePostCompletedTopic,
  hasMarkedCompleted,
  loading,
  topic_id,
  handleNext,
  handlePrevious,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='36px'
      paddingY={2}
      maxWidth='calc(100% - 300px)'
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <TopicTitle>3.Sliding Window Maximum</TopicTitle>
        {hasMarkedCompleted(topic_id) && (
          <p
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              color: 'green',
              fontWeight: 500,
              border: '2px solid green',
              padding: '4px 10px',
              borderRadius: '20px',
              cursor: 'default',
            }}
          >
            Completed
          </p>
        )}
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        gap='24px'
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            width: '34rem',
            background: '#FEF7FF',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab
              label='Problem Statement'
              {...a11yProps(0)}
            />
            <Tab
              label='Brute Force Approach'
              {...a11yProps(1)}
            />
            <Tab
              label='Queue Based Approach'
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel
          value={value}
          index={0}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='10px'
          >
            <p>
              Given an array of integers nums and an integer k, you need to find
              the maximum value in every sliding window of size k as the window
              moves from left to right across the array.
            </p>

            <p style={{ fontSize: '20px' }}>Example:</p>
            <p>Input</p>
            <p>nums = [1,3,-1,-3,5,3,6,7], k = 3</p>
            <p>Output</p>
            <p>[3, 3, 5, 5, 6, 7]</p>
            <p>
              <strong>Explanation:</strong>
            </p>
            <p>For each window of size k = 3, we get:</p>

            <div style={{ fontFamily: 'Arial, sans-serif', fontSize: '16px' }}>
              <ul
                style={{
                  listStyleType: 'disc',
                  marginLeft: '20px',
                  fontSize: '16px',
                }}
              >
                <li>Window 1: [1, 3, -1] → max = 3</li>
                <li>Window 2: [3, -1, -3] → max = 3</li>
                <li>Window 3: [-1, -3, 5] → max = 5</li>
                <li>Window 4: [-3, 5, 3] → max = 5</li>
                <li>Window 5: [5, 3, 6] → max = 6</li>
                <li>Window 6: [3, 6, 7] → max = 7</li>
              </ul>
            </div>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={1}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='20px'
          >
            <p>
              In the brute force approach, for every window, we will find the
              maximum by scanning the k elements within the window. For each
              position i in the array where a window of size k can start, find
              the maximum in the subarray from i to i + k - 1. Store the maximum
              value for each window and move the window one position forward.
            </p>
            <p>Below is the implementation for it</p>
            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '800px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`Function sliding_window_maximum_bruteforce(nums, k):
    Initialize result as an empty list

    For i from 0 to (length of nums - k):
        Initialize max_val as the maximum of the subarray nums[i:i + k]
        Append max_val to result

    Return result

`}
              </pre>
            </Box>

            <p>
              <b>Time and Space Complexity</b>
              <br />
              Since we are traversing the entire string again and again
              resulting in a nested loops
              <br />
              <br />
              Time Complexity : O(n*k)<br></br>
              Space Complexity : O(1)
            </p>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={2}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='20px'
          >
            <Box
              display='flex'
              flexDirection='column'
              gap='8px'
            >
              <p>
                A more efficient approach uses a deque (double-ended queue) to
                keep track of the indices of potential maximum values in the
                window. The deque allows us to access both ends efficiently, so
                we can insert and remove elements from both ends in constant
                time.
              </p>
              <p>Key Idea:</p>
              <p>
                Use a deque to store indices of elements, and maintain the
                following properties:
              </p>
              <div
                style={{ fontFamily: 'Arial, sans-serif', fontSize: '16px' }}
              >
                <ul
                  style={{
                    listStyleType: 'disc',
                    marginLeft: '20px',
                    fontSize: '16px',
                  }}
                >
                  <li>
                    The elements in the deque are always in decreasing order
                    (the element at the front of the deque is the largest).
                  </li>
                  <li>
                    Only indices of elements within the current window are kept
                    in the deque.
                  </li>
                  <li>
                    Before adding a new element, we remove elements from the
                    back of the deque that are smaller than the new element,
                    because they can never be the maximum if the new element is
                    larger.
                  </li>
                </ul>
              </div>
              <p>
                Let us consider the example, ar = [1, 3, -1, -3, 5, 3, 6, 7] and
                take the value of k as 3.
              </p>
              <img
                src={image1}
                alt=''
                style={{ height: '450px', width: '700px' }}
              />
              <img
                src={image2}
                alt=''
                style={{ height: '450px', width: '700px' }}
              />
              <p>
                The diagram above illustrates how this algorithm is going to
                work. Let us understand that with the help of a step by step
                approach.
              </p>
              <img
                src={image3}
                alt=''
                style={{ height: '450px', width: '700px' }}
              />
              <p>So the final output comes out to be {`{3, 3, 5, 5, 6, 7}`}</p>
              <p>Now let us look at its code implementation</p>
            </Box>
            <Box>
              <Box
                sx={{
                  backgroundColor: 'black',
                  padding: '24px',
                  borderRadius: '10px',
                  width: '800px',
                  marginLeft: '1em',
                }}
              >
                <pre style={{ color: 'white', margin: 0 }}>
                  {`
Function sliding_window_maximum(nums, k):
Initialize result as an empty list
Initialize deque dq as an empty deque

For i from 0 to (length of nums - 1):
    If dq is not empty and dq[front] is out of the current window (dq[front] < i - k + 1):
        Remove dq[front]  # Remove element from the front of the deque
    
    While dq is not empty and nums[dq[rear]] < nums[i]:
        Remove dq[rear]  # Remove smaller elements from the rear of the deque
    
    Append i to dq  # Add the current element index to the deque

    If i >= k - 1:
        Append nums[dq[front]] to result  # Add the maximum element (at the front of the deque) to result

Return result

`}
                </pre>
              </Box>
              <br />
              <p>
                <b>Time and Space Complexity</b>
                <br />
                Since our pointer is always moving linearly forward, without any
                sort of repetition, along with that we are using a deque to
                track the elements.
                <br /> <br />
                Time Complexity : O(n)<br></br>
                Space Complexity : O(n)<br></br>
              </p>
            </Box>
          </Box>
        </CustomTabPanel>
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        alignSelf='end'
        justifyContent='space-between'
        gap='12px'
      >
        {!hasMarkedCompleted(topic_id) && (
          <LoadingButton
            variant='contained'
            onClick={() => handlePostCompletedTopic(topic_id)}
            loading={loading}
            loadingPosition='center'
            children='Mark as completed'
            style={{
              width: '170px',
              // backgroundColor: 'transparent',
              borderRadius: '8px',
              border: '1px solid rgba(64, 96, 245, 0.5)',
            }}
          />
        )}

        <Box
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Button
            sx={{ gap: '4px' }}
            onClick={handlePrevious}
          >
            <WestIcon /> Previous
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default SlidingWindow;
