import React, { useState } from 'react';
import {
  Box,
  Typography,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
} from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import VerifiedIcon from '@mui/icons-material/Verified';
import { FiberManualRecord } from '@mui/icons-material';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import CustomTabPanel from '../CustomTabPanel';
import { FindLargestPowerOfTwoConst } from '../../../utils/techniqueSagaConstant';
import TopicTitle from '../TopicTitle';
import ListRenderComponent from '../ListRendereComponent';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import FindLargestPowerTwo01 from '../../../../images/FindLargestPowerTwo01.png';
import FindLargestPowerTwo02 from '../../../../images/FindLargestPowerTwo02.png';
import FindLargestPowerTwo03 from '../../../../images/FindLargestPowerTwo03.png';
import FindLargestPowerTwo04 from '../../../../images/FindLargestPowerTwo04.png';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const LongestNiceSubstring = ({
  handlePostCompletedTopic,
  hasMarkedCompleted,
  loading,
  topic_id,
  handleNext,
  handlePrevious,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='36px'
      paddingY={2}
      maxWidth='calc(100% - 300px)'
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <TopicTitle>3. Longest Nice Substring</TopicTitle>
        {hasMarkedCompleted(topic_id) && (
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              color: 'green',
              fontWeight: '500',
              border: '2px solid green',
              padding: '4px 10px',
              borderRadius: '20px',
              cursor: 'default',
            }}
          >
            Completed
          </Typography>
        )}
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        gap='24px'
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            width: '36rem',
            background: '#FEF7FF',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab
              label='Problem Statement'
              {...a11yProps(0)}
            />
            <Tab
              label='Brute Force Approach'
              {...a11yProps(1)}
            />
            <Tab
              label='Divide and Conquer Approach'
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel
          value={value}
          index={0}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography
              variant='body1'
              paragraph
            >
              Given a string <strong>s</strong>, find the length of the longest
              substring that is "nice." A substring is considered nice if:
            </Typography>
            <Box
              component='ol'
              sx={{ paddingLeft: 4 }}
            >
              <Typography component='li'>
                <Typography
                  variant='body1'
                  component='span'
                >
                  It contains at least one uppercase letter and one lowercase
                  letter.
                </Typography>
              </Typography>
              <Typography component='li'>
                <Typography
                  variant='body1'
                  component='span'
                >
                  Each character in the substring appears in both its uppercase
                  and lowercase forms.
                </Typography>
              </Typography>
            </Box>
            <Typography
              variant='body1'
              paragraph
            >
              For example, given the string <strong>"BalaAab"</strong>, the
              longest nice substring is <strong>"aAa"</strong>, since it
              contains both the uppercase and lowercase of a character.
            </Typography>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={1}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              The intuition behind the brute forcesolution is straightforward.
              We examine every possible substring to determine if it qualifies
              as a nice substring. While doing this, we also track the longest
              nice substring encountered so far by recording both its starting
              index and its length.
            </Typography>

            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '800px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`Function longestNiceSubstring(s: string) -> string
    N = length of s
    maxlen = 0
    start = 0
    
    For i from 0 to N - 1 do
        seen = empty set
        missing = 0
        
        For j from i to N - 1 do
            If s[j] is not in seen then
                Add s[j] to seen
                
                If s[j].lower is not in seen OR s[j].upper is not in seen then
                    missing = missing + 1  // Missing half of this character
                Else
                    missing = missing - 1  // Found both halves, decrease missing count
            
            If missing == 0 AND (j - i + 1) > maxlen then
                maxlen = j - i + 1
                start = i
    
    Return substring of s from start to start + maxlen

`}
              </pre>
            </Box>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={2}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Box
              display='flex'
              flexDirection='column'
              gap='8px'
            >
              <Box sx={{ lineHeight: 1.8 }}>
                <Typography
                  variant='body1'
                  paragraph
                >
                  You could think of a particular insight, that if a particular
                  character is missing its counterpart (upper or lower case),
                  then that cannot be included in any substring as in no way it
                  would form a valid answer. This insight allows us to split the
                  string into subproblems effectively.
                </Typography>
                <Typography
                  variant='body1'
                  paragraph
                >
                  So, basically, each time we find a string that cannot be
                  included anywhere, at that point we will split the array into
                  two parts. For example, for the string{' '}
                  <strong>"abcBCEAamM"</strong>, if we find that{' '}
                  <strong>'E'</strong> is unpaired, we can create two
                  subproblems: <strong>"abcBC"</strong> and{' '}
                  <strong>"AamM"</strong>.
                </Typography>
                <Typography
                  variant='body1'
                  paragraph
                >
                  But when exactly should we stop the division? Whenever we have
                  a string of length less than 2, we can say that it won’t be a
                  nice substring and can stop. After solving the left and right
                  subproblems, we compare their lengths and return the longer
                  one. If both lengths are the same, we return the left
                  substring as per the problem's requirements.
                </Typography>
              </Box>
              <Box
                sx={{
                  backgroundColor: 'black',
                  padding: '24px',
                  borderRadius: '10px',
                  width: '800px',
                }}
              >
                <pre style={{ color: 'white', margin: 0 }}>
                  {`Function longestNiceSubstring(s: string)
    If length of s < 2 then
        Return ""
    
    seen = empty set
    unpaired = empty set
    
    For each character c in s do
        Add c to seen
        If c's lower case not in seen OR c's upper case not in seen then
            Add c to unpaired

    If unpaired is empty then
        Return s  // The string is nice

    // Take the first un-paired character to split the string
    first_unpaired = first element in unpaired
    parts = split s by first_unpaired

    longest_substring = ""
    
    For each part in parts do
        result = longestNiceSubstring(part) // Recursive call
        If length of result > length of longest_substring then
            longest_substring = result

    Return longest_substring

`}
                </pre>
              </Box>
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              gap={2}
            >
              <Typography sx={{ paddingLeft: '1.5rem' }}>
                <b>Time and Space Complexity</b>
                <br />
                The initial loop through the string to build the seen set and
                the unpaired set takes O(n) time, where n is the length of the
                string. Also The operation of splitting the string by the first
                unpaired character is also O(n), since you may need to traverse
                the entire string to find and split at the first unpaired
                character. You are using a set but that is used just to store
                the english latin characters so we can call that as constant.
                <br />
                Time Complexity : O(n)<br></br>
                Space Complexity : O(1)<br></br>
              </Typography>
            </Box>
          </Box>
        </CustomTabPanel>
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        alignSelf='end'
        justifyContent='space-between'
        gap='12px'
      >
        {!hasMarkedCompleted(topic_id) && (
          <LoadingButton
            variant='contained'
            onClick={() => handlePostCompletedTopic(topic_id)}
            loading={loading}
            loadingPosition='center'
            children='Mark as completed'
            style={{
              width: '170px',
              // backgroundColor: 'transparent',
              borderRadius: '8px',
              border: '1px solid rgba(64, 96, 245, 0.5)',
            }}
          />
        )}

        <Box
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Button
            sx={{ gap: '4px' }}
            onClick={handlePrevious}
          >
            <WestIcon /> Previous
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default LongestNiceSubstring;
