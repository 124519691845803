// @ts-nocheck
// material
import { Box, Typography } from '@material-ui/core';
import Button from '@mui/material/Button';
import '../tooltip.css';

// components
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { mechanismTopics } from '../components/utils/techniqueSagaTopics';
import LoadingScreen from './LoadingScreen';
import BitManipulation from './BitManipulation';
import TwoPointerTechnique from './TwoPointerTechnique';
import Recursion from './Recursion';
import BackTracking from './Backtracking';
import Sorting from './sorting';
import BinarySearch from './BinarySearch';
import { fetchTechniqueSaga, getHeaders } from '../apis/urls';
import lock from '../images/lock.png';
import Technique_3_Lock from '../images/Technique_3_Lock.png';
import LevelTwo from '../images/LevelTwo.png';
import path from '../images/path.png';
import unlock from '../images/unlock.png';
import { shouldShowNewStudentLayout } from 'src/components/utils/common';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import DivideAndConquer from './DivideAndConquer';
import level3 from '../../src/assets/level3.png';
import level4 from '../../src/assets/level4.png';
import technique_5 from '../../src/assets/Technique_5.png';
import technique_6 from '../../src/assets/Technique_6.png';
import technique_7 from '../../src/assets/Technique_8.png';
import Maping from './Maps';
import level_8 from '../../src/assets/level_8.png';
import level_9 from '../../src/assets/level_9.png';
import level_10 from '../../src/assets/level_10.png';
import level_11 from '../../src/assets/level_11.png';
import Queue from './Queue';
import Set from './Set';
import Stacktechnique from './Stacktechnique';

export default function NewTechniqueSaga() {
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const [isBitManipulation, setBitManipulation] = useState(false);
  const [isRecursion, setRecursion] = useState(false);
  const [isTwoPointer, setTwoPointer] = useState(false);
  const [isDivideAndConquer, setDivideAndConquer] = useState(false);
  const [isBackTracking, setBackTracking] = useState(false);
  const [isSorting, setSorting] = useState(false);
  const [isBinarySearch, setBinarySearch] = useState(false);
  const [isMaping, setMaping] = useState(false);
  const [isQueue, setQueue] = useState(false);
  const [isSet, setSet] = useState(false);
  const [isStack, setStack] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lastCompletedTopic, setLastCompletedTopic] = useState(1);
  const [completedTopics, setCompletedTopics] = useState([]);
  const [btnText, setBtnText] = useState('Start Learning');
  const [maxWidth, setMaxWidth] = useState('1202px');
  const [showNewLayout, setShowNewLayout] = useState(null);

  const [isBitManipulationVisible, setBitManipulationVisible] = useState(false);
  const [isPointerVisible, setPointerVisible] = useState(false);
  const [isRecursionVisible, setRecursionVisible] = useState(false);
  const [isDivideAndConquerVisible, setDivideAndConquerVisible] =
    useState(false);
  const [isBackTrackingVisible, setBackTrackingVisible] = useState(false);
  const [isSortingVisible, setSortingVisible] = useState(false);
  const [isBinarySearchVisible, setBinarySearchVisible] = useState(false);
  const [isMapingVisible, setMapingVisible] = useState(false);
  const [isQueueVisible, setQueueVisible] = useState(false);
  const [isSetVisible, setSetVisible] = useState(false);

  const handleImageClick = (value) => {
    const toggleMapping = {
      10: setSetVisible,
      9: setQueueVisible,
      8: setMapingVisible,
      7: setBinarySearchVisible,
      6: setSortingVisible,
      5: setBackTrackingVisible,
      4: setDivideAndConquerVisible,
      3: setRecursionVisible,
      2: setPointerVisible,
      default: setBitManipulationVisible,
    };

    // Retrieve the appropriate toggle function or fall back to default
    const toggleFunction = toggleMapping[value] || toggleMapping['default'];

    // Toggle the selected visibility
    toggleFunction((prevState) => !prevState);

    // List all visibility setter functions
    const visibilitySetters = [
      setBinarySearchVisible,
      setSortingVisible,
      setBackTrackingVisible,
      setDivideAndConquerVisible,
      setRecursionVisible,
      setPointerVisible,
      setMapingVisible,
      setBitManipulationVisible,
    ];

    // Reset all other states
    visibilitySetters.forEach((setter) => {
      if (setter !== toggleFunction) {
        setter(false);
      }
    });
  };

  const handleClick = () => {
    setBitManipulation(true);
  };
  const handleRecursionClick = () => {
    setRecursion(true);
  };
  const handlePointerClick = () => {
    setTwoPointer(true);
  };

  const handleDivideAndConquerClick = () => {
    setDivideAndConquer(true);
  };
  const handleBackTrackingClick = () => {
    setBackTracking(true);
  };
  const handleSortingClick = () => {
    setSorting(true);
  };
  const handleBinarySearchClick = () => {
    setBinarySearch(true);
  };
  const handleMapingClick = () => {
    setMaping(true);
  };
  const handleQueueClick = () => {
    setQueue(true);
  };
  const handleSetClick = () => {
    setSet(true);
  };
  const handleStackClick = () => {
    setStack(true);
  };
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1440) {
        setMaxWidth('1080px');
      } else {
        setMaxWidth('1202px');
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get(fetchTechniqueSaga(), {
        headers: getHeaders(currentProgram?.programId),
      })
      .then((res) => {
        const data = res?.data ?? [];
        setLoading(false);
        setCompletedTopics(data.map((topic) => topic.topic_id));
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
    const newLyoutFlag = shouldShowNewStudentLayout(currentProgram.programId);
    setShowNewLayout(newLyoutFlag);
  }, [currentProgram?.programId]);

  let lastCompletedTopicId;

  const setLastCompletedTopicId = () => {
    if (completedTopics.length > 0) {
      // Calculate the total number of topics
      const totalTopics = mechanismTopics.reduce(
        (acc, curr) => acc + curr.topics.length,
        0
      );
      // Find the maximum completed topic ID
      const maxCompletedTopicId = Math.max(...completedTopics);

      // Check if the user has completed all topics
      if (completedTopics.length === totalTopics) {
        setLastCompletedTopic(1); // Reset to 1 if all topics completed
        setBtnText('Completed');
      } else {
        // Find the next topic ID to be completed
        setBtnText('Locked');
        for (let i = 1; i <= totalTopics; i += 1) {
          if (!completedTopics.includes(i)) {
            setLastCompletedTopic(i);
            break;
          }
        }
        // If all topics before the maximum completed topic have been completed, then next topic is the maximum completed topic + 1
        if (lastCompletedTopicId > maxCompletedTopicId) {
          setLastCompletedTopic(maxCompletedTopicId + 1);
        }
      }
    } else {
      setLastCompletedTopic(1);
    }
  };

  useEffect(() => {
    setLastCompletedTopicId();
  }, [completedTopics]);
  // Timeline steps configuration
  const timelineSteps = [
    {
      id: 1,
      title: 'Technique 1: Bit Manipulation',
      background: '',
      contentArrowColor: '#1e3a8a',
      image: unlock,
      buttonLabel: btnText,
      description:
        'Bit manipulation involves algorithmically manipulating bits or smaller units of data within a word.It is used in low-level tasks such as device control, error detection, compression, encryption, and optimization. Bitwise operations like AND, OR, XOR, NOT, and bit shifts are fundamental in this process.',
      onClick: () => {
        handleImageClick('1');
        handleClick();
      },
      buttonAction: handleClick,
    },
    {
      id: 2,
      title: 'Technique 2: Two Pointer',
      background:
        btnText === 'Completed'
          ? 'linear-gradient(90deg, #005eff, #001f5c)'
          : '#d3d3d3',
      contentArrowColor: '#1e3a8a',
      image: LevelTwo,
      buttonLabel: btnText,
      description:
        ' The Two Pointer Algorithmis a popular approach  used in solving programming problems that involve arrays or linked lists.It involves using two pointers that are initialised to different positions in the array or linked list, and then moving them towards each other in a certain way to solve the problem.',

      onClick: () => {
        handleImageClick('2');
        handlePointerClick();
      },
      buttonAction: handlePointerClick,
    },
    {
      id: 3,
      title: 'Technique 3: Recursion',
      background:
        btnText !== 'Completed'
          ? 'linear-gradient(90deg, #005eff, #001f5c)'
          : '#d3d3d3',
      contentArrowColor: '#1e3a8a',
      image: level3,
      buttonLabel: btnText,
      description:
        ' Recursion is a process where a function calls itself, either directly or indirectly. A function that uses this approach is called a recursive function. Recursion is especially useful for solving problems that can be broken down into smaller, similar subproblems.',
      onClick: () => {
        handleImageClick('3');
        handleRecursionClick();
      },
      buttonAction: handleRecursionClick,
    },

    {
      id: 4,
      title: 'Technique 4: Divide And Conquer',
      background:
        btnText !== 'Completed'
          ? 'linear-gradient(90deg, #005eff, #001f5c)'
          : '#d3d3d3',
      contentArrowColor: '#1e3a8a',
      image: level4,
      buttonLabel: btnText,
      description:
        'The Divide and Conquer algorithm is a powerful approach for solving problems by breaking them into smaller subproblems, solving these subproblems independently, and then combining their solutions to form the final answer.',
      onClick: () => {
        handleImageClick('4');
        handleRecursionClick();
      },
      buttonAction: handleDivideAndConquerClick,
    },
    {
      id: 5,
      title: 'Technique 5: Back Tracking',
      background:
        btnText !== 'Completed'
          ? 'linear-gradient(90deg, #005eff, #001f5c)' // Blue to dark blue gradient
          : '#d3d3d3', // Light grey for others,
      contentArrowColor: '#1e3a8a',
      image: technique_5,
      buttonLabel: btnText,
      description:
        'Imagine you’re exploring a giant maze with multiple paths, twists, and turns. Your goal is to find the exit, but you have no map and don’t know which path is correct. So, you start by picking a direction and walking down a path.',
      onClick: () => {
        handleImageClick('5');
        handleBackTrackingClick();
      },
      buttonAction: handleBackTrackingClick,
    },
    {
      id: 6,
      title: 'Technique 6: Sorting',
      background:
        btnText !== 'Completed'
          ? 'linear-gradient(90deg, #005eff, #001f5c)'
          : '#d3d3d3',
      contentArrowColor: '#1e3a8a',
      image: technique_6,
      buttonLabel: btnText,
      description:
        'Sorting is one of the most fundamental concepts in computer science and programming. At its core, sorting is the process of arranging data in a particular order—typically ascending or descending. ',
      onClick: () => {
        handleImageClick('6');
        handleSortingClick();
      },
      buttonAction: handleSortingClick,
    },
    {
      id: 7,
      title: 'Technique 7: Binary Search',
      background:
        btnText !== 'Completed'
          ? 'linear-gradient(90deg, #005eff, #001f5c)' // Blue to dark blue gradient
          : '#d3d3d3', // Light grey for others,
      contentArrowColor: '#1e3a8a',
      image: technique_7,
      buttonLabel: btnText,
      description:
        'Binary Search is a foundational algorithm widely used in programming for its efficiency in locating elements within sorted collections. Think of it as a game of "Guess the Number" where, instead of guessing randomly, you strategically reduce the possible range in half with each guess. ',
      onClick: () => {
        handleImageClick('7');
        handleBinarySearchClick();
      },
      buttonAction: handleBinarySearchClick,
    },
    {
      id: 8,
      title: 'Technique 8: Map',
      background:
        btnText !== 'Completed'
          ? 'linear-gradient(90deg, #005eff, #001f5c)'
          : '#d3d3d3',
      contentArrowColor: '#1e3a8a',
      image: level_8,
      buttonLabel: 'Locked',
      description:
        'Maps are a powerful data structure used in programming to store key-value pairs. Think of a map as a real-world dictionary, where each word (key) has a corresponding definition (value).  ',
      onClick: () => {
        handleImageClick('8');
        handleMapingClick();
      },
      buttonAction: handleMapingClick,
    },
    {
      id: 9,
      title: 'Technique 9: Queues',
      background:
        btnText !== 'Completed'
          ? 'linear-gradient(90deg, #005eff, #001f5c)'
          : '#d3d3d3',
      contentArrowColor: '#1e3a8a',
      image: level_9,
      buttonLabel: 'Locked',
      description:
        'Imagine waiting in line at a coffee shop: the first person to arrive is the first one to be served. This is the core idea of a queue: First-In-First-Out (FIFO). In a queue, items are added at the back and removed from the front, maintaining the order in which they were added',
      onClick: () => {
        handleImageClick('9');
        handleQueueClick();
      },
      buttonAction: handleQueueClick,
    },
    {
      id: 10,
      title: 'Technique 10: Set',
      background:
        btnText !== 'Completed'
          ? 'linear-gradient(90deg, #005eff, #001f5c)'
          : '#d3d3d3',
      contentArrowColor: '#1e3a8a',
      image: level_10,
      buttonLabel: 'Locked',
      description:
        'Sets are a fundamental data structure that is especially valuable when managing collections of unique items. In programming, a set is an unordered collection of distinct elements, meaning that each item in a set appears only once, with no duplicates.',
      onClick: () => {
        handleImageClick('10');
        handleSetClick();
      },
      buttonAction: handleSetClick,
    },
    {
      id: 11,
      title: 'Technique 11: Stack',
      background:
        btnText !== 'Completed'
          ? 'linear-gradient(90deg, #005eff, #001f5c)'
          : '#d3d3d3',
      contentArrowColor: '#1e3a8a',
      image: level_11,
      buttonLabel: 'Locked',
      description:
        'Imagine waiting in line at a coffee shop: the first person to arrive is the first one to be served. This is the core idea of a queue: First-In-First-Out (FIFO). In a queue, items are added at the back and removed from the front, maintaining the order in which they were added',
      onClick: () => {
        handleImageClick('11');
        handleStackClick();
      },
      buttonAction: handleStackClick,
    },
  ];

  return (
    <>
      {isBitManipulation ? (
        <BitManipulation
          setBitManipulation={setBitManipulation}
          completedTopics={completedTopics}
          setCompletedTopics={setCompletedTopics}
          lastCompletedTopic={lastCompletedTopic}
        />
      ) : isTwoPointer ? (
        <TwoPointerTechnique
          setTwoPointer={setTwoPointer}
          completedTopics={completedTopics}
          setCompletedTopics={setCompletedTopics}
          lastCompletedTopic={lastCompletedTopic}
        />
      ) : isRecursion ? (
        <Recursion
          setRecursion={setRecursion}
          completedTopics={completedTopics}
          setCompletedTopics={setCompletedTopics}
          lastCompletedTopic={lastCompletedTopic}
        />
      ) : isDivideAndConquer ? (
        <DivideAndConquer
          setDivideAndConquer={setDivideAndConquer}
          completedTopics={completedTopics}
          setCompletedTopics={setCompletedTopics}
          lastCompletedTopic={lastCompletedTopic}
        />
      ) : isBackTracking ? (
        <BackTracking
          setBackTracking={setBackTracking}
          completedTopics={completedTopics}
          setCompletedTopics={setCompletedTopics}
          lastCompletedTopic={lastCompletedTopic}
        />
      ) : isSorting ? (
        <Sorting
          setSorting={setSorting}
          completedTopics={completedTopics}
          setCompletedTopics={setCompletedTopics}
          lastCompletedTopic={lastCompletedTopic}
        />
      ) : isBinarySearch ? (
        <BinarySearch
          setBinarySearch={setBinarySearch}
          completedTopics={completedTopics}
          setCompletedTopics={setCompletedTopics}
          lastCompletedTopic={lastCompletedTopic}
        />
      ) : isMaping ? (
        <Maping
          setMaping={setMaping}
          completedTopics={completedTopics}
          setCompletedTopics={setCompletedTopics}
          lastCompletedTopic={lastCompletedTopic}
        />
      ) : isQueue ? (
        <Queue
          setQueue={setQueue}
          completedTopics={completedTopics}
          setCompletedTopics={setCompletedTopics}
          lastCompletedTopic={lastCompletedTopic}
        />
      ) : isSet ? (
        <Set
          setSet={setSet}
          completedTopics={completedTopics}
          setCompletedTopics={setCompletedTopics}
          lastCompletedTopic={lastCompletedTopic}
        />
      ) : isStack ? (
        <Stacktechnique
          setStack={setStack}
          completedTopics={completedTopics}
          setCompletedTopics={setCompletedTopics}
          lastCompletedTopic={lastCompletedTopic}
        />
      ) : (
        <div
          style={{
            position: 'relative',
            width: '100%',
            height: '100%',
            overflow: showNewLayout ? 'auto' : 'hidden',
            padding: '20px',
          }}
        >
          <LoadingScreen loading={loading} />

          <Typography
            variant='h4'
            style={{
              marginBottom: '20px',
              fontWeight: 600,
            }}
          >
            Welcome to Technique Saga!
          </Typography>
          <Typography
            style={{
              fontSize: '1.1rem',
              lineHeight: '1.6',
              color: '#555',
              marginBottom: '20px',
            }}
          >
            Technique Saga will help you uncover the secrets behind effective
            problem-solving techniques. Dive deep into the mechanics of each
            method, gain valuable insights, and refine your problem-solving
            skills to perfection. Whether it's mastering algorithmic strategies,
            understanding design principles, or embracing innovative approaches,
            Technique Saga will help you with the knowledge and expertise to
            confidently navigate the complexities of problem-solving.
          </Typography>

          <VerticalTimeline
            lineColor='#ccc'
            style={{
              width: '70%',
              margin: '0 auto',
              padding: '0px 0',
            }}
          >
            {timelineSteps.map((step) => (
              <VerticalTimelineElement
                key={step.id}
                className='vertical-timeline-element--work'
                contentStyle={{
                  background: step.onClick
                    ? 'linear-gradient(90deg, #005eff, #001f5c)'
                    : 'grey',
                  width: '45%',
                  color: '#fff',
                  borderRadius: '8px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                }}
                contentArrowStyle={{
                  borderRight: `7px solid ${step.contentArrowColor}`,
                }}
                icon={
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      borderRadius: '50%',
                      overflow: 'hidden',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={step.image}
                      alt='Unlock'
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                    />
                  </div>
                }
              >
                <h3 style={{ marginBottom: '1px' }}>{step.title}</h3>

                <p
                  style={{
                    fontSize: '15px',
                    fontWeight: 400,
                    marginTop: '0px',
                    marginRight: '15px',
                    lineHeight: '1.2',
                    overflow: 'hidden',
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    textOverflow: 'ellipsis',
                  }}
                  title={step.description}
                >
                  {step.description}
                </p>

                {step.onClick && (
                  <button
                    style={{
                      backgroundColor: 'white',
                      color: 'blue',
                      border: 'none',
                      borderRadius: '4px',
                      cursor: 'pointer',
                      fontSize: '16px',
                      textAlign: 'center',
                      transition: 'transform 0.3s ease',
                    }}
                    onClick={step.onClick}
                  ></button>
                )}
                {step.onClick ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      variant='contained'
                      style={{
                        backgroundColor: 'white',
                        color: 'blue',
                      }}
                      onClick={step.buttonAction}
                    >
                      {'Read'}
                    </Button>
                  </div>
                ) : (
                  ''
                )}
              </VerticalTimelineElement>
            ))}
          </VerticalTimeline>
        </div>
      )}
    </>
  );
}
