import React from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
} from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import { FiberManualRecord } from '@mui/icons-material';
import EastIcon from '@mui/icons-material/East';
import ParagraphBox from './ParagraphBox';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import image1 from '../../../assets/image1queue.png';
import image2 from '../../../assets/image2queue.png';

const Introduction = ({
  handlePostCompletedTopic,
  hasMarkedCompleted,
  loading,
  topic_id,
  handleNext,
}) => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='20px'
      paddingY={2}
      maxWidth='calc(100% - 300px)'
    >
      <Box>
        <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
          TECHNIQUE 10
        </Typography>

        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
        >
          <Typography
            sx={{
              fontSize: '48px',
              fontWeight: 600,
              lineHeight: '58px',
              letterSpacing: '0.01em',
              textAlign: 'left',
            }}
          >
            Set
          </Typography>
          {hasMarkedCompleted(topic_id) && (
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
                color: 'green',
                fontWeight: '500',
                border: '2px solid green',
                padding: '4px 10px',
                borderRadius: '20px',
                cursor: 'default',
              }}
            >
              Completed
            </Typography>
          )}
        </Box>
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        gap='24px'
      >
        <Typography sx={{ fontSize: '22px', fontWeight: 400 }}>
          Introduction to Sets
        </Typography>

        <ParagraphBox>
          <Typography>
            Sets are a fundamental data structure that is especially valuable
            when managing collections of unique items. In programming, a set is
            an unordered collection of distinct elements, meaning that each item
            in a set appears only once, with no duplicates. Sets are
            particularly useful for scenarios where uniqueness is essential,
            such as eliminating duplicates from a list, keeping track of visited
            nodes in graph algorithms, or quickly checking membership within a
            collection.
          </Typography>
          <Typography>
            One of the primary advantages of using sets is their ability to
            perform quick membership checks and basic operations like union,
            intersection, and difference between collections. Unlike lists or
            arrays, where duplicates are common and accessing an element might
            require a sequential search, sets ensure that each element appears
            only once and allow fast lookups due to their underlying hash-based
            structure in most programming languages.
          </Typography>
          <Typography>
            Consider the example of a music playlist where you want to ensure no
            song is repeated. Instead of manually checking for duplicates, a set
            can manage this constraint naturally. Sets are versatile and perform
            well for various tasks, from deduplication and membership testing to
            efficiently handling groups of items.
          </Typography>
        </ParagraphBox>

        <ParagraphBox>
          <Typography sx={{ fontSize: '20px', fontWeight: 400 }}>
            What is a Set?
          </Typography>
          <Typography>
            A set is a collection of unique, unordered elements. Since each
            element is distinct, adding a duplicate item to a set has no effect.
            The set structure is optimized for quick operations, including
            insertions, deletions, and membership checks. Sets are typically
            implemented using hash tables, enabling these operations to have an
            average time complexity of O(1).
          </Typography>
          <Typography>
            Below are some basic operations you can perform with sets:
          </Typography>

          <ListItemIcon sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <FiberManualRecord fontSize='8px' />{' '}
            <Typography>
              <strong>Insertion: </strong>Adds a new element to the set. If the
              element already exists, the set remains unchanged.
            </Typography>
          </ListItemIcon>
          <ListItemIcon sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <FiberManualRecord fontSize='8px' />{' '}
            <Typography>
              <strong>Lookup: </strong>Checks if an element is present in the
              set.
            </Typography>
          </ListItemIcon>

          <ListItemIcon sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <FiberManualRecord fontSize='8px' />{' '}
            <Typography>
              <strong>Deletion: </strong>Removes an element from the set, if it
              exists.
            </Typography>
          </ListItemIcon>
          <ListItemIcon sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <FiberManualRecord fontSize='8px' />{' '}
            <Typography>
              <strong>Union: </strong>Combines all unique elements from two
              sets.
            </Typography>
          </ListItemIcon>
          <ListItemIcon sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <FiberManualRecord fontSize='8px' />{' '}
            <Typography>
              <strong>Intersection: </strong>Finds elements that are common to
              both sets.
            </Typography>
          </ListItemIcon>
          <ListItemIcon sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <FiberManualRecord fontSize='8px' />{' '}
            <Typography>
              <strong>Difference: </strong>Finds elements present in one set but
              not in the other.
            </Typography>
          </ListItemIcon>
        </ParagraphBox>
      </Box>

      <Typography sx={{ fontSize: '20px', fontWeight: 400 }}>
        Example Usage
      </Typography>
      <ParagraphBox>
        <Typography>
          Consider a scenario where you need to remove duplicates from a list of
          numbers. With a set, you can add each number to the set, and because
          sets automatically discard duplicates, you’re left with only unique
          numbers. This approach is efficient, easy to implement, and more
          effective than manually filtering out duplicates.
        </Typography>

        <Box
          sx={{
            backgroundColor: 'black',
            // padding: '24px',
            borderRadius: '10px',
            width: '803px',
            paddingLeft: '1.5em',
            marginLeft: '2em',
          }}
        >
          <pre style={{ color: 'white', margin: 0 }}>
            {`

function removeDuplicates(numbers):
    uniqueSet = new set  // Initialize an empty set to store unique numbers

    // Iterate over each number in the list
    for number in numbers:
        uniqueSet.add(number)  // Add number to the set (duplicates will be ignored)

    return list(uniqueSet)  // Convert set back to list for output

`}
          </pre>
        </Box>

        <Typography>
          In this example, each number is added to a set. If the number already
          exists in the set, it is ignored, which effectively removes duplicates
          from the list. This operation is efficient and achieves deduplication
          with minimal code.
        </Typography>

        <Typography sx={{ fontSize: '20px', fontWeight: 400 }}>
          Types of Sets
        </Typography>

        <Typography>
          Depending on their underlying structure and specific requirements,
          sets come in different types, each offering unique benefits. Here’s an
          overview of the most common types:
        </Typography>

        <ListItemIcon sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <FiberManualRecord fontSize='8px' />{' '}
          <Typography>
            <strong>HashSet: </strong>A hash-based set that provides average{' '}
            <strong>O(1)</strong> time complexity for operations like insertion,
            deletion, and lookup. Common implementations include Python’s set,
            Java’s HashSet, and C++’s unordered_set.
          </Typography>
        </ListItemIcon>
        <ListItemIcon sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <FiberManualRecord fontSize='8px' />{' '}
          <Typography>
            <strong>TreeSet: </strong>A tree-based set that maintains elements
            in sorted order, with an average <strong>O(log n)</strong> time
            complexity for operations. Java’s TreeSet and C++’s set are examples
            of tree-based sets.
          </Typography>
        </ListItemIcon>
        <ListItemIcon sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <FiberManualRecord fontSize='8px' />{' '}
          <Typography>
            <strong>LinkedHashSet: </strong>A set that preserves insertion
            order, combining a linked list with a hash-based structure. It’s
            commonly used when both unique elements and ordered iteration are
            necessary, as seen in Java’s LinkedHashSet.
          </Typography>
        </ListItemIcon>

        <Typography>
          Each type of set is optimized for different needs:
        </Typography>

        <ListItemIcon sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <FiberManualRecord fontSize='8px' />{' '}
          <Typography>
            <strong>HashSet: </strong>Best for general-purpose use cases where
            element order is irrelevant.
          </Typography>
        </ListItemIcon>
        <ListItemIcon sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <FiberManualRecord fontSize='8px' />{' '}
          <Typography>
            <strong>TreeSet: </strong>Ideal when sorted order of elements is
            required.
          </Typography>
        </ListItemIcon>
        <ListItemIcon sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <FiberManualRecord fontSize='8px' />{' '}
          <Typography>
            <strong>LinkedHashSet: </strong>Useful when maintaining insertion
            order matters.
          </Typography>
        </ListItemIcon>

        <Typography sx={{ fontSize: '20px', fontWeight: 400 }}>
          Key Operations and Their Complexities
        </Typography>

        <Typography>
          Sets are designed to optimize the following core operations, enabling
          efficient data management:
        </Typography>

        <ListItemIcon sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <FiberManualRecord fontSize='8px' />{' '}
          <Typography>
            <strong>Insertion (add): </strong>Adds a new element to the set.
            Complexity: <strong>O(1)</strong> for hash-based sets,{' '}
            <strong>O(log n)</strong> for tree-based sets.
          </Typography>
        </ListItemIcon>
        <ListItemIcon sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <FiberManualRecord fontSize='8px' />{' '}
          <Typography>
            <strong>Membership Check (contains): </strong>Checks if an element
            exists in the set. Complexity: <strong>O(1)</strong> for hash-based
            sets, <strong>O(log n)</strong> for tree-based sets.
          </Typography>
        </ListItemIcon>
        <ListItemIcon sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <FiberManualRecord fontSize='8px' />{' '}
          <Typography>
            <strong>Deletion (remove): </strong>Removes a specified element from
            the set. Complexity: <strong>O(1)</strong> for hash-based sets,{' '}
            <strong>O(log n)</strong> for tree-based sets.
          </Typography>
        </ListItemIcon>

        <Typography>
          The complexity of these operations varies with the underlying
          structure. <strong>Hash-based sets</strong> offer average{' '}
          <strong>O(1)</strong> complexity but can degrade to{' '}
          <strong>O(n)</strong> in rare cases of hash collisions.{' '}
          <strong>Tree-based sets</strong> guarantee <strong>O(log n)</strong>{' '}
          complexity by keeping the set balanced.
        </Typography>

        <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>
          When to Use Sets
        </Typography>

        <Typography>
          Sets are especially useful when you need to manage unique items,
          quickly check membership, or perform set operations like union and
          intersection. Here are some typical use cases:
        </Typography>

        <ListItemIcon sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <FiberManualRecord fontSize='8px' />{' '}
          <Typography>
            <strong>Eliminating Duplicates: </strong>Quickly remove duplicates
            from a list or collection (e.g., ensuring unique items in a
            dataset).
          </Typography>
        </ListItemIcon>
        <ListItemIcon sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <FiberManualRecord fontSize='8px' />{' '}
          <Typography>
            <strong>Membership Testing: </strong>Efficiently check for the
            existence of an item in a collection (e.g., determining if a user ID
            exists).
          </Typography>
        </ListItemIcon>
        <ListItemIcon sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <FiberManualRecord fontSize='8px' />{' '}
          <Typography>
            <strong>Cycle Detection: </strong>Track visited nodes in algorithms,
            such as graph traversals, to avoid cycles.
          </Typography>
        </ListItemIcon>
        <ListItemIcon sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <FiberManualRecord fontSize='8px' />{' '}
          <Typography>
            <strong>Mathematical Set Operations: </strong>Efficiently compute
            union, intersection, and difference between collections, useful in
            various data manipulation tasks.
          </Typography>
        </ListItemIcon>
        <ListItemIcon sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <FiberManualRecord fontSize='8px' />{' '}
          <Typography>
            <strong>Tracking Unique States or Configurations: </strong>In games,
            simulations, or states that must remain unique.
          </Typography>
        </ListItemIcon>
      </ParagraphBox>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        alignSelf='end'
        justifyContent='space-between'
        gap='12px'
      >
        {!hasMarkedCompleted(topic_id) && (
          <LoadingButton
            variant='contained'
            onClick={() => handlePostCompletedTopic(topic_id)}
            loading={loading}
            loadingPosition='center'
            children='Mark as completed'
            style={{
              width: '170px',
              // backgroundColor: 'transparent',
              borderRadius: '8px',
              border: '1px solid rgba(64, 96, 245, 0.5)',
            }}
          />
        )}

        <Box
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Button
            sx={{ gap: '4px' }}
            onClick={handleNext}
          >
            Next <EastIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Introduction;
