import React, { useState } from 'react';
import { Box, Tabs, Tab, Button } from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import CustomTabPanel from '../CustomTabPanel';
import TopicTitle from '../../Recursion/TopicTiltle';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const DecodeString = ({
  handlePostCompletedTopic,
  hasMarkedCompleted,
  loading,
  topic_id,
  handleNext,
  handlePrevious,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='36px'
      paddingY={2}
      maxWidth='calc(100% - 300px)'
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <TopicTitle>2. Decode String</TopicTitle>
        {hasMarkedCompleted(topic_id) && (
          <p
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              color: 'green',
              fontWeight: 500,
              border: '2px solid green',
              padding: '4px 10px',
              borderRadius: '20px',
              cursor: 'default',
            }}
          >
            Completed
          </p>
        )}
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        gap='24px'
      >
        <Box
          style={{
            borderBottom: 1,
            borderColor: 'divider',
            width: '34rem',
            background: '#FEF7FF',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab
              label='Problem Statement'
              {...a11yProps(0)}
            />
            <Tab
              label='Brute Force Approach'
              {...a11yProps(1)}
            />
            <Tab
              label='Stack Based Approach'
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel
          value={value}
          index={0}
        >
          <p style={{ fontSize: '20px' }}>Description</p>
          <Box
            display='flex'
            flexDirection='column'
            gap='10px'
          >
            <p>
              Given an encoded string, return its decoded string. The encoding
              rule is as follows:
            </p>

            <div style={{ fontFamily: 'Arial, sans-serif', fontSize: '16px' }}>
              <ul
                style={{
                  listStyleType: 'disc',
                  marginLeft: '20px',
                  fontSize: '16px',
                }}
              >
                <li>
                  k[encoded_string] means the string encoded_string is repeated
                  k times.
                </li>
              </ul>
            </div>
            <p>
              You may assume that the input string is always valid. No extra
              spaces, no malformed characters, and no invalid inputs.
            </p>
            <p>Input: "3[a]2[bc]" Output: "aaabcbc" </p>
            <p>
              Explanation: The encoded string "3[a]2[bc]" means: - "a" is
              repeated 3 times - {'>'} "aaa" - "bc" is repeated 2 times -{'>'}{' '}
              "bcbc" Therefore, the decoded string is "aaabcbc".
            </p>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={1}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='20px'
          >
            <p>
              The brute force approach involves directly processing the string
              by repeatedly extracting the number and substring, and expanding
              them to form the final decoded string. Step-by-step Iterate
              through the string and handle the encoding by manually repeating
              the substring as we encounter each encoded pattern.
            </p>
            <Box
              style={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '800px',
                fontSize: '16px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`function decodeString(s):
    result = ""  # Initialize result to hold the decoded string
    i = 0        # Pointer to traverse the string

    while i < length of s:
        if s[i] is a digit:  
            num = 0
            # Capture the full number (in case it's more than one digit)
            while s[i] is a digit:
                num = num * 10 + int(s[i])
                i = i + 1
            
            # Now s[i] should be '['
            i = i + 1  # Skip '['
            # Capture the substring inside the brackets
            start = i
            count = 1
            while count > 0:
                if s[i] is '[':
                    count = count + 1
                if s[i] is ']':
                    count = count - 1
                i = i + 1
            
            substring = s[start:i-1]  
            # Extract the substring inside the brackets
            result += substring repeated num times 
            # Repeat the substring and append to result
            
        else:
            result += s[i]  # Directly add the character if it's not a digit
            i = i + 1  # Move to next character

    return result

`}
              </pre>
            </Box>

            <p style={{ paddingLeft: '1.5rem' }}>
              <b>Time and Space Complexity</b>
              <br />
              In the worst case the iterator moves along the length of the
              array, but the code involves a lot of checks and complexities.
              <br />
              <br />
              Time Complexity :O(n)<br></br>
              Space Complexity : O(n) due to result string
            </p>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={2}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='20px'
          >
            <Box
              display='flex'
              flexDirection='column'
              gap='8px'
            >
              <p>
                The problem at the end is about seeing a valid bracket,
                processing the string inside it and multiplying it by the number
                before the bracket. By using stacks we can do this in a much
                cleaner way without creating a lot of checks and complexities.
              </p>
              <p>
                The stack-based approach will use a stack to manage intermediate
                strings and repeat counts.
              </p>
              <p>
                As we encounter a digit (indicating the repetition count), we
                push it onto the stack. When we encounter a [ or ], we handle
                the expansion or concatenation logic by popping values from the
                stack.
              </p>
            </Box>
            <Box>
              <Box
                style={{
                  backgroundColor: 'black',
                  padding: '20px',
                  borderRadius: '10px',
                  width: '800px',
                  marginLeft: '1em',
                }}
              >
                <pre style={{ color: 'white', margin: 0 }}>
                  {`
function decodeString(s):
stack = empty stack  # Initialize stack
current_string = ""  # To build the current string
current_number = 0   # To hold the current number for repetitions

for each character c in s:
    if c is a digit:
        current_number = current_number * 10 + int(c)  
        # Handle multi-digit numbers
        
    else if c is '[':
        stack.push(current_string)  # Push current string onto stack
        stack.push(current_number)   # Push current number onto stack
        current_string = ""         # Reset current_string for new substring
        current_number = 0          # Reset current_number for new repetition
         count
    else if c is ']':
        repeat_count = stack.pop()  # Get the repeat count
        previous_string = stack.pop()  # Get the previous string
        current_string = previous_string + current_string * repeat_count 
         # Repeat and append
    else:
        current_string += c  # Add the character to the current string

return current_string


`}
                </pre>
              </Box>
              <br />
              <p style={{ paddingLeft: '1.5rem' }}>
                <b>Time and Space Complexity</b>
                <br />
                Although there is not a significant change when it comes to the
                time complexity, but the code is much cleaner and better in
                terms of understanding and writing.
                <br /> <br />
                Time Complexity : O(n)<br></br>
                Space Complexity : O(n)<br></br>
              </p>
            </Box>
          </Box>
        </CustomTabPanel>
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        alignSelf='end'
        justifyContent='space-between'
        gap='12px'
      >
        {!hasMarkedCompleted(topic_id) && (
          <LoadingButton
            variant='contained'
            onClick={() => handlePostCompletedTopic(topic_id)}
            loading={loading}
            loadingPosition='center'
            children='Mark as completed'
            style={{
              width: '170px',
              // backgroundColor: 'transparent',
              borderRadius: '8px',
              border: '1px solid rgba(64, 96, 245, 0.5)',
            }}
          />
        )}

        <Box
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Button
            style={{ gap: '4px' }}
            onClick={handlePrevious}
          >
            <WestIcon /> Previous
          </Button>

          <Button
            style={{ gap: '4px' }}
            onClick={handleNext}
          >
            Next <EastIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default DecodeString;
