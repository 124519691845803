import React from 'react';
import TechniqueSaga from '../../pages/TechniqueSaga';
import NewTechniqueSaga from '../../pages/NewTechniqueSaga';
import { useSelector } from 'react-redux';
import { shouldShowNewSessionsUI } from '../utils/common';
import TechniqueSagaMainScreen from './TechniqueSagaMainScreen';

export default function NewTechnique() {
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const shouldShowNewTechnique = shouldShowNewSessionsUI(
    currentProgram?.programId
  );

  if (currentProgram?.programId === 2) {
    return <NewTechniqueSaga />;
  }

  return (
    <>{shouldShowNewTechnique ? <NewTechniqueSaga /> : <NewTechniqueSaga />}</>
  );
}
