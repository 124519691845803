import React from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
} from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import { FiberManualRecord } from '@mui/icons-material';
import EastIcon from '@mui/icons-material/East';
import ListRenderComponent from './ListRenderComponent';
import ParagraphBox from './ParagraphBox';
import { Grid } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

// Images

import BubbleSort from '../../../assets/Bubblesort.gif';
import SelectionSort from '../../../assets/selectionsort.gif';
import InsertionSort from '../../../assets/insertionsort.gif';
import QuickSort from '../../../assets/Quicksort.gif';
import MergeSort from '../../../assets/mergesort.gif';

const Introduction = ({
  handlePostCompletedTopic,
  hasMarkedCompleted,
  loading,
  topic_id,
  handleNext,
}) => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='10px'
      paddingY={2}
      maxWidth='calc(100% - 300px)'
    >
      {/* Header */}
      <Box>
        <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
          TECHNIQUE 8
        </Typography>
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
        >
          <Typography
            sx={{
              fontSize: '48px',
              fontWeight: 600,
              lineHeight: '58px',
              letterSpacing: '0.01em',
              textAlign: 'left',
            }}
          >
            Maps
          </Typography>
          {hasMarkedCompleted(topic_id) && (
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
                color: 'green',
                fontWeight: 500,
                border: '2px solid green',
                padding: '4px 10px',
                borderRadius: '20px',
                cursor: 'default',
              }}
            >
              Completed
            </Typography>
          )}
        </Box>
      </Box>

      {/* Introduction */}
      <Box
        display='flex'
        flexDirection='column'
        gap='24px'
      >
        <Typography sx={{ fontSize: '22px', fontWeight: 400 }}>
          Introduction
        </Typography>
        <ParagraphBox>
          <Typography>
            Maps are a powerful data structure used in programming to store
            key-value pairs. Think of a map as a real-world dictionary, where
            each word (key) has a corresponding definition (value). Maps allow
            for efficient data storage and retrieval, which is essential for
            numerous applications such as word counting, database indexing, and
            caching. With maps, we can quickly locate values based on unique
            identifiers or keys, making them a highly effective solution for
            managing relationships between data points.
          </Typography>
          <Typography>
            A primary advantage of using maps lies in their ability to
            efficiently handle associations between items. By organizing data
            with unique keys, maps can speed up access to information that would
            otherwise require sequential searching through arrays or lists.
            Imagine managing a large phone directory: rather than scanning
            through each entry, a map allows you to retrieve a number directly
            based on the person's name, which is set as the key.
          </Typography>
          <Typography>
            Maps are essential tools in many algorithms, data structures, and
            systems design tasks. They provide fast lookups, simple data
            organization, and effective ways to link data elements in memory.
            Maps are versatile and can be adapted for various use cases, from
            simple lookup tables to complex caching mechanisms. Understanding
            maps is crucial for efficient coding and optimized performance in
            data-driven applications.
          </Typography>
          <Box>
            <Typography
              sx={{ fontSize: '22px', fontWeight: 400, lineHeight: '2' }}
            >
              What is a Map?
            </Typography>
            <Typography>
              A map is a collection of key-value pairs, where each key is
              unique, and each key is associated with a specific value. The
              structure is optimized to allow rapid retrieval, insertion, and
              deletion of key-value pairs. Maps are often implemented using hash
              tables or balanced trees, which enable efficient operations with
              average time complexity of O(1) for hash-based maps and O(log n)
              for tree-based maps.
            </Typography>
            <Typography
              variant='body1'
              paragraph
              style={{ marginTop: '10px' }}
            >
              Here’s a step-by-step breakdown:
            </Typography>
            {/* <Box sx={{ marginLeft: '20px', paddingLeft: '20px' }}>
              <ListItem sx={{padding:'0'}}>
                <ListItemIcon>
                  <FiberManualRecord fontSize='8px' />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography>
                      <strong>Insertion :</strong>Adds a new key-value pair to
                      the map.
                    </Typography>
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <FiberManualRecord fontSize='6px' />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography>
                      <strong>Lookup:</strong>Retrieves the value associated
                      with a given key.
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecord fontSize='6px' />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography>
                      <strong>Deletion:</strong>Removes a key-value pair from
                      the map based on a specified key.
                    </Typography>
                  }
                />
              </ListItem>
            </Box> */}
            <Box sx={{ marginLeft: '20px', paddingLeft: '20px' }}>
              <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
                <li style={{ display: 'flex', alignItems: 'flex-start' }}>
                  <FiberManualRecord
                    style={{
                      fontSize: '8px',
                      marginRight: '8px',
                      marginTop: '5px',
                    }}
                  />
                  <Typography>
                    <strong>Insertion:</strong> Adds a new key-value pair to the
                    map.
                  </Typography>
                </li>
                <li
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    marginTop: '10px',
                  }}
                >
                  <FiberManualRecord
                    style={{
                      fontSize: '6px',
                      marginRight: '8px',
                      marginTop: '5px',
                    }}
                  />
                  <Typography>
                    <strong>Lookup:</strong> Retrieves the value associated with
                    a given key.
                  </Typography>
                </li>
                <li
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    marginTop: '10px',
                  }}
                >
                  <FiberManualRecord
                    style={{
                      fontSize: '6px',
                      marginRight: '8px',
                      marginTop: '5px',
                    }}
                  />
                  <Typography>
                    <strong>Deletion:</strong> Removes a key-value pair from the
                    map based on a specified key.
                  </Typography>
                </li>
              </ul>
            </Box>

            <br></br>

            <Typography
              sx={{ fontSize: '22px', fontWeight: 400, lineHeight: '2' }}
            >
              Example Usage
            </Typography>
            <Typography>
              Consider a scenario where you want to count the occurrence of each
              word in a text. With a map, you can store each word as a key and
              its frequency as the value. Each time a word appears, you
              increment the associated value. Maps make this type of data
              organization easy and efficient.
            </Typography>
            <br></br>
            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '800px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`
function countWordOccurrences(text):
wordMap = new map   // Initialize an empty map to store word counts

// Split the text into words (assuming whitespace as delimiter)
words = split(text, " ")

// Iterate over each word in the list of words
for word in words:
    if word in wordMap:
        wordMap[word] += 1   // Increment count if word is already in map
    else:
        wordMap[word] = 1    // Initialize count to 1 if word is not in map

return wordMap
`}
              </pre>
            </Box>
            <br></br>
            <Typography>
              In this example, each word is mapped to a frequency count. The map
              allows fast lookups and updates for each word encountered,
              significantly reducing the complexity compared to other data
              structures.
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{ fontSize: '22px', fontWeight: 400, lineHeight: '2' }}
            >
              Types of Maps
            </Typography>
            <Typography sx={{ lineHeight: '2' }}>
              Depending on implementation and requirements, maps can vary in
              structure:
            </Typography>

            {/* <Box sx={{ marginLeft: '20px', paddingLeft: '20px' }}>
              <ol>
                <li >
                  <Typography variant='body1' >
                    <strong>HashMap:</strong> A hash-based map offering average
                    O(1) time complexity for operations. Examples include
                    Python’s dict, Java’s HashMap, and C++’s unordered_map.{' '}
                  </Typography>
                </li>
                <li>
                  <Typography variant='body1'>
                    <strong> TreeMap:</strong> A tree-based map that maintains a
                    sorted order of keys, with O(log n) complexity for
                    operations. Examples include Java’s TreeMap and C++’s map{' '}
                  </Typography>
                </li>
                <li>
                  <Typography variant='body1'>
                    <strong> LinkedHashMap:</strong> A map that maintains
                    insertion order, combining aspects of linked lists and hash
                    maps.
                  </Typography>
                </li>
              </ol>
            </Box> */}
            <Box sx={{ marginLeft: '20px', paddingLeft: '40px' }}>
              <ol style={{ margin: 0, padding: 0 }}>
                <li style={{ lineHeight: '1.8' }}>
                  <Typography variant='body1'>
                    <strong>HashMap:</strong> A hash-based map offering average
                    O(1) time complexity for operations. Examples include
                    Python’s dict, Java’s HashMap, and C++’s unordered_map.
                  </Typography>
                </li>
                <li style={{ lineHeight: '1.8' }}>
                  <Typography variant='body1'>
                    <strong>TreeMap:</strong> A tree-based map that maintains a
                    sorted order of keys, with O(log n) complexity for
                    operations. Examples include Java’s TreeMap and C++’s map.
                  </Typography>
                </li>
                <li style={{ lineHeight: '1.8' }}>
                  <Typography variant='body1'>
                    <strong>LinkedHashMap:</strong> A map that maintains
                    insertion order, combining aspects of linked lists and hash
                    maps.
                  </Typography>
                </li>
              </ol>
            </Box>

            <br></br>
            <Typography
              sx={{ fontSize: '22px', fontWeight: 400, lineHeight: '2' }}
            >
              Each type of map is suitable for different needs:
            </Typography>

            <Box sx={{ marginLeft: '20px', paddingLeft: '20px' }}>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecord fontSize='8px' />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography>
                      HashMap is best for general-purpose use cases where the
                      order doesn’t matter.
                    </Typography>
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <FiberManualRecord fontSize='6px' />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography>
                      TreeMap is optimal for situations requiring ordered keys.
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecord fontSize='6px' />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography>
                      LinkedHashMap is useful when insertion order is important.
                    </Typography>
                  }
                />
              </ListItem>
            </Box>

            <br />
            <Typography
              sx={{ fontSize: '22px', fontWeight: 400, lineHeight: '2' }}
            >
              Key Operations and Their Complexities
            </Typography>

            <Typography>
              Maps are designed to optimize the following operations:
            </Typography>
            <Box sx={{ marginLeft: '20px', paddingLeft: '20px' }}>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecord fontSize='8px' />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography>
                      <strong>Insertion (put):</strong> Insert a new key-value
                      pair, O(1) for hash maps, O(log n) for tree map
                    </Typography>
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <FiberManualRecord fontSize='6px' />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography>
                      <strong>Search (get):</strong> Retrieve a value based on
                      its key, O(1) for hash maps, O(log n) for tree maps.
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecord fontSize='6px' />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography>
                      <strong>Deletion (remove):</strong> Remove a key-value
                      pair, O(1) for hash maps, O(log n) for tree maps.
                    </Typography>
                  }
                />
              </ListItem>
            </Box>
            <Typography>
              The time complexity of map operations depends on the underlying
              data structure. Hash-based maps offer average O(1) complexity, but
              can degrade to O(n) in rare cases of hash collisions. Tree-based
              maps guarantee O(log n) complexity for all operations by balancing
              the tree structure.
            </Typography>
            <br />
            <Typography
              sx={{ fontSize: '22px', fontWeight: 400, lineHeight: '2' }}
            >
              When to Use Maps
            </Typography>

            <Typography>
              Maps are ideal for situations where you need to store data as
              key-value pairs and perform frequent lookups, insertions, and
              deletions. Here are some common use cases:
            </Typography>
            <Box sx={{ marginLeft: '20px', paddingLeft: '20px' }}>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecord fontSize='8px' />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography>
                      <strong>Frequency Counting: </strong>Efficiently count
                      occurrences of items (e.g., words in a text).
                    </Typography>
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <FiberManualRecord fontSize='6px' />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography>
                      <strong>Caching: </strong>Store computed values for quick
                      retrieval in future operations.
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecord fontSize='6px' />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography>
                      <strong>Database Indexing:</strong> Maps enable rapid data
                      access by organizing data with unique keys.{' '}
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecord fontSize='6px' />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography>
                      <strong> Associative Arrays:</strong> Represent data pairs
                      in applications where each element has a unique
                      identifier.
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecord fontSize='6px' />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography>
                      <strong>Efficient Lookup Tables:</strong> Quickly find
                      corresponding values (e.g., translations, user info based
                      on ID).
                    </Typography>
                  }
                />
              </ListItem>
            </Box>
            <Typography>
              Maps are foundational in algorithm development and are frequently
              used in coding interviews and performance-critical applications.
              By mastering maps, you gain a versatile tool for managing data
              relationships and achieving efficient code.
            </Typography>
          </Box>
        </ParagraphBox>
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        alignSelf='end'
        justifyContent='space-between'
        gap='12px'
      >
        {!hasMarkedCompleted(topic_id) && (
          <LoadingButton
            variant='contained'
            onClick={() => handlePostCompletedTopic(topic_id)}
            loading={loading}
            loadingPosition='center'
            children='Mark as completed'
            style={{
              width: '170px',
              // backgroundColor: 'transparent',
              borderRadius: '8px',
              border: '1px solid rgba(64, 96, 245, 0.5)',
            }}
          />
        )}

        <Box
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Button
            sx={{ gap: '4px' }}
            onClick={handleNext}
          >
            Next <EastIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Introduction;
