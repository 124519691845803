import React, { useState } from 'react';
import { Box, Tabs, Tab, Button } from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import CustomTabPanel from '../CustomTabPanel';
import TopicTitle from '../../Recursion/TopicTiltle';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const TwoSum = ({
  handlePostCompletedTopic,
  hasMarkedCompleted,
  loading,
  topic_id,
  handleNext,
  handlePrevious,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='36px'
      paddingY={2}
      maxWidth='calc(100% - 300px)'
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <TopicTitle>3. Two Sum</TopicTitle>
        {hasMarkedCompleted(topic_id) && (
          <p
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              color: 'green',
              fontWeight: '500',
              border: '2px solid green',
              padding: '4px 10px',
              borderRadius: '20px',
              cursor: 'default',
            }}
          >
            Completed
          </p>
        )}
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        gap='24px'
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            width: '34rem',
            background: '#FEF7FF',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab
              label='Problem Statement'
              {...a11yProps(0)}
            />
            <Tab
              label='Brute Force Approach'
              {...a11yProps(1)}
            />
            <Tab
              label='Set Approach'
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel
          value={value}
          index={0}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='10px'
          >
            <p>
              Given a 1-indexed array of integers numbers that is already sorted
              in non-decreasing order, find two numbers such that they add up to
              a specific target, if it is not present return -1.
            </p>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={1}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='20px'
          >
            <p>
              A straightforward way of finding that out would be to check every
              possible pair that is present in the array.
            </p>
            <p>
              We can have two nested loops. The outer loop iterates through each
              element of the array with index i.
            </p>
            <p>
              The inner loop starts from the element next to i (index j = i + 1)
              and goes until the end of the array.
            </p>

            <p>
              This ensures that every combination of two different elements in
              the array is checked.
            </p>
            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '800px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`function twoSum_bf(numbers, target):
    len = length of numbers
    for i from 0 to len - 1:
        for j from i + 1 to len - 1:
            if numbers[i] + numbers[j] equals target:
                return true

    return false

`}
              </pre>
            </Box>

            <p sx={{ paddingLeft: '1.5rem' }}>
              <b>Time and Space Complexity</b>
              <br />
              Since we are checking every possible pair for which we have two
              nested loops and in the worst case we will have a time complexity
              of n^2 and no extra space is required for this.
              <br />
              <br />
              Time Complexity :O(n^2)<br></br>
              Space Complexity : O(1)
            </p>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={2}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='20px'
          >
            <Box
              display='flex'
              flexDirection='column'
              gap='8px'
            >
              <p sx={{ fontSize: '22px', fontWeight: 400, mb: '8px' }}>
                For each element we can try to see if its pair exists or not,
                for example let’s say the sum was 5 and we come across the
                element 3, then we can try to see if there is a 2 in my array
                (5-3= 2), we can look this up efficiently using a set. Below is
                the implementation for it.
              </p>
            </Box>
            <Box>
              <Box
                sx={{
                  backgroundColor: 'black',
                  padding: '24px',
                  borderRadius: '10px',
                  width: '800px',
                  marginLeft: '1em',
                }}
              >
                <pre style={{ color: 'white', margin: 0 }}>
                  {`
function has_pair_with_sum(nums, target):
    initialize seen as an empty set
    
    for each num in nums:
        if (target - num) is in seen:
            return True  // Pair found with required sum
        
        add num to seen  // Mark num as seen for future pairs
    
    return False  // No pair with the target sum found


`}
                </pre>
              </Box>
              <br />
              <p sx={{ paddingLeft: '1.5rem' }}>
                <b>Time and Space Complexity</b>
                <br />
                We are iterating on the array just once, and along the way we
                store elements in a set for efficient lookup.
                <br /> <br />
                Time Complexity : O(n)<br></br>
                Space Complexity : O(n)<br></br>
              </p>
            </Box>
          </Box>
        </CustomTabPanel>
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        alignSelf='end'
        justifyContent='space-between'
        gap='12px'
      >
        {!hasMarkedCompleted(topic_id) && (
          <LoadingButton
            variant='contained'
            onClick={() => handlePostCompletedTopic(topic_id)}
            loading={loading}
            loadingPosition='center'
            children='Mark as completed'
            style={{
              width: '170px',
              // backgroundColor: 'transparent',
              borderRadius: '8px',
              border: '1px solid rgba(64, 96, 245, 0.5)',
            }}
          />
        )}

        <Box
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Button
            sx={{ gap: '4px' }}
            onClick={handlePrevious}
          >
            <WestIcon /> Previous
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default TwoSum;
