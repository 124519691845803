import React from 'react';
import { Box } from '@mui/material';
import DividerComponent from '../Atoms/DividerComponent';
import TypographyText from '../Atoms/TypographyText';
import Typography from 'src/theme/overrides/Typography';

const PlacementText = () => {
  return (
    <Box
      sx={{
        position: 'absolute', // Absolute positioning to place it relative to parent
        top: '174px', // Adjust to position below triangle8
        left: '55%', // Center horizontally
        transform: 'translateX(-50%)', // Ensure horizontal centering
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '400px',
        gap: '10px',
        zIndex: '0', // Ensure it is below triangle8 but above the background
      }}
    >
      <DividerComponent dividerStyleClass='placement-divider' />
      {/* <TypographyText text='Placement Milestone' /> */}
      <span style={{ minWidth: '160px' }}>Placement Milestone</span>
    </Box>
  );
};

export default PlacementText;
