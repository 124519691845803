// @ts-nocheck
import React, { useState, createContext, useContext, useEffect } from 'react';

const EfPipelineContext = createContext();
export const useEfPipeline = () => useContext(EfPipelineContext);

export const EfPipelineProvider = (props) => {
  const [loading, setLoading] = useState(false);
  const [formId, setFormId] = useState(null);
  const [showConversationFlow, setShowConversationFlow] = useState(false);
  const [showScreeningTest, setShowScreeningTest] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [message, setMessage] = useState('');
  const [showConfetti, setShowConfetti] = useState(false);
  const [
    showFakeResponseAnalyzerForScreeningTest,
    setShowFakeResponseAnalayerForScreeningTest,
  ] = useState(false);
  const [showFastrackOfferLetter, setShowFastrackOfferLetter] = useState(false);
  const [showSopRevisit, setShowSopRevisit] = useState(false);
  const [sopResponse, setSopResponse] = useState('');
  const [screeningTestResult, setScreeningTestResult] = useState(null);
  const [screeningTestStatus, setScreeningTestStatus] = useState(null);
  const [exclusiveToken, setExclusiveToken] = useState(null);
  const [notifyForCoupons, setNotifyForCoupons] = useState(false);
  const [screeningTestVersion, setScreeningTestVersion] = useState(null);
  const [showSopQuestions, setShowSopQuestions] = useState(false);
  const [sopQuestionIndex, setSopQuestionIndex] = useState(0);
  const [startsIn, setStartsIn] = useState();
  const [showReschedule, setShowReschedule] = useState(false);
  const [showRescheduleScreen, setShowRescheduleScreen] = useState(0);
  const [rescheduleTime, setRescheduleTime] = useState(null);
  const [showWelcomeBack, setShowWelcomeBack] = useState(false);
  const [showAllSet, setShowAllSet] = useState(false);
  const [showFstFailFlow, setShowFstFailFlow] = useState(false);
  const [screen, setScreen] = useState(null);
  const [showApplicationProgress, setShowApplicationProgress] = useState(true);
  const [token, setToken] = useState(null);
  const [resumeLink, setResumeLink] = useState('');

  useEffect(() => {
    if (!showFakeResponseAnalyzerForScreeningTest) {
      return;
    }

    const id = setTimeout(() => {
      setShowFakeResponseAnalayerForScreeningTest(false);
    }, 23 * 1000); // 23 seconds

    return () => {
      if (id) {
        clearTimeout(id);
      }
    };
  }, [showFakeResponseAnalyzerForScreeningTest]);

  useEffect(() => {
    if (!showConfetti) {
      return;
    }

    const id = setTimeout(() => setShowConfetti(false), 5 * 1000);

    return () => {
      if (id) {
        clearTimeout(id);
      }
    };
  }, [showConfetti]);

  return (
    <EfPipelineContext.Provider
      value={{
        notifyForCoupons,
        setNotifyForCoupons,
        setScreeningTestStatus,
        screeningTestStatus,
        exclusiveToken,
        setExclusiveToken,
        setScreeningTestResult,
        screeningTestResult,
        setShowFastrackOfferLetter,
        showFastrackOfferLetter,
        showConfetti,
        setShowConfetti,
        showFakeResponseAnalyzerForScreeningTest,
        setShowFakeResponseAnalayerForScreeningTest,
        loading,
        setLoading,
        formId,
        setFormId,
        showConversationFlow,
        setShowConversationFlow,
        showScreeningTest,
        setShowScreeningTest,
        isSuccess,
        setIsSuccess,
        successMessage,
        setSuccessMessage,
        message,
        setMessage,
        showSopRevisit,
        setShowSopRevisit,
        sopResponse,
        setSopResponse,
        screeningTestVersion,
        setScreeningTestVersion,
        setShowSopQuestions,
        showSopQuestions,
        sopQuestionIndex,
        setSopQuestionIndex,
        setStartsIn,
        startsIn,
        setShowReschedule,
        showReschedule,
        setShowRescheduleScreen,
        showRescheduleScreen,
        rescheduleTime,
        setRescheduleTime,
        setShowWelcomeBack,
        showWelcomeBack,
        showAllSet,
        setShowAllSet,
        setShowFstFailFlow,
        showFstFailFlow,
        screen,
        setScreen,
        showApplicationProgress,
        setShowApplicationProgress,
        setToken,
        token,
        resumeLink,
        setResumeLink,
      }}
    >
      {props.children}
    </EfPipelineContext.Provider>
  );
};
