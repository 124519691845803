import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import {
  Typography,
  Box,
  Button,
  IconButton,
  Tooltip,
  Chip,
  Stack,
  Modal,
} from '@material-ui/core';
import DeleteOutlined from '@iconify/icons-ant-design/delete-outlined';
import axios from 'axios';
import tw from 'twin.macro';
import { Link, AttachFileSharp } from '@material-ui/icons';
import { Icon } from '@iconify/react';
import { styled } from '@material-ui/core/styles';
import {
  openS3URL,
  getHeaders,
  deleteResourcesURL,
  markAsCompleted,
} from '../../apis/urls';
import { CopyIcon } from '../utils/common';
import { getUserType, getAccessToken } from '../../configs/auth';
import './style.css';
import { markPreReadAsComplete } from '../redux-store/slices/sessionSlice';

const YOUTUBE_BASE_URL = 'https://www.youtube.com';
const TextC = tw.div`ml-4`;
const ResH5 = tw.h5`text-base mb-2`;
const btnStyle =
  'rounded-lg px-3 py-1 text-sm  mt-2 text-sm sm:text-base font-semibold shadow transition duration-300 focus:outline-none focus:shadow-inner';
const PrimaryBtn = tw.a`${btnStyle} bg-blue-500 hocus:bg-blue-700 text-gray-100`;
const SecondryBtn = tw.a`${btnStyle} bg-gray-100 border border-solid border-blue-500 text-blue-500`;
const DeleteButton = styled('div')(() => ({ color: 'red', cursor: 'pointer' }));

function NewReadingLink({ link, title }) {
  const handleViewClick = () => {
    window.open(link, '_blank');
  };

  return (
    <TextC>
      <ResH5>{title}</ResH5>
      <br />
      {link.includes(process.env.REACT_APP_BLOG_SITE) && (
        <PrimaryBtn
          onClick={handleViewClick}
          style={{ marginTop: '10px', cursor: 'pointer' }}
        >
          View
        </PrimaryBtn>
      )}
      {!link.includes(process.env.REACT_APP_BLOG_SITE) && (
        <a
          href={link}
          target='_blank'
          rel='noopener noreferrer'
        >
          {link}
        </a>
      )}
      {CopyIcon(link)}
    </TextC>
  );
}

function NewYoutubeLink({ link, title }) {
  const embedId = link.split('watch?v=')[1];
  return (
    <TextC
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '0.5rem',
        borderRadius: '0.8rem',
        marginTop: '0.5rem',
      }}
    >
      <iframe
        width='350'
        height='200'
        src={`${YOUTUBE_BASE_URL}/embed/${embedId}`}
        frameBorder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
        title={title}
        style={{ borderRadius: '0.8rem' }}
      />
      <ResH5 style={{ paddingLeft: '1rem' }}>{title}</ResH5>
    </TextC>
  );
}

function NewReadingMaterial({
  setIsSuccess,
  setHasError,
  setErrorMessage,
  setSuccessMessage,
  materialResource,
  setCurrentIndex,
}) {
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const dispatch = useDispatch();
  const userType = getUserType();
  const isCoach = userType === 'coach';
  const [isCompleted, setCompleted] = useState(materialResource.completed);

  const handleDeleteClick = (e, id) => {
    e.stopPropagation();
    const deletResource = async () => {
      try {
        const { data } = await axios.delete(deleteResourcesURL(id), {
          headers: getHeaders(currentProgram?.programId),
        });
        setIsSuccess(true);
        setSuccessMessage('Resource successfully deleted');
      } catch (err) {
        console.log(err);
        setHasError(true);
        setErrorMessage(err.response?.data?.message || err.message);
      }
    };
    deletResource();
  };

  const markCompleted = async () => {
    try {
      const res = await axios.patch(
        markAsCompleted(materialResource.id),
        null,
        {
          headers: getHeaders(currentProgram?.programId),
        }
      );
      setCompleted(true);
      dispatch(
        markPreReadAsComplete({ id: materialResource.id, completed: true })
      );
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    setCompleted(materialResource.completed);
  }, [materialResource]);

  return (
    <>
      {materialResource.resource_type === 'link' && (
        <Box
          display='flex'
          alignItems='center'
          sx={{
            padding: '14px 20px 14px 20px',
            boxShadow:
              ' rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px',
            borderRadius: '0.8rem',
            marginTop: '0.5rem',
          }}
        >
          <Tooltip title='Link'>
            <Link fontSize='medium' />
          </Tooltip>
          <Box>
            {materialResource.link.includes(YOUTUBE_BASE_URL) ? (
              <NewYoutubeLink
                link={materialResource.link}
                title={materialResource.title}
              />
            ) : (
              <NewReadingLink
                link={materialResource.link}
                title={materialResource.title}
              />
            )}
            {isCoach && (
              <Tooltip
                title='Delete'
                aria-label='delete'
                sx={{ float: 'right' }}
              >
                <DeleteButton
                  onClick={(e) => handleDeleteClick(e, materialResource.id)}
                >
                  <Icon
                    icon={DeleteOutlined}
                    width='20'
                    height='20'
                  />
                </DeleteButton>
              </Tooltip>
            )}
          </Box>
        </Box>
      )}

      {materialResource.resource_type === 'file' && (
        <URLOpener
          key={materialResource.id}
          reading={materialResource}
          handleDeleteClick={handleDeleteClick}
          programId={currentProgram?.programId}
        />
      )}
      <div style={{ marginTop: '10px', display: 'flex' }}>
        {isCompleted ? (
          <PrimaryBtn
            style={{ padding: '8px 40px', margin: '8px', cursor: 'pointer' }}
            className='hover-color'
          >
            Completed
          </PrimaryBtn>
        ) : (
          <PrimaryBtn
            style={{ padding: '8px 40px', margin: '8px', cursor: 'pointer' }}
            onClick={markCompleted}
          >
            Mark As Completed
          </PrimaryBtn>
        )}
        <SecondryBtn
          style={{ padding: '8px 40px', margin: '8px', cursor: 'pointer' }}
          onClick={() => {
            setCurrentIndex((prev) => prev + 1);
          }}
        >
          Move to the next item
        </SecondryBtn>
      </div>
    </>
  );
}

function URLOpener({ reading, handleDeleteClick, programId }) {
  const [resourceURL, setResourceURL] = useState(null);
  const userType = getUserType();
  const isCoach = userType === 'coach';
  const [showResource, setShowResource] = useState(false);

  const onCloseModal = () => {
    setShowResource(false);
  };

  useEffect(() => {
    async function fileHandler(id) {
      try {
        const { data } = await axios.get(openS3URL(id), {
          headers: getHeaders(programId),
        });
        setResourceURL(data.file);
      } catch (err) {
        console.log(err);
      }
    }
    fileHandler(reading.id);
  }, []);

  return (
    resourceURL && (
      <>
        <Box
          display='flex'
          alignItems='center'
          sx={{
            padding: '14px 20px 14px 20px',
            boxShadow:
              ' rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px',
            borderRadius: '0.8rem',
            marginTop: '0.5rem',
          }}
        >
          <Tooltip title='File'>
            <AttachFileSharp fontSize='medium' />
          </Tooltip>
          <TextC>
            <Typography>{reading.title}</Typography>
            <div style={{ marginBottom: '8px' }}>
              {reading.topics &&
                reading.topics.map((topic) => {
                  return (
                    <span
                      key={topic.id}
                      style={{ marginRight: '5px' }}
                    >
                      <Chip
                        variant='outlined'
                        label={topic.name}
                      />
                    </span>
                  );
                })}
            </div>
            {resourceURL && (
              <PrimaryBtn
                onClick={(e) => {
                  e.preventDefault();
                  setShowResource(true);
                }}
                style={{
                  textDecoration: 'none',
                  width: 'auto',
                  height: 'auto',
                  marginTop: '1px',
                  cursor: 'pointer',
                }}
              >
                view
              </PrimaryBtn>
            )}
          </TextC>
          {isCoach && (
            <Tooltip
              title='Delete'
              aria-label='delete'
            >
              <DeleteButton onClick={(e) => handleDeleteClick(e, reading.id)}>
                <Icon
                  icon={DeleteOutlined}
                  width='20'
                  height='20'
                />
              </DeleteButton>
            </Tooltip>
          )}
        </Box>
        <Modal
          open={showResource}
          onClose={onCloseModal}
        >
          <div
            style={{
              marginTop: '10px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <embed
              src={resourceURL}
              width='70%'
              height='1000px'
              type='application/pdf'
            />
            <Button
              sx={{
                height: '60px',
                width: '50px',
                '&:hover': {
                  backgroundColor: 'white',
                },
              }}
              onClick={onCloseModal}
            >
              <CloseIcon sx={{ fontSize: '40px' }} />
            </Button>
          </div>
        </Modal>
      </>
    )
  );
}

export default NewReadingMaterial;
