import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

// Import your slices
import programReducer from './slices/programSlice';
import topicReducer from './slices/topicSlice';
import sessionReducer from './slices/sessionSlice';
import userReducer from './slices/userSlice';
import ticketSlice from './slices/ticketSlice';
import attendanceSlice from './slices/attendanceSlice';
import progressReducer from './slices/progressSlice';
import selectedSubTopicReducer from './slices/mindmapSlice';
import subTopicsReducer from './slices/subTopicsSlice';
import mindmapProblemsReducer from './slices/mindmapProblemSlice';

// Combine all reducers
const reducers = combineReducers({
  programs: programReducer,
  topics: topicReducer,
  sessions: sessionReducer,
  users: userReducer,
  tickets: ticketSlice,
  attendance: attendanceSlice,
  progress: progressReducer,
  mindmap: selectedSubTopicReducer,
  subTopics: subTopicsReducer,
  mindmapProblems: mindmapProblemsReducer,
});

// Persist configuration
const persistConfig = {
  key: 'root',
  storage,
};

// Persisted reducer
const persistedReducer = persistReducer(persistConfig, reducers);

// Configure the store
export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk], // Add thunk middleware
});

// Export persistor for use with PersistGate
export const persistor = persistStore(store);
