// src/redux/mindmapSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedSubTopic: null,
};

const mindmapSlice = createSlice({
  name: 'mindmap',
  initialState,
  reducers: {
    setSelectedSubTopic: (state, action) => {
      state.selectedSubTopic = action.payload;
    },
    resetSelectedSubTopic: (state) => {
      state.selectedSubTopic = '';
    },
  },
});

export const { setSelectedSubTopic, resetSelectedSubTopic } =
  mindmapSlice.actions;
export const selectSelectedSubTopic = (state) => state.mindmap.selectedSubTopic;
export default mindmapSlice.reducer;
