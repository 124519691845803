import React, { useState } from 'react';
import {
  Box,
  Typography,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
} from '@material-ui/core';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import VerifiedIcon from '@mui/icons-material/Verified';
import { FiberManualRecord } from '@mui/icons-material';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import CustomTabPanel from '../CustomTabPanel';
import { FindLargestPowerOfTwoConst } from '../../../utils/techniqueSagaConstant';
import TopicTitle from '../TopicTitle';
import ListRenderComponent from '../ListRenderComponent';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import AggresiveCow1 from '../../../../assets/AggressiveCow1.png';
import AggressiveCow2 from '../../../../assets/AggressiveCow2.png';
import AggressiveCow3 from '../../../../assets/AggressiveCow3.png';
import AggressiveCow4 from '../../../../assets/AggressiveCow4.png';
import AggressiveCow5 from '../../../../assets/AggressiveCow5.png';
import AggressiveCow6 from '../../../../assets/AggressiveCow6.png';

import BinarySearch6 from '../../../../assets/BinarySearch6.png';
import BinarySearch7 from '../../../../assets/BinarySearch7.png';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const AggressiveCow = ({
  handlePostCompletedTopic,
  hasMarkedCompleted,
  loading,
  topic_id,
  handleNext,
  handlePrevious,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='36px'
      paddingY={2}
      maxWidth='calc(100% - 300px)'
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <TopicTitle>2.Aggressive Cows</TopicTitle>
        {hasMarkedCompleted(topic_id) && (
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              color: 'green',
              fontWeight: '500',
              border: '2px solid green',
              padding: '4px 10px',
              borderRadius: '20px',
              cursor: 'default',
            }}
          >
            Completed
          </Typography>
        )}
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        gap='24px'
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            width: '36rem',
            background: '#FEF7FF',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab
              label='Problem Statement'
              {...a11yProps(0)}
            />
            <Tab
              label='Brute Force Approach'
              {...a11yProps(1)}
            />
            <Tab
              label='Binary Search Approach'
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel
          value={value}
          index={0}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='10px'
          >
            <Typography>
              The "Aggressive Cows" problem involves placing a certain number of
              cows in stalls such that the minimum distance between any two cows
              is maximized. Given an array of integers representing the
              positions of the stalls and an integer representing the number of
              cows, the task is to determine the largest minimum distance that
              can be achieved between the cows.
            </Typography>
            <Typography>Example:</Typography>
            <Box sx={{ paddingLeft: '1.9rem' }}>
              <ul>
                <li>
                  <Typography variant='body1'>
                    <strong>Input:</strong> stalls = [1, 2, 4, 8, 9], cows = 3
                  </Typography>
                </li>
                <li>
                  <Typography variant='body1'>
                    <strong>Output:</strong> 3 (The cows can be placed in stalls
                    at positions 1, 4, and 7, maximizing the minimum distance of
                    3 between them.)
                  </Typography>
                </li>
              </ul>
            </Box>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={1}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              In order to efficiently calculate the minimum distances between
              two cows, one thing that comes to mind is to sort as the minimum
              distance will always be between two consecutive stalls. Let’s take
              the example given in description which is:
            </Typography>
            <Typography>
              <b> Input:</b> stalls = [1, 2, 4, 8, 9], cows = 3
            </Typography>
            <Typography>
              In this case, the maximum answer that you can get is (9-1) and the
              minimum answer that one can get is zero. We can iteratively try
              placing the cows at this distance, if it works then that would be
              our answer or we will try placing them at a smaller distance. Look
              at the table below
            </Typography>
            <Typography>Placing at distance 9-1 = 8.</Typography>

            <img
              src={AggresiveCow1}
              alt=''
              style={{ height: '80px', width: '600px', paddingLeft: '10px' }}
            ></img>
            <br />
            <Typography>
              When Placing at distance 8 you can just place two cows, but you
              have to place 3 so that will not work. Next we try to place them
              at distance 7 because that is the next best answer.
            </Typography>
            <Typography>Placing at distance 7.</Typography>
            <img
              src={AggressiveCow2}
              alt=''
              style={{ height: '80px', width: '600px', paddingLeft: '10px' }}
            ></img>
            <br />
            <Typography>
              Again we were able to place two cows, but in order to place the
              third one we need a stall at or after 8+7=15, which does not
              exist.
            </Typography>
            <Typography>
              We will keep repeating the step and eventually will find an
              answer.
            </Typography>
            <img
              src={AggressiveCow3}
              alt=''
              style={{ height: '80px', width: '600px', paddingLeft: '10px' }}
            ></img>
            <br />
            <Typography>
              This is the best way of placing so that the minimum distance
              between any two cows can be maximized which is 3.
            </Typography>
            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '800px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`function can_place_cows(stalls, cows, min_dist):
    count = 1  # place the first cow in the first stall
    last_position = stalls[0]
    
    for i from 1 to length of stalls - 1:
        if stalls[i] - last_position >= min_dist:
            count += 1  # place the next cow
            last_position = stalls[i]  # update the last occupied position
            if count == cows:
                return true
    return false

function largest_min_distance(stalls, cows):
    sort stalls in ascending order  # sort stalls to try placing cows in ascending order
    max_distance = 0
    Max_possible = stalls[-1] - stalls[0]
    
    # brute-force iterate over all possible distances
    for dist from Max_possible to (1):
        if can_place_cows(stalls, cows, dist):
            max_distance = dist  # update max distance if possible
        else:
            break  # stop if no longer possible to place cows with this distance

    return max_distance
`}
              </pre>
            </Box>

            <Typography sx={{ paddingLeft: '1.5rem' }}>
              <b>Time and Space Complexity</b>
              <br />
              For each value of distance, which can be from max_distance to 0 in
              the worst case we are iterating on every stall which are total n.
              <br />
              Time Complexity : O(n*d), where d is the max distance between
              farthest stalls<br></br>
              Space Complexity : O(1)<br></br>
            </Typography>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={2}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              Let us try to observe if there is some sort of monotonicity when
              it comes to checking valid stalls. First of all we already have
              sorted the array, thus stalls are in an increasing order making
              checking of distance between them easier. Now, assume that you
              found a valid minimum distance ‘x’ between any two cows. Since,
              you already have ‘x’ you will never check for ‘x-1’ distance
              between any two cows for a very simple reason that you want the
              maximum possible answer. For example, let us take the stalls = [1,
              2, 4, 8, 9] . We know that both 3 and 2 are valid, but now since
              you already found the answer at 3 you will not check for 2 now
              because any which ways you want the maximum.'
            </Typography>
            <Typography>
              Now, let’s say you try to find the distance ‘x’ and you are unable
              to find that, then it is absolutely certain that you won’t find
              any answer greater than ‘x’ as well. We will use both these logics
              to create a binary search solution.
            </Typography>

            <Typography>
              Consider, placing a cow at a distance of 4 which is the mid value
              for (8 and 0). Let’s see if that is possible.
            </Typography>
            <img
              src={AggressiveCow4}
              alt=''
              style={{ height: '80px', width: '600px', paddingLeft: '10px' }}
            ></img>

            <Typography>
              As you can see after placing the cow at first stall the next
              available stall at distance 4 or greater is the 8th stall and
              after that we have nowhere else to place the third cow thus that
              is not possible answer.
            </Typography>
            <Typography>
              Now if 4 is not possible every distance greater than 4 will also
              not be possible. So let’s check on values less than 4. The new mid
              comes out to be 1 = (3+0)/2.
            </Typography>
            <img
              src={AggressiveCow5}
              alt=''
              style={{ height: '80px', width: '600px', paddingLeft: '10px' }}
            ></img>

            <Typography>
              As you can see its is absolutely possible to place cows at
              distance 1. Now since we are looking to maximize the distance we
              won’t be checking for values less than 1 thus let us look around
              values greater than 1 and less than 4. So new mid comes out to be
              (2+3)/2 = 2.
            </Typography>
            <img
              src={AggressiveCow6}
              alt=''
              style={{ height: '80px', width: '600px', paddingLeft: '10px' }}
            ></img>

            <Typography>
              Again let’s try to find a better answer by searching at 3, since
              that is the only distance left to be checked and all else is
              discarded.
            </Typography>
            <img
              src={AggressiveCow6}
              alt=''
              style={{ height: '80px', width: '600px', paddingLeft: '10px' }}
            ></img>

            <Typography>
              Thus, 3 is the best answer possible and we were able to do it much
              more efficiently. Let us look at its implementation now.
            </Typography>
            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '800px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`function canPlaceCows(stalls, cows, minDist):
    count = 1  // Place the first cow in the first stall
    lastPosition = stalls[0]

    for i from 1 to length of stalls - 1:
        if stalls[i] - lastPosition >= minDist:  // Check distance
            count += 1
            lastPosition = stalls[i]
            if count == cows:  // All cows are placed
                return true
    return false

function aggressiveCows(stalls, cows):
    sort(stalls)  // Sort the stall positions
    low = 1  // Minimum possible distance
    high = stalls[length of stalls - 1] - stalls[0]  // Maximum possible distance
    answer = 0

    while low <= high:
        mid = low + (high - low) // 2
        if canPlaceCows(stalls, cows, mid):
            answer = mid  // Update answer
            low = mid + 1  // Try for a larger distance
        else:
            high = mid - 1  // Try for a smaller distance

    return answer
`}
              </pre>
            </Box>

            <Typography sx={{ paddingLeft: '1.5rem' }}>
              <b>Time and Space Complexity</b>
              <br />
              The search on the distance was optimized from linear to binary, so
              our new time and space complexity becomes.
              <br />
              Time Complexity: O(n*logd) where d is the maximum possible
              distance<br></br>
              Space Complexity: O(1)<br></br>
            </Typography>
          </Box>
        </CustomTabPanel>
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        alignSelf='end'
        justifyContent='space-between'
        gap='12px'
      >
        {!hasMarkedCompleted(topic_id) && (
          <LoadingButton
            variant='contained'
            onClick={() => handlePostCompletedTopic(topic_id)}
            loading={loading}
            loadingPosition='center'
            children='Mark as completed'
            style={{
              width: '170px',
              // backgroundColor: 'transparent',
              borderRadius: '8px',
              border: '1px solid rgba(64, 96, 245, 0.5)',
            }}
          />
        )}

        <Box
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Button
            sx={{ gap: '4px' }}
            onClick={handlePrevious}
          >
            <WestIcon /> Previous
          </Button>

          <Button
            sx={{ gap: '4px' }}
            onClick={handleNext}
          >
            Next <EastIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AggressiveCow;
