import React from 'react';
import { Box } from '@material-ui/system';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedSubTopic } from '../../../redux-store/slices/mindmapSlice'; // Adjust path accordingly
import { slugify } from 'src/components/utils/common';

const SubTopicsList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { topic } = useParams();

  const selectedSubTopic = useSelector(
    (state) => state.mindmap.selectedSubTopic
  );

  const subTopics = useSelector((state) => state.subTopics.subTopics);
  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const programSlug = slugify(currentProgram.name);

  // Check if subTopics is valid
  if (!subTopics || Object.keys(subTopics).length === 0) {
    return <Box>No subtopics available</Box>; // Fallback in case subTopics is empty or null
  }

  return (
    <Box>
      {Object.entries(subTopics).map(([subTopic, description], index) => (
        <Box
          key={index}
          display='flex'
          flexDirection='row'
          style={{
            transition: 'background-color 0.3s',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
          mb={3}
          ml={4}
          mr={5}
          p={1}
          onClick={() => {
            // Dispatch the action to update the selected subtopic
            dispatch(setSelectedSubTopic(subTopic));
            navigate(
              `/student/${programSlug}/signature-frameworks/mindmap/${topic}/${subTopic}`
            );
          }}
          onMouseEnter={(e) =>
            (e.currentTarget.style.backgroundColor = 'lightblue')
          }
          onMouseLeave={(e) =>
            (e.currentTarget.style.backgroundColor = 'transparent')
          }
        >
          <Box
            style={{
              position: 'relative',
              backgroundColor: '#1976D2',
              width: '100px',
              height: '100px',
              borderRadius: '50%',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <Box
              style={{
                color: 'white',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                textTransform: 'capitalize',
              }}
            >
              {subTopic}
            </Box>
          </Box>
          <Box
            pt={4}
            pl={2}
            style={{ maxWidth: 'calc(100% - 100px)' }}
          >
            {description}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default SubTopicsList;
