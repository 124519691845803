import React, { useState } from 'react';
import {
  Box,
  Typography,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
} from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import VerifiedIcon from '@mui/icons-material/Verified';
import { FiberManualRecord } from '@mui/icons-material';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import CustomTabPanel from '../CustomTabPanel';
import { FindLargestPowerOfTwoConst } from '../../../utils/techniqueSagaConstant';
import TopicTitle from '../TopicTitle';
import ListRenderComponent from '../ListRenderComponent';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import BinarySearch6 from '../../../../assets/BinarySearch6.png';
import BinarySearch7 from '../../../../assets/BinarySearch7.png';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const SearchInaRotatedSortedArray = ({
  handlePostCompletedTopic,
  hasMarkedCompleted,
  loading,
  topic_id,
  handleNext,
  handlePrevious,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='36px'
      paddingY={2}
      maxWidth='calc(100% - 300px)'
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <TopicTitle>1. Search in a Rotated Sorted Array</TopicTitle>
        {hasMarkedCompleted(topic_id) && (
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              color: 'green',
              fontWeight: '500',
              border: '2px solid green',
              padding: '4px 10px',
              borderRadius: '20px',
              cursor: 'default',
            }}
          >
            Completed
          </Typography>
        )}
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        gap='24px'
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            width: '36rem',
            background: '#FEF7FF',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab
              label='Problem Statement'
              {...a11yProps(0)}
            />
            <Tab
              label='Brute Force Approach'
              {...a11yProps(1)}
            />
            <Tab
              label='Binary Search Approach'
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel
          value={value}
          index={0}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='20px'
          >
            <Typography>
              Given a rotated sorted array and a target value, determine if the
              target exists in the array. If it does, return its index. If it
              does not, return -1. A rotated sorted array is an array that has
              been sorted and then rotated at some pivot unknown to you
              beforehand.
            </Typography>
            <Typography>Example:</Typography>
            <Box sx={{ paddingLeft: '1.9rem' }}>
              <ul>
                <li>
                  <Typography variant='body1'>
                    <strong>Input:</strong> nums = [4, 5, 6, 7, 0, 1, 2], target
                    = 0
                  </Typography>
                </li>
                <li>
                  <Typography variant='body1'>
                    <strong>Output:</strong> 4 (The target 0 is found at index
                    4.)
                  </Typography>
                </li>
              </ul>
            </Box>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={1}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              A simple way to solve this problem is to perform a linear search
              through the array. Check each element to see if it matches the
              target and if it does you will return that index and if no
              elements match then you can return -1.
            </Typography>

            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '800px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`function linearSearch(nums, target):

    for index from 0 to length of nums - 1:
        if nums[index] == target:
            return index

    return -1  // Target not found

`}
              </pre>
            </Box>

            <Typography sx={{ paddingLeft: '1.5rem' }}>
              <b>Time and Space Complexity</b>
              <br />
              Since we are doing a simple linear search on the array.
              <br />
              Time Complexity : O(n)<br></br>
              Space Complexity : O(1)<br></br>
            </Typography>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={2}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              The dataset that is given to you is not exactly sorted but it is
              of monotonic nature. If you observe carefully, you can divide your
              rotated array into two parts, the first part being where it was
              rotated and the other which was rotated. We can treat both these
              as sorted half and modify our binary search accordingly. Red and
              blue highlight the two parts with low, high and mid.
            </Typography>
            <img
              src={BinarySearch6}
              alt=''
              style={{ height: '50px', width: '800px', paddingLeft: '10px' }}
            ></img>
            <Typography>
              In the example zero is our target, so the first thing that we need
              to do is figure out in which sorted part does it lie. In this
              case, we are able to observe a few things, let’s highlight those:
            </Typography>
            <Box sx={{ paddingLeft: '36px' }}>
              <ul>
                <li>
                  <Typography variant='body1'>
                    <strong>{'nums[low] < nums[mid]'} </strong>, meaning the
                    elements from low to high must definitely be sorted.
                  </Typography>
                </li>
                <li>
                  <Typography variant='body1'>
                    <strong>Target &lt; nums[mid]:</strong> So it could be on
                    the left side of mid.
                  </Typography>
                </li>
                <li>
                  <Typography variant='body1'>
                    <strong>Target &gt; nums[mid]:</strong> That means it is not
                    present on the left side of mid, and thus we can reduce our
                    search space towards the right.
                  </Typography>
                </li>
              </ul>
            </Box>
            <img
              src={BinarySearch7}
              alt=''
              style={{ height: '50px', width: '350px', paddingLeft: '10px' }}
            ></img>
            <Typography>
              Now, you can see we actually have a full sorted search space and
              we can easily find the index of the target element. So, from this
              question you can see how it is not important that the array has to
              be sorted, it could be that the array displays some sort of
              monotonic nature and that could be enough to apply binary search.
            </Typography>

            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '800px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`function search(nums, target):
    low = 0
    high = length of nums - 1

    while low <= high:
        mid = low + (high - low) // 2

        // Check if the middle element is the target
        if nums[mid] == target:
            return mid

        // Determine which half is sorted
        if nums[low] <= nums[mid]:  // Left half is sorted
            if nums[low] <= target < nums[mid]:  // Target in the left half
                high = mid - 1
            else:  // Target in the right half
                low = mid + 1
        else:  // Right half is sorted
            if nums[mid] < target <= nums[high]:  // Target in the right half
                low = mid + 1
            else:  // Target in the left half
                high = mid - 1

    return -1  // Target not found


`}
              </pre>
            </Box>

            <Typography sx={{ paddingLeft: '1.5rem' }}>
              <b>Time and Space Complexity</b>
              <br />
              It was just a modified binary search with a few extra checks, so
              the time and space complexity won’t change.
              <br />
              Time Complexity: O(log n)<br></br>
              Space Complexity: O(1)<br></br>
            </Typography>
          </Box>
        </CustomTabPanel>
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        alignSelf='end'
        justifyContent='space-between'
        gap='12px'
      >
        {!hasMarkedCompleted(topic_id) && (
          <LoadingButton
            variant='contained'
            onClick={() => handlePostCompletedTopic(topic_id)}
            loading={loading}
            loadingPosition='center'
            children='Mark as completed'
            style={{
              width: '170px',
              // backgroundColor: 'transparent',
              borderRadius: '8px',
              border: '1px solid rgba(64, 96, 245, 0.5)',
            }}
          />
        )}

        <Box
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Button
            sx={{ gap: '4px' }}
            onClick={handlePrevious}
          >
            <WestIcon /> Previous
          </Button>

          <Button
            sx={{ gap: '4px' }}
            onClick={handleNext}
          >
            Next <EastIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default SearchInaRotatedSortedArray;
