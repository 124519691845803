import { Box, Typography } from '@material-ui/core';
import CodeBlockComponent from './Recursion/CodeBlockComponent';
import { List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { FiberManualRecord } from '@mui/icons-material';

// Reusable component for Box with a margin
const MarginBox = ({ children, marginBottom }) => (
  <Box sx={{ marginBottom }}>{children}</Box>
);

// Reusable Typography component with dynamic font size and weight
const TextComponent = ({ bold, fontSize, children }) => (
  <Typography sx={{ fontWeight: bold ? 600 : 400, fontSize }}>
    {children}
  </Typography>
);

const ListRenderComponent = ({ data, header, listType = 'bullet' }) => {
  return (
    <>
      {header && <Typography>{header}</Typography>}
      <List>
        {data.map((operator, index) => (
          <ListItem
            key={index}
            sx={{ paddingY: '2px', alignItems: 'flex-start' }}
          >
            <ListItemIcon>
              {listType === 'bullet' ? (
                <FiberManualRecord sx={{ width: '0.5em' }} />
              ) : listType === 'number' ? (
                <Typography>{index + 1}.</Typography>
              ) : (
                <Typography>{listType}</Typography> // For custom symbols or text
              )}
            </ListItemIcon>
            <ListItemText primary={renderElement(operator)} />
          </ListItem>
        ))}
      </List>
    </>
  );
};

export const renderElement = (element) => {
  if (Array.isArray(element)) {
    return (
      <MarginBox marginBottom='10px'>{element.map(renderElement)}</MarginBox>
    );
  }

  if (!element?.type) return <Typography>{element}</Typography>;

  const { type, value, bold, listheader, listType, width = '500px' } = element;

  switch (type) {
    case 'code':
      return (
        <MarginBox marginBottom='20px'>
          <CodeBlockComponent code={value} />
        </MarginBox>
      );

    case 'heading-l':
      return (
        <MarginBox marginBottom='15px'>
          <TextComponent
            bold={bold}
            fontSize='20px'
          >
            {value}
          </TextComponent>
        </MarginBox>
      );

    case 'heading-xl':
      return (
        <MarginBox marginBottom='20px'>
          <TextComponent
            bold={bold}
            fontSize='22px'
          >
            {value}
          </TextComponent>
        </MarginBox>
      );

    case 'heading':
      return (
        <MarginBox marginBottom='10px'>
          <TextComponent
            bold={bold}
            fontSize='18px'
          >
            {value}
          </TextComponent>
        </MarginBox>
      );

    case 'pointers':
      return (
        <ListRenderComponent
          data={value}
          header={listheader}
          listType={listType}
        />
      );

    case 'image':
      return (
        <MarginBox margin='10px'>
          <img
            src={`/static/technique-saga/${value}`}
            width={width}
          />
        </MarginBox>
      );

    default:
      return null;
  }
};

export const areAllTopicsCompleted = (
  mechanismTopics,
  completedTopics,
  currentTopicId
) => {
  // Flatten the topics to get all topic IDs for the technique
  const topicIds = mechanismTopics.flatMap((group) =>
    group.topics.map((topic) => topic.id)
  );

  // Include the current topic ID in the completed list
  const updatedCompletedTopics = [...completedTopics, currentTopicId];

  // Check if all topics for the technique are completed
  return topicIds.every((topicId) => updatedCompletedTopics.includes(topicId));
};
