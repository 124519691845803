import React, { useState } from 'react';
import { Box, Tabs, Tab, Button } from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import CustomTabPanel from '../CustomTabPanel';
import TopicTitle from '../../Recursion/TopicTiltle';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const NonRepeatingCharacter = ({
  handlePostCompletedTopic,
  hasMarkedCompleted,
  loading,
  topic_id,
  handleNext,
  handlePrevious,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='36px'
      paddingY={2}
      maxWidth='calc(100% - 300px)'
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <TopicTitle>2. First Non-Repeating Character in a Stream</TopicTitle>
        {hasMarkedCompleted(topic_id) && (
          <p
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              color: 'green',
              fontWeight: 500,
              border: '2px solid green',
              padding: '4px 10px',
              borderRadius: '20px',
              cursor: 'default',
            }}
          >
            Completed
          </p>
        )}
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        gap='24px'
      >
        <Box
          style={{
            borderBottom: 1,
            borderColor: 'divider',
            width: '34rem',
            background: '#FEF7FF',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab
              label='Problem Statement'
              {...a11yProps(0)}
            />
            <Tab
              label='Brute Force Approach'
              {...a11yProps(1)}
            />
            <Tab
              label='Queue Based Approach'
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel
          value={value}
          index={0}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='10px'
          >
            <p>
              You are given a stream of characters one by one and are tasked to
              find the first non-repeating character in the stream at any point.
              If there are no non-repeating characters, return -1.
            </p>

            <p style={{ fontSize: '20px' }}>Example:</p>
            <p>Input</p>
            <p>stream = ['a', 'b', 'a', 'c', 'b', 'd']</p>
            <p>Output</p>
            <p>['a', 'a', 'b', 'b', 'c', 'c']</p>
            <p>
              <strong>Explanation:</strong>
            </p>

            <div style={{ fontFamily: 'Arial, sans-serif', fontSize: '16px' }}>
              <ul
                style={{
                  listStyleType: 'disc',
                  marginLeft: '20px',
                  fontSize: '16px',
                }}
              >
                <li>
                  After processing 'a': 'a' is the first non-repeating
                  character.
                </li>
                <li>
                  After processing 'b': 'a' is still the first non-repeating
                  character.
                </li>
                <li>
                  After processing 'a': 'b' becomes the first non-repeating
                  character.
                </li>
                <li>
                  After processing 'c': 'b' remains the first non-repeating
                  character.
                </li>
                <li>
                  After processing 'b': 'c' becomes the first non-repeating
                  character.
                </li>
                <li>
                  After processing 'd': 'c' is still the first non-repeating
                  character.
                </li>
              </ul>
            </div>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={1}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='20px'
          >
            <p>
              For every new character in the stream, traverse the entire
              processed stream to find the first character that has occurred
              exactly once.
            </p>
            <Box
              style={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '800px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`function first_non_repeating_bruteforce(stream):
    result = []
    for i from 0 to length of stream:
        found = false
        for j from 0 to i:
            count = 0
            for k from 0 to i:
                if stream[j] == stream[k]:
                    count += 1
            if count == 1:
                append stream[j] to result
                found = true
                break
        if not found:
            append -1 to result
    return result 
`}
              </pre>
            </Box>

            <p style={{ paddingLeft: '1.5rem' }}>
              <b>Time and Space Complexity</b>
              <br />
              Since we are traversing the entire string again and again
              resulting in a nested loops
              <br />
              <br />
              Time Complexity :O(n^2)<br></br>
              Space Complexity : O(1)
            </p>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={2}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='20px'
          >
            <Box
              display='flex'
              flexDirection='column'
              gap='8px'
            >
              <p>
                Use a queue to keep track of potential non-repeating characters,
                and use a frequency dictionary to count occurrences. The first
                character in the queue is guaranteed to be the first
                non-repeating character if its count is 1.
              </p>
            </Box>
            <Box>
              <Box
                style={{
                  backgroundColor: 'black',
                  padding: '24px',
                  borderRadius: '10px',
                  width: '800px',
                  marginLeft: '1em',
                }}
              >
                <pre style={{ color: 'white', margin: 0 }}>
                  {`
function first_non_repeating_optimal(stream):
queue = empty queue
freq = empty map
result = []

for char in stream:
    # Increment the frequency of the character
    if char not in freq:
        freq[char] = 0
    freq[char] += 1
    
    # Add the character to the queue
    append char to queue
    
    # Remove characters from the front of the queue if their frequency > 1
    while queue is not empty and freq[queue[0]] > 1:
        pop from queue front
    
    # Append the first non-repeating character or -1 if none exists
    if queue is not empty:
        append queue[0] to result
    else:
        append -1 to result

return result

`}
                </pre>
              </Box>
              <br />
              <p style={{ paddingLeft: '1.5rem' }}>
                <b>Time and Space Complexity</b>
                <br />
                Although we still need to traverse the whole line for each
                person, the simulation process becomes easier with the
                implementation of the queue.
                <br /> <br />
                Time Complexity : O(n)<br></br>
                Space Complexity : O(n)<br></br>
              </p>
            </Box>
          </Box>
        </CustomTabPanel>
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        alignSelf='end'
        justifyContent='space-between'
        gap='12px'
      >
        {!hasMarkedCompleted(topic_id) && (
          <LoadingButton
            variant='contained'
            onClick={() => handlePostCompletedTopic(topic_id)}
            loading={loading}
            loadingPosition='center'
            children='Mark as completed'
            style={{
              width: '170px',
              // backgroundColor: 'transparent',
              borderRadius: '8px',
              border: '1px solid rgba(64, 96, 245, 0.5)',
            }}
          />
        )}

        <Box
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Button
            style={{ gap: '4px' }}
            onClick={handlePrevious}
          >
            <WestIcon /> Previous
          </Button>

          <Button
            style={{ gap: '4px' }}
            onClick={handleNext}
          >
            Next <EastIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default NonRepeatingCharacter;
