import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  problems: {}, // Store problems as an object, not an array
};

const mindmapProblemsSlice = createSlice({
  name: 'mindmapProblems',
  initialState,
  reducers: {
    setProblem: (state, action) => {
      state.problems = action.payload; // Store fetched problems as an object
    },
    resetProblem: (state) => {
      state.problems = {}; // Reset to an empty object
    },
  },
});

export const { setProblem, resetProblem } = mindmapProblemsSlice.actions;
export default mindmapProblemsSlice.reducer;
