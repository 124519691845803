import React from 'react';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
} from '@material-ui/core';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';
import { FiberManualRecord } from '@mui/icons-material';
import EastIcon from '@mui/icons-material/East';
import ListRenderComponent from './ListRenderComponent';
import { TricksWithBitsConst } from '../../utils/techniqueSagaConstant';
import ParagraphBox from './ParagraphBox';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

// components
import CustomButton from '../../practice/MindMaps/Atoms/CustomButton';
import BinarySearch1 from '../../../assets/BinarySearch1.png';
import BinarySearch2 from 'src/assets/BinarySearchh2.png';
import BinarySearch3 from 'src/assets/BinarySearch3.png';
import BinarySearch4 from 'src/assets/BinarySearch4.png';
import BinarySearch5 from 'src/assets/BinarySearch5.png';

const Introduction = ({
  handlePostCompletedTopic,
  hasMarkedCompleted,
  loading,
  topic_id,
  handleNext,
}) => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='20px'
      paddingY={2}
      maxWidth='calc(100% - 300px)'
    >
      <Box>
        <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
          TECHNIQUE 7
        </Typography>

        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='center'
        >
          <Typography
            sx={{
              fontSize: '48px',
              fontWeight: 600,
              lineHeight: '58px',
              letterSpacing: '0.01em',
              textAlign: 'left',
            }}
          >
            Binary Search
          </Typography>
          {hasMarkedCompleted(topic_id) && (
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
                color: 'green',
                fontWeight: '500',
                border: '2px solid green',
                padding: '4px 10px',
                borderRadius: '20px',
                cursor: 'default',
              }}
            >
              Completed
            </Typography>
          )}
        </Box>
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        gap='30px'
      >
        <Typography sx={{ fontSize: '22px', fontWeight: 400 }}>
          Introduction
        </Typography>
        <ParagraphBox>
          <Typography sx={{ fontSize: '20px', fontWeight: 400 }}>
            Binary Search
          </Typography>
          <Typography>
            Binary Search is a foundational algorithm widely used in programming
            for its efficiency in locating elements within sorted collections.
            Think of it as a game of "Guess the Number" where, instead of
            guessing randomly, you strategically reduce the possible range in
            half with each guess. This method makes it possible to find a target
            value incredibly fast compared to checking each item one by one.
          </Typography>
          <Typography>
            The real beauty of Binary Search lies in its simplicity and power.
            By leveraging the sorted order of data, Binary Search quickly zeroes
            in on the desired value by repeatedly dividing the search range.
            This makes it a highly efficient solution to many problems where
            linear search would be too slow, such as in large datasets. Imagine
            searching a word in a dictionary: rather than scanning each page
            sequentially, you open it around the middle, check the name, and
            immediately know whether to search in the front or back half. Binary
            search is same as searching in dictionary.
          </Typography>
          <Typography>
            Binary Search is more than just a search tool—it's a versatile
            technique that forms the backbone of numerous algorithms and data
            structures, from locating elements in arrays to optimizing certain
            types of decisions. Whether applied to find specific values, search
            for insertion points, or identify elements satisfying certain
            conditions, Binary Search is an essential skill that can save
            computation time and improve the performance of your code
            significantly.
          </Typography>
        </ParagraphBox>
        <ParagraphBox>
          <Typography sx={{ fontSize: '20px', fontWeight: 400 }}>
            What is Binary Search?
          </Typography>
          <Typography>
            Binary Search works by keeping track of two pointers—typically
            referred to as low and high—which represent the current search
            bounds within the array or list. Initially, low is set to the
            beginning of the array and high to the end. At each step, the
            algorithm calculates the midpoint (mid) of this range, checks if the
            middle element matches the target, and narrows the search range
            based on the result:
          </Typography>
          <Box sx={{ paddingLeft: '36px' }}>
            <ul>
              <li>
                <Typography variant='body1'>
                  If the middle element is equal to the target, the search is
                  complete.
                </Typography>
              </li>
              <li>
                <Typography variant='body1'>
                  If the target is smaller than the middle element, the
                  algorithm shifts high to mid - 1, narrowing the range to the
                  left half.
                </Typography>
              </li>
              <li>
                <Typography variant='body1'>
                  If the target is larger, the algorithm shifts low to mid + 1,
                  narrowing the range to the right half.
                </Typography>
              </li>
            </ul>
          </Box>
          <Typography>
            This approach reduces the size of the search range exponentially,
            achieving logarithmic efficiency. By strategically adjusting the
            pointers (low and high), Binary Search quickly hones in on the
            target element or concludes that it is not present in the dataset.
            Let us try to understand this using an example.
          </Typography>
        </ParagraphBox>
      </Box>
      <Box>
        <Typography sx={{ fontSize: '22px', fontWeight: 400, lineHeight: '2' }}>
          Example Usage
        </Typography>
        <Typography>
          Let's consider a scenario where we have a sorted array, and we need to
          determine if a target value exists in the array. Binary search is
          particularly suited for this problem, as it allows us to locate the
          target element (or confirm its absence) with high efficiency.
        </Typography>
        <Typography>
          Given a sorted array<b> arr = [2, 5, 8, 12, 16, 25, 41] </b>and a
          target value<b>target = 25</b> , determine if the target exists in the
          array and return its index if it does. Otherwise, return -1.
        </Typography>
        <br></br>
        <Typography sx={{ fontSize: '22px', fontWeight: 400, lineHeight: '2' }}>
          <b>Linear Search</b>
        </Typography>
        <Typography>
          It is pretty obvious how we can do this using linear search, we will
          just iterate on the array see if there is a match if there is one we
          will return the index else if the search ends and we find nothing we
          will return -1 saying it does not exist.
        </Typography>
        <br />
        <Box
          sx={{
            backgroundColor: 'black',
            padding: '24px',
            borderRadius: '10px',
            width: '800px',
          }}
        >
          <pre style={{ color: 'white', margin: 0 }}>
            {`LinearSearch(array, target):

    for i = 0 to array.length - 1:
        if array[i] == target:
            return i          // Target found at index i

    return -1                 // Target not found



`}
          </pre>
        </Box>
        <br />
        <Typography sx={{ paddingLeft: '1.5rem' }}>
          Since in the worst case it could take us to traverse the whole array,
          the time complexity would go O(n).
        </Typography>
        <br />
        <Typography sx={{ fontSize: '22px', fontWeight: 400, lineHeight: '2' }}>
          <b>Binary Search</b>
        </Typography>
        <Typography>
          We can use the fact that we have a sorted dataset to optimize our
          search. Going back to the dictionary analogy, we won’t search for a
          word in dictionary by sequentially turning pages but opening a page
          from the middle, if the word happens to be lexicographically bigger we
          will search in the front pages and if it is smaller we will search on
          the pages before middle. We keep doing this until we find the word.
          The reason we could do this is because we know that it is
          alphabetically sorted and that is going to be the intuition here as
          well.
        </Typography>
        <br />{' '}
        <Typography sx={{ paddingBottom: '10px' }}>
          Set two pointers, low to the start of the array (index 0) and high to
          the end of the array (index 9). Also, calculate the mid by using mid =
          (low+high)/2.
        </Typography>
        <img
          src={BinarySearch1}
          alt=''
          style={{ height: '50px', width: '840px' }}
        />
        <br />
        <Typography>
          Now compare the element at mid with your target value. Following cases
          could happen when you do that:
        </Typography>
        <br />
        <Typography style={{ paddingLeft: '10px', paddingBottom: '10px' }}>
          1. mid=target, in this case you found the target and your search will
          end.
        </Typography>
        <img
          src={BinarySearch2}
          alt=''
          style={{ height: '50px', width: '800px', paddingLeft: '15px' }}
        />
        <br />
        <Typography style={{ paddingLeft: '10px', paddingBottom: '10px' }}>
          {
            '2. mid>target, in this case since the array is sorted and you know that mid is greater, that means the element will never be present in the right half and you should check in the left half.'
          }
        </Typography>
        <img
          src={BinarySearch3}
          alt=''
          style={{ height: '50px', width: '800px', paddingLeft: '15px' }}
        />
        <br />
        <Typography style={{ paddingLeft: '10px', paddingBottom: '10px' }}>
          {
            '3. mid<target, in this case since the array is sorted and you know that mid is smaller, that means the element will never be present in the left half and you should check in left half'
          }
        </Typography>
        <img
          src={BinarySearch4}
          alt=''
          style={{ height: '50px', width: '800px', paddingLeft: '15px' }}
        />
        <br />
        <Typography sx={{ paddingBottom: '10px' }}>
          In our case, since 25 is greater than mid we will go with the third
          case. Now if you observe we just have to deal with all the elements on
          the right half and effectively this is what our search space looks
          like now:
        </Typography>
        <img
          src={BinarySearch5}
          alt=''
          style={{ height: '50px', width: '300px', paddingLeft: '10px' }}
        />
        <br />
        <Typography>
          We will repeat the same steps on the reduced search space and you will
          see that this time we found the element which is present at mid and
          thus we will return its index. In case the element was not present we
          would return -1 but for that we also need a way to know when our
          search ends. We can say that our search would end anytime our low
          crosses the high pointer which would represent that we are looking at
          the same elements we did before.
        </Typography>
        <br />
        <Typography sx={{ fontSize: '22px', fontWeight: 400, lineHeight: '2' }}>
          <b>
            <h3>Alternate way of finding mid</h3>
          </b>
        </Typography>
        <Typography sx={{ paddingBottom: '10px' }}>
          RIght now we used (low+high)/2 to find the mid, but in a more
          practical sense it could lead to problems, for example imagine if low
          and high are sufficiently high values then low + high could lead to
          integer overflow. That’s why it is actually better to find mid in the
          following way:
        </Typography>
        <Typography style={{ textAlign: 'center' }}>
          {' '}
          <b>Mid = (low) + (high-low)/2 </b>
        </Typography>
        <br />
        <Typography>Below is the code implementation for the same.</Typography>
        <br />
        <Box
          sx={{
            backgroundColor: 'black',
            padding: '24px',
            borderRadius: '10px',
            width: '800px',
          }}
        >
          <pre style={{ color: 'white', margin: 0 }}>
            {`BinarySearch(array, target):

    low = 0
    high = array.length - 1
    
    while low <= high:
        mid = (low) + (high-low)//2
        
        if array[mid] == target:
            return mid           // Target found at index mid
        elif array[mid] < target:
            low = mid + 1        // Search in the right half
        else:
            high = mid - 1       // Search in the left half
    
    return -1                    // Target not found

`}
          </pre>
        </Box>
        <br />
        <Typography>
          After each iteration the search space gets reduced by half. Let’s say
          the initial search space is n and in the worst case we will keep going
          until n is equal to 1. Assume that n becomes 1 after some k
          iterations.
        </Typography>
        <br />
        <TableContainer
          component={Paper}
          sx={{
            maxWidth: 600,
            margin: 'auto',
            border: '1px solid black',
            borderRadius: '0', // Removes border radius
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  align='center'
                  sx={{ border: '1px solid black', fontWeight: 'bold' }}
                >
                  Iteration
                </TableCell>
                <TableCell
                  align='center'
                  sx={{ border: '1px solid black', fontWeight: 'bold' }}
                >
                  Search Space
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  align='center'
                  sx={{ border: '1px solid black' }}
                >
                  1st
                </TableCell>
                <TableCell
                  align='center'
                  sx={{ border: '1px solid black' }}
                >
                  n = n/2<sup>0</sup>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align='center'
                  sx={{ border: '1px solid black' }}
                >
                  2nd
                </TableCell>
                <TableCell
                  align='center'
                  sx={{ border: '1px solid black' }}
                >
                  n/2 = n/2<sup>1</sup>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align='center'
                  sx={{ border: '1px solid black' }}
                >
                  3rd
                </TableCell>
                <TableCell
                  align='center'
                  sx={{ border: '1px solid black' }}
                >
                  n/4 = n/2<sup>2</sup>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align='center'
                  sx={{ border: '1px solid black' }}
                >
                  kth
                </TableCell>
                <TableCell
                  align='center'
                  sx={{ border: '1px solid black' }}
                >
                  n/2<sup>k</sup>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <br></br>
        <Typography>
          Remember that we assumed that after this k iteration, search space
          becomes 1.
          <br />
          So, n/2^k = 1 or n=2^k, taking log on both sides we get:
          <br />K = log n, and we took k as the number of iterations, so in the
          worst case our TC will be O(log n). No space is utilized to do this so
          space complexity is O(1).
        </Typography>
      </Box>
      <Box>
        <Typography sx={{ fontSize: '22px', fontWeight: 400, lineHeight: '2' }}>
          Upper and Lower Bound
        </Typography>
        <Typography>
          Another application of binary search is to find the upper and lower
          bound of an element in sorted space. Upper Bound is the position of
          the first element in a sorted array that is greater than the target.
          If all elements are less than or equal to the target, the upper bound
          will be the length of the array. Lower Bound is the position of the
          first occurrence of a target value in a sorted array, or if the target
          is not present, the index where the target could be inserted while
          maintaining sorted order.
        </Typography>
        <br />
        <Typography>
          When working with a sorted array, sometimes it’s more convenient to
          locate the range of elements that match a particular value k, rather
          than simply finding an exact match. Using lower bound and upper bound
          searches, we can determine the range of indices where k might appear
          in the array. This approach is especially useful when:
        </Typography>
        <Box sx={{ paddingLeft: '36px' }}>
          <ul>
            <li style={{ lineHeight: '2' }}>
              <Typography variant='body1'>
                You want to find the first element greater than or equal to{' '}
                <strong>k</strong> (lower bound).
              </Typography>
            </li>

            <li style={{ lineHeight: '2' }}>
              <Typography variant='body1'>
                You need to locate the first element strictly greater than{' '}
                <strong>k</strong> (upper bound).
              </Typography>
            </li>
          </ul>
        </Box>
        <Typography>
          Together, lower and upper bounds help create a half-interval in the
          array that includes all occurrences of k or determines that no such
          occurrences exist.
        </Typography>
        <br />
        <Typography>
          We can find that by doing a simple modification to our original binary
          search code, below is the implementation for it.
        </Typography>
        <br />
        <Typography>
          <b>Lower Bound</b>
        </Typography>
        <br />
        <Box
          sx={{
            backgroundColor: 'black',
            padding: '24px',
            borderRadius: '10px',
            width: '800px',
          }}
        >
          <pre style={{ color: 'white', margin: 0 }}>
            {`LowerBound(array, k):
    low = 0
    high = array.length
    
    while low < high:
        mid = (low + high) // 2
        if array[mid] < k:
            low = mid + 1
        else:
            high = mid
    
    return low  // This is the index of the lower bound of k


`}
          </pre>
        </Box>
        <br></br>
        <Typography>
          <b>Upper Bound</b>
        </Typography>
        <br />
        <Box
          sx={{
            backgroundColor: 'black',
            padding: '24px',
            borderRadius: '10px',
            width: '800px',
          }}
        >
          <pre style={{ color: 'white', margin: 0 }}>
            {`UpperBound(array, k):
    low = 0
    high = array.length
    
    while low < high:
        mid = (low + high) // 2
        if array[mid] <= k:
            low = mid + 1
        else:
            high = mid
    
    return low  // This is the index of the upper bound of k


`}
          </pre>
        </Box>
        <br />
        <Typography sx={{ paddingLeft: '1.5rem' }}>
          The time and space complexity of both of these is same as that of
          binary search algorithm because it works on the same principle.
        </Typography>
      </Box>
      <Box>
        <Typography sx={{ fontSize: '22px', fontWeight: 400, lineHeight: '2' }}>
          When to use binary search
        </Typography>
        <Typography>
          Binary Search is ideal for any problem that involves searching in a
          sorted dataset or determining the position of an element that meets
          specific criteria in an ordered sequence. But sometimes it won’t be as
          obvious if we can use binary search, here are some common use cases
          for Binary Search:
        </Typography>
        <br />
        <Box sx={{ paddingLeft: '36px' }}>
          <ul>
            <li>
              <Typography variant='body1'>
                <strong>Finding an Element in a Sorted Array:</strong> Quickly
                locate a target value in a sorted array or list.
              </Typography>
            </li>
            <li>
              <Typography variant='body1'>
                <strong>Searching in a monotonic function:</strong> There will
                be some data set which may not be strictly sorted but they would
                have a monotonic nature associated with them. If you are able to
                spot it, then you can apply binary search over that.
              </Typography>
            </li>
            <li>
              <Typography variant='body1'>
                <strong>Finding Boundaries (Lower and Upper Bounds):</strong> In
                many applications, such as finding where to insert an element,
                Binary Search helps identify the smallest or largest index where
                a condition holds true.
              </Typography>
            </li>
            <li>
              <Typography variant='body1'>
                <strong>Solving Optimization Problems:</strong> Binary Search
                can be adapted to solve problems that require searching within a
                numeric range to find the optimal solution, like minimizing or
                maximizing values within constraints.
              </Typography>
            </li>
          </ul>
        </Box>
        <br />
        <Typography>
          In the upcoming problems you will see various kinds of problems where
          binary search was applied. It is important to practice problems which
          would allow you to spot it better to which problems this powerful
          algorithm can be applied.
        </Typography>
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        alignSelf='end'
        justifyContent='space-between'
        gap='12px'
      >
        {!hasMarkedCompleted(topic_id) && (
          <LoadingButton
            variant='contained'
            onClick={() => handlePostCompletedTopic(topic_id)}
            loading={loading}
            loadingPosition='center'
            children='Mark as completed'
            style={{
              width: '170px',
              // backgroundColor: 'transparent',
              borderRadius: '8px',
              border: '1px solid rgba(64, 96, 245, 0.5)',
            }}
          />
        )}

        <Box
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Button
            sx={{ gap: '4px' }}
            onClick={handleNext}
          >
            Next <EastIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Introduction;
