// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/system';
import { useParams, useNavigate } from 'react-router-dom';
import { Stack, Typography, Button } from '@material-ui/core';
import { mindmapTopicDescriptions } from '../mindmapTopicDescriptions';
import SubTopicsList from '../Organisms/SubTopicsList';
import TopicProblems from '../Organisms/TopicProblems';
import './SubTopics.css';
import { useDispatch, useSelector } from 'react-redux';
import { slugify } from 'src/components/utils/common';
import { setSubTopics } from 'src/components/redux-store/slices/subTopicsSlice';
import {
  setSelectedSubTopic,
  selectSelectedSubTopic,
} from 'src/components/redux-store/slices/mindmapSlice';
const SubTopics = ({ problems }) => {
  const { topic } = useParams();
  const { subTopic } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Fetch subTopics from Redux state
  const subTopics = useSelector((state) => state.subTopics.subTopics);
  const selectedSubTopic = useSelector(selectSelectedSubTopic);
  // Update Redux state with subTopics for the selected topic
  useEffect(() => {
    if (topic && mindmapTopicDescriptions[topic]) {
      dispatch(setSubTopics(mindmapTopicDescriptions[topic]));
    }
  }, [topic, dispatch]);

  const currentProgram = useSelector((state) => state.programs.currentProgram);
  const programSlug = slugify(currentProgram.name);
  return (
    <Box>
      <Box
        display='flex'
        ml={4}
        mb={3}
      >
        <Typography
          style={{
            color: '#1976D2',
            cursor: 'pointer',
            textTransform: 'capitalize',
          }}
          onClick={() => {
            navigate(`/student/${programSlug}/signature-frameworks/mindmap`);
          }}
        >
          Mindmap &nbsp; &gt; &nbsp;
        </Typography>
        <Typography
          component='span'
          style={{
            color: subTopic && '#1976D2',
            cursor: 'pointer',
            textTransform: 'capitalize',
          }}
          onClick={() => {
            dispatch(setSelectedSubTopic(' '));
            navigate(
              `/student/${programSlug}/signature-frameworks/mindmap/${topic}`
            );
          }}
        >
          {topic}
        </Typography>
        {subTopic && (
          <Typography style={{ textTransform: 'capitalize' }}>
            &nbsp; &gt; &nbsp; {subTopic}
          </Typography>
        )}
      </Box>
      {topic && subTopic ? (
        <TopicProblems
          selectedSubTopic={selectedSubTopic}
          subTopics={subTopics}
          problems={problems}
        />
      ) : (
        <>
          {topic && (
            <SubTopicsList
              topic={topic}
              subTopics={subTopics}
              setSelectedSubTopic={setSelectedSubTopic}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default SubTopics;
