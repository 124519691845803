import React, { useState } from 'react';
import {
  Box,
  Typography,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
} from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import VerifiedIcon from '@mui/icons-material/Verified';
import { FiberManualRecord } from '@mui/icons-material';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import CustomTabPanel from '../CustomTabPanel';

import TopicTitle from '../../Recursion/TopicTiltle';
import ListRenderComponent from '../ListRenderComponent';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const FindPowerOfX = ({
  handlePostCompletedTopic,
  hasMarkedCompleted,
  loading,
  topic_id,
  handleNext,
  handlePrevious,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='36px'
      paddingY={2}
      maxWidth='calc(100% - 300px)'
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <TopicTitle>1.Find power of x</TopicTitle>
        {hasMarkedCompleted(topic_id) && (
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              color: 'green',
              fontWeight: '500',
              border: '2px solid green',
              padding: '4px 10px',
              borderRadius: '20px',
              cursor: 'default',
            }}
          >
            Completed
          </Typography>
        )}
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        gap='24px'
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            width: '34rem',
            background: '#FEF7FF',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab
              label='Problem Statement'
              {...a11yProps(0)}
            />
            <Tab
              label='Iterative Approach'
              {...a11yProps(1)}
            />
            <Tab
              label='Recursive Approach'
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel
          value={value}
          index={0}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              Given a number ‘x’ and a number ‘n’. Return the answer of x to the
              power of n (x^n).
            </Typography>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={1}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Typography>
              We can just iterate on the number one by one and multiply x to the
              result until we have done n number of iteration. Below is the
              implementation for it.
            </Typography>

            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '800px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`Func power(int x, int n) {
    // Handle the case where n is 0
    if (n == 0) {
        return 1; // x^0 is 1 for any x
    }

    // Initialize result to 1
    int result = 1;

    // Multiply x, n times
    for (int i = 0; i < n; i++) {
        result *= x;
    }

    return result;
}

`}
              </pre>
            </Box>

            <Typography sx={{ paddingLeft: '1.5rem' }}>
              <b>Time and Space Complexity</b>
              <br />
              The time complexity is O(n). This is because the function uses a
              loop that runs n times apart from that no extra space is used.
              <br />
              <br />
              Time Complexity : O(n)<br></br>
              Space Complexity : O(1)
            </Typography>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={2}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Box
              display='flex'
              flexDirection='column'
              gap='8px'
            >
              <Typography sx={{ fontSize: '22px', fontWeight: 400, mb: '8px' }}>
                The recursive steps that we defined earlier let us try to see
                the problem from that perspective:
              </Typography>
              <Typography style={{ paddingLeft: '1.5em' }}>
                <b>
                  1. Let us first try to break down the problem into smaller
                  subproblems. So, one way to break that down would be.
                </b>
                <br></br> &nbsp;&nbsp;x^n = x * x * x .... * x (n times)
                <br></br>
                &nbsp;&nbsp;So, x^n = x * x^(n-1).
              </Typography>
              <Typography style={{ paddingLeft: '1.5em' }}>
                But, can there be another way to break it down into smaller
                steps?
              </Typography>

              <Typography style={{ paddingLeft: '1.5em' }}>
                <b>
                  2. Next we have to define a base case, to figure that out let
                  us look for a case for which the answer is always known. There
                  could be two ways to define a base case over here.
                </b>
                <br></br>
                &nbsp;&nbsp;x^1 = x OR x^0 = 1
              </Typography>

              <Typography style={{ paddingLeft: '1.5em' }}>
                You can pick any one of them.
              </Typography>
              <Typography style={{ paddingLeft: '1.5em' }}>
                <b>
                  3. Calculate the value to smaller sub problem which would be
                  x^(n-1).
                </b>
              </Typography>
              <Typography style={{ paddingLeft: '1.5em' }}>
                <b>
                  4. Combine the values to find the output of the larger
                  problem.
                </b>
              </Typography>
            </Box>
            <Box>
              <Typography style={{ paddingLeft: '1.5em' }}>
                Now, let us look at this in terms of code.<br></br>
                <br></br>
              </Typography>
              <Box
                sx={{
                  backgroundColor: 'black',
                  padding: '24px',
                  borderRadius: '10px',
                  width: '800px',
                  marginLeft: '2em',
                }}
              >
                <pre style={{ color: 'white', margin: 0 }}>
                  {`
Func power(int x, int n){

    //Base Case
    if(n==1){
        Return x;
    }

    //Smaller output calculation
    Int small_output = power(x, n-1)

    //Combine the values
    Int larger_output = x*small_output

   Return larger_output;
}





`}
                </pre>
              </Box>
              <br />
              <Typography sx={{ paddingLeft: '1.5rem' }}>
                <b>Time and Space Complexity</b>
                <br />
                Since every function calls one function, the Time complexity
                would be O(n) and the recursion stack could go till n becomes 1
                so it will occupy a space of O(n) as well.
                <br />
                Time Complexity : O(n)<br></br>
                Space Complexity : O(n)<br></br>
              </Typography>
            </Box>
          </Box>
          <Box
            display='flex'
            flexDirection='column'
            gap='30px'
          >
            <Box
              display='flex'
              flexDirection='column'
              gap='8px'
            >
              <br></br>

              <Typography>
                <h3>Another approach:</h3>
              </Typography>
              <Typography>
                You could break the problem in a different way.<br></br>
                &nbsp;&nbsp;x^n = x*x*x*....x (n times)
                <br></br>
                &nbsp;&nbsp;x^n = x^(n/2) * x^(n/2) (in case n is even).
                <br />
                &nbsp;&nbsp;x^n = x* x^(n/2) * x^(n/2) (in case n is odd).
              </Typography>
            </Box>
            <Box>
              <Typography>
                Let’s use this way of breaking down the problem and write the
                code for it:<br></br>
                <br></br>
              </Typography>
              <Box
                sx={{
                  backgroundColor: 'black',
                  padding: '24px',
                  borderRadius: '10px',
                  width: '800px',
                }}
              >
                <pre style={{ color: 'white', margin: 0 }}>
                  {`
Func power(int x, int n){

    //Base Case
    if(n==0){
        Return 1;
    }

    //Smaller output calculation
    Int small_output = power(x, n/2)

    //Combine the values
    if(n%2==0){
        Return small_output * small_output;
    }

    Else{
        Return small_output * small_output * x
    }
}







`}
                </pre>
              </Box>
              <br></br>
              <Typography sx={{ paddingLeft: '1.5rem' }}>
                <b>Time and Space Complexity</b>
                <br />
                We are reducing our problem in half every time we call the
                function and each function calls only one other function, thus
                the time complexity would be O(log n) and so is the case for the
                recursion call stacks so the space complexity is also O(log n).
                <br />
                Time complexity: O(log n)<br></br>
                Space Complexity: O(log n)<br></br>
              </Typography>
            </Box>
          </Box>
        </CustomTabPanel>
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        alignSelf='end'
        justifyContent='space-between'
        gap='12px'
      >
        {!hasMarkedCompleted(topic_id) && (
          <LoadingButton
            variant='contained'
            onClick={() => handlePostCompletedTopic(topic_id)}
            loading={loading}
            loadingPosition='center'
            children='Mark as completed'
            style={{
              width: '170px',
              // backgroundColor: 'transparent',
              borderRadius: '8px',
              border: '1px solid rgba(64, 96, 245, 0.5)',
            }}
          />
        )}

        <Box
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Button
            sx={{ gap: '4px' }}
            onClick={handlePrevious}
          >
            <WestIcon /> Previous
          </Button>

          <Button
            sx={{ gap: '4px' }}
            onClick={handleNext}
          >
            Next <EastIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default FindPowerOfX;
