import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import Description from '../Atoms/Text/Description';
import { useSelector } from 'react-redux';
import { shouldShowNewStudentLayout } from 'src/components/utils/common';

const cardStyle = {
  position: 'relative',
  border: '1px solid #ccc',
  borderRadius: '18px',
  backgroundColor: '#1976D2',
  padding: '1em',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'left',
  height: '260px',
  width: '270px',
  margin: 'auto',
  transition: 'opacity 0.3s',
  cursor: 'pointer',
};
const cardStyle2 = {
  position: 'relative',
  border: '1px solid #ccc',
  borderRadius: '18px',
  backgroundColor: '#1976D2',
  padding: '0.5em',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'left',
  height: '260px',
  width: '230px',
  margin: 'auto',
  transition: 'opacity 0.3s',
  cursor: 'pointer',
};

const gifStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)', // Center the GIF
  width: '55%', // Adjust the size of the GIF
  height: 'auto', // Maintain aspect ratio
  transition: 'opacity 0.5s ease', // Add transition for smooth animation
};

const Card = ({ gif, image_count, name, description, category }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [activeSvg, setActiveSvg] = useState(1);
  const navigate = useNavigate();
  const topic = category;
  const currentProgram = useSelector((state) => state.programs?.currentProgram);
  const [showNewLayout, setShowNewLayout] = useState(null);

  useEffect(() => {
    let timer;
    if (isHovered) {
      timer = setTimeout(() => setShowContent(true), 100);
    } else {
      timer = setTimeout(() => setShowContent(false), 100);
    }
    return () => clearTimeout(timer);
  }, [isHovered]);

  useEffect(() => {
    const newLayoutFlag = shouldShowNewStudentLayout(currentProgram.programId);
    setShowNewLayout(newLayoutFlag);
  }, [currentProgram]);

  const nameStyle = {
    color: 'white',
    textAlign: 'left',
    marginBottom: '5px',
    marginTop: '0px',
    height: '20px',
    opacity: showContent ? 1 : 0,
    transition: 'opacity 0.5s ease',
  };

  const descriptionStyle = {
    color: 'white',
    textAlign: 'left',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center', // Center text vertically within the description area
    padding: '0px',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    opacity: showContent ? 1 : 0,
    transition: 'opacity 0.5s ease',
  };

  const replaceImage = () => {
    const nextSvg = (activeSvg % image_count) + 1;
    if (
      document.getElementById(`${name}_image_${activeSvg}`) != null &&
      document.getElementById(`${name}_image_${nextSvg}`) != null
    ) {
      document.getElementById(`${name}_image_${activeSvg}`).style.display =
        'none';
      document.getElementById(`${name}_image_${nextSvg}`).style.display =
        'block';
    }
    setActiveSvg(nextSvg);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      replaceImage();
    }, 1000);

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(timer);
  });

  return (
    <Box
      sx={{
        // ...cardStyle,
        ...(showNewLayout ? cardStyle2 : cardStyle),
        flexDirection: showContent ? 'column' : 'row',
        justifyContent: showContent ? 'start' : 'center',
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        const currentPath = window.location.pathname;
        const newPath = currentPath.includes(
          'signature-frameworks/dsa-visualization'
        )
          ? `${currentPath}/${topic}`
          : `signature-frameworks/dsa-visualization/${topic}`;

        navigate(newPath);
      }}
    >
      {!showContent && (
        <Typography
          variant='h6'
          sx={{
            ...nameStyle,
            opacity: !isHovered ? 1 : 0,
            display: 'hidden',
            marginTop: '0px',
          }}
        >
          {name}
        </Typography>
      )}
      {/* Display the GIF */}
      {Array(image_count)
        .fill(0)
        .map((_, index) => {
          return (
            <img
              src={`${gif}${index + 1}.svg`}
              id={`${name}_image_${index + 1}`}
              alt='Loading...'
              style={{
                ...gifStyle,
                display: 'none',
                opacity: isHovered ? 0 : 1, // Hide GIF when hovered
                transform: `translate(-50%, -50%) scale(${
                  isHovered ? 0.9 : 1
                })`, // Scale down when hovered
              }}
            />
          );
        })}

      {/* Display the name and description */}
      {showContent && (
        <>
          <Typography
            variant='h6'
            sx={nameStyle}
          >
            {name}
          </Typography>
          <Description
            description={description}
            style={descriptionStyle}
          />
        </>
      )}
    </Box>
  );
};

export default Card;
