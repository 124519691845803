import React, { useState } from 'react';
import { Box, Tabs, Tab, Button } from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import CustomTabPanel from '../CustomTabPanel';
import TopicTitle from '../../Recursion/TopicTiltle';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Subarrayzerosumimg1 from '../../../../assets/Subarrayzerosumimg1.png';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const SubarrayZeroSum = ({
  handlePostCompletedTopic,
  hasMarkedCompleted,
  loading,
  topic_id,
  handleNext,
  handlePrevious,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='36px'
      paddingY={2}
      maxWidth='calc(100% - 300px)'
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <TopicTitle>2. Subarray with zero sum</TopicTitle>
        {hasMarkedCompleted(topic_id) && (
          <p
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '4px',
              color: 'green',
              fontWeight: '500',
              border: '2px solid green',
              padding: '4px 10px',
              borderRadius: '20px',
              cursor: 'default',
            }}
          >
            Completed
          </p>
        )}
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        gap='24px'
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            width: '34rem',
            background: '#FEF7FF',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab
              label='Problem Statement'
              {...a11yProps(0)}
            />
            <Tab
              label='Brute Force Approach'
              {...a11yProps(1)}
            />
            <Tab
              label='Set Approach'
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel
          value={value}
          index={0}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='10px'
          >
            <p>
              Given an integer array nums, find if there exists a subarray (of
              at least one element) with a sum equal to zero. If such a subarray
              exists, return true; otherwise, return false.
            </p>

            <p>
              For example, in arr = {'{1, 2, -1, -1, 3}'}, the subarray{' '}
              {'{2,-1,-1}'} results in a sum zero and thus we should return
              zero.
            </p>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={1}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='20px'
          >
            <p>
              One very straightforward way of doing this would be to check every
              subarray and see if its sum is zero.
            </p>

            <Box
              sx={{
                backgroundColor: 'black',
                padding: '24px',
                borderRadius: '10px',
                width: '800px',
              }}
            >
              <pre style={{ color: 'white', margin: 0 }}>
                {`function has_zero_sum_subarray_brute_force(nums):
    n = length of nums
    
    for start from 0 to n - 1:
        current_sum = 0
        
        for end from start to n - 1:
            current_sum += nums[end]
            
            if current_sum == 0:
                return True  // Zero-sum subarray found
                
    return False  // No zero-sum subarray found


`}
              </pre>
            </Box>

            <p sx={{ paddingLeft: '1.5rem' }}>
              <b>Time and Space Complexity</b>
              <br />
              Since the approach requires checking every single subarray which
              would approximately be n^2.
              <br />
              <br />
              Time Complexity : O(n^2)<br></br>
              Space Complexity : O(1)
            </p>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={2}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap='20px'
          >
            <Box
              display='flex'
              flexDirection='column'
              gap='8px'
            >
              <p>
                Let us make an observation, let us consider the array = [1, 2,
                -1, 1, -3], one thing is clear if we ever find an element that
                is zero we can just return true. Also if the sum ever becomes
                zero then also we can easily return true. The problem is when
                the subarray is somewhere in between. Let us assume that your
                total sum till the index ‘i’ is x, then at some point at index
                ‘j’ your total sum again becomes x, this clearly means that
                along the way you must have added zero to your sum because then
                and only then can you get zero again in your sum.
              </p>
              <img
                src={Subarrayzerosumimg1}
                alt=''
                style={{ height: '250px', width: '700px', text: 'center' }}
              />

              <p>
                You will observe that one occurs again in your array, that means
                along the way the sum that was added must be zero. Let us use
                this and write a code.
              </p>

              <Box>
                <Box
                  sx={{
                    backgroundColor: 'black',
                    padding: '24px',
                    borderRadius: '10px',
                    width: '800px',
                    marginLeft: '1em',
                  }}
                >
                  <pre style={{ color: 'white', margin: 0 }}>
                    {`
function has_zero_sum_subarray(nums):
    initialize seen_sums as an empty set
    cumulative_sum = 0
    
    for each num in nums:
        cumulative_sum += num
        
        if cumulative_sum == 0 or cumulative_sum is in seen_sums:
            return True  // Zero-sum subarray found
        
        add cumulative_sum to seen_sums
    
    return False  // No zero-sum subarray found


`}
                  </pre>
                </Box>
                <br />
                <p sx={{ paddingLeft: '1.5rem' }}>
                  <b>Time and Space Complexity</b>
                  <br />
                  This approach requires us to iterate on the array just once
                  and along the way keep a track of elements in a set.
                  <br /> <br />
                  Time Complexity : O(n)<br></br>
                  Space Complexity : O(n)<br></br>
                </p>
              </Box>
            </Box>
          </Box>
        </CustomTabPanel>
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        alignSelf='end'
        justifyContent='space-between'
        gap='12px'
      >
        {!hasMarkedCompleted(topic_id) && (
          <LoadingButton
            variant='contained'
            onClick={() => handlePostCompletedTopic(topic_id)}
            loading={loading}
            loadingPosition='center'
            children='Mark as completed'
            style={{
              width: '170px',
              // backgroundColor: 'transparent',
              borderRadius: '8px',
              border: '1px solid rgba(64, 96, 245, 0.5)',
            }}
          />
        )}

        <Box
          display='flex'
          alignItems='center'
          gap='8px'
        >
          <Button
            sx={{ gap: '4px' }}
            onClick={handlePrevious}
          >
            <WestIcon /> Previous
          </Button>

          <Button
            sx={{ gap: '4px' }}
            onClick={handleNext}
          >
            Next <EastIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default SubarrayZeroSum;
